import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from "react-select";
import FloatingInput from './../../../components/FormsComponent/FloatingInput';
import FormsSelect from './../../../components/FormsComponent/FormsSelect';
import FloatingTextArea from './../../../components/FormsComponent/FloatingTextArea';
import LoginBtn from './../../../components/FormsComponent/LoginBtn';
import makeAnimated from 'react-select/animated';
import SearchBar from '../../Auth/Register/Components/SearchBar';
const animatedComponents = makeAnimated();

// class ReciverForms extends Component {
const ReciverForms = (props) => {
  const [address, setAddress] = useState("");
  const [shortCountryName, setShortCountryName] = useState("");

  const [formValues, setFormValues] = useState({
    lastname: "",
    firstname: "",
    birth: "",
    gender: "",
    country: null,
    city: null,
    phone: "",
    address: "",
    email: "",
    password: "",
  });

  const setStartDate = (date) => {
    props.gotToParentValue(date, 'date');
  }
  const handleOnChange = (value) => {
    props.gotToParentValue(value, 'phone');
  };

  const handleAddressGeocode = (addr) => {
    setAddress(addr);
    props.handleChangeDistrict(addr);
    formValues.address = addr;
    setFormValues({
      ...formValues
    });
  }

  const getProfileschedulesWithMyLocation = (coord) => {
    setShortCountryName(coord.short_countryName);
  }

  return (
    <>
      <div className="form-group user_modal_t receiverForm" style={{ textAlign: 'left' }}>

        <div className="form-row row_t">

          <div className="col-12 col-sm-6">
            <div>
              <FloatingInput
                className={'input_float_posit_t form-control input-solid'}
                classNamePlaceh={'label_float_posit_t placeholder'}
                style_f_floating_div={'form-group form-floating-label input_g_parent_2'}
                type={'text'}
                name={'lastname'}
                id={'lastname'}
                value={props.postregister.lastname}
                placeholder="Nom"
                onChange={props.handleChange}
                text_or_not={true}
                required={true}
                icon_user={true}
                class_for_img_icon_style={true}
                input_left_t={false}
                black_profile_img_t={true}
              />
            </div>

          </div>

          <div className="col-12 col-sm-6">
            <div className={classnames('form-line', { 'error': props.errors.has('firstname') })}>
              <FloatingInput
                className={'input_float_posit_t form-control input-solid'}
                classNamePlaceh={'label_float_posit_t placeholder'}
                style_f_floating_div={'form-group form-floating-label input_g_parent_2'}
                type={'text'}
                name={'firstname'}
                id={'firstname'}
                value={props.postregister.firstname}
                placeholder='Prenom'
                onChange={props.handleChange}
                text_or_not={true}
                required={true}
                input_left_t={true}
                black_profile_img_t={true}
                icon_user={true}
                class_for_img_icon_style={true}
              />
            </div>
            {props.errors.has('firstname') &&
              <p
                className="error_perso"
                id="name-error"
                htmlFor="firstname">
                {props.errors.first('firstname')}
              </p>
            }
          </div>

        </div>

        <div className="form-row form-row_2_t">

          <div className="col-12 col-sm-6">
            <div>
              <FloatingInput
                className={'input_float_posit_t form-control input-solid'}
                classNamePlaceh={'label_float_posit_t placeholder'}
                style_f_floating_div={'form-group form-floating-label input_g_parent_2'}
                type={'email'}
                name={'email'}
                id={'email'}
                value={props.postregister.email}
                placeholder='Email'
                onChange={props.handleChange}
                text_or_not={true}
                required={true}
                disabled={props.UpdateUser_bool ? true : false}
                icon_email={true}
                class_for_img_icon_style={true} //for style of email image icon
              />
            </div>
            {props.errors.has('email') &&
              <p
                className="error_perso"
                id="name-error"
                htmlFor="email">
                {props.errors.first('email')}
              </p>
            }
            {props.email_exit ?
              <p
                className="error_perso"
                id="name-error"
                htmlFor="email_exit">
                {props.email_not_val}
              </p>
              :
              ''
            }
          </div>


          <div className="col-12 col-sm-6 input_phone_regist_t marginResponsivePhone_t">
            <div>
              <label
                for="birth"
                className="label_select_t"
                style={{ 'margin-top': '8px', 'margin-left': '5%' }}>Phone
              </label>
              <PhoneInput
                className="form-control"
                country={'cm'}
                Defaultvalue={props.postregister.phone}
                value={props.postregister.phone}
                placeholder={props.postregister.phone !== '' ? props.postregister.phone : "— Téléphone"}
                onChange={handleOnChange}
              />
            </div>

          </div>
        </div>


        {/* <div className="form-row form-row_2_t">
          <div className="row justify-content-center my-auto">
            <div className="col-xxl-9 col-sm-12 col-lg-12 col-xl-9 mb-3">
              <label for="inputEmail4">Adresse</label>

              <SearchBar
                name="address"
                address={address}
                handleAddressGeocode={handleAddressGeocode}
                getProfileschedulesWithMyLocation={getProfileschedulesWithMyLocation}
              />
            </div>
          </div>
        </div> */}


        {/* <div className="form-row form-row_2_t">
                           <div className="col-12 col-sm-6">
                            <div>
                              <FormsSelect  
                                select_value={props.postregister.country_id}
                                style_f_Select_div={'form-group select_t styleFSelectRigth_div'}
                                classNameLabel={"label_select_t"}
                                name={'country'}
                                options={props.option_country}
                                placeholder={props.postregister.country !== '' ? props.postregister.country : 'Pays...' }
                                label_t={'Pays'}
                                gotToParentValue={gotToParentValue}
                              />
                            </div>
                           </div>
                            

                            <div className="col-12 col-sm-6 input_phone_regist_t">
                            <div>
                            <FloatingInput  
                                  className={'input_float_posit_t form-control input-solid'} 
                                  classNamePlaceh={'label_float_posit_t placeholder'} 
                                  style_f_floating_div={'form-group form-floating-label input_g_parent_2'}
                                  type={'text'} 
                                  name={'district'} 
                                  id={'district'} 
                                  value={props.postregister.district}
                                  placeholder={t('Quartier')} 
                                  onChange={props.handleChange} 
                                  text_or_not={true}
                                  required={true}
                                  input_left_t={true}
                                  black_profile_img_t={true}
                                  icon_district={true}
                                  class_for_img_icon_style={true}
                                />
                              </div>
                            </div>
                          </div> */}

        <div className="form-row form-row_2_t">
          <div className="col-12 col-sm-8">
            <label for="inputEmail4">Adresse</label>

            <SearchBar
              name="address"
              address={address}
              handleAddressGeocode={handleAddressGeocode}
              getProfileschedulesWithMyLocation={getProfileschedulesWithMyLocation}
            />
          </div>


          <div className="col-12 col-sm-4 input_phone_regist_t" style={{ display: 'flex', 'justify-content': 'center' }} >
            <div className="divDowload_t">
              <img
                src={require("../../../../images/plus.png")}
                alt="Image prod"
                className="imgSettingPlusRound_t"
              />
              <img
                src={require("../../../../images/downloadIcon.png")}
                alt="Image prod"
                className="imgSettingDownloadIconRound_t"
              />
            </div>
            <p className="downloadTitle_t">
              Télécharger une image
            </p>
          </div>
        </div>

      </div>
    </>
  );
}
export default ReciverForms;