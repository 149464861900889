import React from 'react';
// import Register from '../common/pages/Auth/Register/RegisterW.js';
import Register from '../common/pages/Auth/Register';
import Login from '../common/pages/Auth/Login';
import Home from '../common/pages/Home';
import Produit from '../common/pages/Produit';
import Produits from '../common/pages/Produits';
import Panier from '../common/pages/Panier';
import Demandes from '../common/pages/Demandes';
import Liaison from '../common/pages/Liaison';
import Setting from '../common/pages/Setting';
import Profil from '../common/pages/Profil';
import Commande from '../common/pages/Commande';
import Historique from '../common/pages/Historique';
// import ComingSoon from '../common/pages/ComingSoon';

const routes = [
    {
        path: '/',
        exact: true,
        auth: false,
        verify: false,
        component: Home
    },
    {
        path: '/product/:id1?/:id2?/:id3?/:id4?/:id5?/:id6?',
        exact: true,
        auth: false,
        verify: false,
        component: Produit
    },
    {
        path: '/categories/:id1?/:id2?/:id3?/:id4?/:id5?/:id6?',
        exact: true,
        auth: false,
        verify: false,
        component: Produits
    },
    {
        path: '/panier',
        exact: true,
        auth: false,
        verify: false,
        component: Panier
    },
    {
        path: '/link',
        exact: true,
        auth: false,
        verify: false,
        component: Liaison
    },
    {
        path: '/setting',
        exact: true,
        auth: false,
        verify: false,
        component: Setting
    },
    {
        path: '/setting/:idSetting',
        exact: true,
        auth: false,
        verify: false,
        component: Setting
    },
    {
        path: '/profile',
        exact: true,
        auth: false,
        verify: false,
        component: Profil
    },
    {
        path: '/profile/:idProf',
        exact: true,
        auth: false,
        verify: false,
        component: Profil
    },
    {
        path: '/orders',
        exact: true,
        auth: false,
        verify: false,
        component: Commande
    },
    {
        path: '/history',
        exact: true,
        auth: false,
        verify: false,
        component: Historique
    },
    {
        path: '/request',
        exact: true,
        auth: false,
        verify: false,
        component: Demandes
    },
    {
        path: '/register',
        exact: true,
        auth: false,
        verify: false,
        component: Register
    },
    {
        path: '/login',
        exact: true,
        auth: false,
        verify: false,
        component: Login
    }

];

export default routes;