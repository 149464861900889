import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import GoogleLogin from 'react-google-login';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loading from './../../../../components/Loading';
import LoginComponent from './../../../Auth/Login/Components/LoginComponent';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from "react-select";
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class GoToLogin extends Component {

  render() {
    const {
      t,
      errors,
      postlogin,
      eye_password_bool,
      toggle,
      handleChange,
      validateAndSubmit,
      offline,
      isLoading,
      responseGoogle,
      sign_in,
    } = this.props;
    return (
      <>
        <SweetAlert
          showCancel
          cancelBtnBsStyle={{ padding: '25px' }}
          title={this.props.modalAlertTitle}
          onConfirm={this.props.onConfirm}
          onCancel={this.props.onCancel}
          customButtons={
            <React.Fragment>

            </React.Fragment>
          }
          show={this.props.showAlert}
          type={'controlled'}
        >
          <div className="modal_p_t" >
            <span className="titleHeaderModal_t">Pour l'étape suivant</span>
            <hr />
            <div className="row">
              <div className="col col-sm-5 oauth_t">
                <span className="connexion_text2_t">
                  connectez-vous
                </span>
                <GoogleLogin
                  clientId="87278614366-jerd4n2kenrt7gqpmu06rrq94086nmj6.apps.googleusercontent.com"
                  buttonText="Se connecter avec Google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}

                />
              </div>
              <div className="col col-sm-7">
                <LoginComponent
                  errors={errors}
                  postlogin={postlogin}
                  eye_password_bool={eye_password_bool}
                  toggle={toggle}
                  handleChange={handleChange}
                  validateAndSubmit={validateAndSubmit}
                  offline={offline}
                  isLoading={isLoading}
                  sign_in={sign_in}
                  registerBtnCompte={true}
                  labelRegisterBtnCompte={true}
                  className={'btn btn_login2'}
                  lock_style={{
                    margin: '.5rem 0px 0px 0rem',
                    'z-index': '3',
                    position: 'absolute',
                    color: 'rgb(255, 255, 255)',
                    width: '20px'
                  }}
                />
              </div>
            </div>

          </div>
        </SweetAlert>
      </>
    );
  }
}
export default withTranslation()(GoToLogin);