import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import fr from 'ree-validate/dist/locale/fr';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, matchPath } from 'react-router-dom';
import { Redirect } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import AuthService from '../../../services/auth';
import ProductService from '../../../services/product';
import UsersService from '../../../services/users';
import { lightTheme, darkTheme } from '../../../js/theme';
import { GlobalStyles } from '../../../js/global';
import { changeNavigatorTheme, auth_t, prodId_t } from '../../../store/actions';


import HeaderUsers from './../../layout/HeaderUsers';
import HeaderMenu from './../../layout/HeaderMenu';
import HeaderTop from './../../layout/HeaderTop';
import BandeBlanche from './../../layout/BandeBlanche';
import AcideLeftItem from './Components/AcideLeftItem';
import AcideRightItem from './Components/AcideRightItem';
// import ReciverForms from './Components/ReciverForms';
import ReciverForms from '../../pages/Panier/Components/ReciverForms';
import HeaderUsersMobile from './../../layout/HeaderUsersMobile';
import Footer from './../../layout/Footer';
import MobileCategoriesMenu from '../../layout/HeaderComponents/MobileCategoriesMenu';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NewHeader from '../../layout/NewHeader5';

const getParams = (pathname) => {
  const macthProfile = matchPath(pathname, {
    path: `/setting/:idSetting`,
  });
  return (macthProfile && macthProfile.params) || {};
}
class Page extends Component {
  constructor(props) {
    super();
    this.validator = new ReeValidate.Validator({
      lastname: 'required',
      firstname: 'required',
      email: 'required|email',
      password: 'required|min:6',
      country: 'required',
      city: 'required',
      district: 'required',
    });
    this.validator.localize('fr', fr)
    this.state = {
      postregister: {
        lastname: '',
        firstname: '',
        email: '',
        district: '',
        phone: '',
        country_id: null,
        country: '',
        city_id: null,
        city: ''
      },
      postsProfil: {
        lastname: '',
        firstname: '',
        country_id: null,
        country: '',
        address: '',
        email: '',
        phone: '',
        password: '',
        description: '',
        newsletter: false,
        privacy_police: null,
        sexe: '',
        date: new Date(),
        cni_t: ''
      },
      errors: this.validator.errors,
      theme_bool: false,
      langBool: true,
      def_theme: '',
      checked_switch: true,
      isLoading: false,
      options_fr: [
        { value: 'en', label: "Anglais" }
      ],
      options_eng: [
        { value: 'fr', label: "Francais" }
      ],
      options_devise: [
        { value: '€', label: "Dollar" }
      ],
      toggleBool_t: false,
      date: new Date(),
      email_exit: false,
      email_not_val: '',
      option_country: [
        { value: 'cmr', label: "Cameroun" },
        { value: 'cg', label: "Congo k" }
      ],
      option_city: [
        { value: 'dla', label: "Douala" }
      ],
      eye_password_bool: true,
      showBtnTrue: false,
      offline: false,
      allCategories: []
    };
    this.theme_botton_D = this.theme_botton_D.bind(this);
    this.theme_botton_W = this.theme_botton_W.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.checked_t = this.checked_t.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.def_theme === '') {
      this.check_theme();
    }
  }
  componentDidMount() {
    this.props.dispatch(auth_t({ __iA: true, __iV: true }));
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: true });
    }
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: false });
    }
    if (this.props.i18n.language === 'fr-FR' || 'fr') {
      this.setState({ langBool: true })
    }
    if (this.props.i18n.language === 'en') {
      this.setState({ langBool: false })
    }
    this.allCountries();

    const { pathname } = this.props.location;

    const currentParams = getParams(pathname);

    this.findUserProfilById(currentParams.idSetting);
    this.getTargetuserByUserid();
    this.allCategories();
  }

  allCategories = () => {
    this.props.dispatch(ProductService.getAllCategories())
      .then((result) => {
        let data = result.data;

        const categories = this.buildCategoryHierarchy(data);

        let optionsHome = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));

        this.setState({ option_categorie: optionsHome, allCategories: categories });


        let initResp = [{ value: '', label: 'Tout' }]
        let optionsResp = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
        initResp = initResp.concat(optionsResp);
        let options = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  buildCategoryHierarchy = (data) => {
    // console.log("VBVV ", categories); return;

    const categoryMap = {};
    const hierarchy = [];

    // Map categories by slug
    data.forEach(item => {
      categoryMap[item.slug] = {
        name: item.name,
        iconUrl: item.images,
        path: item.url_slug,
        slug: item.slug,
        level: !!item.level ? parseInt(item.level) : 0,
        parent_id: item.parent_id,
        subcategories: []
      };
    });

    // Build hierarchy based on level and url_slug
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.level === 0 || category.level === '') {
        hierarchy.push(category);
      } else {
        const parentSlug = category.path.split('/')[category.level];
        if (categoryMap[parentSlug]) {
          categoryMap[parentSlug].subcategories.push(category);
        }
      }
    });

    // Update path if subcategories length is 0
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.subcategories.length > 0) {
        delete category.path;
      }
    });

    return hierarchy;
  }

  findUserById = (value) => {
    this.props.dispatch(UsersService.findUserById(value))
      .then((result) => {
        const data = result.data;
        const postsProfil = {
          lastname: data.lastname,
          firstname: data.firstname,
          country_id: data.country_id,
          country: data.country.data.name_fr,
          address: '',
          email: data.email,
          phone: data.phone,
          password: '',
          description: '',
          newsletter: false,
          privacy_police: null,
          sexe: data.gender,
          date: new Date(data.birth),
          cni_t: ''
        }
        this.setState({ postsProfil: postsProfil })
        return;
        // this.getCommandLines(data.id)
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  getTargetuserByUserid = () => {
    this.props.dispatch(UsersService.searchTargetUser(this.props.prodId_t?.__rid))
      .then((result) => {
        const data = result.data;
        const postsProfil = {
          lastname: data.lastname,
          firstname: data.firstname,
          country_id: data.country_id,
          country: data.country.data.name_fr,
          address: '',
          email: data.email,
          phone: data.phone,
          password: '',
          description: '',
          newsletter: false,
          privacy_police: null,
          sexe: data.gender,
          date: new Date(data.birth),
          cni_t: ''
        }
        this.setState({ postsProfil: postsProfil })
        return;
        // this.getCommandLines(data.id)
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  findUserProfilById = (hashUserProfil_id) => {
    this.props.dispatch(UsersService.getAllReceiverProfilesByid(hashUserProfil_id))
      .then((result) => {
        const data = result.data;
        const postsProfil = {
          lastname: data.lastname,
          firstname: data.firstname,
          email: data.email,
          district: data.district,
          phone: data.phone,
          country: '',
          country_id: null,
          city_id: null,
          city: ''
        }

        this.setState({ postregister: postsProfil })
        this.findUserById(this.props.prodId_t.__id);
        // this.getCommandLines(data.id)
      })
      .catch(({ data }) => {
        console.log('errors ', data);
        this.findUserById(this.props.prodId_t.__id);
      });
  }

  allCountries() {
    this.props.dispatch(AuthService.getAllCountries())
      .then((result) => {

        let data = result.data;
        let options = data.map((value, index) => (

          {
            value: { id: value.id, real_id: value.real_id },
            label: this.state.langBool === true ? value.name_fr : value.name_en
          }
        ));
        this.setState({
          isLoading: false,
          showAlert: false,
          alert2: null,
          option_country: options
        });
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  allCitiesCountry(value) {
    this.props.dispatch(AuthService.getCitiesCountry(value))
      .then((result) => {

        let data = result;
        let options = data.map((value, index) => (

          {
            value: value.id,
            label: value.name
          }
        ));
        this.setState({
          isLoading: false,
          showAlert: false,
          alert2: null,
          option_city: options
        });
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  handleClick(lang) {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    this.setState({ langBool: !this.state.langBool });
  }
  checked_t() {
    this.setState({ checked_switch: !this.state.checked_switch });
  }

  check_theme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // console.log('🎉 Dark mode is supported');
      this.setState({ def_theme: 'dark', theme_bool: true, checked_switch: false })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      // console.log('🎉 light mode is supported');
      this.setState({ def_theme: 'light', theme_bool: true, checked_switch: true })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
      // console.log('ciphertext ', ciphertext)
    }
  }

  // for_remove_js(e) {
  //   e.preventDefault();
  // }

  theme_botton_D(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'dark', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  theme_botton_W(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'light', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    const { postregister } = this.state;

    postregister[name] = value;
    if (name === 'email') {
      this.setState({ email_exit: false })
    }
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors, postregister })
      });
  }

  handleChangeDistrict = (val) => { 
    const name = 'district';
    const value = val;
    const { errors } = this.validator;
    const { postregister } = this.state;

    postregister[name] = value;

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors, postregister })
      });
  }

  toggle = () => {
    this.setState({ eye_password_bool: !this.state.eye_password_bool })
  }
  onToggle_t = () => {
    this.setState({ toggleBool_t: !this.state.toggleBool_t });
  }
  onLogout = () => {
    // this.setState({isLoading: true});
    this.props.dispatch(AuthService.logout())
      .then((result) => {
        this.props.history.push("/");
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  gotToParentValue = (value, label) => {
    const { postregister } = this.state;
    if (label === "Francais" || label === "French") {
      const options_fr = [{ value: 'en', label: "Anglais" }];
      const options_eng = [{ value: 'fr', label: "Francais" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("fr");
    }
    if (label === "Anglais" || label === "English") {
      const options_fr = [{ value: 'en', label: "English" }];
      const options_eng = [{ value: 'fr', label: "French" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("en");
    }
    if (label === "Dollar") {
      this.setState({
        device_data: value
      })
    }
    if (label === "date") {
      postregister['date'] = value
      this.setState({ postregister })
    }
    if (label === "phone") {
      postregister['phone'] = value
      this.setState({ postregister })
    }
    if (label === "sexe") {
      postregister['sexe'] = value
      this.setState({ postregister })
    }
    if (label === "country") {
      postregister['country'] = value
      this.setState({ postregister })
      this.allCitiesCountry(value.real_id)
    }
    if (label === "city_id") {
      postregister['city_id'] = value
      this.setState({ postregister })
    }
  }

  handleSubmit(postregisterValue) {
    this.setState({ isLoading: true });
    const { postregister } = this.state;

    // let data = {
    //   name: postregister.lastname + ' ' + postregister.firstname,
    //   email: postregister.email,
    //   phone: postregister.phone,
    //   district: postregister.district,
    //   city_id: 19
    // }
    let data = {
      firstname: postregister.firstname,
      lastname: postregister.lastname,
      email: postregister.email,
      phone: postregister.phone,
      address: postregister.district,
      full_name: postregister.firstname+' '+postregister.lastname,
      created_by: this.props.prodId_t?.__rid,
    }
    // console.log('data Receiver ', data); return;
    navigator.onLine ? 
      // this.props.dispatch(UsersService.createReceiverProfile(data))
      this.props.dispatch(UsersService.creatorCreateUserProfile(data))
      .then((result) => {
          const postregister = {
            lastname: '',
            firstname: '',
            email: '',
            district: '',
            phone: '',
            country_id: null,
            country: '',
            city_id: null,
            city: ''
          }
          this.setState({ postregister, showBtnTrue: true })
        })
        .catch((errors) => {
          console.log('errors ', errors);
        })
      :
      this.setState({ offline: true, isLoading: false })
  }

  async validateAndSubmit(e) {
    e.preventDefault()

    const { postregister } = this.state
    const { errors } = this.validator

    const valid = this.validator.validateAll(postregister)

    if (valid) {
      this.handleSubmit(postregister)
    } else {
      this.setState({ errors })
    }
  }

  render() {
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);

    const { t } = this.props;
    let { errors, postsProfil } = this.state;

    return (
      <ThemeProvider theme={d_theme === "\"dark\"" ? darkTheme : lightTheme}>
        <>
          <GlobalStyles />
          <Helmet>
            <script src="../../../assets/js/core/popper.min.js" type="text/javascript" />
            <script src="../../../assets/js/core/bootstrap.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/chart.js/chart.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/datatables/datatables.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/bootstrap-notify/bootstrap-notify.min.js" type="text/javascript" />
            <script src="../../../assets/js/plugin/sweetalert/sweetalert.min.js" type="text/javascript" />

            <script src="../../../assets/js/atlantis.min.js" type="text/javascript" />

            <script src="../../../assets/js/setting-demo.js" type="text/javascript" />
            <script src="../../../assets/js/demo.js" type="text/javascript" />
          </Helmet>
          <div className="wrapper register_t">

          <NewHeader
              allCategories={this.state.allCategories}
              fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
              cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
              idProf={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
              // gotToParentValue={this.searchCityWithCallback}
              isShowSearch_t={false}
              // searchProducts_t={this.state.searchProducts_t}
              // searchNameWithCallback={this.searchNameWithCallback}
              onLogout={this.onLogout}
              onToggle_t={this.onToggle_t}

            // propsCustom={this.props}
            // allProductsBycategories={this.allProductsBycategories}
            // includesForAllProducts_t={this.state.includesForAllProducts_t}
            />

            <div id="side-bar"
              className={this.state.toggleBool_t ? "side-bar header-two show" : "side-bar header-two"}
            >
              <button className="close-icon-menu"
                onClick={this.onToggle_t}>
                <IconButton aria-label="close">
                  <CloseIcon />
                </IconButton>
              </button>
              <MobileCategoriesMenu
                prodId_t={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
                onLogout={this.onLogout}
                allCategories={this.state.allCategories}
                isShowSearch_t={true}
                fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
              />

            </div>

            {/* <div className="showAndHiddenRespDesk_t">
              <HeaderMenu
                langBool={this.state.langBool}
                // for_remove_js={this.for_remove_js} 
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                option_city={this.state.option_city}
                city_id={this.state.city_id}
                gotToParentValue={this.gotToParentValue}
                option_country={this.state.option_country}
                country_value={this.state.country_value}
                toggleBool_t={this.state.toggleBool_t}
                onToggle_t={this.onToggle_t}
                isAuth_t={this.props.isAuthenticated}
                onLogout={this.onLogout}
                showCountry={false}
                // idProf={this.props.prodId_t !== undefined ? this.props.prodId_t.__id : ''}
                idProf={this.props.prodId_t?.__id}
              />

              <BandeBlanche />

              <HeaderUsers
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
                isShowSearch_t={false}
              />
              <HeaderTop
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                options_fr={this.state.options_fr}
                options_eng={this.state.options_eng}
                options_devise={this.state.options_devise}
                device_data={this.state.device_data}
                gotToParentValue={this.gotToParentValue}
                logo_noir={false}
                fName={this.props.prodId_t?.__pfn ? this.props.prodId_t.__pfn : ''}
              />
            </div>
            <div className="showAndHiddenResp_t">

              <HeaderUsersMobile
                cartValue={this.props.cartValue}
                isAuthResponsive={false}
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                option_city={this.state.option_city}
                city_id={this.state.city_id}
                gotToParentValue={this.gotToParentValue}
                option_country={this.state.option_country}
                country_value={this.state.country_value}
                toggleBool_t={this.state.toggleBool_t}
                onToggle_t={this.onToggle_t}
                isAuth_t={this.props.isAuthenticated}
                onLogout={this.onLogout}
                isLoadingHeader={this.state.isLoadingHeader}
                idProf={this.props.prodId_t !== undefined ? this.props.prodId_t.__id : ''}
                showCountry={false}
              />

            </div> */}

            <div className="container-fluid containerSetting_t">

              <div className="row marg_null_t">
                <div className="col-12 col-xs-6 col-sm-4 col-md-3 col-lg-3 settingAcideLeft">
                  <div className="divProfil_t">
                    <img
                      src={require("../../../images/userProfil.png")}
                      alt="Image prod"
                      className="imgSettingRound_t"
                    />
                    <p className="profileName_t">
                      {postsProfil.firstname} {postsProfil.lastname}
                    </p>
                    <p className="nombreReceveur_t">
                      {/*128 Receveurs*/}
                      {postsProfil.country}
                    </p>
                    {this.state.offline ?
                      <span className="offlinePage_t">
                        Verifiez votre connextion internet puis réesayez
                      </span>
                      : ''
                    }
                    <a
                      href="javascript:;"
                      className="btn btnEditSetting_t"
                      // onClick={onLogout} 
                      style={{ background: '#1E2431' }}
                    >
                      Editer mon profil
                    </a>

                  </div>

                  <AcideLeftItem
                    divUserImg_t={'divUserImg_t'}
                    iconList={require("../../../images/mailBlack.png")}
                    title_t={'Email'}
                    label_t={postsProfil.email}
                    imgUserSize_t={'imgUserSize_t'}
                    settingTextUserInfoAL_t={'settingTextUserInfoAL_t '}
                    settingTitleUserInfoAL_t={'settingTitleUserInfoAL_t'}
                    showBtn={true}
                  />

                  <AcideLeftItem
                    divUserImg_t={'divUserImg_t'}
                    iconList={require("../../../images/call.png")}
                    title_t={'Téléphone'}
                    label_t={postsProfil.phone}
                    imgUserSize_t={'imgUserSize_t'}
                    settingTextUserInfoAL_t={'settingTextUserInfoAL_t '}
                    settingTitleUserInfoAL_t={'settingTitleUserInfoAL_t'}
                    showBtn={false}
                  />

                  <AcideLeftItem
                    divUserImg_t={'divUserImg_t'}
                    iconList={require("../../../images/locationBlack.png")}
                    title_t={'Localisation'}
                    label_t={`Rue , Ville, ${postsProfil.country}`}
                    imgUserSize_t={'imgUserSize_t'}
                    settingTextUserInfoAL_t={'settingTextUserInfoAL_t '}
                    settingTitleUserInfoAL_t={'settingTitleUserInfoAL_t'}
                    showBtn={false}
                  />

                  <AcideLeftItem
                    divUserImg_t={'divUserLogOutImg_t'}
                    iconList={require("../../../images/export.png")}
                    title_t={''}
                    label_t={'Se deconnecter'}
                    imgUserSize_t={'imgUserSize_t'}
                    settingTextUserInfoAL_t={'settingTextUserLogOutInfoAL_t '}
                    settingTitleUserInfoAL_t={'settingTitleUserInfoAL_t'}
                    showBtn={false}
                  />

                  <AcideLeftItem
                    divUserImg_t={'divUserStatImg_t'}
                    iconList={require("../../../images/statImg.png")}
                    title_t={'Dernier 6 mois'}
                    label_t={'18 paniers en moyenne'}
                    imgUserSize_t={'imgUserSize2_t'}
                    settingTextUserInfoAL_t={'settingTextUserStatInfoAL_t '}
                    settingTitleUserInfoAL_t={'settingTitleUserStatInfoAL_t'}
                    showBtn={false}
                  />
                </div>
                <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-9 settingAcideRight ">
                  <div className="row">
                    <div className="col col-sm-12">
                      <AcideRightItem
                        linkTo={'/setting'}
                        iconList={require("../../../images/whiteProfile.png")}
                        settingDivHeader={'settingDivHeader'}
                        label_t={'Mes contacts'}
                        settingDiv={'settingDiv'}
                        imgBool={true}
                        br_t={true}
                      />
                      <AcideRightItem
                        linkTo={'/setting'}
                        flaticon={'flaticon-mailbox'}
                        settingDivHeader={'settingDivHeader2 displayAndHiddenResp_t'}
                        label_t={'Contact'}
                        settingDiv={'settingDiv2'}
                        imgBool={false}
                        br_t={false}
                      />
                      <AcideRightItem
                        linkTo={'/'}
                        flaticon={'flaticon-home'}
                        settingDivHeader={'settingDivHeader2'}
                        label_t={'Home'}
                        settingDiv={'settingDiv2'}
                        imgBool={false}
                        br_t={false}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-sm-12 titleForm_t">
                      Ajouter un nouveau profil
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-sm-12 formSetting_t">
                      <form id="sign_on" onSubmit={this.validateAndSubmit}>
                        <div className="firstDivFormSetting_t">
                          <ReciverForms
                            errors={errors}
                            postregister={this.state.postregister}
                            handleChange={this.handleChange}
                            handleChangeDistrict={this.handleChangeDistrict}
                            gotToParentValue={this.gotToParentValue}
                            option_country={this.state.option_country}
                            option_city={this.state.option_city}
                            eye_password_bool={this.state.eye_password_bool}
                            toggle={this.toggle}
                            isLoading={this.state.isLoading}
                            // offline={this.props.offline}
                            email_exit={this.state.email_exit}
                            email_not_val={this.state.email_not_val}
                          />




                          {/* <ReciverForms
                            errors={errors}
                            postregister={this.props.postregister}
                            handleChange={this.props.handleChange}
                            handleChangeDistrict={this.props.handleChangeDistrict}
                            gotToParentValue={this.props.gotToParentValue}
                            option_country={this.props.option_country}
                            option_city={this.props.option_city}
                            eye_password_bool={this.props.eye_password_bool}
                            toggle={this.props.toggle}
                            isLoading={this.props.isLoading}
                            offline={this.props.offline}
                            email_exit={this.props.email_exit}
                            email_not_val={this.props.email_not_val}
                            userClickOnList={seacrchList.length > 0 && seacrchList}
                          /> */}

                        </div>
                        <div className="divSaveReceiverProfile_t">
                          <button className="btn saveReceiverProfile_t">
                            Enregistrer
                          </button>
                        </div>

                        {this.state.showBtnTrue ?
                          <img
                            src={require("../../../images/checkOK.png")}
                            alt="Image prod"
                            className="imgOkUserRoundReceiverSetting"
                          />
                          :
                          ''
                        }

                      </form>
                    </div>
                  </div>

                </div>
              </div>

              <div className="showAndHiddenRespDesk_t">
                <Footer />
              </div>
              <div className="footerMobil_t">
                &nbsp;&nbsp;
              </div>

            </div>

          </div>
        </>
      </ThemeProvider>
    );
  }
}
export default withTranslation()(Page);