import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const InvoiceDetails = (props) => {
    const history = useHistory();
    let dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);

    const [ttc, setTtc] = useState(0);
    const [ht, setHt] = useState(0);
    const [tauxInteret, setTauxInteret] = useState(props.interest_rate);

    const { t, i18n } = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        sumTtcAndHt2();
    }, [props]);

    useEffect(() => {
        if (typeof props.onHandleParentStateTotal === 'function') {
            props.onHandleParentStateTotal(ht, tauxInteret, ttc)
        }
        // else {
        //     console.warn("onHandleParentStateTotal is not a function");
        // }
    }, [ht, tauxInteret, ttc]);

    const sumTtcAndHt = () => {

        let ht = props.showCarteadhesionGS ?
            props.isSocialAssistanceOption ?
                parseFloat((props.sum_t + 2 + parseInt(props.deliveryPrice) + ((props.sum_t * parseInt(props.selectedValue)) / 100)).toFixed(2))
                :
                parseFloat((props.sum_t + 2 + parseInt(props.deliveryPrice)).toFixed(2))
            :
            parseFloat((props.sum_t + parseInt(props.deliveryPrice)).toFixed(2))

        let htArrondie = parseFloat(ht).toFixed(2)


        let tauxInteret = props.isSocialAssistanceOption ?
            (parseFloat((props.sum_t + 2 + parseInt(props.deliveryPrice) + ((props.sum_t * parseInt(props.selectedValue)) / 100)).toFixed(2)) * 10) / 100
            :
            (parseFloat((props.sum_t + 2 + parseInt(props.deliveryPrice)).toFixed(2)) * 8) / 100

        let tauxInteretArrondie = parseFloat(tauxInteret).toFixed(2)


        let ttc = props.showCarteadhesionGS ?
            props.isSocialAssistanceOption ?
                parseFloat((props.sum_t + 2 + parseInt(props.deliveryPrice) + ((props.sum_t * parseInt(props.selectedValue)) / 100)).toFixed(2)) +
                (parseFloat((props.sum_t + 2 + parseInt(props.deliveryPrice) + ((props.sum_t * parseInt(props.selectedValue)) / 100)).toFixed(2)) * 10) / 100
                :
                parseFloat((props.sum_t + 2 + parseInt(props.deliveryPrice)).toFixed(2)) +
                (parseFloat((props.sum_t + 2 + parseInt(props.deliveryPrice)).toFixed(2)) * 8) / 100
            :
            parseFloat((props.sum_t + parseInt(props.deliveryPrice)).toFixed(2))

        let ttcArrondie = parseFloat(ttc).toFixed(2)

        setHt(htArrondie);
        setTauxInteret(tauxInteretArrondie);
        setTtc(ttcArrondie);
    }



    const sumTtcAndHt2 = () => {
        // totalHtArrondie: productObject.price + treatment_costs + social_assistance + delivery
        // social_assistance = (props.sum_t * parseInt(props.selectedValue)) / 100
        let ht = parseFloat((props.sum_t + parseInt(props.treatment_costs) + (props.social_assistance) + parseInt(props.delivery)).toFixed(2))

        // let ht = props.showCarteadhesionGS ?
        //     props.isSocialAssistanceOption ?
        //         parseFloat((props.sum_t + 2 + parseInt(props.deliveryPrice) + ((props.sum_t * parseInt(props.selectedValue)) / 100)).toFixed(2))
        //         :
        //         parseFloat((props.sum_t + 2 + parseInt(props.deliveryPrice)).toFixed(2))
        //     :
        //     parseFloat((props.sum_t + parseInt(props.deliveryPrice)).toFixed(2))

        let htArrondie = parseFloat(ht).toFixed(2)

        //   interest_rate = parseFloat(parseFloat(amountCMD) - (parseFloat(productObject.price) + parseFloat(data.command.data.treatment_costs) + parseFloat(data.command.data.social_assistance) + parseFloat(data.command.data.delivery))).toFixed(2) : 0,

        let tauxInteret = props.isSocialAssistanceOption ?
            // (parseFloat((props.sum_t + parseInt(props.treatment_costs) + ((props.sum_t * parseInt(props.selectedValue)) / 100) + parseInt(props.delivery)).toFixed(2)) * 10) / 100
            (ht * 10) / 100
            :
            (parseFloat((props.sum_t + parseInt(props.treatment_costs) + parseInt(props.delivery)).toFixed(2)) * 8) / 100

        let tauxInteretArrondie = parseFloat(tauxInteret).toFixed(2)

        let ttc = props.showCarteadhesionGS ?
            props.isSocialAssistanceOption ?
                ht + (ht * 10) / 100
                :
                parseFloat((props.sum_t + parseInt(props.treatment_costs) + parseInt(props.delivery)).toFixed(2)) +
                (parseFloat((props.sum_t + parseInt(props.treatment_costs) + parseInt(props.delivery)).toFixed(2)) * 8) / 100
            :
            parseFloat((props.sum_t + parseInt(props.deliveryPrice)).toFixed(2))

        let ttcArrondie = parseFloat(ttc).toFixed(2)

        setHt(htArrondie);
        setTauxInteret(tauxInteretArrondie);
        setTtc(ttcArrondie);
    }


    return (
        <>
            {/* <Header
                t={t}
                changeLanguage={changeLanguage}
            /> */}

            <div className={props.classNameFirstDiv}>
                <span className='childInvoiceDetail'>
                    <p className="p1_t">
                        <span className="al_first_span_t">
                            {props.sumQtys} articles
                        </span>
                        <span className="ar_first_span_t">
                            {parseFloat((props.sum_t).toFixed(2))} €
                        </span>
                    </p>
                    {
                        props.showCarteadhesionGS ?
                            <p className="p1_t">
                                <span className="al_first_span_t">
                                    Frais de traitement
                                </span>
                                <span className="ar_first_span_t">
                                    {/* 2 € */}
                                    {props.treatment_costs} €
                                </span>
                            </p>
                            :
                            ""
                    }
                    {
                        props.isSocialAssistanceOption ?
                            <p className="p1_t">
                                <span className="al_first_span_t">
                                    aide social
                                </span>
                                <span className="ar_first_span_t">
                                    {props.social_assistance} €
                                    {/* {parseFloat(((props.sum_t * parseInt(props.selectedValue)) / 100).toFixed(2))} € */}
                                </span>
                            </p>
                            :
                            ""
                    }
                    <p className="p2_t">
                        <span className="al_first_span_t">
                            Livraison
                        </span>
                        <span className="ar_first_span_t">
                            {props.delivery !== 0 ? props.delivery : "gratuit"}
                            {/* {parseInt(props.deliveryPrice) !== 0 ? props.deliveryPrice : "gratuit"} */}
                        </span>
                    </p>
                    <hr />
                    <p className="p1_t">
                        <span className="al_first_span_t">
                            Total (HT)
                        </span>
                        <span className="ar_first_span_t">

                            {
                                props.totalHtArrondie
                            } €
                        </span>
                    </p>

                    {
                        props.showCarteadhesionGS ?

                            <p className="p1_t">
                                <span className="al_first_span_t">
                                    Taux d'interet {props.isSocialAssistanceOption ? "(10%)" : "(8%)"}
                                </span>
                                <span className="ar_first_span_t">
                                    {props.interest_rate} €
                                    {/* { props.totalTauxInteretArrondie } */}
                                </span>
                            </p>
                            :
                            ""
                    }

                    <p className="p1_t">
                        <span className="al_first_span_t">
                            Total (TTC)
                        </span>
                        <span className="ar_first_span_t">

                            {
                                props.totalTtcArrondie

                                // props.showCarteadhesionGS ?
                                //     props.isSocialAssistanceOption ?
                                //         parseFloat((props.sum_t + 2 + props.deliveryPrice + ((props.sum_t * parseInt(props.selectedValue)) / 100)).toFixed(2)) +
                                //         (parseFloat((props.sum_t + 2 + props.deliveryPrice + ((props.sum_t * parseInt(props.selectedValue)) / 100)).toFixed(2)) * 10) / 100
                                //         :
                                //         parseFloat((props.sum_t + 2 + props.deliveryPrice).toFixed(2)) +
                                //         parseFloat(((props.sum_t + 2 + props.deliveryPrice) * 8) / 100).toFixed(2)
                                //     :
                                //     parseFloat((props.sum_t + props.deliveryPrice).toFixed(2))
                            } €
                        </span>
                    </p>


                    {props.onBtnClick !== null ?
                        <>
                            <hr />
                            <p className={props.fromCMD ? 'continuousWithoutPDifBtn2' : 'continuousWithoutPDifBtn'}>
                                {/* {props.modalEligible ? */}
                                {props.fromCMD ?
                                    <a href="javascript:;"
                                        // onClick={onShowPointRelais_t}
                                        onClick={props.onBtnClick}
                                        style={{ color: '#FFF' }}
                                        className="btn next_cart_t">
                                        Payer votre facture
                                    </a>
                                    :
                                    props.isRelayPoint ?
                                        ""
                                        :
                                        <a href="javascript:;"
                                            // onClick={onShowPointRelais_t}
                                            onClick={props.onBtnClick}
                                            style={{ color: '#FFF' }}
                                            className="btn next_cart_t">
                                            {/* Continuer sans paiement différé */}
                                            {props.nextBtnTitle ?
                                                props.nextBtnTitle
                                                :
                                                "Continuer"
                                            }

                                        </a>


                                }

                            </p></>
                        :
                        ""}
                </span>
            </div>
        </>
    )
}

export default InvoiceDetails