import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
// import { LazyLoadImage } from 'react-image';

class ProductComponentGoodDeal extends Component {
  constructor(props) {
    super();
  }
  render() {
    const { t,
      // onDeleteProduct, 
      onUpdateProduct,
      products,
      // onShowSetting_t,
      // show_setting,
      index_t,
      onAddProduct,
      isCart_t,
      hash_id,
      setIsHovered,
      isHovered,
      indexHomeProd,
      detailPage,
      gooddeal_bool,
      new_bool
    } = this.props;

    let productsList = products.length > 0 && products.slice(0, 8).map((v, i) => {
      // console.log('viit', v, 'viit2', v.shop.data.provider.data.user.data.country.data.name_fr, v.shop.data.city.data.name, v.shop.data.provider.data.user.data.country_id);
      const imageUrl = v.annexesImages.data[0].first_annex // en production
      // en developpement
      // const modifiedImageUrl = `${imageUrl.split('//')[0]}//${imageUrl.split('//')[1].split('/')[0]}:8080${imageUrl.split('192.168.100.136')[1]}`;
      const reelPriceInEuro = parseFloat((v.price / 650).toFixed(2))
      const reductionPriceInEuro = parseFloat((v.reduction / 650).toFixed(2))

      return (
        <div
          onMouseEnter={() => setIsHovered(true, i)}
          onMouseLeave={() => setIsHovered(false, i)}
          className="col-12 col-sm-4 col-md-3 col-lg-3 padding_prod_t">

          <div class="triangleGreen"></div>

          <div class="shapeGreen">
            <span>Promo</span>
          </div>

          <div class="card card-pricing heightResponsive_t">
            <div class="card-header card_header_t" onClick={(url_slug) => detailPage(v.category.data.url_slug)}>

              <img
                src={v.annexesImages.data[0].first_annex} //en prod
                // src={modifiedImageUrl} // endev
                alt="Image prod 1"
                className="img_prod_t"
              />

            </div>
            <div class="card-body" style={{ 'margin-bottom': '20px' }}>
              <p className="showAndHiddenResp_t hcartdiv">
                <span className="coast_left">{
                  v.reduction !== 0 ?
                    // Math.round(parseInt(v.reduction) * 100 / parseInt(v.price)) + " " + v.currency 
                    Math.round(parseFloat((((v.price - v.reduction) / v.price) * 100).toFixed(2))) + ` €`
                    :
                    //  v.price + " " + v.currency
                    parseFloat((v.price / 650).toFixed(2)) + ` €`
                }

                  {/* price: ,
                  usedPrice: reduction === 0 ? parseFloat((price / 650).toFixed(2)) : parseFloat((reduction / 650).toFixed(2)),
                  reduction: parseFloat((reduction / 650).toFixed(2)), */}

                </span>
              </p>

              {/* {isCart_t && hash_id === v.id ?
              <div className="">
                <img
                  src={require("../../../../images/checkOK.png")}
                  alt="Check Ok"
                /> dans le panier
              </div>
              :
              ''
            } */}
              <span className="product_name">{v.shop.data.name.toUpperCase()}

              </span>
              <span className="product_desc productDots sameHeightItems_t">
                {v.name}
              </span>
              <span className="product_ran">
                <img
                  src={require("../../../../images/star_yellow.png")}
                  alt="Image prod 1"
                  className="star_yellow" />
                <img
                  src={require("../../../../images/star_yellow.png")}
                  alt="Image prod 1"
                  className="star_yellow" />
                <img
                  src={require("../../../../images/star_yellow.png")}
                  alt="Image prod 1"
                  className="star_yellow" />
                <img
                  src={require("../../../../images/star_white.png")}
                  alt="Image prod 1"
                  className="star_white" />
              </span>
              <p className="showAndHiddenRespDesk_t">
                {/* <span className="coast_left">{v.reduction !== 0 ? Math.round(parseInt(v.reduction)*100/parseInt(v.price))+" "+v.currency : v.price+" "+v.currency}</span>
                              <span className="coast_right">{v.reduction !== 0 ? v.price+" "+v.currency : Math.round(parseInt(v.reduction)*100/parseInt(v.price))}</span> */}
                <span className="coast_left">
                  {
                    v.reduction !== 0 ?
                      //  parseInt(v.reduction) + " " + v.currency 
                      parseFloat((v.reduction / 650).toFixed(2)) + `€`
                      :
                      parseFloat((v.price / 650).toFixed(2)) + `€`
                    //  v.price + " " + v.currency
                  }

                  {/* usedPrice: reduction === 0 ? parseFloat((price / 650).toFixed(2)) : parseFloat((reduction / 650).toFixed(2)),
                  reduction: parseFloat((reduction / 650).toFixed(2)), */}

                </span>
                <span className="coast_right">
                  {
                    v.reduction !== 0 ?
                      // v.price + " " + v.currency
                      parseFloat((v.price / 650).toFixed(2)) + `€`
                      :
                      // parseInt(v.reduction)
                      parseFloat((v.reduction / 650).toFixed(2)) + `€`
                  }
                </span>
              </p>

            </div>
            {
              (isHovered && indexHomeProd === i && gooddeal_bool === true)
                ?
                <div class="card-footer" style={{ 'margin-top': '20px' }}>
                  <a href="javascript:;"
                    onClick={(e, url_slug, hash_id, real_id, nameProd, price, reduction, quantity, image, shopName, country, city, country_real_id) => onAddProduct(e, v.category.data.url_slug, v.id, v.real_id, v.name, reelPriceInEuro, reductionPriceInEuro, v.quantity, v.image, v.shop.data.name, v.shop.data.provider.data.user.data.country.data.name_fr, v.shop.data.city.data.name, v.shop.data.provider.data.user.data.country_id)}
                    className="btn btn-block btn_basket_prod">
                    <span className="text_btn_upd_prod">
                      <ShoppingBasketIcon sx={{ fontSize: 18, margin: '10px 10px' }} />
                      AJOUTER AU PANIER</span>
                  </a>
                </div>
                :
                ''
            }
          </div>
        </div>
      )
    });
    return (
      <>
        {productsList}
      </>
    );
  }
} 
export default withTranslation()(ProductComponentGoodDeal);