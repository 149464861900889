import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class BasicInput extends Component {
	constructor(props) {
		super();
	}
	render(){
       let { t } = this.props
        	return(
                <>
                    <input 
                        type={this.props.type} 
                        value={this.props.value}
                        name={this.props.name}
                        className={this.props.className} 
                        placeholder={t(this.props.placeholder)}
                        onClick={this.props.onClick}
                        onChange={this.props.onChange}
                        aria-describedby={this.props.aria_describedby}
                        id={this.props.id}
                        checked={this.props.checked}
                        title={this.props.title}
                     />
                </>
        	);
        }
}
export default withTranslation()(BasicInput);