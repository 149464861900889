import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ProductList from './ProductList';
import InvoiceDetails from './InvoiceDetails';

class Panier extends Component {
  render() {
    const { t,
      qty_t,
      sum_t,
      onShowPointRelais_t,
      onshowCarteadhesion_t,
      cartValue,
      qtyValue_t,
      qtyValueArr_t,
      onQty_t,
      coefAndPrice,
      goToParentCoefAndPrice,
      onDeleteProduct,
      showCarteadhesionGS,
      onNotshowCarteadhesion_t,
      onShowPayment_t,
      clickOnCmd,
      montantLivraison,
      onHandleParentStateTotal,
      totalHtArrondie,
      totalTauxInteretArrondie,
      totalTtcArrondie,
      treatment_costs,
      social_assistance,
      delivery,
      amountS,
      fromCMD,
      totalHtArrondieS,
      interest_rate,
      isSocialAssistanceOption,
      onPayment
    } = this.props;
    let arrayQty = cartValue.length > 0 && cartValue.map((v, i) => v.qty);
    let sumQtys = arrayQty.length > 0 && arrayQty.reduce(function (a, b) {
      return parseInt(a) + parseInt(b);
    }, 0)

    return (
      <>
        <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-6">
          <ProductList
            cartValue={cartValue}
            qtyValue_t={qtyValue_t}
            qtyValueArr_t={qtyValueArr_t}
            onQty_t={onQty_t}
            coefAndPrice={coefAndPrice}
            goToParentCoefAndPrice={goToParentCoefAndPrice}
            onDeleteProduct={onDeleteProduct}
            sum_t={sum_t}
          />

        </div>
        <InvoiceDetails
          sumQtys={sumQtys}
          sum_t={sum_t}
          modalEligible={this.props.modalEligible}
          onshowCarteadhesion_t={onshowCarteadhesion_t}
          onShowPointRelais_t={onShowPointRelais_t}
          showCarteadhesionGS={showCarteadhesionGS}
          isSocialAssistanceOption={isSocialAssistanceOption}
          // onBtnClick={!clickOnCmd ? onNotshowCarteadhesion_t : onShowPayment_t}
          onBtnClick={
            fromCMD === true ?
              onPayment
              :
              !clickOnCmd ?
                onNotshowCarteadhesion_t
                :
                onShowPayment_t
          }
          classNameFirstDiv={'col-12 col-xs-12 col-sm-6 col-md-4 col-lg-6 acideLeftCart_t'}
          deliveryPrice={montantLivraison}
          onHandleParentStateTotal={onHandleParentStateTotal}
          totalHtArrondie={totalHtArrondie}
          totalTauxInteretArrondie={totalTauxInteretArrondie}
          totalTtcArrondie={totalTtcArrondie}

          treatment_costs={treatment_costs}
          social_assistance={social_assistance}
          delivery={delivery}
          amountS={amountS}
          fromCMD={fromCMD}
          totalHtArrondieS={totalHtArrondieS}
          interest_rate={interest_rate}
        />
      </>
    );
  }
}
export default withTranslation()(Panier);