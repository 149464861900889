import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import fr from 'ree-validate/dist/locale/fr';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import AuthService from '../../../services/auth';
import ProductService from '../../../services/product';
import Commande from '../../../services/commande';
import UsersService from '../../../services/users';
import { lightTheme, darkTheme } from '../../../js/theme';
import { GlobalStyles } from '../../../js/global';
import {
  changeNavigatorTheme, auth_t, prodId_t, showCartTab_t, cartTab_t,
  navCartStore, onClickOnCmd, cartInitTab_t, existantReceiverUser,
  economicAccessibility, socialAssistanceObject, showCarteadhesionStore, typeOfHomeLocalization
} from '../../../store/actions';


import HeaderUsers from './../../layout/HeaderUsers';
import HeaderMenu from './../../layout/HeaderMenu';
import HeaderTop from './../../layout/HeaderTop';
import BandeBlanche from './../../layout/BandeBlanche';
import CommadesComponent from './Components/CommadesComponent';
import HeaderUsersMobile from './../../layout/HeaderUsersMobile';
import Footer from './../../layout/Footer';
import MenuCategiries from '../Home/Components/MenuCategiries';
import NewHeader from '../../layout/NewHeader5';
import MobileCategoriesMenu from '../../layout/HeaderComponents/MobileCategoriesMenu';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

class Page extends Component {
  constructor(props) {
    super();
    this.state = {
      theme_bool: false,
      langBool: true,
      def_theme: '',
      checked_switch: true,
      isLoading: false,
      productHashId: '',
      options_fr: [
        { value: 'en', label: "Anglais" }
      ],
      options_eng: [
        { value: 'fr', label: "Francais" }
      ],
      options_devise: [
        { value: '€', label: "Dollar" }
      ],
      toggleBool_t: false,
      commandLines: [],
      paginationProductValue: 'commandes',
      commandes: [],
      commandesTabs: [],
      total: 8,
      pageNo: 1,
      limit: 8,
      total_pages: 0,
      current_page: 0,
      per_page: 1,
      total_t: null,
      count: 8,
      cmdVal: null,
      country_real_id: null,
      offline: false,
      tabsCatValue: 0,
      allCategories: []
    };
    this.theme_botton_D = this.theme_botton_D.bind(this);
    this.theme_botton_W = this.theme_botton_W.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.checked_t = this.checked_t.bind(this);
    // this.for_remove_js = this.for_remove_js.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.def_theme === '') {
      this.check_theme();
    }
  }
  componentDidMount() {
    this.props.dispatch(auth_t({ __iA: true, __iV: true }));
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: true });
    }
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: false });
    }
    if (this.props.i18n.language === 'fr-FR' || 'fr') {
      this.setState({ langBool: true })
    }
    if (this.props.i18n.language === 'en') {
      this.setState({ langBool: false })
    }
    // if (this.props.isAuthenticated === true) {
    this.profile();
    this.renitCardInformation();
    // }
    this.allCategories();
  }

  allCategories = () => {
    this.props.dispatch(ProductService.getAllCategories())
      .then((result) => {
        let data = result.data;

        const categories = this.buildCategoryHierarchy(data);

        let optionsHome = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));

        this.setState({ option_categorie: optionsHome, allCategories: categories });


        let initResp = [{ value: '', label: 'Tout' }]
        let optionsResp = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
        initResp = initResp.concat(optionsResp);
        let options = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  buildCategoryHierarchy = (data) => {
    // console.log("VBVV ", categories); return;

    const categoryMap = {};
    const hierarchy = [];

    // Map categories by slug
    data.forEach(item => {
      categoryMap[item.slug] = {
        name: item.name,
        iconUrl: item.images,
        path: item.url_slug,
        slug: item.slug,
        level: !!item.level ? parseInt(item.level) : 0,
        parent_id: item.parent_id,
        subcategories: []
      };
    });

    // Build hierarchy based on level and url_slug
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.level === 0 || category.level === '') {
        hierarchy.push(category);
      } else {
        const parentSlug = category.path.split('/')[category.level];
        if (categoryMap[parentSlug]) {
          categoryMap[parentSlug].subcategories.push(category);
        }
      }
    });

    // Update path if subcategories length is 0
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.subcategories.length > 0) {
        delete category.path;
      }
    });

    return hierarchy;
  }

  handleChangeTabsCatValue = (event, newValue) => {
    this.setState({ tabsCatValue: newValue });
    if (newValue === 0) {
      this.props.history.push("/");
    }
    if (newValue === 1) {
      this.props.history.push("/products/country:33+Cameroun/categorie:8?Alimentaires");
    }
    if (newValue === 2) {
      this.props.history.push("/products/country:33+Cameroun/categorie:20?Épiceries%20et%20boisson%C2%A0");
    }
    if (newValue === 3) {
      this.props.history.push("/products/country:33+Cameroun/categorie:19?Beauté%20et%20bien-être%C2%A0");
    }
    if (newValue === 4) {
      this.props.history.push("/products/country:33+Cameroun/categorie:17?Jouets,%20enfants%20et%20bébés");
    }
    if (newValue === 5) {
      this.props.history.push("/products/country:33+Cameroun/categorie:21?modes%20&%20vêtements");
    }
    if (newValue === 6) {
      this.props.history.push("/products/country:33+Cameroun/categorie:9?Electroniques");
    }
    if (newValue === 7) {
      this.props.history.push("/products/country:33+Cameroun/categorie:13?Multimedia");
    }
  };

  handleClick(lang) {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    this.setState({ langBool: !this.state.langBool });
  }
  checked_t() {
    this.setState({ checked_switch: !this.state.checked_switch });
  }

  check_theme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // console.log('🎉 Dark mode is supported');
      this.setState({ def_theme: 'dark', theme_bool: true, checked_switch: false })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      // console.log('🎉 light mode is supported');
      this.setState({ def_theme: 'light', theme_bool: true, checked_switch: true })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
      // console.log('ciphertext ', ciphertext)
    }
  }

  // for_remove_js(e) {
  //   e.preventDefault();
  // }

  theme_botton_D(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'dark', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  theme_botton_W(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'light', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }

  onToggle_t = () => {
    this.setState({ toggleBool_t: !this.state.toggleBool_t });
  }

  onLogout = () => {
    this.props.dispatch(AuthService.logout())
      .then((result) => {
        this.props.history.push("/");
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  profile = () => {
    this.props.dispatch(UsersService.profile())
      .then((result) => {
        let data = result;
        this.setState({ cmdVal: data.real_id })
        this.allCommands(data.real_id, this.state.pageNo, this.state.limit);
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            // show_t: 'show_t', 
            isLoading: false
          });
        }
      });
  }

  getCommandesParents = (dataTable2) => {
    this.setState({ commandes: dataTable2 });
  }

  getAllReceiverProfilesByid = (lengthFUP, index, hashId, dataParent, dataTable2) => {
    this.props.dispatch(UsersService.getAllReceiverProfilesByid(hashId))
      .then((result) => {
        let data = result.data;
        let obj_t = {
          name: data.name,
          phone: data.phone,
          city: dataParent.relaypoint.data.city.data.name,
          country: dataParent.relaypoint.data.city.data.country.data.name_fr,
          amount: dataParent.amount,
          id: dataParent.id,
          quantity: dataParent.quantity,
          relaypointName: dataParent.relaypoint.data.name,
          relaypointReal_id: dataParent.relaypoint.data.real_id,
          hash_receiverprofile_id: '',
          hash_receiver_id: dataParent.hash_receiver_id,
          receiver_id: dataParent.receiver_id,
          interest_rate: data.interest_rate,
          treatment_costs: data.treatment_costs,
          social_assistance: data.social_assistance,
          delivery: data.delivery
        }
        // console.log("NHX ", obj_t);
        dataTable2.push(obj_t)
        // alert(dataTable2.length);
        this.getCommandesParents(dataTable2);
        //   if (lengthFUP-1 === parseInt(index)) {
        // }

      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  findUserById = (lengthFU, index, hashId, dataParent, dataTable2) => {
    this.props.dispatch(UsersService.findUserById(hashId))
      .then((result) => {
        let data = result.data;
        let obj_t = {
          name: data.firstname + ' ' + data.lastname,
          phone: data.phone,
          city: dataParent.relaypoint.data.city.data.name,
          country: dataParent.relaypoint.data.city.data.country.data.name_fr,
          amount: dataParent.amount,
          id: dataParent.id,
          quantity: dataParent.quantity,
          relaypointName: dataParent.relaypoint.data.name,
          relaypointReal_id: dataParent.relaypoint.data.real_id,
          hash_receiverprofile_id: '',
          hash_receiver_id: dataParent.hash_receiver_id
        }
        dataTable2.push(obj_t);

        this.getCommandesParents(dataTable2);
        // if (lengthFU === parseInt(index)) {
        //     alert(dataTable2.length);
        // }

      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  renitCardInformation = () => {

    this.props.dispatch(cartInitTab_t([]));
    this.props.dispatch(economicAccessibility({
      treatment_costs: 0,
      social_assistance: 0,
      delivery: 0,
      interest_rate: 0,
      amountCMD: 0,
      fromCMD: false,
      totalHtArrondie: 0
    }));
    this.props.dispatch(socialAssistanceObject({
      isSocialAssistanceOption: false,
      paymentType: 'direct',
      isSocialPayment: false
    }))
    this.props.dispatch(onClickOnCmd({ clickOnCmd: false }));
    this.props.dispatch(showCarteadhesionStore({ showCarteadhesionGlobalState: false }));

    this.props.dispatch(typeOfHomeLocalization({
      typeOfHLocatization: "No data",
      montantLivraison: 0
    }));

  }

  allCommands = (value, pageNo, limit) => {
    this.props.dispatch(Commande.getAllCommands(value, pageNo, limit))
      .then((result) => {
        const data = result.data;
        const meta = result.meta;
        this.setState({
          current_page: meta.pagination.current_page,
          total_pages: meta.pagination.total_pages,
          per_page: meta.pagination.per_page,
          count: meta.pagination.count,
          total: meta.pagination.total,
          commandes: data,
          commandesTabs: data,
          // country_real_id: data[1].products.data[0].shop.data.provider.data.user.data.country.data.real_id
          country_real_id: data[0].products.data[0].shop.data.provider.data.user.data.country.data.real_id
        });

        // console.log('errorsTab ', data); return;


        let dataTable2 = [];
        data.map((v, i) => {
          if (v.hash_receiverprofile_id !== "") {
            // alert(`hash_receiverprofile_id ${i}`)
            this.getAllReceiverProfilesByid(data.length, i, v.hash_receiverprofile_id, v, dataTable2)
          } else {
            if (v.hash_receiver_id !== "") {
              // alert(`hash_receiver_id ${i}`); 
              // alert(v.hash_receiverprofile_id); 
              this.findUserById(data.length, i, v.hash_receiver_id, v, dataTable2)
            }
            // if (v.hash_receiver_id === '' && v.hash_receiverprofile_id === '') {
            //   let obj_t = { 
            //     name: v.user.data.name,
            //     phone: v.user.data.phone,
            //     city: v.relaypoint.data.city.data.name,
            //     country: v.relaypoint.data.city.data.country.data.name_fr,
            //     amount: v.amount,
            //     id: v.id,
            //     quantity: v.quantity,
            //     relaypointName: v.relaypoint.data.name,
            //     relaypointReal_id: v.relaypoint.data.real_id,
            //     hash_receiverprofile_id: '',
            //     hash_receiver_id: ''
            //   }
            //     dataTable2.push(obj_t)

            //   if (i+1 === data.length) {
            //     this.getCommandesParents(dataTable2);  
            //   }
            // }
          }
        });

      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  getCommandLines = (sendVal, hash_receiver_id, country_real_id, receiverName, receiver_id, amountCMD) => {
    const { commandLines } = this.state;
    this.setState({ isLoading: true, productHashId: sendVal.id });

    // this.props.dispatch(economicAccessibility({
    //   treatment_costs: 0,
    //   social_assistance: 0,
    //   delivery: 0,
    //   interest_rate: 0
    // }));

    this.props.dispatch(Commande.getCommandLines(sendVal.id))
      .then((result) => {
        let data = result.data;

        this.props.dispatch(navCartStore({
          showPr: false,
          showCart: true,
          showCarteadhesion: false,
          showReceveur: false,
          showPaiement: false,
          realCard: false
        }))
        // data.map((v, i) => {
        //   this.productsById(v.product_hash_id, v.quantity, totalQuantity, data.length, i, relaypoint, relaypointId, hash_receiver_id, country_real_id, commandeId, receiverName, receiver_id)
        // });
        const commandes = { product_id: data.product_id, quantity: data.quantity }
        commandLines.push(commandes)
        this.setState({ commandLines });
        let productObject = {};
         data.forEach(item => {
           productObject = {
            shopName: item.product.data.shop.data.name,
            productName: item.product.data.name,
            price: parseFloat((item.product.data.price / 650).toFixed(2)),
            usedPrice: item.product.data.reduction === 0 ? parseFloat((item.product.data.price / 650).toFixed(2)) : parseFloat((item.product.data.reduction / 650).toFixed(2)),
            reduction: parseFloat((item.product.data.reduction / 650).toFixed(2)),
            id: item.id,
            image: item.product.data.image,
            totalQty: item.product.data.quantity,
            qty: item.quantity,
            real_id: item.real_id,
            relaypoint: item.command.data.relaypoint.data.name,
            hash_receiver_id: hash_receiver_id,
            relaypointId: item.command.data.relaypoint.data.real_id,
            country_real_id: country_real_id,
            cmdId: item.command_id
          }
          this.props.dispatch(cartTab_t(productObject));
        });

        // let productObject = {
        //   shopName: data.product.data.shop.data.name,
        //   productName: data.product.data.name,
        //   price: parseFloat((data.product.data.price / 650).toFixed(2)),
        //   usedPrice: data.product.data.reduction === 0 ? parseFloat((data.product.data.price / 650).toFixed(2)) : parseFloat((data.product.data.reduction / 650).toFixed(2)),
        //   reduction: parseFloat((data.product.data.reduction / 650).toFixed(2)),
        //   id: data.id,
        //   image: data.product.data.image, 
        //   totalQty: data.product.data.quantity,
        //   qty: data.quantity,
        //   real_id: data.real_id,
        //   relaypoint: data.command.data.relaypoint.data.name,
        //   hash_receiver_id: hash_receiver_id,
        //   relaypointId: data.command.data.relaypoint.data.real_id,
        //   country_real_id: country_real_id,
        //   cmdId: data.command_id
        // }
        // this.props.dispatch(cartTab_t(productObject));

        const command = data[0].command.data
        this.props.dispatch(economicAccessibility({
          treatment_costs: command.treatment_costs,
          social_assistance: command.social_assistance,
          delivery: command.delivery,
          // interest_rate: data.command.data.interest_rate,
          interest_rate: parseFloat(command.treatment_costs) !== 0 ? parseFloat(parseFloat(amountCMD) - (parseFloat(productObject.price) + parseFloat(command.treatment_costs) + parseFloat(command.social_assistance) + parseFloat(command.delivery))).toFixed(2) : 0,
          amountCMD,
          fromCMD: true,
          // totalHtArrondie: productObject.price + treatment_costs + social_assistance + delivery
          totalHtArrondie: parseFloat(productObject.price) + parseFloat(command.treatment_costs) + parseFloat(command.social_assistance) + parseFloat(command.delivery)
        }));

        this.props.dispatch(socialAssistanceObject({
          isSocialAssistanceOption: true,
          paymentType: 'aide social',
          isSocialPayment: true
        }))

        this.props.dispatch(showCarteadhesionStore({ showCarteadhesionGlobalState: true }))


        this.props.dispatch(onClickOnCmd({ clickOnCmd: true }));

        this.props.dispatch(existantReceiverUser({
          receiverUserReal_id: receiver_id,
          nameChoice: receiverName
        }))

        this.props.history.push("/panier");
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }
  
  productsById = (product_hash_id, quantity, totalQuantity, length, i, relaypoint, relaypointId, hash_receiver_id, country_real_id, commandeId, receiverName, receiver_id) => {
    this.props.dispatch(cartInitTab_t([]));
    this.props.dispatch(ProductService.getProductsById(product_hash_id))
      .then((result) => {
        let data = result.data;
        let productObject = {
          shopName: data.shop.data.name,
          productName: data.name,
          price: parseFloat((data.price / 650).toFixed(2)),
          usedPrice: data.reduction === 0 ? parseFloat((data.price / 650).toFixed(2)) : parseFloat((data.reduction / 650).toFixed(2)),
          reduction: parseFloat((data.reduction / 650).toFixed(2)),
          id: data.id,
          image: data.image,
          totalQty: data.quantity,
          qty: quantity,
          real_id: data.real_id,
          relaypoint: relaypoint,
          hash_receiver_id: hash_receiver_id,
          relaypointId: relaypointId,
          country_real_id: country_real_id,
          cmdId: commandeId
        }
        this.props.dispatch(cartTab_t(productObject));
        this.props.dispatch(onClickOnCmd({ clickOnCmd: true }));



        this.props.dispatch(existantReceiverUser({
          receiverUserReal_id: receiver_id,
          nameChoice: receiverName
        }))


        if (i === length - 1) {
          this.props.history.push("/panier");
        }
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  render() {
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);

    const { t } = this.props;
    let { errors } = this.state;

    return (
      <ThemeProvider theme={d_theme === "\"dark\"" ? darkTheme : lightTheme}>
        <>
          <GlobalStyles />
          <Helmet>
            <script src="../../../assets/js/core/popper.min.js" type="text/javascript" />
            <script src="../../../assets/js/core/bootstrap.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/chart.js/chart.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/datatables/datatables.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/bootstrap-notify/bootstrap-notify.min.js" type="text/javascript" />
            <script src="../../../assets/js/plugin/sweetalert/sweetalert.min.js" type="text/javascript" />

            <script src="../../../assets/js/atlantis.min.js" type="text/javascript" />

            <script src="../../../assets/js/setting-demo.js" type="text/javascript" />
            <script src="../../../assets/js/demo.js" type="text/javascript" />
          </Helmet>
          <div className="wrapper register_t">

          <NewHeader
              allCategories={this.state.allCategories}
              fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
              cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
              idProf={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
              // gotToParentValue={this.searchCityWithCallback}
              isShowSearch_t={false}
              // searchProducts_t={this.state.searchProducts_t}
              // searchNameWithCallback={this.searchNameWithCallback}
              onLogout={this.onLogout}
              onToggle_t={this.onToggle_t}

            // propsCustom={this.props}
            // allProductsBycategories={this.allProductsBycategories}
            // includesForAllProducts_t={this.state.includesForAllProducts_t}
            />

            <div id="side-bar"
              className={this.state.toggleBool_t ? "side-bar header-two show" : "side-bar header-two"}
            >
              <button className="close-icon-menu"
                onClick={this.onToggle_t}>
                <IconButton aria-label="close">
                  <CloseIcon />
                </IconButton>
              </button>
              <MobileCategoriesMenu
                prodId_t={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
                onLogout={this.onLogout}
                allCategories={this.state.allCategories}
                isShowSearch_t={true}
                fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
              />

            </div>

            <div className="container-fluid container_demande_t marginCmdAndDmd_t">
              <div className="row marg_null_t demandeRowTitle_t">
                <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-9">
                  <span className="demandeTitle_t">
                    Mes Commandes en cours ({this.state.commandes.length})
                  </span>
                </div>
                <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-3 showAndHiddenRespDesk_t">
                  <span className="trierTitleDemande_t">Trier par :</span>
                  Récent
                </div>
              </div>

              <div className="row marg_null_t demandeMargin_t">
                <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-12">

                  <CommadesComponent
                    commandes={this.state.commandesTabs}
                    getCommandLines={this.getCommandLines}
                    isLoading={this.state.isLoading}
                    productHashId={this.state.productHashId}
                    pagination={{
                      enabled: true,
                      type: "short" // long, short
                    }}
                    pageLength={this.state.count}
                    total_pages={this.state.total_pages}
                    current_page={this.state.current_page}
                    data={this.state.commandes}
                    paginationProductValue={this.state.paginationProductValue}
                    per_page={this.state.per_page}
                    allCommands={this.allCommands}
                    cmdVal={this.state.cmdVal}
                    limit={this.state.limit}
                    country_real_id={this.state.country_real_id}
                    offline={this.state.offline}
                  />

                </div>
              </div>

              <div className="showAndHiddenRespDesk_t">
                <Footer />
              </div>
              <div className="footerMobil_t">
                &nbsp;&nbsp;
              </div>

            </div>


          </div>
        </>
      </ThemeProvider>
    );
  }
}
export default withTranslation()(Page);