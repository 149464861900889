import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import yourParents from '../../../../../../assets/img/common/yourParents.png'
import click_here from '../../../../../../assets/img/common/click_here.png'

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            styleLoading: 'loading_Modal',
        }
    }
    handleChange = (e, label) => {
        // e.preventDefault();
        // if (label === "name") {
        //   this.props.gotToParentValue(e.target.value, 'name');
        // }
        // if (label === "description") {
        //   this.props.gotToParentValue(e.target.value, 'description');
        // }
    };

    render() {
        return (
            <div className="rdv_modal_t home_modal_t">

                <SweetAlert
                    showCancel
                    cancelBtnBsStyle={{ padding: '25px' }}
                    title={'Rendez-vous'}
                    onConfirm={this.props.onConfirm}
                    onCancel={this.props.onCancel}
                    customButtons={
                        <React.Fragment>
                            {/* <button className="btn primary_p2_t" onClick={() => this.props.oncompaniesOrPers(1)}>

                                {this.props.t('vRDVCat')}

                                &nbsp;<i class="fa fa-building" aria-hidden="true"></i>
                            </button>  */}
                            <img
                                src={yourParents}
                                className='pdt0To10_t respDesktopFlex_t'
                                style={{ 'object-fit': 'contain', margin: '0 0 10% 0', width: '25%' }}
                                alt="image"
                                loading="lazy"
                            />
                            <button
                                className="btn primary_p_t respDesktopFlex_t"
                                style={{ position: 'absolute', bottom: '0' }}
                                onClick={() => this.props.oncompaniesOrPers(2, "prestation de consultation : Tarif 80 € HT")}
                            >
                                {this.props.t('vRDVCatParent')}
                                <img
                                    src={click_here}
                                    className='pdt0To10_t respDesktopFlex_t'
                                    style={{ margin: '0 0 0 3%', width: '15%' }}
                                    alt="image"
                                    loading="lazy"
                                />
                            </button>

                            <button
                                className="btn primary_p_t respFlex_t"
                                onClick={() => this.props.oncompaniesOrPers(2, "prestation de consultation : Tarif 80 € HT")}
                            >
                                {this.props.t('vRDVCatParent')}
                                &nbsp; <i class="fa fa-user" aria-hidden="true"></i>
                            </button>

                        </React.Fragment>
                    }
                    show={this.props.showAlert}
                    type={'controlled'}
                >
                    <a
                        href="javascript:;"
                        className="cancel_btn_t"
                        onClick={this.props.onCancel}
                    >
                        X
                    </a>
                    <form className="modal_rdv_t">
                        {this.props.t('vprdRDV')}
                        <hr />
                        {
                            this.props.isRdv ? "" :
                                <>
                                    <div class="form-group">
                                        <label for="exampleFormControlInput1">Nom de la zone &nbsp;&nbsp;&nbsp;&nbsp;
                                            {this.props.errorName ?
                                                <span style={{ color: 'red', padding: '0 0 0 40%' }}>
                                                    Ce champ est obligatoire
                                                </span>
                                                :
                                                ''
                                            }
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="nom de la zone"
                                            value={this.props.value}
                                            onChange={(e, label) => this.handleChange(e, 'name')} />
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Description de la zone &nbsp;&nbsp;&nbsp;&nbsp;
                                            {this.props.errorDescription ?
                                                <span style={{ color: 'red', padding: '0 0 0 20%' }}>
                                                    Ce champ est obligatoire
                                                </span>
                                                :
                                                ''
                                            }
                                        </label>
                                        <textarea
                                            value={this.props.value2}
                                            onChange={(e, label) => this.handleChange(e, 'description')}
                                            class="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows="3">
                                        </textarea>
                                    </div>
                                </>
                        }
                    </form>
                </SweetAlert>
            </div>
        );
    }
}
export default Input;