import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import fr from 'ree-validate/dist/locale/fr';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import ImageZoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import AuthService from '../../../services/auth';
import ProductService from '../../../services/product';
import { lightTheme, darkTheme } from '../../../js/theme';
import { GlobalStyles } from '../../../js/global';
import { changeNavigatorTheme, auth_t } from '../../../store/actions';


class ProduitComponent extends Component {

  onShowBig = (value) => {
    this.props.goToParentBigImg(value);
  }
  render() {
    const {
      t,
      bigImages,
      prodId_t,
      qteValue,
      onChangeValue,
      onAddCart_t,
      isCart_t
    } = this.props;
    const props = { width: 400, height: 250, zoomWidth: 500, img: require("../../../images/truck.png") };
    // const reelPriceInEuro = parseFloat((prodId_t.price / 650).toFixed(2))
    // const reductionPriceInEuro = parseFloat((prodId_t.reduction / 650).toFixed(2))
    return (
      <>
        <div className="col-12 col-xs-12 col-sm-4 col-md-3 col-lg-4 oneProduct_left_t">
          <div className="container-fluid firstDivImgCentral_t">
            <div className="divImgCentral_t">

              {this.props.offline ?
                <span className="offlinePage_t">
                  Verifiez votre connextion internet puis réesayez
                </span>
                : ''
              }

              <ImageZoom>

                <img
                  className="imgCentral_t"
                  src={bigImages}
                  width="500"
                />

              </ImageZoom>

            </div>
            <div className="row">
              <div className="col col-sm-3 divImgSmal_t divAlignLeft_t">
              </div>
              <div className="col col-sm-3 divImgSmal_t">

                {/* <img
                  className="imgSmal_t"
                  onClick={(value) => this.onShowBig(prodId_t !== null ? prodId_t.image : '')}
                  onMouseOver={(value) => this.onShowBig(prodId_t !== null ? prodId_t.image : '')}
                  src={prodId_t !== null ? prodId_t.image : ''} /> */}
                <img
                  className="imgSmal_t"
                  onClick={(value) => this.onShowBig(prodId_t !== null ? prodId_t.annexesImages.data[0].first_annex : '')}
                  onMouseOver={(value) => this.onShowBig(prodId_t !== null ? prodId_t.annexesImages.data[0].first_annex : '')}
                  src={prodId_t !== null ? prodId_t.annexesImages.data[0].first_annex : ''} />
              </div>

              {(prodId_t !== null && prodId_t.annexesImages.data.length > 0 && prodId_t.annexesImages.data[0].second_annex !== "http://api.wemaza.com/uploads/products/") ?
                <div className="col col-sm-3 divImgSmal_t">
                  <img
                    className="imgSmal_t"
                    onClick={(value) => this.onShowBig(prodId_t !== null ? prodId_t.annexesImages.data[0].second_annex : '')}
                    onMouseOver={(value) => this.onShowBig(prodId_t !== null ? prodId_t.annexesImages.data[0].second_annex : '')}
                    src={prodId_t !== null ? prodId_t.annexesImages.data[0].second_annex : ''} />
                </div>
                :
                ""
              }

              {(prodId_t !== null && prodId_t.annexesImages.data.length > 0 && prodId_t.annexesImages.data[0].third_annex !== "http://api.wemaza.com/uploads/products/") ?
                <div className="col col-sm-3 divImgSmal_t">
                  <img
                    className="imgSmal_t"
                    onClick={(value) => this.onShowBig(prodId_t !== null ? prodId_t.annexesImages.data[0].third_annex : '')}
                    onMouseOver={(value) => this.onShowBig(prodId_t !== null ? prodId_t.annexesImages.data[0].third_annex : '')}
                    src={prodId_t !== null ? prodId_t.annexesImages.data[0].third_annex : ''} />
                </div>
                :
                ""
              }

            </div>

          </div>
        </div>

        <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-6 oneProduct_center_t">
          <p className="productName_t">
            {prodId_t !== null ? prodId_t.name : ''}

            {/*EDA Jardinière Graphit - 99,5 x 39 x 43 cm - 97,6 L - Gris anthracite*/}
          </p>
          <p className="productDescription_t">
            {prodId_t !== null ? prodId_t.description : ''}

            {/*Cette jardinière Graphit de 98L apporte modernité et élégance. 
              Une jardinière qui se module parfaitement avec les autres pots 
              de fleur de la gamme Graphit ainsi que le muret. Les + de ce 
              pot de fleur : - Non gélif et anti-UV - Zone de rétention d'eau 
              intégrée à la jardinière - Usage intérieur et extérieur*/}
          </p>
          <p className="infosVente_t">
            Vendu et expédié par <span className="wemazaBold_t">Wemaza</span> <br />
            <span className="nbre_stock_t">
              En stock ! {prodId_t !== null ? prodId_t.quantity : ''} produit{prodId_t?.quantity > 1 ? 's' : ''} !
            </span>
          </p>
          <p className="productPromoPrice_t">
            {prodId_t !== null ? parseFloat((prodId_t.reduction / 650).toFixed(2)) : ''}€

            {/*35.400 xaf */}
            <br />
            <span className="productRealPrice_t">
              {prodId_t !== null ? parseFloat((prodId_t.price / 650).toFixed(2)) : ''}€
              {/*45.000 xaf*/}
            </span>
          </p>

          <span className="qte_t">
            Quantité :
            <input
              className="num_t"
              value={qteValue}
              onChange={onChangeValue}
              type="number" />
          </span>
          <a
             href="javascript:void();"
            onClick={(
              id,
              real_id,
              shopName,
              productName,
              price,
              reduction,
              image,
              qty,
              country_real_id
            ) => onAddCart_t(
              prodId_t.id,
              prodId_t.real_id,
              prodId_t.shop.data.name,
              prodId_t.name,
              parseFloat((prodId_t.price / 650).toFixed(2)),
              parseFloat((prodId_t.reduction / 650).toFixed(2)),
              prodId_t.annexesImages.data[0].first_annex,
              prodId_t.quantity,
              prodId_t.shop.data.provider.data.user.data.country_id
            )}
            className="productAddProduct">
            AJOUTER AU PANIER
          </a>

          {isCart_t ?
            <div className="imgOkProduct_t">
              <img
                src={require("../../../images/checkOK.png")}
                alt="Check Ok"
              /> Mis dans le panier
            </div>
            :
            ''
          }

        </div>

        <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-2 oneProduct_rigth_t">

        </div>
      </>
    );
  }
}
export default withTranslation()(ProduitComponent);