import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class SearchInput extends Component {

  handleChange = (e) => {
    if (e.target.name === 'searchLink_t') {
      this.props.gotToParentValue(e.target.value, 'searchR_t');
    }

    if (e.target.name === 'searchR_t') {

      this.props.gotToParentValue(e.target.value, 'searchR_t');

    }
  }

  render() {
    let { t } = this.props
    return (
      <div className="col col-xs-5 search-bar w-search" style={this.props.style_search_div}>
        <div className={this.props.className_div_2}>
          <input
            className={this.props.input_className}
            id={this.props.input_id}
            placeholder={this.props.input_placeholder}
            type={this.props.input_type}
            name={this.props.name}
            onChange={this.handleChange}
            value={this.props.searchR_t} // Search peut fonctionner sans
          />
          <button type="submit">
            {/*<i class="fas fa-search"></i>*/}
            <img
              src={require("../../../images/Search.png")}
              alt="Search"
              className={this.props.images_className} />

          </button>
        </div>
      </div>
    );
  }
}
export default withTranslation()(SearchInput);