import React, { Component, Suspense } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { withTranslation } from 'react-i18next';
import ReactPlaceholder from './../../../components/ReactPlaceholder';
import CatSelectComponent from './../../../components/CatSelectComponent';
import CatListComponent from './../../../components/CatListComponent';
import CatTrieComponent from './../../../components/CatTrieComponent';
import Footer from './../../../layout/Footer';
const ProductListComponent = React.lazy(() => import('./ProductListComponent'));

class ProductListPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: props.headers,
      data: props.data,
      pageData: props.data,
      pageLength: props.pageLength || null,
      currentPage: props.current_page || 0,
      total_pages: props.total_pages,
      per_page: props.per_page,
      total_t: props.total_t,
      totalItems: 0
    }
    this.pagination = this.props.pagination || {};
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  onPageLengthChange = (pageLength) => {
    this.setState({
      pageLength: parseInt(pageLength, 10)
    }, () => {
      this.onGotoPage(this.state.currentPage);
    });
  }
  // componentDidMount() {
  //   if (this.pagination.enabled) {
  //     this.onGotoPage(this.state.currentPage)
  //   }
  // }
  // onGotoPage = (pageNo) => {
  //   let pageData = this.getPageData(pageNo, this.state.pageLength);
  //   this.setState({
  //     pageData: pageData,
  //     currentPage: pageNo
  //   });
  //   switch (this.props.paginationProductValue) {
  //     case "product":
  //       return this.props.filterAllProducts(
  //         this.props.categoriesFucnt,
  //         this.props.country_idFucnt,
  //         this.props.city_idFucnt,
  //         this.props.price_minFucnt,
  //         this.props.price_maxFucnt,
  //         this.props.nameFucnt,
  //         pageNo,
  //         this.props.limit
  //       )
  //     default:
  //       return;
  //   }

  // }
  // getPageData = (pageNo, pageLength) => {
  //   let startOfRecord = (pageNo - 1) * pageLength;
  //   let endOfRecord = startOfRecord + pageLength;
  //   let data = this.state.data;
  //   let pageData = data.slice(startOfRecord, endOfRecord);

  //   return pageData;
  // }

  handlePageChange = () => {
    this.props.filterAllProductsInfiniscroll(
      this.props.categoriesFucnt,
      this.props.country_idFucnt,
      this.props.city_idFucnt,
      this.props.price_minFucnt,
      this.props.price_maxFucnt,
      this.props.nameFucnt,
      this.props.pageNo,
      this.props.limit
    )
  }

  render() {
    const {
      t,
      categorieLabel,
      parent_categorieLabel,
      prix_t,
      devise_t,
      onAddProduct,
      index_t,
      products,
      parent_add_categorie,
      showTrieBool_t,
      onProductId,
      homeCartvalue_t
    } = this.props;

    let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
    // console.log('option_categorie ', categorie_value);

    return (
      <div className="container-fluid container_home_products_section prop_list_t productHPage">

        <div className="row marg_null_t">
          <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-3 color_cat_t"
            //  style={{margin: '25px 0 0', padding: '5px' }}
            style={{ margin: '20px 0 0 10px', padding: '5px', 'max-width': '250px' }}
          >
            {/* <div className="showAndHiddenRespDesk_t">
              <CatSelectComponent
                select_value={categorie_value}
                style_f_Select_div={{ margin: '5px 0 0 -5px', color: '#000' }}
                name={'cat_prod'}
                options={option_categorie}
                placeholder={categorieDefault_value !== '' ? categorieDefault_value : 'Catégorie'}
                gotToParentCatValue={gotToParentCatValue}
                bool_val={this.props.bool_val}
              />
            </div> */}
            {/* <div className="showAndHiddenResp_t">
              <CatSelectComponent
                select_value={categorie_value}
                style_f_Select_div={{ margin: '-5px 0 0 -5px', color: '#000', width: '200px' }}
                name={'cat_prod_mobil'}
                options={this.props.optionsResp}
                placeholder={categorieDefault_value !== '' ? categorieDefault_value : 'Catégorie'}
                gotToParentCatValue={gotToParentCatValue}
                bool_val={this.props.bool_val}
              />
            </div> */}

          </div>
          <CatListComponent
            showResponsiveClass={"cat_list_t showAndHiddenRespDesk_t"}
            categorieLabel={categorieLabel.length > 0 ? categorieLabel.slice(0, 4) : categorieLabel}
            parent_categorieLabel={parent_categorieLabel}
            parent_add_categorie={parent_add_categorie}
          />
        </div>

        <div className="row marg_the_band_on_top_t color_cat_t justify_content_center_t">

          <Stack sx={{ width: '50%', position: 'absolute', top: '15rem' }} spacing={2}>
            {/* {this.props.open && ( */}
            {this.props.showAlertHProd && (
              <Alert
                variant="filled"
                severity="success"
                sx={{ width: '100%', bgcolor: '#54a649', fontSize: '16px' }}
              >
                Le produit&nbsp;:&nbsp;<b>{homeCartvalue_t.productName}</b>&nbsp;qui&nbsp;coûte&nbsp;:
                &nbsp;<b>{homeCartvalue_t.price}</b>&nbsp;est&nbsp;mis&nbsp;dans&nbsp;le&nbsp;panier.
                {/* Ceci est une alerte. */}
              </Alert>
            )}
          </Stack>

          <p className="title_cat_bande_t">

            {catNameList.length > 0 ?
              // catNameList.toString()
              catNameList.length > 1 ?
                decodeURIComponent(catNameList.join("&"))
                :
                decodeURIComponent(catNameList.toString())
              :
              'Bienvenu sur wemaza'
            }
          </p>

          <span className="under_title_cat_bande_t">
            <span className="acceuil_cat_bande_t">Acceuil</span>
            <span className="cat_label_cat_bande_t">
              {catNameList.length > 0 ?
                // catNameList.toString()
                catNameList.length > 1 ?
                  decodeURIComponent(catNameList.join(" & "))
                  :
                  decodeURIComponent(catNameList.toString())
                :
                'Bienvenu sur wemaza'
              }
            </span>
          </span>

          <img
            src={require("../../../../images/electrinique2.png")}
            alt="Camera_t"
            className="cat_bande_img_t" />
        </div>

        {/* <div className="row marg_null_t">
          <span className="btn trier_t">
            Trier Par:
          </span>
          <Trier
            onPrice_t={onPrice_t}
            onDropdownBtn_t={onDropdownBtn_t}
            dropdownBtn_t={dropdownBtn_t}
            onChange_t={onChange_t}
            required={required === true ? 'required' : ''}
            greater_than={greater_than}
            less_than={less_than}
            equal_t={equal_t}
            interval_t={interval_t}
            input2_bool_t={input2_bool_t}
            input1_bool_t={input1_bool_t}
            show_val_interv={show_val_interv}
            prix_t={prix_t}
            prix2_t={prix2_t}
            interval_error={interval_error}
            gotToParentShowInput={gotToParentShowInput}
          />

        </div> */}


        <img
          src={require("../../../../images/camera.png")}
          alt="Camera_t"
          className="camera_t showAndHiddenRespDesk_t" />
        <img
          src={require("../../../../images/jack.png")}
          alt="Jack"
          className="jack_t showAndHiddenRespDesk_t" />
        <img
          src={require("../../../../images/casque.png")}
          alt="Casque"
          className="casque_t" />
        <img
          src={require("../../../../images/casque.png")}
          alt="Casque"
          className="casque2_t" />
        <img
          src={require("../../../../images/jack.png")}
          alt="Jack"
          className="jack2_t showAndHiddenRespDesk_t" />



        <div className="row marg_null_t justify_content_center_t acideLeftMainDiv_t">
          {/* <div className="col col-sm-2 acideLeft_t showAndHiddenRespDesk_t"> */}
          <div className="col col-sm-2 showAndHiddenRespDesk_t">
            <fieldset>
              {/* <legend>FILTRER&nbsp;PAR&nbsp;PRIX&nbsp;(xaf)</legend> */}
              {/* <legend>FILTRER&nbsp;PAR&nbsp;PRIX&nbsp;($)</legend> */}
              <legend>FILTRER&nbsp;PAR&nbsp;PRIX&nbsp;(€)</legend>
              <div className='row'>
                <div className='col-5'>
                  <FormControl fullWidth style={{ margin: '10px 0 0 5px' }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Prix</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={<InputAdornment position="start">€</InputAdornment>}
                      label="Prix"
                      placeholder="Min"
                      sx={{ width: '72px', fontSize: '10px' }}
                      value={this.props.minFieldSetInputValue}
                      onChange={this.props.handleMinChange}
                    />
                  </FormControl>
                </div>
                <div className='col-5'>
                  <FormControl fullWidth style={{ margin: '10px 0 0 5px' }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Prix</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={<InputAdornment position="start">€</InputAdornment>}
                      label="Prix"
                      placeholder="Max"
                      sx={{ width: '72px', margin: '0 5px 0 0', fontSize: '10px' }}
                      value={this.props.maxFieldSetInputValue}
                      onChange={this.props.handleMaxChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div className='row'>
                <div className='col-11'>
                  <a
                    href='javascript:;'
                    className='btn'
                    style={{
                      width: '100%',
                      backgroundColor: '#54a649',
                      padding: '5px',
                      color: '#FFFFFF'
                    }}
                    onClick={this.props.handleGoClick}>Filtrer</a>

                </div>
              </div>
            </fieldset>

          </div>
          <div className="col col-sm-10 infinitSProductsBloc_t">

            <div className="row marg_null_t">
              <CatTrieComponent
                label={this.props.textTriePrix}
                prix_t={prix_t}
                devise_t={devise_t}
                parent_categorieLabel={parent_categorieLabel}
                parent_add_categorie={parent_add_categorie}
                showTrieBool_t={showTrieBool_t}
              />

            </div>
            <Suspense fallback={<ReactPlaceholder bool_place_h={false} />}>

              <ProductListComponent
                onUpdateProductModal={onAddProduct}
                index_t={index_t}
                products={products}
                onProductId={onProductId}
                handlePageChange={this.handlePageChange}
                count={this.props.count}
                currentPage={this.props.current_page}
                totalPages={this.props.total_pages}
                hasMore={this.props.hasMore}
                pageNo={this.props.pageNo}
              />
            </Suspense>
            {this.props.offline ?
              <span className="offlinePage_t">
                Verifiez votre connextion internet puis réesayez
              </span>
              : ''
            }
            <div className="row justify_content_center_t" style={{ margin: '30px 0 0 0' }}>
              {/* {this.pagination.enabled &&

                <Pagination
                  currentPage={this.props.current_page}
                  totalPages={this.props.total_pages}
                  handlePageChange={this.handlePageChange}
                />

              } */}
            </div>

          </div>
        </div>

        <div className="showAndHiddenRespDesk_t">
          <Footer />
        </div>
        <div className="footerMobil_t">
          &nbsp;&nbsp;
        </div>

      </div>
    );
  }
}
export default withTranslation()(ProductListPageComponent);