import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

import tootreeImg from '../../images/tenisTest.png';

export default function CardWithImgInLeftOright(props) {
    const theme = useTheme();

    return (
        <Card sx={{ display: 'flex' }}>
            <CardMedia
                component="img"
                className="image-resize"
                image={props.productImage}
                alt="Tootree logo"
            />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    {/* <Typography variant="h2" color="text.secondary" component="div">
                        Pays : {props.country}
                    </Typography>
                    <Typography variant="h3" color="text.secondary" component="div">
                        Ville: {props.city}
                    </Typography>
                    <Typography variant="h4" color="text.secondary" component="div">
                        Prestataire: {props.shopName}
                    </Typography> */}
                    <div className="modal_p_t" >
                        <h2 style={{ color: '#575962' }}>Pays : {props.country}</h2>
                        <h3 style={{ color: '#575962' }}> Ville: {props.city}</h3>
                        <h4 style={{ color: '#575962' }}>Prestataire: {props.shopName}</h4>
                    </div>
                </CardContent>
            </Box>

        </Card>
    );
}