import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class FooterTop extends Component {

	render(){
       let { t, 
           // shops, 
           // oneShop, 
           // onShowSetting_t, 
           // show_setting, 
           // index_t, 
           // onDelete, 
           // onUpdate 
         } = this.props
       // let shopList = shops.length > 0 && shops.map((v, i) => (
       //          <>
       //            <div 
       //              key={i}
       //              className="col col-xs-12 col-sm-6 col-md-4 col-lg-3 shop_div_color">
       //                  <div className="row">
       //                    <div className="col col-sm-2">
       //                        <div className="round_for_img_h_prest"></div>
       //                    </div>
       //                    <div className="col col-sm-10 shop_marg_img_text">
       //                      <a href="javascript:;" onClick={(hash_id, real_id, shop_n) => oneShop(v.id, v.real_id, v.name)}>
       //                        <span className="shop_title_t">{v.name}</span><br /> 
       //                        <span className={v.is_validate === 1 ? "is_validate_shop__true_t" : "is_validate_shop__false_t"}>{v.is_validate === 1 ? "Activé" : "En attente"}</span>
       //                      </a>
       //                        <span>  
       //                            <img 
       //                            src={require("../../images/shop_location.png")} 
       //                            alt="location"
       //                            className="shop_location_img" />

       //                            <span className="shop_location_t">  
       //                               <span className="desc_shop_t">
       //                                  {v.description}, Douala
       //                               </span>&nbsp;&nbsp;
       //                               <a href="javascript:;"
       //                               onClick={(index) => onShowSetting_t(i)}
       //                               className="point_click_t">
       //                                  :
       //                               </a>
       //                               <div className={(show_setting === true && index_t === i) ? "display_options_t display_t" : "display_options_t hidden_t"}>
       //                                 <a href="javascript:;"
       //                                 onClick={(e, hash_id, name) => onDelete(e, v.id, v.name)}
       //                                 className="supr_t">
       //                                  Supprimer
       //                                 </a>
       //                                 <a href="javascript:;"
       //                                 onClick={(e, hash_id) => onUpdate(e, v.id)}
       //                                 className="Modif_t">
       //                                  Modifier
       //                                 </a>
       //                               </div>
       //                            </span>
       //                        </span>
       //                        <br />
       //                            <span className="shop_under_location_t">  
       //                                Nombre de produits : <span className="bold_t"> 0 </span>
       //                            </span>
       //                    </div>
       //                  </div>
       //                </div>
       //          </>
       //        ));
        	return(
                <>
                    {/*{ shopList }*/}
                    <div 
                      className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3">
                        <div className="row f_t_border_t">
                          <div className="col-3 col-sm-1">
                            <img 
                              className="img_round_footer_top_t"
                              src={this.props.require} 
                              alt="" />
                          </div>
                          <div className="col col-sm-10 shop_marg_img_text f_t_text_div_t">
                              
                              <p className="f_t_first_title bold_t"> 
                              { this.props.first_title }  
                              </p>

                              <p className="text_footer_top_t">
                              { this.props.second_title } 
                              </p>

                          </div>
                        </div>
                      </div>
                </>
        	);
        }
}
export default withTranslation()(FooterTop);