import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';

class Loading extends Component {
	constructor(props) {
		super(); 
	}
	render(){
       let { t } = this.props
        	return(
                <div 
                className={this.props.styleLoading}
				>
			{/*bars, spin, bubbles, spinningBubbles, spokes*/}
                    <ReactLoading type="bars" color="#0b7bff" height={'6%'} width={'6%'} />
                </div>
        	);
        }
}
export default withTranslation()(Loading);
