import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NavDropdown from 'react-bootstrap/NavDropdown';
import CryptoJS from 'crypto-js';
import { Link } from "react-router-dom";
import { changeNavigatorTheme, auth_t } from './../../store/actions';
import SeConnecterBtn from './../components/SeConnecterBtn'
import SearchInput from './../components/FormsComponent/SearchInput'
import FormsSelect from './../components/FormsComponent/FormsSelect';
import LoadingDifferent from './../components/LoadingDifferent';
import AccountMenu from '../components/AccountMenu';
import SwipeableComponent from './SwipeableComponent';
import SelectComponent from '../components/FormsComponent/SelectComponent';

class HeaderMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
    }
  }
  onShowT = () => {
    this.setState({ show: !this.state.show })
  }
  gotToParentValue = (value, label) => {
    const { postregister } = this.state;
    if (label === "Francais" || label === "French") {
      const options_fr = [{ value: 'en', label: "Anglais" }];
      const options_eng = [{ value: 'fr', label: "Francais" }];
      // Debut Passer les parametres dans redux
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      // Fin Passer les parametres dans redux
      this.handleClick("fr");
    }
    if (label === "Anglais" || label === "English") {
      const options_fr = [{ value: 'en', label: "English" }];
      const options_eng = [{ value: 'fr', label: "French" }];
      // Debut Passer les parametres dans redux
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      // Fin Passer les parametres dans redux
      this.handleClick("en");
    }
    if (label === "Dollar") {
      this.setState({
        device_data: value
      })
    }
  }

  handleClick = (lang) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    // Debut cette variable dans redux
    this.setState({ langBool: !this.state.langBool });
    // Fin cette variable dans redux
  }

  checked_t() {
    // Debut cette variable dans redux
    this.setState({ checked_switch: !this.state.checked_switch });
    // Fin cette variable dans redux
  }

  render() {
    const {
      t,
      toggleBool_t,
      onToggle_t,
      isAuth_t,
      onLogout,
      isLoadingHeader,
      idProf,
      showCountry,
      categorie_value,
      option_categorie,
      optionsResp,
      categorieDefault_value,
      gotToParentCatValue,
      bool_val
    } = this.props;

    return (
      <div className="main-header main-header-color mrg_header_men_t isResponsive_t">
        <nav className="navbar navbar-header navbar-expand-lg navbar_header_t">
          <div className="container-fluid">
            <div className="nav-toggle nav-toggle-h-menu_t swipeableComponentParentDiv_t ">
              {/* <button
                className="btn btn-toggle toggle-sidebar btn-toggle-h-menu_t"
                onClick={onToggle_t}>
                <img
                  src={require("../../images/menu_line_1.png")}
                  alt="Search"
                  className="search_toggle_t"
                  style={{ position: 'absolute', width: '15px', margin: '5px' }}
                />
                <img
                  src={require("../../images/menu_line_2.png")}
                  alt="Search"
                  className="search_toggle_t"
                  style={{ margin: '6px 0 0 6px', width: '10px' }}
                />
                <span className="menu_tog_t">
                  Tous les produits
                </span>
              </button> */}

              <SwipeableComponent

                categorie_value={categorie_value}
                style_f_Select_div={{ margin: '-5px 0 0 -5px', color: '#000', width: '200px' }}
                name={'cat_prod'}
                allCategoriesLabel={option_categorie}
                placeholder={categorieDefault_value !== '' ? categorieDefault_value : 'Catégorie'}
                gotToParentCatValue={gotToParentCatValue}
                bool_val={bool_val}

              // style_f_Select_div={{ margin: '5px 0 0 -5px', color: '#000' }}
              // name={'cat_prod_mobil'}
              // options={optionsResp}



              />
              <div className={toggleBool_t ? "toggleDiv_t display_t" : "toggleDiv_t hidden_t"}>
                {isAuth_t ?
                  <>
                    <Link to="/orders" className="togglePage_t">
                      Mes commandes en cours
                    </Link>
                    <a href="javascript:;" className="togglePage_t" onClick={this.onShowT}>
                      Liaisons {this.state.show ?
                        <img src={require("../../images/caret-down.png")} className="caretUp_Size" alt="caret Up" />
                        :
                        <img src={require("../../images/caret-up.png")} className="caretUp_Size" alt="caret Up" />
                      }
                    </a>
                    <div className={this.state.show ? "transfDropDownT" : "hidden_t"}>
                      <ul>
                        <ol>
                          <Link to="/request">
                            Mes demandes
                          </Link>
                        </ol>
                        <ol>
                          <Link to="/link">
                            Mes liaisons
                          </Link>
                        </ol>
                      </ul>
                    </div>
                    {/*<Link to="/request" className="togglePage_t">
                      Mes demandes de liason
                    </Link>
                    <Link to="/link" className="togglePage_t">
                      Mes liaisons
                    </Link>*/}
                    <Link to="/setting" className="togglePage_t">
                      Réglages et confidentialité
                    </Link>
                    <Link to="/history" className="togglePageText_t">
                      Historique de Commandes
                    </Link>
                    <Link to={`/profile/${idProf}`} className="togglePageText_t">
                      Mon profil
                    </Link>
                    <a href="javascript:;" className="togglePageText_t">
                      Aide et assistance
                    </a>
                  </>
                  :
                  <a href="javascript:;" className="togglePageText_t">
                    Aide et assistance
                  </a>
                }

              </div>
            </div>
            <ul className="row navbar-nav topbar-nav top_bar_menu_t">

              {/* <li className={showCountry ? "nav-item" : "nav-item hidden_t"} style={{ margin: '10px 0 0 0' }}>
                <FormsSelect
                  style_f_Select_div={'form-group select_t styleFSelectHeader_div'}
                  classNameLabel={"label_select_t"}
                  name={'country'}
                  options={this.props.option_country}
                  // placeholder={this.props.country_value ? this.props.country_value : 'Pays de livraison'}
                  placeholder={this.props.country_value ? this.props.country_value : 'Cameroun'}
                  label_t={''}
                  gotToParentValue={this.props.gotToParentValue}
                />

              </li> */}
              {this.props.isHome ?
                ""
                :
                <li className={showCountry ? "nav-item" : "nav-item hidden_t"} style={{ margin: '10px 0 0 0' }}>

                  {/* <SelectComponent
                    style_f_Select_div={'form-group select_t styleFSelectHeader_div'}
                    classNameLabel={"label_select_t"}
                    name={'city'}
                    options={this.props.option_city}
                    placeholder={this.props.city_id ? this.props.city_id : 'Ville de livraison'}
                    label_t={''}
                    gotToParentValue={this.props.gotToParentValue}
                  /> */}
                  <FormsSelect
                    style_f_Select_div={'form-group select_t styleFSelectHeader_div'}
                    classNameLabel={"label_select_t"}
                    name={'city'}
                    options={this.props.option_city}
                    placeholder={this.props.city_id ? this.props.city_id : 'Ville de livraison'}
                    label_t={''}
                    gotToParentValue={this.props.gotToParentValue}
                  />
                </li>
              }

            </ul>
            <ul
              // style={{ wordSpacing: '-5px' }}
              className="row navbar-nav topbar-nav ml-md-auto align-items-center top_bar_menu2_t" style={{ margin: '.6rem 1% 0 0' }}>

              <li class="nav-item nav_item_width">
                <a href="javascript:;"
                  style={{ color: '#FFF', wordSpacing: '-5px' }}>
                  A propos de nous
                </a>
              </li>
              {/*<li class="nav-item nav_item_width">
                     <a href="javascript:;"
                       style={{color: '#FFF'}}>
                         Contactez nous
                      </a>
                </li>*/}
              {isAuth_t ?
                <>
                  <li class="nav-item nav_item_width loginBtn_t">
                    <a href="javascript:;" onClick={onLogout} style={{ wordSpacing: '-5px' }}>
                      {isLoadingHeader ?
                        <>
                          <span style={{ 'font-size': '15px', margin: '0 0 0 10px' }}>
                            Se deconnecter
                          </span>
                          <div>
                            <LoadingDifferent
                              type={'spin'}
                              styleLoading={'loadingLogin'}
                            />
                          </div>
                        </>
                        :
                        <span style={{ 'font-size': '15px' }}>
                          Se deconnecter
                        </span>
                      }


                    </a>
                  </li>
                  <AccountMenu
                    onToggle_t={onToggle_t} />
                </>
                :
                <>
                  <li class="nav-item nav_item_width sigoutBtn_t">
                    <Link to="/register" style={{ color: '#FFF', wordSpacing: '-5px' }}>
                      S'enregistrer
                    </Link>
                  </li>
                  <li class="nav-item nav_item_width loginBtn_t">
                    <Link to="/login" style={{ color: '#FFF', wordSpacing: '-5px' }}>
                      Se connecter
                    </Link>
                  </li>
                </>
              }

            </ul>

            {/* <div className="nav-toggle nav-toggle-h-menu2_t">

              <button
                className="btn btn-toggle toggle-sidebar btn-toggle-h-menu_t"
                onClick={onToggle_t}>
                <span className="menu_tog_t">
                  Menu utilisateur
                </span>

                <img
                  src={require("../../images/menu_line_1.png")}
                  alt="Search"
                  className="search_toggle_t"
                  style={{ position: 'absolute', width: '15px', margin: '5px' }}
                />
                <img
                  src={require("../../images/menu_line_2.png")}
                  alt="Search"
                  className="search_toggle_t"
                  style={{ margin: '6px 0 0 6px', width: '10px' }}
                />

              </button>
            </div> */}

          </div>
        </nav>
        {/* End Navbar */}
      </div>
    );
  }
}
export default withTranslation()(HeaderMenu);