import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { withTranslation } from 'react-i18next';

class PhoneNumber extends Component {
	render(){
       let { t } = this.props 
        	return(
        		<>
        			<label className="" for="phone">{this.props.label} 
                      {this.props.error_bool ?
                        <span style={{color: 'red'}}> (*) </span>
                        :
                        ''
                      }
                    </label>
                    <PhoneInput
                        className="form-control input_driver_t" 
                        country={'cm'}
                        Defaultvalue={this.props.value}
                        value={this.props.value}
                        placeholder={this.props.value !== '' ? this.props.value : "— Téléphone"}
                        onChange={this.handleOnChange}
                      />
          		</>
        	);
        }
}
export default withTranslation()(PhoneNumber);