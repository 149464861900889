import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import FloatingInputBis from './../../../components/FormsComponent/FloatingInputBis';
import { Link } from "react-router-dom";

class UserLinks extends Component {
  handleChange = (e) => {
    e.preventDefault();
    switch(e.target.name){
        case "amount_t":
            return this.props.gotToParentValue(e.target.value, 'amount_t');
        default:
            return ;
      }
  };
	render(){
        	const { 
            t, 
            userLinks,
            image,
            deleteUserRelayPoint,
            onRejectLink_t
          } = this.props;

          let userLinksList = userLinks.length > 0 && userLinks.map((v, i) =>(

                  <p className="ListUsers_t" key={i}>
                    <span>
                        <img 
                          src={image} 
                          alt="Image prod" 
                          className="imgSearchNameByLink_t" 
                        />
                        <span className="userName_t">

                            <a href="javascript:;" 
                                className="deleteIcon_t"
                                onClick={v.name ? (e, hash_id) => deleteUserRelayPoint(e, v.id) : (e, hash_id) => onRejectLink_t(e, v.id)}
                                > 
                                  <i className="fas fa-trash-alt fa-lg"></i>
                              </a>

                            <span className="spanName_t">
                              {
                                v.receivers !== undefined ? 
                                  `${v.receivers.data.firstname} ${v.receivers.data.lastname}` 
                                : 
                                  v.firstname ? 
                                    `${v.firstname} ${v.lastname}` 
                                  : 
                                    v.name
                              }

                            </span>
                            {v.receivers !== undefined ? 
                              <Link to={`/profile/${v.receivers.data.id}`} className="btn ListNameByLink_t">
                                Voir les details
                              </Link>
                            :
                              <Link to={`/setting/${v.id}`} className="btn ListNameByLink_t">
                                Voir les details
                              </Link>
                          }
                            {/*<a href="javascript:;"
                             className="btn ListNameByLink_t">
                               Voir les details
                            </a>*/}
                        </span> 
                     </span> 
                  </p>
            ));
        	return(
                	<>
                    { userLinksList }
                  </>
        	);
        }
}
export default withTranslation()(UserLinks);