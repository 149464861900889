import Http from '../../Http'
import * as action from '../../store/actions'

export function commands(data) {
    // let formdata = new FormData();

    // formdata.append('amount', data.amount);
    // formdata.append('quantity', data.quantity);
    // formdata.append('relaypoint_id', data.relaypoint_id);
    // formdata.append('receiverprofile_id', data.receiverprofile_id);
    // formdata.append('donator_id', data.donator_id);
    // formdata.append('commandLines', data.commandLines);
    // formdata.append('name', data.name);
    // formdata.append('phone', data.phone);
    // formdata.append('is_pay_by_creator', data.is_pay_by_creator);

    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`/commands`, data)
                .then(function (res) {
                    // handle success           
                    return resolve(res);
                })
                .catch(function (err) {
                    return reject(err);
                })

        })
    )
};

export function updateCommand(hash_id, data) {
    // let formdata = new FormData();

    // formdata.append('amount', data.amount);
    // formdata.append('quantity', data.quantity);
    // formdata.append('relaypoint_id', data.relaypoint_id);
    // formdata.append('receiverprofile_id', data.receiverprofile_id);
    // formdata.append('donator_id', data.donator_id);
    // formdata.append('commandLines', data.commandLines);
    // formdata.append('name', data.name);
    // formdata.append('phone', data.phone);
    // formdata.append('is_pay_by_creator', data.is_pay_by_creator);

    return dispatch => (
        new Promise((resolve, reject) => {
            Http.patch(`/commands/${hash_id}`, data)
                .then(function (res) {
                    // handle success     
                    const data = res.data.data
                    // dispatch(action.profile(profileD));
                    return resolve(data);
                })
                .catch(function (err) {
                    return reject(err);
                })

        })
    )
};

export function deleteCommand(hash_id) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.delete(`/commands/${hash_id}`)
                .then(function (res) {
                    // handle success     
                    const data = res.data.data
                    return resolve(data);
                })
                .catch(function (err) {
                    return reject(err);
                })

        })
    )
};


export function getAllCommands(hash_id, pageNo, limit) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/commands?search=status:0;donator_id:${hash_id}&searchJoin=and&include=user,receiver,receiverprofile,relaypoint.city.country,products.shop.provider.user.country,receiverprofile&page=${pageNo}&limit=${limit}`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};

export function findCommandById(id) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/commands/${id}`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                });
        })
    )
};

export function getCommandLines(commandId, productId) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/get-all-command-product/${commandId}?include=product,command,product.shop,command.relaypoint`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};
