import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class AcideLeftItem extends Component {

	render(){
        	const { 
            t, 
            iconList,
            divUserImg_t,
            title_t,
            label_t,
            showBtn,
            imgUserSize_t,
            settingTextUserInfoAL_t,
            settingTitleUserInfoAL_t,
          } = this.props;
        	return(
                  <div className="settingUsers_t">
                    <span>
                    <p className={divUserImg_t}>
                      <img 
                        src={iconList} 
                        alt="Image prod" 
                        className={imgUserSize_t} 
                      />
                    </p>
                        <span className="userName_t">
                            <span className={settingTitleUserInfoAL_t}>
                              {title_t}
                            </span>

                            <span className={settingTextUserInfoAL_t }>
                               {label_t}
                            </span>
                            {showBtn ? 
                              <a href="javascript:;"
                               className="btn settingBtnUserInfoAL_t">
                                 Envoyer une verification
                              </a>
                            : 
                              ''
                            }
                        </span> 
                     </span> 
                  </div>
        	);
        }
}
export default withTranslation()(AcideLeftItem);