import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';

class LoadingDifferent extends Component {
	constructor(props) {
		super(); 
	}
	render(){
       let { t } = this.props
        	return(
                <div 
                className={this.props.styleLoading}
				>
			{/*bars, spin, bubbles, spinningBubbles, spokes*/}
                    <ReactLoading type={this.props.type} color="#FFFFFF" height={this.props.height} width={this.props.width} />
                </div>
        	);
        }
}
export default withTranslation()(LoadingDifferent);
