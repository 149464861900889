import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// import Carousel from '../components/Carousel';

class FloatingInput extends Component {
	constructor(props) {
		super();
	}

	handleChange = (e, label) => {
		e.preventDefault();
		switch (label) {
			case "name":
				return this.props.gotToParentValue(e.target.value, 'name');
			case "firstname":
				return this.props.gotToParentValue(e.target.value, 'firstname');
			case "email":
				return this.props.gotToParentValue(e.target.value, 'email');
			case "adresse":
				return this.props.gotToParentValue(e.target.value, 'adresse');
			default:
				return;
		}
	};

	render() {
		let { t, errors } = this.props
		return (
			<>
				{this.props.text_or_not ?
					<div className={this.props.class_for_img_icon_style ? "class_for_usr_style" : ''}>
						<div className={this.props.style_f_floating_div}>
							<div className="input-group input_g_2">
								<input
									type={this.props.type}
									value={this.props.value}
									name={this.props.name}
									id={this.props.id}
									className={this.props.className}
									style={{ padding: '.3rem .1rem 0 2rem' }}
									onClick={this.props.onClick}
									onChange={this.props.onChange}
									aria-describedby={this.props.aria_describedby}
									checked={this.props.checked}
									title={this.props.title}
									required={this.props.required === true ? 'required' : ''}
									disabled={this.props.disabled === true ? 'disabled' : ''} />
								{this.props.disabled ? '' :
									<label
										style={this.props.label_style}
										for={this.props.id}
										className={this.props.classNamePlaceh}>
										{t(this.props.placeholder)}
									</label>
								}
							</div>
							{this.props.icon_user ?
								<img
									className={this.props.input_left_t ? "black_profile_img_perso_t" : "black_profile_img_t"}
									style={{ left: '1rem' }}
									src={this.props.black_profile_img_t ? require('../../../images/profile_black.png') : require('../../../images/profile_white.png')} />
								: ''
							}
							{this.props.icon_email ?
								<img
									className={this.props.black_profile_img_t ? "black_profile_img_perso_t" : "black_profile_img_t"}
									src={this.props.black_profile_img_t ? require('../../../images/mail_black.png') : require('../../../images/mail_black.png')} />
								: ''
							}
							{this.props.icon_district ?
								<img
									className={this.props.input_left_t ? "black_profile_img_perso_t" : "black_profile_img_t"}
									src={this.props.black_profile_img_t ? require('../../../images/home.png') : require('../../../images/home.png')} />
								: ''
							}
						</div>
					</div>
					:
					<div className={this.props.class_for_img_icon_style ? "class_for_usr_style" : ''}>
						<div className={this.props.style_f_floating_div}
						>

							<div className="input-group input_g_2">
								<input
									type={this.props.type}
									value={this.props.value}
									name={this.props.name}
									id={this.props.id}
									className={this.props.className}
									onClick={this.props.onClick}
									onChange={this.props.onChange}
									aria-describedby={this.props.aria_describedby}
									checked={this.props.checked}
									title={this.props.title}
									required={this.props.required === true ? 'required' : ''}
									disabled={this.props.disabled === true ? 'disabled' : ''} />
								<label
									style={this.props.label_style}
									for={this.props.id}
									className={this.props.classNamePlaceh}>
									{t(this.props.placeholder)}
								</label>
								<div class="eye_float_posit_t input-group-addon">
									<span class="input-group-text eye_t">
										{this.props.black_profile_img_t ?
											<i
												style={{ color: '#000' }} className={this.props.eye_password_bool ? "fa fa-eye-slash" : "fa fa-eye"}
												id="eye"
												onClick={this.props.toggle}></i>
											:
											<i
												style={{ color: '#FFF' }} className={this.props.eye_password_bool ? "fa fa-eye-slash" : "fa fa-eye"}
												id="eye"
												onClick={this.props.toggle}></i>
										}

									</span>
								</div>
								{this.props.icon_lock ?
									<img
										style={this.props.lock_style}
										className={this.props.black_profile_img_t ? "black_profile_img_perso_t" : "black_profile_img_t"}
										src={this.props.black_profile_img_t ? require('../../../images/lock.png') : require('../../../images/lock-white.png')} />
									: ''
								}
							</div>
						</div>
					</div>
				}
			</>
		);
	}
}
export default withTranslation()(FloatingInput);