import React from 'react'
import {Route, Redirect} from 'react-router'
import {connect} from 'react-redux'
const Login = React.lazy(() => import('../common/pages/Auth/Login'));

const PrivateRoute = ({isVerify_t, isAuthenticated_t, path, component, isAuthenticated, isVerify,...rest}) => (
        (isAuthenticated_t) ? (
            (isAuthenticated_t) ? (
                (isVerify_t) ? (
                        <Route path={path} component={component}/>
                ) : (
                    <Redirect to={{ pathname: '/verify' }}/>
                )
            ) : (
                <Redirect to={{ pathname: '/login' }}/>
            )
        ) : (
                (isAuthenticated) ? (
                    (isVerify) ? (
                            <Route path={path} component={component}/>
                    ) : (
                        <Redirect to={{ pathname: '/verify' }}/>
                    )
                ) : (
                    <Redirect to={{ pathname: '/login' }}/>
                )
            )
        
);


const mapStateToProps = (state) => {
    return {
        isAuthenticated : state.__iA.isAuthenticated,
        isVerify : state.__iA.isVerify
    }
};

export default connect(mapStateToProps)(PrivateRoute);