import {connect} from 'react-redux'
import Page from './Page'
const mapStateToProps = state => {
    return { 
        def_theme: state.__iA.__dt,
        isAuthenticated : state.__iA.isAuthenticated,
        cartValue : state.__iA.__cv,
        showCartValue : state.__iA.__showC,
        prodId_t : state.__iA.__pid
    }
};

export default connect(mapStateToProps)(Page);