import React from 'react';
import Slider from 'react-slick';
import { Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Les données des catégories à afficher
const categories = [
    { id: 1, image: 'assets/images/category/01.png', title: 'Organic Vegetable', items: 299 },
    { id: 2, image: 'assets/images/category/02.png', title: 'Organic Vegetable', items: 299 },
    { id: 3, image: 'assets/images/category/03.png', title: 'Organic Vegetable', items: 299 },
    { id: 4, image: 'assets/images/category/04.png', title: 'Organic Vegetable', items: 299 },
    { id: 5, image: 'assets/images/category/05.png', title: 'Organic Vegetable', items: 299 },
    { id: 6, image: 'assets/images/category/06.png', title: 'Organic Vegetable', items: 299 },
    { id: 7, image: 'assets/images/category/07.png', title: 'Organic Vegetable', items: 299 },
    { id: 8, image: 'assets/images/category/08.png', title: 'Organic Vegetable', items: 299 },
  ];


const Arrow = ({ className, style, onClick, direction }) => (
  <div
    className={className}
    style={{
      ...style,
      display: 'block',
      background: 'rgba(0,0,0,0.5)',
      borderRadius: '50%',
      zIndex: 1,
    }}
    onClick={onClick}
  >
    {direction === 'left' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
  </div>
);

const FeaturedCategories = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Slider {...settings}>
        {categories.map((category) => (
          <Box key={category.id} px={1}>
            <Card sx={{ width: 150, height: 220, margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <CardMedia
                component="img"
                image={category.image}
                alt={category.title}
                style={{ objectFit: 'contain', height: '110px', margin: '10px 0' }}
              />
              <CardContent sx={{ textAlign: 'center', padding: '10px' }}>
                <Typography variant="body1" component="div" sx={{ 
                    fontWeight: 'bold', 
                    'font-size': '14px', 
                    'font-weight': '700', 
                    'margin-bottom': '8px',
                    color: '#2C3C28',
                    padding: '0 10px' }}>
                  {category.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{color: '#629d23', 'text-transform': 'uppercase', 'font-size': '12px'}}>
                  {category.items} ITEMS
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default FeaturedCategories;
