import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import GoogleLogin from 'react-google-login';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Alert } from "reactstrap";
import AlertMaterial from '@mui/material/Alert';
import Loading from './../../../../components/Loading';
import LoginComponent from './../../../Auth/Login/Components/LoginComponent';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from "react-select";
import makeAnimated from 'react-select/animated';
import CardWithImgInLeftOright from '../../../../components/CardWithImgInLeftOright';

const animatedComponents = makeAnimated();

class AlertPosition extends Component {

  render() {
    const {
      t,
      shopName,
      country,
      country_real_id,
      cityProd,
      isCart_t,
      modalAlertTitle,
      productPrice,
      imageUrl,
      cardPage,
      onCancel
    } = this.props;
    return (
      <>
        <SweetAlert
          showCancel
          cancelBtnBsStyle={{ padding: '25px' }}
          title={<AlertMaterial
            variant="filled"
            severity="success"
            sx={{ width: '100%', bgcolor: '#54a649', fontSize: '16px' }}
          >
            Le produit&nbsp;:&nbsp;<b>{modalAlertTitle}</b>&nbsp;qui&nbsp;coûte&nbsp;
            &nbsp;{productPrice}€&nbsp;est&nbsp;mis&nbsp;dans&nbsp;le&nbsp;<b>panier</b>.
          </AlertMaterial>}
          onConfirm={this.props.onConfirm}
          onCancel={onCancel}
          customButtons={
            <React.Fragment>
              <button className="btn btn-go-to-card" onClick={cardPage}>Voir mon panier</button>
              <button
                className="btn btn_basket_prod"
                onClick={(real_contryId) => this.props.onConfirm(country_real_id)}>
                Continuer Mes achats
              </button>
            </React.Fragment>
          }
          show={this.props.showAlert}
          type={'controlled'}
        >
          <a
            href="javascript:;"
            className="cancel_btn_t"
            onClick={this.props.onCancel}
          >
            X
          </a>
          {/* {isCart_t ?
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Alert color="success">
                <img
                  src={require("../../../../../images/checkOK.png")}
                  alt="Check Ok"
                /> déjà dans le panier
              </Alert>
            </div>
            :
            ''
          } */}
          {/* <div className="modal_p_t" >
            <h2>se trouve au : {country}</h2>
            <h2>lieu : {country}</h2>
            <h3>dans la ville : {cityProd}</h3>
            <h3> ville : {cityProd}</h3>
            <h4>vendu par la boutique : {shopName}</h4>

          </div> */}

          <div className="homeModalCardleftImg_t">
            <CardWithImgInLeftOright
              title={modalAlertTitle}
              country={country}
              city={cityProd}
              shopName={shopName}
              productImage={imageUrl}
            />
          </div>

        </SweetAlert>
      </>
    );
  }
}
export default withTranslation()(AlertPosition);