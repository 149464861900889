import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class CatTrieComponent extends Component {
  delete_sous_cat(i) {
     const {categorieLabel} = this.props;
        categorieLabel.splice(i, 1);
        this.props.parent_categorieLabel(categorieLabel);
   }

  render(){
       let { t, label, prix_t, devise_t } = this.props
          return(
                  <div className={this.props.showTrieBool_t ? "cat_list_t display_t": "cat_list_t hidden_t"}>
                   <span className="sous_cat_t">
                      { label } { prix_t } { devise_t } 
                      <a 
                        href="javascript:;" 
                        onClick={this.delete_sous_cat.bind(this, 1)}
                        className="del_sous_cat_t">
                        x
                      </a>
                   </span>
                  </div>
          );
        }
}
export default withTranslation()(CatTrieComponent);