import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import LoadingDifferent from './../../../components/LoadingDifferent';
// import Pagination from './../../../components/Pagination';
import Pagination from './../../../components/Pagination/PaginationClass.js';

class CommadesComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      pageData: props.data,
      pageLength: props.pageLength || null,
      currentPage: props.current_page || 0,
      total_pages: props.total_pages,
      per_page: props.per_page,
      total_t: props.total_t,
    }
    this.pagination = this.props.pagination || {};
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  onPageLengthChange = (pageLength) => {
    this.setState({
      pageLength: parseInt(pageLength, 10)
    }, () => {
      this.onGotoPage(this.state.currentPage);
    });
  }
  // componentDidMount() {
  //   if (this.pagination.enabled) {
  //     this.onGotoPage(this.state.currentPage)
  //   }
  // }

  handlePageChange = (cur_page) => {
    let pageData = this.getPageData(cur_page, this.state.pageLength);
    this.setState({
      pageData: pageData,
      currentPage: cur_page
    });
    switch (this.props.paginationProductValue) {
      case "commandes":
        return this.props.allCommands(
          this.props.cmdVal,
          cur_page,
          this.props.limit
        )
      default:
        return;
    }

  }
  getPageData = (pageNo, pageLength) => {
    let startOfRecord = (pageNo - 1) * pageLength;
    let endOfRecord = startOfRecord + pageLength;
    let data = this.state.data;
    let pageData = data.slice(startOfRecord, endOfRecord);

    return pageData;
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //     if (nextProps.commandes !==  prevState.commandes) {
  //         return {
  //             commandes: nextProps.commandes,
  //         };
  //     }
  // }

  render() {
    let { t, commandes, getCommandLines } = this.props
    let commandesList = commandes.length > 0 && commandes.map((v, i) => {
      let receiverAddressValue = !!v.address ?
        v.address
        :
        v.receiverprofile?.data?.district
          ?
          v.receiverprofile.data.district
          :
          v.user.data.address

      let phoneValue = v.receiverprofile?.data?.phone
        ?
        v.receiverprofile.data.phone
        :
        v.user.data.phone

      let receiverNameValue = v.receiverprofile?.data?.name
        ?
        v.receiverprofile.data.name
        :
        parseInt(v?.receiver_id) !== parseInt(v?.user_id) ?
          v.receiver.data.full_name
          :
          v.user.data.firstname + " " + v.user.data.lastname


      return (
        // commandes.map((v) => v.real_id)
        <span className="backgroundCommande_t" key={i}>
          <img
            src={require("../../../../images/userProfil.png")}
            alt="Image prod"
            className="imgDemandeRound_t" />
          <div className="divBlocCommande_t">
            <span className="spanDemandeNameTitle_t">
              Nom complet
            </span>

            <span className="spanDemandeName_t">
              {/* {v.name} */}
              {
                receiverNameValue
              }
            </span>
          </div>

          <img
            src={require("../../../../images/vertcalLine.png")}
            alt="Image prod"
            className="orOperator_t"
          />

          <div className="divBlocCommande_t">
            <span className="spanDemandeNameTitle_t">
              Téléphone
            </span>

            <span className="spanDemandeName_t">
              {/* {v.phone} */}
              {
                phoneValue
              }
            </span>
          </div>

          <img
            src={require("../../../../images/vertcalLine.png")}
            alt="Image prod"
            className="orOperator_t" />

          <div className="divBlocCommande_t">
            <span className="spanDemandeNameTitle_t">
              Localisation
            </span>

            <span className="spanDemandeName_t">
              {/*Akwa, */}
              {/* {v.city}, {v.country} */}

              {
                receiverAddressValue
              }

            </span>
          </div>

          <img
            src={require("../../../../images/vertcalLine.png")}
            alt="Image prod"
            className="orOperator_t"
          />

          <div className="divBlocCommande_t">
            <span className="spanDemandeNameTitle_t">
              Montant
            </span>

            <span className="spanDemandeName_t">
              {v.amount} xaf
            </span>
          </div>

          <div className="divButtonCommande_t">
            <span className="bgButCommande_t">
              <a href="javascript:;"
                onClick={(sendVal, hash_receiver_id, country_real_id, receiverName, receiver_id, amountCMD) => getCommandLines(v, v.hash_receiver_id !== "" ? v.hash_receiverprofile_id : v.hash_receiver_id, this.props.country_real_id, receiverNameValue, v.receiver_id, v.amount)}
                className="accepterCommande_t"
              >
                {
                  this.props.isLoading && this.props.productHashId === v.id ?
                    <>
                      <LoadingDifferent
                        type={'spin'}
                        styleLoading={'loadingCommande'}
                      />
                      <span className="bntCommandLoad_t">
                        Voir le panier
                      </span>
                    </>
                    :
                    <span className="btn_t">
                      Voir le panier
                    </span>
                }
              </a>
            </span>
          </div>
        </span>
      )
    });

    return (
      <>
        {this.props.offline ?
          <span className="offlinePage_t">
            Verifiez votre connextion internet puis réesayez
          </span>
          : ''
        }
        {commandesList}
        <div className="row justify_content_center_t" style={{ margin: '30px 0 0 0' }}>
          {/* <span className="pag_t">
            <span className="pag1_t">
              Affiche
            </span>
            <span className="pag2_t">
              {
                parseInt(this.props.current_page) > 1 ?
                  (parseInt(this.props.current_page) * parseInt(this.props.per_page) - parseInt(this.props.per_page))
                  :
                  parseInt(this.props.current_page)
              }
            </span>
            <span className="pag2_t">
              à
            </span>
            <span className="pag2_t">
              {
                parseInt(this.props.current_page) > 1 ?
                  (parseInt(this.props.current_page) * parseInt(this.props.per_page))
                  :
                  parseInt(this.props.per_page)
              }
            </span>
            <span className="pag2_t">
              sur
            </span>
            <span className="pag2_t">
              {this.props.total_pages}
            </span>
            <span className="pag2_pageNot">
              entrées
            </span>

          </span> */}

          {this.pagination.enabled &&
            <Pagination
              currentPage={this.props.current_page}
              totalPages={this.props.total_pages}
              handlePageChange={this.handlePageChange}
            />
          }

        </div>
      </>
    );
  }
}
export default withTranslation()(CommadesComponent);