import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import FloatingInputBis from './../../../components/FormsComponent/FloatingInputBis';
import { Link } from "react-router-dom";

class Liasons extends Component {
  handleChange = (e) => {
    e.preventDefault();
    switch(e.target.name){
        case "amount_t":
            return this.props.gotToParentValue(e.target.value, 'amount_t');
        default:
            return ;
      }
  };
	render(){
        	const { 
            t, 
            seacrchList, 
            onLink_t, 
            onShowDetails_t, 
            image, 
            amountBool_t,
            real_id,
            amount_t,
            gotToParentValue,
            onCancelAmount_t,
            onShowAmount_t,
          } = this.props;

          let liaisonList = seacrchList.length > 0 && seacrchList.map((v, i) =>(

                  <p className={ (amountBool_t && v.real_id === real_id) ? "searchUsersAmountBool_t_t" : "searchUsers_t"} key={i}>
                    <span 
                       key={i} 
                       className={ (amountBool_t && v.real_id === real_id) ? "display_t amountBoolDiv_t amountEffect_t" : "hidden_t"} 
                       >
                       <a 
                         href="javascript:;" 
                         className="cancelAmount_t"
                         onClick={onCancelAmount_t}> X </a>
                        
                     <span className="form-group" style={{width: '100%'}}>
                      <label className="labelAmount_t" for="amount_t">
                       Montant max
                      </label>
                      <input 
                        type="text" 
                        value={amount_t}
                        name="amount_t"
                        id="amount_t"
                        className="inputAmount"
                        placeholder="Entrer le montant max"
                        onChange={this.handleChange}
                        required='required'
                      />
                      <a 
                        href="javascript:;"
                        onClick={(real_id)=>onLink_t(v.real_id)}
                        className="btn btnInputAmount_t">
                         Envoyer
                      </a>
                      
                      </span>
                    </span>
                    <span className={ (amountBool_t && v.real_id === real_id) ? "hidden_t" : "displayInLineBlock_t"}>
                        <img 
                          src={image} 
                          alt="Image prod" 
                          className="imgSearchNameByLink_t" 
                        />
                        <span className="userName_t">
                            <span className="spanName_t">
                              {v.firstname} {v.lastname}
                            </span>

                            <a 
                              href="javascript:;"
                              onClick={(real_id)=>onShowAmount_t(v.real_id)}
                              className="btn searchNameByLink_t">
                               Envoyer une demande
                            </a>
                            <Link to={`/profile/${v.receivers.data.length > 0 ? v.receivers.data[0].id : ''}`} 
                              className="btn searchNameByLink2_t">
                              Voir les details
                            </Link>
                        </span> 
                     </span>
                  </p>
            ));
        	return(
                	<>
                    { liaisonList }
                  </>
        	);
        }
}
export default withTranslation()(Liasons);