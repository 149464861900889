import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

class Footer extends Component {
  constructor(props) {
    super();
  }
  render() {
    const { t } = this.props;
    return (
      <footer className="footer_t">
        <div className="container-fluid">
          {/* <nav className="pull-left">
                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="javascript:;">
                      &nbsp;
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="javascript:;">
                      
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="javascript:;">
                      
                    </a>
                  </li>
                </ul>
              </nav> */}

          <div className="row">
            <div className="col col-md-4">
              <div className="div1_footer">
                <form>
                  <label className="labelFooter_t" for="amount_t">
                    Souscrivez dès maintenant
                  </label>
                  <input
                    type="text"
                    // value={amount_t}
                    name="amount_t"
                    id="amount_t"
                    className="inputFooter_t"
                    // placeholder="Entrer le montant max"
                    onChange={this.handleChange}
                    required='required'
                  />
                  <a
                    href="javascript:;"
                    // onClick={(real_id)=>onLink_t(v.real_id)}
                    className="btn btnSubmitFooter_t">
                    Souscrire
                  </a>
                  <p className="TextBottomInputFooter_t">
                    Pour être informé des meilleurs offres tous les jours
                  </p>
                  <p className="restezConFooter_t">
                    Restez connecté :
                  </p>
                  <a href="javascript:;">
                    <img
                      src={require("../../images/facebookFooter.png")}
                      alt="Facebook"
                      className="imageRSFooter" />
                  </a>

                  <a href="javascript:;">
                    <img
                      src={require("../../images/whatsAppFooter.png")}
                      alt="Whatsapp"
                      className="imageRSFooter" />
                  </a>

                  <a href="javascript:;">
                    <img
                      src={require("../../images/instagramFooter.png")}
                      alt="Instagrem"
                      className="imageRSFooter" />
                  </a>

                </form>
              </div>
            </div>
            <div className="col col-md-4">
              <div className="div1_footer">
                <p className="footerRow1Div2_t">
                  Guide d’utilisation
                </p>
                <span className="footerRow1Div2Green_t">
                  Comment créer un compte ?
                </span>
                <span className="footerRow1Div2Green_t">
                  Comment placer un produit dans un panier ?
                </span>
                <span className="footerRow1Div2Green_t">
                  Comment payer un article sur Wemaza
                </span>

              </div>
            </div>
            <div className="col col-md-3">
              <div className="div1_footer">
                <p className="footerRow1Div2_t">
                  Centre d’aide
                </p>
                <span className="footerRow1Div2Green_t">
                  Questions fréquentes
                </span>
                <span className="footerRow1Div2Green_t">
                  Politique de confidentialité
                </span>

              </div>
            </div>
          </div>
          <div className="row footerMarginTopRow2_t">
            <div className="col col-md-5">
              <div className="div1_footer">
                <p className="footerRow1Div2_t">
                  FAQs
                </p>
                <span className="footerRow1Div2White_t">
                  Remise,&nbsp;engagement de service, commentaires,
                  comment devenir prestataire, magasin, panier
                </span>

                <p className="footerRow1Div2_t">
                  Naviguer par catégorie
                </p>
                <span className="footerRow1Div2White_t">
                  Collier, robes, bagues, sacs à bandoulière, bas, trame de
                  cheveux et fermeture, ensembles pour bébé
                </span>

                <p className="footerRow1Div2_t">
                  A propos de Wezama
                </p>
                <span className="footerRow1Div2White_t">
                  Politique de confidentialité
                </span>

              </div>
              <span className="copyright_t">
                &copy; 2021 <span> www.wemaza.com. All rights reserved. </span>
              </span>
            </div>
            <div className="col col-md-6">
              <span className="div_footer">
                <p className="footerRow1Div2_t">
                  Les pays où nous livrons
                </p>
                <span className="footerRow1Div2White_t">
                  Cameroon, D.R.Congo, Congo, Cote d Ivoire, Senegal, Rwanda, Kenya,
                  Zambia, Mali, South Africa
                </span>
                <p className="footerRow1Div2_t">
                  Contacter nous
                </p>
                <p className="footerRow1Div2_t">
                  Adresse :
                </p>

              </span>
            </div>

          </div>


        </div>
      </footer>
    );
  }
}
export default withTranslation()(Footer);