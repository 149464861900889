import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import GoodDealProdComponent from './../../../components/GoodDealProdComponent';
import ProductComponent from './ProductComponent';
import Footer from './../../../layout/Footer';
import ProductComponentGoodDeal from './ProductComponentGoodDeal';
import '../../../../styleSheets/App.css';
import PopularProduct from './PopularProduct';

class HomeComponent extends Component {

  render() {
    const {
      t,
      onAddProduct,
      index_t,
      products,
      isCart_t,
      hash_id,
      fromHomePage,
      isHovered,
      setIsHovered,
      indexHomeProd,
      detailPage
    } = this.props;

    return (
      <div className="container-fluid container_home_products_section HomePageFirstChild">


        {this.props.offline ?
          <span className="offlinePage_t">
            Verifiez votre connextion internet puis réesayez
          </span>
          : ''
        }

        <PopularProduct />


        <div className="row marg_null_t">
          <span className="new_product_t">
            Bons plans : jusqu'à - 50 %
          </span>
        </div>


        <div className="row marg_null_t justify_content_center_t">
          <ProductComponentGoodDeal
            onAddProduct={onAddProduct}
            index_t={index_t}
            products={this.props.data}
            isCart_t={isCart_t}
            hash_id={hash_id}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            indexHomeProd={indexHomeProd}
            detailPage={detailPage}
            gooddeal_bool={true}
          />
        </div>


        <div className="row marg_null_t">
          <span className="new_product_t">
            Nouveautés
          </span>
        </div>


        <div className="row marg_null_t justify_content_center_t">
          <ProductComponent
            onAddProduct={onAddProduct}
            index_t={index_t}
            products={products}
            isCart_t={isCart_t}
            hash_id={hash_id}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            indexHomeProd={indexHomeProd}
            detailPage={detailPage}
            gooddeal_bool={false}
          />
        </div>

        <div className="row marg_null_t">
          {/* <div className="col col-sm-6">
            <p className="slider_or_t"></p>
          </div>
          <div className="col col-sm-6">
            <p className="slider_or_t"></p>
          </div> */}
        </div>

        {/* <p className="paragr_m_deal_t"> */}
        <p className="new_product_t" style={{ margin: '20px .6rem 40px 1rem' }}>
          Ne dépensez pas beaucoup... Découvrez toutes les meilleurs ventes
        </p>

        <div className="row marg_null_gd_t">
          <GoodDealProdComponent
            onAddProduct={onAddProduct}
            index_t={index_t}
            products={products}
            isCart_t={isCart_t}
            hash_id={hash_id}
            fromHomePage={fromHomePage}
          />
        </div>

        {/* <span className="new_product_t" style={{
          display: 'block',
          margin: '30px 0 20px 15px'
        }}>
          Témoignage client
        </span>

        <CarouselTemoignage />

        <div className="row marg_footerTop_gd_t" >
          <FooterTop
            require={require("../../../../images/smile.png")}
            first_title={'Satisfait ou remboursé'}
            second_title={'Remise pour les membre'}
          />
          <FooterTop
            require={require("../../../../images/thumbs-up.png")}
            first_title={'Retours sans frais'}
            second_title={'Service Après Vente agrée'}
          />
          <FooterTop
            require={require("../../../../images/truck.png")}
            first_title={'Paiement 100% sécurisé'}
            second_title={'Nous sécurison vos paiements'}
          />
          <FooterTop
            require={require("../../../../images/truck.png")}
            first_title={'Assistance 24/7'}
            second_title={'Contactez-nous 24/24'}
          />
        </div> */}


        <div className="showAndHiddenRespDesk_t">
          <Footer />
        </div>
        <div className="footerMobil_t">
          &nbsp;&nbsp;
        </div>
      </div>
    );
  }
}
export default withTranslation()(HomeComponent);