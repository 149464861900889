import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ProductList from './ProductList';
import CatSelectComponent from '../../../components/CatSelectComponent';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PaymentTypeComponent from './Selects/PaymentTypeComponent';
import PaymentRadioTypeComponent from './radios/PaymentRadioTypeComponent';
import InvoiceDetails from './InvoiceDetails';



class MembershipCard extends Component {
  render() {
    const {
      onShowPointRelaisFromPDiff_t,
      onChooseSocialAssistance,
      t,
      pointRelaisValue,
      optionPointRelais_t,
      gotToParentValue,
      bool_val,
      cartValue,
      sum_t,
      showCarteadhesionGS,
      handleChangeSocialOption,
      selectedValue,
      isSocialAssistanceOption,
      onSetPaymentType,
      paymentType,
      onHandleParentStateTotal,
      totalHtArrondie,
      totalTauxInteretArrondie,
      totalTtcArrondie
    } = this.props;
    let relaypointArray = []
    let prelaiyId = []

    let relaypointId = cartValue.length > 0 && cartValue.map((v) => v.relaypointId);
    prelaiyId = relaypointId.length > 0 && relaypointId.filter((val, i) => val !== undefined);

    let relaypoint = cartValue.length > 0 && cartValue.map((v) => v.relaypoint);
    relaypointArray = relaypoint.length > 0 && relaypoint.filter((v, i) => v !== undefined);

    let arrayQty = cartValue.length > 0 && cartValue.map((v, i) => v.qty);
    let sumQtys = arrayQty.length > 0 && arrayQty.reduce(function (a, b) {
      return parseInt(a) + parseInt(b);
    }, 0);


    const PaymentType = [
      {
        "value": 8,
        "label": "En une fois"
      },
      {
        "value": 9,
        "label": "Avec aide sociale"
      }
    ];
    const PaymentTypeInit = [
      {
        "value": " ",
        "label": " "
      }
    ];

    return (
      <>
        <div className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-6">
          <p style={{ background: '#f9f9f9' }}>
            {/* <p className="chooseRelayPoint_t">
              Carte d'adhérent
            </p> */}
            {/* <div className="row">
              <div className="col-3 col-xs-12 col-sm-6 col-md-4 col-lg-2" style={{ padding: '25px' }}>
                <span>Carte</span>
              </div>
              <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-10">

                <input
                  type='text'
                  // value={this.props.showCarteadhesion} 
                  style={{
                    margin: '5px 0 0 -5px',
                    color: '#000',
                    width: '100%',
                    maxWidth: '450px',
                    border: '1px solid rgb(229, 229, 229)',
                    borderRadius: '5px',
                    padding: '10px'
                  }}
                  placeholder="XGDHJSYIDHFJ_I@HDY " />

              </div>
            </div> */}


            <PaymentRadioTypeComponent
              onChooseSocialAssistance={onChooseSocialAssistance}
              sum_t={sum_t}
              handleChangeSocialOption={handleChangeSocialOption}
              selectedValue={selectedValue}
              onSetPaymentType={onSetPaymentType}
              paymentType={onSetPaymentType}

            />

          </p>

        </div>

        <InvoiceDetails
          sumQtys={sumQtys}
          sum_t={sum_t}
          modalEligible={this.props.modalEligible}
          showCarteadhesionGS={showCarteadhesionGS}
          onBtnClick={onShowPointRelaisFromPDiff_t}
          classNameFirstDiv={'col-12 col-xs-12 col-sm-6 col-md-4 col-lg-6 acideLeftCart_t'}
          selectedValue={selectedValue}
          isSocialAssistanceOption={isSocialAssistanceOption}
          onHandleParentStateTotal={onHandleParentStateTotal}
          totalHtArrondie={totalHtArrondie}
          totalTauxInteretArrondie={totalTauxInteretArrondie}
          totalTtcArrondie={totalTtcArrondie}
        />

      </>
    );
  }
}
export default withTranslation()(MembershipCard);