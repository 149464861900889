import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class DemandesComponent extends Component {
	constructor(props) {
		super();
	}
	render(){
       let { t, userLinks, image ,imageVerticale, onAccept_t } = this.props

       let userLinksList = userLinks.length > 0 && userLinks.map((v, i) =>(
                <>
                  <span className="backgroundCommande_t" key={i}>
                      <img 
                        src={require("../../../../images/userProfil.png")} 
                        alt="Image prod" 
                        className="imgDemandeRound_t" /> 
                        <div className="divBlocCommande_t">
                          <span className="spanDemandeNameTitle_t">
                            Nom complet
                          </span>

                          <span className="spanDemandeName_t">
                            {v.donators.data.lastname} {v.donators.data.firstname}
                          </span>
                        </div>

                        <img 
                        src={require("../../../../images/vertcalLine.png")} 
                        alt="Image prod" 
                        className="orOperator_t" />

                        <div className="divBlocCommande_t">
                          <span className="spanDemandeNameTitle_t">
                            Téléphone
                          </span>

                          <span className="spanDemandeName_t">
                            {v.donators.data.phone === null ? 'pas de numéro' : v.donators.data.phone}
                          </span>
                        </div>

                        <img 
                        src={require("../../../../images/vertcalLine.png")} 
                        alt="Image prod" 
                        className="orOperator_t" />

                        <div className="divBlocCommande_t">
                          <span className="spanDemandeNameTitle_t">
                            Montant 
                          </span>

                          <span className="spanDemandeName_t">
                            { v.amount === null ? "Aucun defini" : v.amount+' xaf' }
                          </span>
                        </div>

                        <div className="divButtonDemande_t">
                          <span className="bgButCommande_t">
                            <a href="javascript:;"
                              onClick={(hash_id)=>onAccept_t(v.id)}
                               className="AccepterDemande_t"
                             >
                               Accepter la demande
                            </a> 

                            <Link to={`/profile/${v.donators.data.id}`} className="seeCart_t">
                              Voir le profil
                            </Link> 
                          </span>
                        </div>
                        </span> <br />
                      </>
            ));

        	return(
                <>
                    { userLinksList }
                </>
        	);
        }
}
export default withTranslation()(DemandesComponent);