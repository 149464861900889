import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

class GoodDealProdComponent extends Component {
  constructor(props) {
    super();
  }
  render() {
    const { t,
      // onDeleteProduct, 
      onUpdateProduct,
      products,
      // onShowSetting_t,
      // show_setting,
      index_t,
      onAddProduct,
      fromHomePage,
      handleChangeProductAndReloadPage } = this.props;

    let productsList = products.length > 0 && products.map((v, i) => {

      const reelPriceInEuro = parseFloat((v.price / 650).toFixed(2))
      const reductionPriceInEuro = parseFloat((v.reduction / 650).toFixed(2))

      return (
        // <div className="col-5 col-xs-12 col-sm-6 col-md-4 col-lg-3 mr-3 goodDeal_border_t" key={i}>
        <div className="col-5 col-xs-12 col-sm-6 col-md-4 col-lg-3 mr-3 goodDeal_border_t" key={i}>
          {fromHomePage ?
            <div className="row product-card-inner" style={{ border: '1px solid #E5E5E5' }}>
              <div className="col-12 col-sm-4 product-image">
                <img
                  src={v.annexesImages.data[0].first_annex}
                  alt="Image prod"
                  className="img_round2_t" />
              </div>
              <div className="col col-sm-8 shop_marg_img_text">

                <span className="product_name">{v.shop.data.name.toUpperCase()}</span>

                {/* <p className="showAndHiddenResp_t" style={{ 'margin-top': '-40px' }}> */}
                <span className="showAndHiddenResp_t" style={{ 'margin-top': '-3px' }}>
                  <span className="coast_left">
                    {
                      v.reduction !== 0 ?
                        //  Math.round(parseInt(v.reduction) * 100 / parseInt(v.price)) + " " + v.currency 
                        Math.round(parseFloat((((v.price - v.reduction) / v.price) * 100).toFixed(2))) + `€`
                        :
                        // v.price + " " + v.currency
                        parseFloat((v.price / 650).toFixed(2)) + `€`
                    }
                  </span>
                </span>

                <p className="product_gd_desc">
                  {v.description}
                </p>
                <span className="product_gd_ran">
                  <img
                    src={require("../../images/star_yellow.png")}
                    alt="Image prod 1"
                    className="star_yellow" />
                  <img
                    src={require("../../images/star_yellow.png")}
                    alt="Image prod 1"
                    className="star_yellow" />
                  <img
                    src={require("../../images/star_yellow.png")}
                    alt="Image prod 1"
                    className="star_yellow" />
                  <img
                    src={require("../../images/star_white.png")}
                    alt="Image prod 1"
                    className="star_white" />
                </span>
                <span className="product_gd_ran showAndHiddenRespDesk_t">
                  <span className="coast_left">
                    {
                      v.reduction !== 0 ?
                        //  v.reduction + " CFA" 
                        parseFloat((v.reduction / 650).toFixed(2)) + `€`
                        :
                        // v.price + " CFA"
                        parseFloat((v.price / 650).toFixed(2)) + `€`
                    }
                  </span>
                  <span className="coast_right">
                    {
                      v.reduction !== 0 ?
                        //  v.price + " CFA" 
                        parseFloat((v.price / 650).toFixed(2)) + `€`
                        :
                        // v.reduction
                        parseFloat((v.reduction / 650).toFixed(2)) + `€`
                    }
                  </span>
                </span>
                <a href="javascript:;"
                  onClick={(e, url_slug, hash_id, real_id, nameProd, price, reduction, quantity, image, shopName, country, city, country_real_id) => onAddProduct(e, v.category.data.url_slug, v.id, v.real_id, v.name, reelPriceInEuro, reductionPriceInEuro, v.quantity, v.image, v.shop.data.name, v.shop.data.provider.data.user.data.country.data.name_fr, v.shop.data.city.data.name, v.shop.data.provider.data.user.data.country_id)}
                  // onClick={(e, hash_id) => onAddProduct(e, v.id)}
                  className="btn btn-block btn_basket_gd_prod">
                  <span className="text_btn_gd_prod">AJOUTER AU PANIER</span>
                </a>

              </div>
            </div>
            :
            // <Link to={`/product/${v.id}`} key={i}>
            <div className="row" style={{ border: '1px solid #E5E5E5', cursor: 'pointer' }}
              onClick={(id) => handleChangeProductAndReloadPage(v.id)}>
              <div className="col col-sm-4">
                <img
                  src={v.annexesImages.data[0].first_annex}
                  alt="Image prod"
                  className="img_round2_t" />
              </div>
              <div className="col col-sm-8 shop_marg_img_text">

                <span>{v.shop.data.name.toUpperCase()}</span>

                <p className="showAndHiddenResp_t" style={{ 'margin-top': '-40px' }}>
                  <span className="coast_left">
                    {v.reduction !== 0 ?
                      //  Math.round(parseInt(v.reduction) * 100 / parseInt(v.price)) + " " + v.currency 
                      Math.round(parseFloat((((v.price - v.reduction) / v.price) * 100).toFixed(2))) + `€`
                      :
                      //  v.price + " " + v.currency
                      parseFloat((v.price / 650).toFixed(2)) + `€`
                    }
                  </span>
                </p>

                <span className="product_gd_desc">
                  {v.description}
                </span>
                <span className="product_gd_ran">
                  <img
                    src={require("../../images/star_yellow.png")}
                    alt="Image prod 1"
                    className="star_yellow" />
                  <img
                    src={require("../../images/star_yellow.png")}
                    alt="Image prod 1"
                    className="star_yellow" />
                  <img
                    src={require("../../images/star_yellow.png")}
                    alt="Image prod 1"
                    className="star_yellow" />
                  <img
                    src={require("../../images/star_white.png")}
                    alt="Image prod 1"
                    className="star_white" />
                </span>
                <span className="product_gd_ran showAndHiddenRespDesk_t">
                  <span className="coast_left">
                    {
                      v.reduction !== 0 ?
                        //  v.reduction + " CFA" 
                        parseFloat((v.reduction / 650).toFixed(2)) + `€`
                        :
                        // v.price + " CFA"
                        parseFloat((v.price / 650).toFixed(2)) + `€`
                    }

                  </span>
                  <span className="coast_right">
                    {
                      v.reduction !== 0 ?
                        //  v.price + " CFA" 
                        parseFloat((v.price / 650).toFixed(2)) + `€`
                        :
                        // v.reduction
                        parseFloat((v.reduction / 650).toFixed(2)) + `€`
                    }
                  </span>
                </span>

              </div>
            </div>
            // </Link>
          }

        </div>
      )
    });
    return (
      <>
        {productsList}
      </>
    );
  }
}
export default withTranslation()(GoodDealProdComponent);