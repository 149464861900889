import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CatSelectComponent from './../../../components/CatSelectComponent';
import FloatingInputBis from './../../../components/FormsComponent/FloatingInputBis';
import searchUsersComponent from './../../../components/searchUsersComponent';
import FloatingInput from './../../../components/FormsComponent/FloatingInput';
import ProductList from './ProductList';
import ReciverForms from './ReciverForms';
import AcideRightItem from './AcideRightItem';
import InvoiceDetails from './InvoiceDetails';
import PaymentRadioTypeComponent from './radios/PaymentRadioTypeComponent';

class Payment extends Component {

  render() {
    const { t,
      cartValue,
      sum_t,
      onsubmitNewUser_t,
      onUserNotExistBool,
      creator_name,
      onPayment,
      showCarteadhesionGS,
      isSocialAssistanceOption,
      selectedValue,
      onChooseSocialAssistance,
      handleChangeSocialOption,
      postregisterStore,
      nameChoiceStore,
      onsubmitExitingUsers_t,
      totalSum,
      montantLivraison,
      onHandleParentStateTotal,
      totalHtArrondie,
      totalTauxInteretArrondie,
      totalTtcArrondie,
      paymentType,
      isSocialPayment,
      sendCart,
      userNotExistBool,
      treatment_costs,
      social_assistance,
      delivery,
      amountS,
      fromCMD,
      totalHtArrondieS,
      interest_rate,
      insertInEconomicAccessibility
    } = this.props;

    let arrayQty = cartValue.length > 0 && cartValue.map((v, i) => v.qty);
    let sumQtys = arrayQty.length > 0 && arrayQty.reduce(function (a, b) {
      return parseInt(a) + parseInt(b);
    }, 0);
    // console.log("Payment Lud Test ");
    return (
      <>
        <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-1 recipient_t showAndHiddenRespDesk_t">
        </div>
        <div className="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 recipient_t">

          <div className="searchRecipent_t backgroundPaymentSection">
            <p onClick={onUserNotExistBool} className="searchRecipentTitle_t">
              Choisissez un mode de paiement
            </p>
            <p className='receiver_payment_name_first_nama'>
              Nom du bénéficiaire :
              <span className='receiver_payment_name'>{
                postregisterStore && !!postregisterStore.firstname ?
                  postregisterStore.firstname + ' ' + postregisterStore.lastname
                  :
                  nameChoiceStore

              }</span>
            </p>
            <p className='paymentInOneInstalment'>
              Paiement en 1 fois
              {/* <p className="paymentName_t">
                {creator_name} <span className="delBtnPayment_t"> X </span>
              </p> */}
            </p>
            {/* <div className="paymentDidName_t">
              <p className="payerName_t">
                Nom ou prénom d’un payeur
                pour qu’il se charge de payer votre panier
              </p>
              <p className="imgPayment_t">
                <img src={require("../../../../images/paymentName.png")} />
              </p>

              <p className="paymentName_t">
                {creator_name} <span className="delBtnPayment_t"> X </span>
              </p>
            </div> */}

            {/* <div className="payment_t">
              <p className="payerName_t">
                Il vous faudra transférer le montant de la facture sur notre compte bancaire.
                Nous vous enverrons votre confirmation par e-mail. Nous traiterons la votre
                commande dès la réception du paiement.
              </p>
              <p className="imgPayment_t">
                <img style={{ width: '200px' }} src={require("../../../../images/rectanglePayment.png")} />
              </p>

              <a
                href="javascript:;"
                onClick={onPayment}
                className="paymentName2_t">
                Payer par virement bancaire
              </a>
            </div> */}

            <form className="blackProfileImg_t" onSubmit={onsubmitNewUser_t}>

              {/*<ReciverForms
                                errors={errors}
                                postregister={this.props.postregister}
                                handleChange={this.props.handleChange}
                                gotToParentValue={this.props.gotToParentValue}
                                option_country={this.props.option_country}
                                option_city={this.props.option_city}
                                eye_password_bool={this.props.eye_password_bool}
                                toggle={this.props.toggle}
                                isLoading={this.props.isLoading}
                                // offline={this.props.offline}
                                email_exit={this.props.email_exit}
                                email_not_val={this.props.email_not_val}
                              />*/}

              {/*<div className="justifyRight_t">
                              <button
                                 style={{color: '#FFF'}}
                                 className="btn next_cart_t">
                                   Continuer
                              </button>
                              </div>*/}

              {/*{showBtnTrue ? 
                                  <img 
                                    src={require("../../../../images/checkOK.png")} 
                                    alt="Image prod" 
                                    className="imgOkUser_round_t" 
                                  />
                                :
                                    ''
                                }*/}

              <PaymentRadioTypeComponent
                onChooseSocialAssistance={onChooseSocialAssistance}
                sum_t={sum_t}
                handleChangeSocialOption={handleChangeSocialOption}
                selectedValue={selectedValue}
                totalSum={totalSum}
                paymentType={paymentType}
                isSocialPayment={isSocialPayment}
                totalHtArrondie={totalHtArrondie}
                insertInEconomicAccessibility={insertInEconomicAccessibility}
              // onSetPaymentType={onSetPaymentType}
              // paymentType={onSetPaymentType}

              />

            </form>

          </div>

        </div>

        <InvoiceDetails
          sumQtys={sumQtys}
          sum_t={sum_t}
          modalEligible={this.props.modalEligible}
          showCarteadhesionGS={showCarteadhesionGS}
          // onBtnClick={onPayment}
          // onBtnClick={(sendCart && userNotExistBool) ? onsubmitNewUser_t : onsubmitExitingUsers_t}
          onBtnClick={onsubmitExitingUsers_t}
          classNameFirstDiv={"col col-xs-12 col-sm-12 col-md-4 col-lg-4 acideLeftCart2_t"}
          selectedValue={selectedValue}
          isSocialAssistanceOption={isSocialAssistanceOption}
          nextBtnTitle={"Choisir un mode de paiement"}
          deliveryPrice={montantLivraison}
          onHandleParentStateTotal={onHandleParentStateTotal}
          totalHtArrondie={totalHtArrondie}
          totalTauxInteretArrondie={totalTauxInteretArrondie}
          totalTtcArrondie={totalTtcArrondie}

          treatment_costs={treatment_costs}
          social_assistance={social_assistance}
          delivery={delivery}
          amountS={amountS}
          fromCMD={fromCMD}
          totalHtArrondieS={totalHtArrondieS}
          interest_rate={interest_rate}
        />


      </>
    );
  }
}
export default withTranslation()(Payment);