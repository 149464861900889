import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import fr from 'ree-validate/dist/locale/fr';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router'
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { lightTheme, darkTheme } from '../../../../js/theme';
import { GlobalStyles } from '../../../../js/global';
import {
  changeNavigatorTheme,
  auth_t,
  initialStatManual // pour rediriger vers la page coming soon
} from '../../../../store/actions';
import CryptoJS from 'crypto-js';
import AuthService from '../../../../services/auth';
import ProductService from '../../../../services/product';
import LoginComponent from './Components/LoginComponent';
import NewHeader from '../../../layout/NewHeader5';
import MobileCategoriesMenu from '../../../layout/HeaderComponents/MobileCategoriesMenu';

class Page extends Component {
  constructor(props) {
    super();
    this.validator = new ReeValidate.Validator({
      email: 'required|email',
      password: 'required|min:3',
    });
    this.validator.localize('fr', fr)
    this.state = {
      postlogin: {
        email: '',
        password: '',
      },
      errors: this.validator.errors,
      offline: false,
      theme_bool: false,
      langBool: true,
      def_theme: '',
      eye_password_bool: true,
      checked: true,
      checked_2: false,
      checked_3: false,
      checked_switch: true,
      show_t: 'hidden_t',
      text_or_not: false,
      isLoading: false,
      options_fr: [
        { value: 'en', label: "Anglais" }
      ],
      options_eng: [
        { value: 'fr', label: "Francais" }
      ],
      options_devise: [
        { value: '€', label: "Dollar" }
      ],
      option_pays: [
        { value: 'cmr', label: "Cameroun" },
        { value: 'cg', label: "Congo k" }
      ],
      pays_value: '',
      option_villes: [
        { value: 'Dla', label: "Douala" },
        { value: 'Ynde', label: "Yaounde" }
      ],
      villes_value: '',
      device_data: '',
      messageBool_t: false,
      toggleBool_t: false,
      allCategories: []
    };
    this.theme_botton_D = this.theme_botton_D.bind(this);
    this.theme_botton_W = this.theme_botton_W.bind(this);
    this.toggle = this.toggle.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.checked_t = this.checked_t.bind(this);
    this.checked_rad1_t = this.checked_rad1_t.bind(this);
    this.checked_rad2_t = this.checked_rad2_t.bind(this);
    this.checked_rad3_t = this.checked_rad3_t.bind(this);
    this.for_remove_js = this.for_remove_js.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this)
    this.post_login_user = this.post_login_user.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.def_theme === '') {
      this.check_theme();
    }
    this.props.dispatch(initialStatManual({}));
  }
  componentDidMount() {
    this.props.dispatch(auth_t({ __iA: true, __iV: true }));
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: true });
    }
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: false });
    }
    if (this.props.i18n.language === 'fr-FR' || 'fr') {
      this.setState({ langBool: true })
      // console.log('this.props.lang true', this.props.i18n.language)
    }
    if (this.props.i18n.language === 'en') {
      this.setState({ langBool: false })
      // console.log('this.props.lang false', this.props.i18n.language)
    }
    this.allCategories();
  }

  allCategories = () => {
    this.props.dispatch(ProductService.getAllCategories())
      .then((result) => {
        let data = result.data;

        const categories = this.buildCategoryHierarchy(data);

        let optionsHome = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));

        this.setState({ option_categorie: optionsHome, allCategories: categories });


        let initResp = [{ value: '', label: 'Tout' }]
        let optionsResp = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
        initResp = initResp.concat(optionsResp);
        let options = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  buildCategoryHierarchy = (data) => {
    // console.log("VBVV ", categories); return;

    const categoryMap = {};
    const hierarchy = [];

    // Map categories by slug
    data.forEach(item => {
      categoryMap[item.slug] = {
        name: item.name,
        iconUrl: item.images,
        path: item.url_slug,
        slug: item.slug,
        level: !!item.level ? parseInt(item.level) : 0,
        parent_id: item.parent_id,
        subcategories: []
      };
    });

    // Build hierarchy based on level and url_slug
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.level === 0 || category.level === '') {
        hierarchy.push(category);
      } else {
        const parentSlug = category.path.split('/')[category.level];
        if (categoryMap[parentSlug]) {
          categoryMap[parentSlug].subcategories.push(category);
        }
      }
    });

    // Update path if subcategories length is 0
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.subcategories.length > 0) {
        delete category.path;
      }
    });

    return hierarchy;
  }

  handleClick(lang) {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    this.setState({ langBool: !this.state.langBool });
  }
  checked_t() {
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  checked_rad1_t() {
    if (this.state.checked) {
      this.setState({ checked: true, checked_2: false, checked_3: false });
    }
    this.setState({ checked_2: false, checked_3: false });
  }
  checked_rad2_t() {
    if (this.state.checked_2) {
      this.setState({ checked: false, checked_2: true, checked_3: false });
    }
    this.setState({ checked: false, checked_3: false });
  }
  checked_rad3_t() {
    if (this.state.checked_3) {
      this.setState({ checked: false, checked_2: false, checked_3: true });
    }
    this.setState({ checked: false, checked_2: false });
  }
  toggle() {
    this.setState({ eye_password_bool: !this.state.eye_password_bool })
  }

  check_theme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // console.log('🎉 Dark mode is supported');
      this.setState({ def_theme: 'dark', theme_bool: true, checked_switch: false })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      // console.log('🎉 light mode is supported');
      this.setState({ def_theme: 'light', theme_bool: true, checked_switch: true })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
      // console.log('ciphertext ', ciphertext)
    }
  }
  for_remove_js(e) {
    e.preventDefault();
  }
  theme_botton_D(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'dark', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  theme_botton_W(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'light', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  post_login_user() {
    this.setState({ isLoading: true });
    let credentials = { email: this.state.email, password: this.state.password }
    // alert("Login");

    //  navigator.onLine ?
    //   this.props.dispatch(AuthService.login(credentials))
    //     .then((result)  => {
    //       this.setState({isLoading: false})
    //     })
    //     .catch(({data}) => {
    //         this.setState({show_t: 'show_t'});
    //         console.log('errors ', data);
    //         this.setState({isLoading: false})
    //     })
    // :
    // alert("No connexion");
    // this.setState({offline: true, isLoading_modal: false})
  }
  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    const { postlogin } = this.state;
    postlogin[name] = value;

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors, postlogin })
      });
  }

  handleSubmit(postlogin) {
    this.setState({ isLoading: true });

    this.props.dispatch(AuthService.login(postlogin))
      .then((result) => {
        this.props.history.push("/");
        this.setState({ isLoading: false, offline: false })
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {

          this.setState({ show_t: 'show_t', isLoading: false, messageBool_t: true, messageError_t: err.statusCode.response.data.message ? err.statusCode.response.data.message : "" });
        }
      })

  }

  async validateAndSubmit(e) {
    e.preventDefault()

    const { postlogin } = this.state
    const { errors } = this.validator

    const valid = this.validator.validateAll(postlogin)

    if (valid) {
      this.handleSubmit(postlogin)
    } else {
      this.setState({ errors })
    }
  }

  gotToParentValue = (value, label) => {
    if (label === "Francais" || label === "French") {
      const options_fr = [{ value: 'en', label: "Anglais" }];
      const options_eng = [{ value: 'fr', label: "Francais" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("fr");
    }
    if (label === "Anglais" || label === "English") {
      const options_fr = [{ value: 'en', label: "English" }];
      const options_eng = [{ value: 'fr', label: "French" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("en");
    }
    if (label === "Dollar") {
      this.setState({
        device_data: value
      })
    }
  }
  responseGoogle = async (response) => {
    const profileObj = response.profileObj;
    console.log('response_t ', profileObj === undefined ? 'Yess' : 'Noo');
    const data = {
      email: profileObj !== undefined ? profileObj.email : '',
      social_provider: 'google',
      social_id: profileObj !== undefined ? profileObj.googleId : '',
      social_nickname: profileObj !== undefined ? profileObj.name : '',
      social_token: response.accessToken,
      social_refresh_token: response.accessToken
    }

    await this.props.dispatch(AuthService.socialLogin(data))
      .then((result) => {
        this.props.history.push("/");
        this.setState({ isLoading: false, offline: false })
      })
      .catch((err) => {
        this.setState({ show_t: 'show_t' });
        this.setState({ isLoading: false });
      })
  }
  responseFacebook = (response) => {
    console.log(response)
  }

  onToggle_t = () => {
    this.setState({ toggleBool_t: !this.state.toggleBool_t });
  }

  onLogout = () => {
    this.props.dispatch(AuthService.logout())
      .then((result) => {
        this.props.history.push("/");
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  render() {
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);

    const { t } = this.props;
    let { errors } = this.state;
    //console.log('d_theme ', {d_theme})

    return (
      <ThemeProvider theme={d_theme === "\"dark\"" ? darkTheme : lightTheme}>
        <>
          <GlobalStyles />
          <Helmet>
            <meta charSet="UTF-8" />
            <meta
              name="description"
              content="Ekomart-Grocery-Store(e-Commerce) HTML Template: A sleek, responsive, and user-friendly HTML template designed for online grocery stores."
            />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="keywords" content="Grocery, Store, stores" />
            <title>Wemaza</title>
            {/* <link rel="shortcut icon" type="image/x-icon" href="assets/images/fav.png" /> */}
            <link rel="shortcut icon" type="image/x-icon" href="assets/favicon.ico" />
            {/* plugins css */}
            {/* <link rel="stylesheet preload" href="assets/css/plugins.css" as="style" /> */}
            <link rel="stylesheet preload" href="assets/css/App.css" as="style" />
            {/* rts header area start */}
            <script defer src="assets/js/plugins.js"></script>
            <script defer src="assets/js/main.js"></script>
          </Helmet>
          <div className="wrapper login_t login_prest_t">


            <NewHeader
              allCategories={this.state.allCategories}
              fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
              cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
              idProf={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
              // gotToParentValue={this.searchCityWithCallback}
              isShowSearch_t={false}
              // searchProducts_t={this.state.searchProducts_t}
              // searchNameWithCallback={this.searchNameWithCallback}
              onLogout={this.onLogout}
              onToggle_t={this.onToggle_t}

            // propsCustom={this.props}
            // allProductsBycategories={this.allProductsBycategories}
            // includesForAllProducts_t={this.state.includesForAllProducts_t}
            />

            <div id="side-bar"
              className={this.state.toggleBool_t ? "side-bar header-two show" : "side-bar header-two"}
            >
              <button className="close-icon-menu"
                onClick={this.onToggle_t}>
                <IconButton aria-label="close">
                  <CloseIcon />
                </IconButton>
              </button>
              <MobileCategoriesMenu
                prodId_t={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
                onLogout={this.onLogout}
                allCategories={this.state.allCategories}
                isShowSearch_t={true}
                fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
              />

            </div>

            <section className="container-fluid h-100 img_bg_user">
              <div className="row" style={{ padding: '10% 0 0 0' }}>
                <div className="col col-sm-3 showAndHiddenRespDesk_t"></div>
                <div className="col div_login_t">

                  {/*<p className="header_f_reg_t">
                Commencez à vendre sur Wemaza
            </p>*/}
                  <div className="row">
                    <div className="col col-sm-5 oauth_t showAndHiddenRespDesk_t">
                      <span className="connexion_text_t">
                        connectez-vous
                      </span>
                      <GoogleLogin
                        clientId="87278614366-jerd4n2kenrt7gqpmu06rrq94086nmj6.apps.googleusercontent.com"
                        buttonText="Se connecter avec Google"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={'single_host_origin'}

                      />
                      {/*<FacebookLogin
              appId="1205237793275621"
              autoLoad={true}
              fields="name,email,picture"
              callback={this.responseFacebook}
            />*/}

                      {/*<a href="javascript:;" className="google_style_t">
               <img className="img_oauth_t" src={require('../../../../images/google_icon.png')} alt="Google" />
                <span className="text_oauth_t">
                  Se connecter avec Google
                </span>
            </a>*/}
                      {/*<a href="javascript:;" className="facebook_style_t">
               <img className="img_oauth_t" src={require('../../../../images/facebook_icon.png')} alt="Facebook" />              
                <span className="text_oauth_white_t">
                  Se connecter avec facebook
                </span>
            </a>*/}
                    </div>
                    <div className="col-12 col-sm-7">
                      {/*
            <spam className="connexion_text_t showAndHiddenResp_t">
                connectez-vous
            </spam>*/}

                      <LoginComponent
                        errors={this.state.errors}
                        postlogin={this.state.postlogin}
                        eye_password_bool={this.state.eye_password_bool}
                        toggle={this.toggle}
                        handleChange={this.handleChange}
                        validateAndSubmit={this.validateAndSubmit}
                        offline={this.state.offline}
                        messageError_t={this.state.messageError_t}
                        messageBool_t={this.state.messageBool_t}
                        isLoading={this.state.isLoading}
                        sign_in={'sign_in'}
                        className={'btn btn_login'}
                        lock_style={{
                          margin: '.8rem 0px 0px 0rem',
                          'z-index': '3',
                          position: 'absolute',
                          color: 'rgb(255, 255, 255)',
                          width: '20px'
                        }}
                      />
                    </div>
                    <div className="col-12 oauth_t showAndHiddenResp_t">
                      <GoogleLogin
                        clientId="87278614366-jerd4n2kenrt7gqpmu06rrq94086nmj6.apps.googleusercontent.com"
                        buttonText="Se connecter avec Google"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={'single_host_origin'}

                      />
                    </div>
                  </div>
                </div>
                <div className="col col-sm-3 showAndHiddenRespDesk_t"></div>

              </div>
            </section>
          </div>
        </>
      </ThemeProvider>
    );
  }
}
export default withTranslation()(Page);