import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class FormsChoice extends Component {
	render(){
       let { t } = this.props 
        	return(
        		<>
        			<div className="form-check-inline" style={{margin: '0 0 6px 0'}}>
		                <label className={`form-check-label ${this.props.className_perso}`}> 
		                  <input 
		                    type={this.props.type} 
		                    className="form-check-input" 
		                    value={this.props.value}
		                    name={this.props.name}
		                    checked={this.props.checked}
		                    onChange={this.props.onChange}
                  			required={this.props.required === true ? 'required' : ''}
		                     />
		                    {t(this.props.write_text)} 
		                </label>
		             </div>
          		</>
        	);
        }
}
export default withTranslation()(FormsChoice);