import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactPlaceholder_T from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import Stats from './Stats';

class ReactPlaceholder extends Component {
  constructor(props){
    super(props)
    this.state = {
      num_map: [1 , 2],
    }
  }
	render(){
        	const { t } = this.props; 
          let place_h_list = this.state.num_map.map((v, i)=>(
              <>
                     <ReactPlaceholder_T customPlaceholder={<Stats />} ready={this.props.bool_place_h} >
                        <div className="row" style={{textAlign: 'inherit'}}>
                            <div className="col-3 col-md-3 col-lg-4 col-xl-3">

                            </div>
                        </div>
                      </ReactPlaceholder_T>     
                  </>
          ));
        	return(
                	<div className="liste_place_h_t">

                     { place_h_list }  

                  </div>
        	);
        }
}
export default withTranslation()(ReactPlaceholder);