import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Header from './../../layout/HeaderOne';
import Dashboard from './Components/Dashboard'
import Footer from './../../layout/Footer';
import AuthService from '../../../services/auth';

const Historique = (props) => {
  const history = useHistory();
  let dispatch = useDispatch();
  const [langBool, setLangBool] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [country_t, setCountryT] = useState(null);
  const [countryHash_t, setCountryHashT] = useState('');
  const [home_t, setHomeT] = useState(true);
  const [city_id, setCityId] = useState(null);
  const [toggleBool_t, setToggleBool_t] = useState(false);
  const [country_value, setCountryValue] = useState('');
  const [isLoadingHeader, setIsLoadingHeader] = useState(false);
  const [option_country, setOptionCountry] = useState([
    { value: '', label: "" },
    { value: '', label: "" }
  ]);
  const [option_city, setOptionCity] = useState([
    { value: '', label: "" },
    { value: '', label: "" }
  ]);
  const [options_fr, setOptionsFr] = useState([
    { value: 'en', label: "Anglais" }
  ]);
  const [options_eng, setOptionsEng] = useState([
    { value: 'fr', label: "Francais" }
  ]);

  let isAuthenticated = useSelector((state) => state.__iA ? state.__iA.isAuthenticated : false);

  let reduxName = useSelector((state) => state.__iA ? state.__iA.__pid.__pfn : '');

  let cartValue = useSelector((state) => state.__iA ? state.__iA.__cv : []);

  let idProf = useSelector((state) => state.__iA ? state.__iA.__pid : null);

  const { t, i18n } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {

    if (i18n.language === 'fr-FR' || 'fr') {
      setLangBool(true);
    }

    if (i18n.language === 'en') {
      setLangBool(false);
    }

    allCountries();
    // changeLanguage('fr');
  }, []);

  const allCountries = () => {
    dispatch(AuthService.getAllCountries())
      .then((result) => {

        let data = result.data;
        let options = data.map((value, index) => (

          {
            value: { id: value.id, real_id: value.real_id },
            label: this.state.langBool === true ? value.name_fr : value.name_en
          }
        ));

        setIsLoading(false);
        setShowAlert(false);
        setOptionCountry(options);

      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  const allCitiesCountry = (value) => {
    dispatch(AuthService.getCitiesCountry(value))
      .then((result) => {

        let data = result;
        let options = data.map((value, index) => (
          {
            value: value.id,
            label: value.name
          }
        ));

        setIsLoading(false);
        setShowAlert(false);
        setOptionCity(options);

      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  const gotToParentValue = (value, label) => {
    if (label === "Francais" || label === "French") {
      const options_frVar = [{ value: 'en', label: "Anglais" }];
      const options_engVar = [{ value: 'fr', label: "Francais" }];
      setOptionsFr(options_frVar);
      setLangBool(true);
      setOptionsEng(options_engVar);

      this.handleClick("fr");
    }
    if (label === "Anglais" || label === "English") {
      const options_frVar = [{ value: 'en', label: "English" }];
      const options_engVar = [{ value: 'fr', label: "French" }];

      setOptionsFr(options_frVar);
      setLangBool(true);
      setOptionsEng(options_engVar);

      this.handleClick("en");

    }
    if (label === "country") {
      dispatch({ type: "HOMEP", payload: { __hp: false } });
      setCountryT(value.real_id);
      setCountryHashT(value.id);
      setHomeT(false);
      setCityId(null);

      // this.setState({
      //   country_t: value.real_id,
      //   countryHash_t: value.id,
      //   home_t: false,
      //   city_id: null
      // }, () => this.filterAllProducts())

      // history.push(`/category/country:${value.real_id}`);
    }
    if (label === "city_id") {
      setCityId(value);
      // this.setState({ city_id: value }, () => this.filterAllProducts());
      // history.push(`/category/city:${value}`);
    }
  }

  const onToggle_t = () => {
    setToggleBool_t(!toggleBool_t);
  }

  const onLogout = () => {
    setIsLoadingHeader(true);
    dispatch(AuthService.logout())
      .then((result) => {
        dispatch({ type: "PRODID_T", payload: '' });
        history.push("/");
        setIsLoadingHeader(false);
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  return (
    <>

      <Header
        t={t}
        changeLanguage={changeLanguage}
        langBool={langBool}
        isLoading={isLoading}
        showAlert={showAlert}
        option_country={option_country}
        option_city={option_city}
        options_fr={options_fr}
        options_eng={options_eng}
        country_t={country_t}
        countryHash_t={countryHash_t}
        home_t={home_t}
        city_id={city_id}
        gotToParentValue={gotToParentValue}
        isLoadingHeader={isLoadingHeader}
        isAuth_t={isAuthenticated}
        fName={reduxName}
        toggleBool_t={toggleBool_t}
        onToggle_t={onToggle_t}
        cartValue={cartValue}
        country_value={country_value}
        onLogout={onLogout}
        // idProf={idProf}
        idProf={idProf?.__id}
      // checked_switch={checked_switch}
      />
      <Dashboard
        t={t}
      />
      <Footer />
    </>
  )
}

export default Historique