import React from 'react';
import axios from 'axios'
// import pica from 'pica';
// import Http from '../Http'


// Fonction pour convertir une image Blob en format WebP
// export async function blobToWebP(blob) {
//     const picaInstance = pica();
//     const canvas = document.createElement('canvas');
//     const image = document.createElement('img');

//     return new Promise((resolve, reject) => {
//         const fileReader = new FileReader();
//         fileReader.onload = function () {
//             image.onload = function () {
//                 canvas.width = image.width;
//                 canvas.height = image.height;
//                 picaInstance
//                     .resize(image, canvas)
//                     .then((result) => {
//                         const webpData = canvas.toDataURL('image/webp');
//                         resolve(webpData);
//                     })
//                     .catch((error) => {
//                         reject(error);
//                     });
//             };
//             image.src = fileReader.result;
//         };
//         fileReader.readAsDataURL(blob);
//     });
// }

// Fonction principale pour convertir l'image en WebP
// export async function convertToWebP(imageBlob) {
//   try {
//     const webpData = await blobToWebP(imageBlob);
//     console.log('Image converted to WebP:', webpData);
//     // Autres opérations à effectuer avec les données de l'image WebP
//   } catch (error) {
//     console.error('Error converting image to WebP:', error);
//   }
// }


// Fonction pour convertir l'image en WebP
const convertToWebP = async (imageUrl) => {
    // try {
    //     // const response = await fetch(imageUrl);
    //     // const response = await axios.get(imageUrl);
    //     let response
    //     const secureImageUrl = imageUrl.replace('http://api', 'https://api');
    //     new Promise((resolve, reject) => {
    //         Http.get(`${secureImageUrl}`)
    //             .then(function (res) {
    //                 // handle success   
    //                 response = resolve(res)
    //                 return resolve(res);
    //             })
    //             .catch(function (err) {
    //                 return reject(err);
    //             })

    //     })

    //     // const response = await axios.get(secureImageUrl);
    //     console.error('Error converting Lud:', response);
    //     const blob = await response.blob();

    //     const convertedBlob = await blobToWebP(blob);
    //     const convertedUrl = URL.createObjectURL(convertedBlob);

    //     return convertedUrl
    // } catch (error) {
    //     console.error('Error converting image:', error);
    //     return imageUrl; // Retourner l'URL d'origine en cas d'erreur
    // }
};

export default convertToWebP;


// const pica = require('pica');

// // Fonction pour convertir une image en WebP
// async function convertToWebP(imagePath, outputPath) {
//     const inputImage = await fetch(imagePath);
//     const inputImageBlob = await inputImage.blob();

//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     const img = new Image();
//     const imgLoaded = new Promise((resolve, reject) => {
//         img.onload = resolve;
//         img.onerror = reject;
//     });
//     img.src = URL.createObjectURL(inputImageBlob);
//     await imgLoaded;

//     canvas.width = img.width;
//     canvas.height = img.height;
//     context.drawImage(img, 0, 0, img.width, img.height);

//     const convertedCanvas = document.createElement('canvas');
//     const convertedContext = convertedCanvas.getContext('2d');
//     convertedCanvas.width = img.width;
//     convertedCanvas.height = img.height;

//     const picaResizer = pica();
//     await picaResizer.resize(canvas, convertedCanvas, { quality: 0.85 });
//     await picaResizer.toBlob(convertedCanvas, 'image/webp', 0.85);

//     const convertedBlob = await new Promise((resolve) => convertedCanvas.toBlob(resolve, 'image/webp', 0.85));
//     const convertedImage = new File([convertedBlob], outputPath, { type: 'image/webp' });

//     return convertedImage;
// }

// // Exemple d'utilisation
// // const imagePath = '../images/sliderImage.png';
// // const outputPath = '../assets/img';

// // convertToWebP(imagePath, outputPath)
// //     .then((convertedFile) => {
// //         console.log('Image convertie avec succès en WebP !', convertedFile);
// //     })
// //     .catch((error) => {
// //         console.error(`Erreur lors de la conversion de l'image : ${error}`);
// //     });

// export default convertToWebP;
