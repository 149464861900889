import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CatSelectComponent from './../../../components/CatSelectComponent';
import FloatingInputBis from './../../../components/FormsComponent/FloatingInputBis';
import searchUsersComponent from './../../../components/searchUsersComponent';
import FloatingInput from './../../../components/FormsComponent/FloatingInput';
import ProductList from './ProductList';
import ReciverForms from './ReciverForms';
import InvoiceDetails from './InvoiceDetails';
import SearchInput from '../../../components/FormsComponent/SearchInput';

class Receveur extends Component {
  constructor(props) {
    super();
  }
  
  render() {
    const { t,
      pointRelaisValue,
      optionPointRelais_t,
      gotToParentCatValue,
      bool_val,
      cartValue,
      qtyValue_t,
      qtyValueArr_t,
      onQty_t,
      coefAndPrice,
      goToParentCoefAndPrice,
      qty_t,
      sum_t,
      onShowReceveurRelais_t,
      searchR_t,
      handleChange,
      errors,
      gotToParentValue,
      seacrchList,
      onUserDetails,
      nameChoice,
      firstname,
      lastname,
      country,
      phone,
      myRef,
      onsubmitExitingUsers_t,
      onsubmitNewUser_t,
      postregister,
      showBtnTrue2,
      showBtnTrue,
      sendCart,
      userExistBool,
      userNotExistBool,
      onUserExistBool,
      onUserNotExistBool,
      showCarteadhesionGS,
      isSocialAssistanceOption,
      selectedValue,
      onShowPayment_t,
      montantLivraison,
      onHandleParentStateTotal,
      totalHtArrondie,
      totalTauxInteretArrondie,
      totalTtcArrondie,
      treatment_costs,
      social_assistance,
      delivery,
      amountS,
      fromCMD,
      totalHtArrondieS,
      interest_rate,
      creatorCreateUserProfile
    } = this.props;
    let listUsers = seacrchList.length > 0 && seacrchList.map((v, i) => {
      return (
        <>
          <a
            href="javascript:;"
            className="searchUsersText_t"
            style={{ display: 'inline-flex' }}
            // onClick={(real_id, firstname, lastname, country, phone) => onUserDetails(v.real_id, v.firstname, v.lastname, v.country.data.name_fr, v.phone)}
            onClick={(amount, real_id, isUserProf, name) => onUserDetails(
              v.amount !== undefined ? v.amount : null,

              sendCart ?
                !!v.receiver_id ?
                  v.receiver_id
                  :
                  v.real_id
                :
                !!v.donator_id ?
                  v.donator_id
                  :
                  !!v.receiver_id ?
                    v.receiver_id
                    :
                    v.real_id
              ,

              sendCart,

              sendCart ?
                v.receivers !== undefined ?
                  v.receivers.data.firstname
                  :
                  v.firstname ?
                    `${v.firstname} ${v.lastname}`
                    :
                    v.name
                :
                v.donators ?
                  v.donators.data.name
                  :
                  v.firstname ?
                    `${v.firstname} ${v.lastname}`
                    :
                    v.name
            )}
          >
            <img
              src={require("../../../../images/userProfil.png")}
              alt="Image prod"
              className="imgProfilUser_round_t"
              style={{ marginTop: '5px' }}
            />
            <span className="userName_t" style={{ marginLeft: '5px' }}>
              {v.donators ?
                <>{v.donators.data.name}
                  {v.email}</>

                :
                v.firstname ?
                  <>
                    <span className="spanNameReceveur_t">
                      {`${v.firstname} ${v.lastname}`}
                    </span>
                    {v.email}
                  </>

                  :
                  <>
                    <span className="spanNameReceveur_t">
                      {v.name}
                    </span>
                    {v.email}
                  </>

              }
              {v.receivers ?
                <>
                  {v.receivers.data.name}
                  {/* {v.email} */}
                </>

                :
                ''
              }
            </span>
          </a>
          {(i !== 0 && i % 4 === 0) ? <br /> : ''}
        </>
      )
    });
    let arrayQty = cartValue.length > 0 && cartValue.map((v, i) => v.qty);
    let sumQtys = arrayQty.length > 0 && arrayQty.reduce(function (a, b) {
      return parseInt(a) + parseInt(b);
    }, 0);
    return (
      <>
        <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-1 recipient_t showAndHiddenRespDesk_t">
        </div>
        <div className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-6 recipient_t">
          <div className="searchRecipent_t">
            <p onClick={onUserExistBool} className="searchRecipentTitle_t">
              {/* Compte(s) existant des bénéficiaires  */}


              {sendCart === false ?

                <>Beneficiaire (Compte(s) existant)</>
                :
                <>Payeurs (Compte(s) existant)</>
              }

            </p>
            {userExistBool ?
              // <form onSubmit={sendCart ? onsubmitExitingUsers_t : onShowPayment_t}>
              <form onSubmit={onShowPayment_t}>

                {/*<FloatingInputBis  
                            className={'input_float_posit_t form-control input-solid'} 
                            classNamePlaceh={'label_float_posit_t placeholder'} 
                            style_f_floating_div={{width: '50%'}}
                            type={'text'} 
                            name={'searchR_t'}  
                            id={'searchR_t'} 
                            value={searchR_t}
                            placeholder={t('Rechercher un nom ou prénom')} 
                            onChange={handleChange} 
                            text_or_not={true}
                            required={true}
                            icon_user={false}
                            class_for_img_icon_style={false}
                            input_left_t={false}
                            black_profile_img_t={false}
                            gotToParentValue={gotToParentValue}
                          />*/}
                <div className="">

                  <p className="searchUsers_t">

                    {this.props.sendCart === false ?
                      ""
                      :
                      <div className="row bgLinkImg_t">
                        <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-2 displayAndHiddenResp_t">
                        </div>
                        <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-8 searchLink_t">
                          <SearchInput
                            className_div_2={'form-group with-button search-input is-empty'}
                            input_className={'form-control'}
                            input_id={'headerSearch'}
                            input_placeholder={'Recherchez le nom de celui qui paiera pour vous'}
                            input_type={'search'}
                            images_className={'search_t'}
                            name={'searchLink_t'}
                            searchR_t={this.props.searchR_t}
                            seacrchList={this.props.seacrchList}
                            gotToParentValue={this.props.gotToParentValue}
                          />
                        </div>
                        <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-2 displayAndHiddenResp_t">
                        </div>
                      </div>
                    }

                    {listUsers}
                  </p>

                  <div className="justifyRight_t">

                    <button
                      style={{ color: '#FFF' }}
                      className="btn next_cart2_t"
                      disabled={nameChoice === '' ? 'disabled' : ''}>
                      {sendCart === false ?
                        "Continuer"
                        :
                        "Envoyer"
                      }
                    </button>

                    <FloatingInput
                      className={'input_float_posit_t form-control input-solid colorBlack_t'}
                      classNamePlaceh={'label_float_posit_t placeholder'}
                      style_f_floating_div={{ margin: '0 0 0 8%' }}
                      type={'text'}
                      name={'nameChoice'}
                      id={'nameChoice'}
                      value={nameChoice}
                      placeholder={t('')}
                      onChange={handleChange}
                      text_or_not={true}
                      required={true}
                      icon_user={false}
                      class_for_img_icon_style={true}
                      input_left_t={false}
                      black_profile_img_t={true}
                      disabled={true}
                    />

                    {/* <FloatingInputBeneficiaire
                      className={'input_float_posit_t form-control input-solid colorBlack_t'}
                      classNamePlaceh={'label_float_posit_t placeholder'}
                      style_f_floating_div={{ margin: '0 0 0 8%' }}
                      type={'text'}
                      value={nameChoice}
                      name={'nameChoice'}
                      id={'nameChoice'}
                      onChange={handleChange}
                      required={true}
                      disabled={true}
                    /> */}

                  </div>
                  {showBtnTrue2 ?
                    <img
                      src={require("../../../../images/checkOK.png")}
                      alt="Image prod"
                      className="imgOkUser_round_t"
                    />
                    :
                    ''
                  }
                </div>

              </form>
              :
              ''
            }

          </div>



          <div className="searchRecipent_t">
            <p onClick={onUserNotExistBool} className="searchRecipentTitle_t">
              {/* Informations personnelles du destinataire  */}
              {/* Renseignez manuellement les informations du bénéficiaire */}
              {sendCart === false ?

                <>Beneficiaire (Nouveau compte)</>
                :
                <>Payeurs (Nouveau compte)</>
              }
            </p>

            {userNotExistBool ?
              // <form className="blackProfileImg_t" onSubmit={onsubmitNewUser_t}>
              <form className="blackProfileImg_t" onSubmit={creatorCreateUserProfile}> 
              {/* <form className="blackProfileImg_t" onSubmit={onShowPayment_t}> / */}

                <ReciverForms
                  errors={errors}
                  postregister={this.props.postregister}
                  handleChange={this.props.handleChange}
                  handleChangeDistrict={this.props.handleChangeDistrict}
                  gotToParentValue={this.props.gotToParentValue}
                  option_country={this.props.option_country}
                  option_city={this.props.option_city}
                  eye_password_bool={this.props.eye_password_bool}
                  toggle={this.props.toggle}
                  isLoading={this.props.isLoading}
                  // offline={this.props.offline}
                  email_exit={this.props.email_exit}
                  email_not_val={this.props.email_not_val}
                  userClickOnList={seacrchList.length > 0 && seacrchList}
                />

                <div className="justifyRight_t">
                  <button
                    style={{ color: '#FFF' }}
                    className="btn next_cart_t">
                    Continuer
                  </button>
                </div>

                {showBtnTrue ?
                  <img
                    src={require("../../../../images/checkOK.png")}
                    alt="Image prod"
                    className="imgOkUser_round_t"
                  />
                  :
                  ''
                }

              </form>
              :
              ''
            }

            {/*<span ref={myRef}></span>*/}
          </div>


        </div>

        <InvoiceDetails
          sumQtys={sumQtys}
          sum_t={sum_t}
          modalEligible={this.props.modalEligible}
          showCarteadhesionGS={showCarteadhesionGS}
          onBtnClick={null}
          classNameFirstDiv={"col col-xs-12 col-sm-6 col-md-4 col-lg-5 acideLeftCart2_t"}
          selectedValue={selectedValue}
          isSocialAssistanceOption={isSocialAssistanceOption}
          deliveryPrice={montantLivraison}
          onHandleParentStateTotal={onHandleParentStateTotal}
          totalHtArrondie={totalHtArrondie}
          totalTauxInteretArrondie={totalTauxInteretArrondie}
          totalTtcArrondie={totalTtcArrondie}

          treatment_costs={treatment_costs}
          social_assistance={social_assistance}
          delivery={delivery}
          amountS={amountS}
          fromCMD={fromCMD}
          totalHtArrondieS={totalHtArrondieS}
          interest_rate={interest_rate}
        />


      </>
    );
  }
}
export default withTranslation()(Receveur);