import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// import Carousel from '../components/Carousel';

class FloatingTextArea extends Component {

	handleChange = (e, label) => {
		this.props.gotToParentValue(e.target.value, 'textarea');
    e.preventDefault();
  };

	render(){
       let { t } = this.props 
        	return(
        		<>
        		{this.props.text_or_not ?
                <div className="styleFFloatinf_t form-group form-floating-label input_g_1" style={this.props.style_f_floating_div}>
	                <textarea 
	                value={this.props.value}
	                name={this.props.name}
	                id={this.props.id}
	                className={this.props.className}
	                onClick={this.props.onClick}
	                onChange={this.props.onChange}
	                aria-describedby={this.props.aria_describedby} 
	                title={this.props.title} required />
	                <label 
	                for={this.props.id} 
	                className={this.props.classNamePlaceh}>
		            	{t(this.props.placeholder)}
		            </label>
              </div>
              :
              <div class="form-group form-floating-label input_g_parent_2" style={this.props.style_f_floating_div}>
                
                	<div class="input-group input_g_2">
	                <input 
	                type={this.props.type} 
	                value={this.props.value}
	                name={this.props.name}
	                id={this.props.id}
	                className={this.props.className}
	                onClick={this.props.onClick}
	                onChange={this.props.onChange}
	                aria-describedby={this.props.aria_describedby}
	                checked={this.props.checked}
	                title={this.props.title} required />
	                <label 
	                style={this.props.label_style}
	                for={this.props.id} 
	                className={this.props.classNamePlaceh}>
		            	{t(this.props.placeholder)}
		            </label>
	                <div class="eye_float_posit_t input-group-addon">
                      <span class="input-group-text eye_t">
                        <i className={this.props.eye_password_bool ? "fa fa-eye-slash" : "fa fa-eye"} 
                        id="eye"
                        onClick={this.props.toggle}></i> 
                      </span>
                    </div>
              </div>
              </div>
          }
          </>
        	);
        }
}
export default withTranslation()(FloatingTextArea);