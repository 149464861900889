import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import VerticalLinearStepper from './VerticalLinearStepper';

const HaveYouMembershipCard2 = (props) => {


    return (
        <div>
            {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button> */}

            <Modal show={props.show} onHide={props.handleClose} className='haveYouMembershipCard2'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className='haveYMembershipCardTitle_t'>
                            voulez-vous une carte d'adhérent ?
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className='haveYMembershipCard_t'>
                        Informations générales ?
                    </span>
                    <VerticalLinearStepper />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="haveYouMembershipCardBtnNo" onClick={props.handleClose}>
                        NON
                    </Button>
                    <Button className="haveYouMembershipCardBtnYes" onClick={props.handleClose}>
                        OUI
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default HaveYouMembershipCard2;