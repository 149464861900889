import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import FormsChoice from './FormsChoice';

class Trier extends Component {

  onChangeChoice_t = (e, num) => { 
     if (num === 1) {
       this.props.gotToParentShowInput('not_intervalle', 1);
     } 
     if (num === 2) {
       this.props.gotToParentShowInput('not_intervalle', 2);
     } 
     if (num === 3) {
       this.props.gotToParentShowInput('not_intervalle', 3);
     } 
     if (num === 4) {
       this.props.gotToParentShowInput('intervalle', 4);
     } 
   } 

	render(){
        	const { t,
          onPrice_t,
          onDropdownBtn_t,
          dropdownBtn_t,
          onChange_t,
          required,
          greater_than,
          prix_t,
          prix2_t,
          less_than,
          equal_t,
          interval_t,
          input1_bool_t,
          input2_bool_t,
          onChangeChoice_t,
          show_val_interv,
          interval_error } = this.props; 
        	return(
                	<form className="dropdown" onSubmit={onPrice_t} style={{'max-height': '35px', background: '#f9f9f9'}}>
          <a 
            href="javascript:;"
            class="btn btn_color_t dropdown-toggle"
            onClick={onDropdownBtn_t}>
            Prix
          </a>
          <div 
            class={dropdownBtn_t ? "dropdown-menu sizeTrieForm display_t" : "dropdown-menu hidden_t"} >
            <div class="form-inline" style={{'justify-content': 'center'}}>
              <div className={input1_bool_t ? "form-group mx-sm-3 mb-2 display_t" : "form-group mx-sm-3 mb-2 hidden_t"}>
                <label for="inputPassword2" className="sr-only">prix</label>
                <input 
                  type="text" 
                  className="form-control" 
                  id="prix_t"
                  name="prix_t" 
                  placeholder="Prix"
                  value={prix_t}
                  style={input2_bool_t ? {width: '130px'} : {}}
                  required={'required'}
                  onChange={onChange_t} />
              </div>
              <div className={input2_bool_t ? "form-group mx-sm-3 mb-2 display_t" : "form-group mx-sm-3 mb-2 hidden_t"}>
                <label for="inputPassword2" className="sr-only">prix</label>
                <input 
                  type="text" 
                  className="form-control" 
                  id="prix2_t"
                  name="prix2_t" 
                  value={prix2_t}
                  placeholder="Second prix"
                  style={{width: '130px'}}
                  required={required}
                  onChange={onChange_t} />
              </div>
              </div>
            <div class="form-inline" style={{'justify-content': 'center'}}>

              <div class="form-group mb-2">
                <label for="inputSupperieur" className="sr-only">Inferieur</label>
                <FormsChoice 
                  type={'radio'}
                  value={less_than}
                  name={'price_choice'}
                  required={true}
                  onChange={(e, num) => this.onChangeChoice_t(e, 1)}
                  write_text={`<${prix_t !== null ? prix_t : ''}`}
                /> 
              </div>
              <div class="form-group mb-2">
                <label for="inputSupperieur" className="sr-only">Supperieur</label>
                <FormsChoice 
                  type={'radio'}
                  value={greater_than}
                  name={'price_choice'}
                  onChange={(e, num) => this.onChangeChoice_t(e, 2)}
                  write_text={`>${prix_t !== null ? prix_t : ''}`}
                /> 
              </div>
              {/*<div class="form-group mb-2">
                <label for="inputSupperieur" className="sr-only">Egale</label>
                <FormsChoice 
                  type={'radio'}
                  value={equal_t}
                  name={'price_choice'}
                  onChange={(e, num) => this.onChangeChoice_t(e, 3)}
                  write_text={`=${prix_t !== null ? prix_t : ''}`}
                /> 
              </div>*/}
              <div class="form-group mb-2">
                <label for="inputSupperieur" className="sr-only">Intervalle</label>
                <FormsChoice 
                  type={'radio'}
                  value={interval_t}
                  name={'price_choice'}
                  onChange={(e, num) => this.onChangeChoice_t(e, 4)}
                  write_text={`[${show_val_interv ? 

                    `${prix_t !== null ? prix_t : ''} , ${prix2_t !== null ? prix2_t : ''}`

                    : ''}]`}
                /> 
              </div>
            </div>
            <p 
              className={interval_error === true ? "error2_t display_t" : "hidden_t"}>
              La première valeur <span className="bold_t">{prix_t}</span> doit 
              être inférieur la seconde <span className="bold_t">{prix2_t}</span>
            </p>
            <div class="form-inline" style={{'justify-content': 'center'}}>
              <button 
                  type="submit" 
                  className="btn btn-primary mb-2">Recherche</button>

            </div>
          </div>
        </form>
        	);
        }
}
export default withTranslation()(Trier);