import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import ComputerIcon from '@mui/icons-material/Computer';
import MovieIcon from '@mui/icons-material/Movie';
import KitchenIcon from '@mui/icons-material/Kitchen';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import ToysIcon from '@mui/icons-material/Toys';
import SpaIcon from '@mui/icons-material/Spa';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
//Drawer on Material-UI

export default function SwipeableComponent(props) {

    const handleChange = (newValue) => {
        if (props.name === "cat_prod") {
            props.gotToParentCatValue(newValue.value, decodeURIComponent(newValue.label), 'cat_prod');
        }
        if (props.name === "cat_prod_mobil") {
            props.gotToParentCatValue(newValue.value, decodeURIComponent(newValue.label), 'cat_prod_mobil');
        }
    }

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => {
        const iconCategoriesTabs = [
            <SoupKitchenIcon />,
            <ComputerIcon />,
            <MovieIcon />,
            <KitchenIcon />,
            <DesktopWindowsIcon />,
            <ToysIcon />,
            <KitchenIcon />,
            <SpaIcon />,
            <LocalGroceryStoreIcon />,
            <ShoppingBagIcon />,
            <SportsHandballIcon />,
            <DirectionsCarIcon />,
        ]
        return (

            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <List>
                    {props.allCategoriesLabel && props.allCategoriesLabel.map((item, index) => (
                        <ListItem
                            onClick={() => handleChange(item)}
                            key={item.value}
                            disablePadding
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    {iconCategoriesTabs[index]}
                                </ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                <Divider />
                <List>
                    {['Aide et assistance'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        )
    };

    return (
        <div>
            {['Tous les produits'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <button
                        className="btn btn-toggle toggle-sidebar btn-toggle-h-menu_t"
                        onClick={toggleDrawer(anchor, true)}>
                        <img
                            src={require("../../images/menu_line_1.png")}
                            alt="Search"
                            className="search_toggle_t"
                            style={{ position: 'absolute', width: '15px', margin: '5px' }}
                        />
                        <img
                            src={require("../../images/menu_line_2.png")}
                            alt="Search"
                            className="search_toggle_t"
                            style={{ margin: '6px 0 0 6px', width: '10px' }}
                        />
                        <span className="menu_tog_t" style={{ fontFamily: 'Maison Neue Bold' }}>
                            Toutes les catégories
                        </span>
                    </button>

                    {/* <Button >{anchor}</Button> */}
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        <div className='firstSectionOfSwipeableBtn_t'>
                            &nbsp;
                            <span className='titleOffirstSection_t'>
                                Nos catégories
                            </span>
                            <span
                                className='closeSwipeableBtn_t'
                                onClick={toggleDrawer(anchor, false)}>
                                Fermer
                            </span>
                        </div>
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}