import React from 'react';
import pMinDelay from 'p-min-delay';
import {BrowserRouter as Router,
    Switch
} from 'react-router-dom';

import routes from './routes'
import PublicRoute from './Public'
import PrivateRoute from './Private'
import Loading from '../common/components/loader';
import loadable from '../common/components/loader/loadable';

const isAuthenticated_t = localStorage.getItem('isAuthenticated');
const isVerify_t = localStorage.getItem('isVerify');
const ScrollToTop = loadable(() => pMinDelay(import('../common/components/ScrollToTop'), 1250), { fallback: <Loading /> });
const Routes = () => (
    <Router>
        <ScrollToTop>
            <Switch>
                {routes.map((route,i) => {
                    if(route.auth){ 
                        return <PrivateRoute isVerify_t={isVerify_t} isAuthenticated_t={isAuthenticated_t} key={i} path={route.path} component={route.component} {...route}/>
                    }else{
                        return <PublicRoute key={i} path={route.path} component={route.component} {...route}/>
                    }
                })}
            </Switch>
            </ScrollToTop>
    </Router>
);

export default Routes;