import { connect } from 'react-redux'
import Page from './Page'
const mapStateToProps = state => {
    return {
        def_theme: state.__iA.__dt,
        isAuthenticated: state.__iA.isAuthenticated,
        cartValue: state.__iA.__cv,
        showCartValue: state.__iA.__showC,
        prodId_t: state.__iA.__pid,
        home_t: state.__iA.__hp,
        showCarteadhesionGS: state.__iA.showCarteadhesionGlobalState,
        relaypointId: state.__iA.relaypointId,
        showPr: state.__iA.__sPr,
        showCart: state.__iA.__sCart,
        showCarteadhesion: state.__iA.__sCarteA,
        showReceveur: state.__iA.__sReceveur,
        showPaiement: state.__iA.__sPaiement,
        realCard: state.__iA.__realCard,
        cartBrowse: state.__iA.__cartBrowse,
        amountUserLinkStore: state.__iA.__amtusrL,
        receiverUserReal_idStore: state.__iA.__recusrRId,
        isUserProfStore: state.__iA.__isusrPrf,
        nameChoiceStore: state.__iA.__nmeChoice,
        postregisterStore: state.__iA.__postRter,
        clickOnCmdStore: state.__iA.clickOnCmd,
        optionsRPStore: state.__iA.optionsRP,
        distanceBeneficiarySTore: state.__iA.distanceBeneficiary,
        typeOfHLocatizationStore: state.__iA.typeOfHLocatization,
        montantLivraisonSotre: state.__iA.montantLivraison,
        isSocialAssistanceOptionStore: state.__iA.isSocialAssistanceOption,
        selectedValueStore: state.__iA.selectedValueStore,
        paymentTypeStore: state.__iA.paymentType,
        isSocialPaymentStore: state.__iA.isSocialPayment,
        treatment_costsStore: state.__iA.__tcosts,
        social_assistanceStore: state.__iA.__sassist,
        deliveryStore: state.__iA.__delivery,
        interest_rateStore: state.__iA.__irate,
        amountStore: state.__iA.__amountCMD,
        fromCMDStore: state.__iA.__fromCMD,
        totalHtArrondieStore: state.__iA.__tHtAr,
        seacrchListStore: state.__iA.seacrchListStore

    }
};

export default connect(mapStateToProps)(Page);