import React, { Component } from 'react';
import { Alert } from "reactstrap";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import LoadingStep from './../../../components/LoadingStep';
import PointRelais from './PointRelais';
import Panier from './Panier';
import Receveur from './Receveur';
import Payment from './Payment';
import Footer from './../../../layout/Footer';
import MembershipCard from './MembershipCard';
import HaveYouMembershipCard from './Modals/HaveYouMembershipCard';

class PanierContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      styleLoading: 'loading_dataTable_step',
      type: 'spin',
      height: props.height,
      width: props.width,
      widthResponsive: props.widthResponsive,
      heightResponsive: props.heightResponsive,
      headers: props.headers,
      data: props.data,
      pageData: props.data,
      pageLength: props.pageLength || null,
      currentPage: props.current_page || 1,
      total_pages: props.total_pages,
      per_page: props.per_page,
      total_t: props.total_t,
      show: false,
      modalEligible: false
    }
    this.pagination = this.props.pagination || {};
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  onReturnCartLocal_t = () => {
    this.setState({ modalEligible: false });
    this.props.onReturnCart_t();
  }

  handleHaveMembershipCard = () => {
    this.props.onshowCarteadhesion_t();
    this.setState({ show: false });
    this.setState({ modalEligible: true });
    this.props.onMembershipCardClick();

  }


  onPageLengthChange = (pageLength) => {
    this.setState({
      pageLength: parseInt(pageLength, 10)
    }, () => {
      this.onGotoPage(this.state.currentPage);
    });
  }
  componentDidMount() {
    if (this.pagination.enabled) {
      this.onGotoPage(this.state.currentPage)
    }

  }
  onGotoPage = (pageNo) => {
    let pageData = this.getPageData(pageNo, this.state.pageLength);
    this.setState({
      pageData: pageData,
      currentPage: pageNo
    });
    switch (this.props.bool_eleve_funct) {
      case "abonnement":
        return this.props.elevesAbonnes(this.state.limit, pageNo)
      default:
        return;
    }

  }
  getPageData = (pageNo, pageLength) => {
    let startOfRecord = (pageNo - 1) * pageLength;
    let endOfRecord = startOfRecord + pageLength;
    let data = this.state.data;
    let pageData = data.slice(startOfRecord, endOfRecord);

    return pageData;
  }

  calculateTotal = () => {
    return this.props.cartValue.reduce((total, item) => total + (item.usedPrice * item.qty), 0);
  };

  backup = (val) => {
    if (val === "shoppingCart") {
      // this.props.insertInEconomicAccessibility(null, null, null, null, null, false, null);
      this.props.historyFromHome.push("/products/country:33+Cameroun");
    }
  }
  render() {
    const {
      t,
      categorie_value,
      option_categorie,
      categorieLabel,
      pointRelaisValue,
      optionPointRelais_t,
      parent_categorieLabel,
      onPrice_t,
      onDropdownBtn_t,
      dropdownBtn_t,
      onChange_t,
      required,
      greater_than,
      less_than,
      equal_t,
      interval_t,
      input2_bool_t,
      input1_bool_t,
      show_val_interv,
      prix_t,
      devise_t,
      prix2_t,
      interval_error,
      gotToParentShowInput,
      onAddProduct,
      index_t,
      products,
      gotToParentValue,
      handleChange,
      gotToParentCatValue,
      parent_add_categorie,
      showTrieBool_t,
      cartValue,
      cartValueForCmd,
      qtyValue_t,
      qtyValueArr_t,
      onQty_t,
      coefAndPrice,
      goToParentCoefAndPrice,
      qty_t,
      onShowPointRelais_t,
      onReturnCart_t,
      onMembershipCard_t,
      onShowPointRelaisFromPDiff_t,
      membershipCardClick,
      typeOfDeferredPayment,
      onChooseSocialAssistance,
      onReturnRelaisPoint_t,
      showCart,
      showPr,
      showCarteadhesion,
      showReceveur,
      showPaiement,
      onShowPayment_t,
      onShowReceveur_t,
      errors,
      option_sexe,
      option_country,
      option_city,
      eye_password_bool,
      toggle,
      isLoading,
      isValue,
      email_exit,
      email_not_val,
      postregister,
      searchR_t,
      seacrchList,
      onUserDetails,
      nameChoice,
      firstname,
      lastname,
      country,
      phone,
      onShowReceveurRelais_t,
      myRef,
      onDeleteProduct,
      onsubmitExitingUsers_t,
      onsubmitNewUser_t,
      showBtnTrue2,
      showBtnTrue,
      onSendCart,
      onNotSendCart,
      sendCart,
      userExistBool,
      userNotExistBool,
      onUserExistBool,
      onUserNotExistBool,
      onPayment,
      creator_name,
      cartActiveBool,
      relaypointId,
      realCard,
      rpointIsNotchoosed,
      showCarteadhesionGS,
      onNotshowCarteadhesion_t,
      handleChangeSocialOption,
      selectedValue,
      isSocialAssistanceOption,
      isDeferredPayment,
      handleChangeDistrict,
      postregisterStore,
      nameChoiceStore,
      totalSum,
      onReturnShop_t,
      clickOnCmdStore,
      onHandleRelayPointId,
      handleAddressBeneficiary,
      getShortContryNameOfBeneficiary,
      distanceBetweenBeneficiaryAndOurLocaltionViaGoogleMap,
      addressOfBeneficiary,
      heure, minute, seconde,
      distanceBeneficiary,
      onAtHomeChoose,
      montantLivraison,
      onHandleParentStateTotal,
      totalHtArrondie,
      totalTauxInteretArrondie,
      totalTtcArrondie,
      typeOfHLocatization,
      paymentType,
      isSocialPayment,
      treatment_costs,
      social_assistance,
      delivery,
      amountS,
      fromCMD,
      totalHtArrondieS,
      interest_rate,
      insertInEconomicAccessibility,
      creatorCreateUserProfile
    } = this.props;

    // const steps = ['Step 1', 'Step 2', 'Step 3'];


    const steps = [
      'Panier',
      'carte d\'adhérent',
      'Livraison',
      'Bénéficiaire',
      'Payeur',
    ];

    const stepsData = [
      { title: "Panier", showText: "1/4", showContent: showCart },
      // { title: "carte d\'adhérent", showText: "2/4", showContent: showCart },
      { title: "Livraison", showText: "3/4", showContent: showPr },
      { title: "Receveur", showText: "4/4", showContent: showReceveur },
      { title: "Paiement", showText: "5/4", showContent: showPaiement }
    ];


    // let sum_t = 0
    // coefAndPrice.map((v) => {
    //   v.reduction === 0 ?
    //     sum_t += v.productsPrice
    //     :
    //     sum_t += v.reduction
    // });

    let sum_t = 0;

    coefAndPrice.forEach((v) => {
      sum_t += v.usedPrice;
    });

    // let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
    // console.log("Summmm ", totalSum, "coefAndPrice ", coefAndPrice);
    return (
      <div className="container-fluid container_panier_t prop_list_t">

        <div className="row" style={{ width: '100%' }}>


          <div className="col col-sm-7 col-lg-6 stepDiv_t">

            <div class="stepper">
              <div
                onClick={onReturnCart_t}
                className={showCart === true ? "step active" : "step"}>
                <div class="circle">1</div>
                <div class="label">Panier</div>
                <div class="progress">
                  {showCart === true ? <span class="completed"></span> : <span></span>}
                </div>
                <div class="number">1/4</div>
              </div>
              <div
                onClick={onReturnRelaisPoint_t}
                className={showPr === true ? "step active" : "step"}>
                <div class="circle">2</div>
                <div class="label">Livraison</div>
                <div class="progress">
                  {showPr === true ? <span class="completed"></span> : <span></span>}
                </div>
                <div class="number">2/4</div>
              </div>
              <div
                onClick={(e, relaypointIdArray) => onShowReceveur_t(e, relaypointId)}
                className={showReceveur === true ? "step active" : "step"}>
                <div class="circle">3</div>
                <div class="label">Bénéficiaire</div>
                <div class="progress">
                  {showReceveur === true ? <span class="completed"></span> : <span></span>}
                </div>
                <div class="number">3/4</div>
              </div>
              <div className={showPaiement === true ? "step active" : "step"}>
                <div class="circle">4</div>
                <div class="label">Paiement</div>
                <div class="progress">
                  {showPaiement === true ? <span class="completed"></span> : <span></span>}
                </div>
                <div class="number">4/4</div>
              </div>
            </div>

            {/* <ul className={`steps ${showCarteadhesion || showPr || showReceveur || showPaiement ?
              this.state.modalEligible ?
                "stepsColor_t stepsColorTrue_t"
                :
                "stepsColorMdalEligible_t stepsColorTruemMdalEligible_t"
              :
              this.state.modalEligible ?
                "stepsColor_t"
                :
                "stepsColorMdalEligible_t"
              }`}>
              <li className="step step-success topLine_t">
                <div className="step-content">
                  <span className="step-title"
                    onClick={onReturnCart_t}
                    style={{ cursor: 'pointer' }}>Panier</span>
                  <span className="step-circle">
                    <LoadingStep
                      type={this.state.type}
                      styleLoading={`showContentsDeskResp loading_dataTable_step ${showCart ? "" : "hidden_t"}`}
                      height={this.state.height}
                      width={this.state.width}
                    />
                    <LoadingStep
                      type={this.state.type}
                      styleLoading={`showContentsMobResp loading_dataTable_step ${showCart ? "" : "hidden_t"}`}
                      height={this.state.heightResponsive}
                      width={this.state.heightResponsive}
                    />
                    <img
                      src={require("../../../../images/circle_check_outline.png")}
                      alt="circle_check_outline"
                      className={`circle_check_outline_t ${showCart ? "hidden_t" : "display2_t"}`}
                    />
                    <img
                      src={require("../../../../images/line.png")}
                      alt="line"
                      className="line_t"
                    />
                  </span>
                  <span className="step-text-active_t">{this.state.modalEligible ? "1/5" : '1/4'}</span>
                </div>
              </li>



              <li className={`step ${showCarteadhesion || showPr || showReceveur || showPaiement ? "step-active topLine_t loadActiv_t" : "step-active"}`}>
                <div className="step-content">
                  <span className="step-title showAndHiddenRespDesk_t"
                    onClick={onReturnRelaisPoint_t}
                    style={{ cursor: 'pointer', paddingLeft: '1rem' }}>Livraison</span>
                  <span className="step-title showAndHiddenResp_t">livraison</span>
                  <span className="step-circle" style={showCarteadhesion || showPr || showReceveur || showPaiement ? { margin: '0 0 0 1.3rem' } : { margin: '0 0 0 0', paddingLeft: '2.5rem' }}>
                    <LoadingStep
                      type={this.state.type}
                      styleLoading={`showContentsDeskResp loading_dataTable_step ${showPr && !showCarteadhesion ? "" : "hidden_t"}`}
                      height={this.state.height}
                      width={this.state.width}
                    />
                    <LoadingStep
                      type={this.state.type}
                      styleLoading={`showContentsMobResp loading_dataTable_step ${showPr && !showCarteadhesion ? "" : "hidden_t"}`}
                      height={this.state.heightResponsive}
                      width={this.state.widthResponsive}
                    />
                    <img
                      src={require("../../../../images/circle_check_outline.png")}
                      alt="circle_check_outline"
                      className={`circle_check_outline_t ${!showPr && (showReceveur || showPaiement) ? "display2_t" : "hidden_t"}`}
                    />
                    <img
                      src={require("../../../../images/point_t.png")}
                      alt="point"
                      className={showCarteadhesion || showPr || showReceveur || showPaiement ?
                        `point1RP2_t ${!showPr === false && !showReceveur === false ? "display2_t" : "hidden_t"}`
                        :
                        `point1RP_t ${!showPr === true && !showReceveur === true ? "display2_t" : "hidden_t"}`}
                    />
                    <img
                      src={require("../../../../images/line.png")}
                      alt="line"
                      style={showCarteadhesion || showPr || showReceveur || showPaiement ? { margin: '.45rem 0px 0px 5.8rem', position: 'absolute', width: '65px' } : { margin: '.45rem 0px 0px 5.8rem', position: 'absolute', width: '65px' }}
                    />
                  </span>
                  <span className="step-text"
                    style={{ paddingLeft: '1rem' }}>{this.state.modalEligible ? "3/5" : "2/4"}</span>
                </div>
              </li>
              <li className="step topLine_t">
                <div className="step-content">
                  <span className="step-title"
                    onClick={(e, relaypointIdArray) => onShowReceveur_t(e, relaypointId)}
                    style={{ cursor: 'pointer' }}>Bénéficiaire</span>
                  <span className="step-circle" style={showCarteadhesion || showPr || showReceveur || showPaiement ? { margin: '0 0 0 1rem' } : { margin: '0 0 0 0' }}>
                    <LoadingStep
                      type={this.state.type}
                      styleLoading={`loading_dataTable_step displayLoading_t loadActiv_t ${showReceveur && !showPr ? "" : "hidden_t"}`}
                      height={this.state.height}
                      width={this.state.width}
                    />
                    <img
                      src={require("../../../../images/circle_check_outline.png")}
                      alt="circle_check_outline"
                      className={`circle_check_outline_t ${!showReceveur && showPaiement ? "display2_t" : "hidden_t"}`}
                    />
                    <img
                      src={require("../../../../images/point_t.png")}
                      alt="point"
                      className={`point_t ${!showPaiement && !showReceveur ? "display2_t" : "hidden_t"}`}
                    />
                    <img
                      src={require("../../../../images/line.png")}
                      alt="line"
                      style={showReceveur || showPaiement ? { margin: '.45rem 0px 0px 6.8rem', position: 'absolute', width: '65px' } : { margin: '.45rem 0px 0px 7.8rem', position: 'absolute', width: '65px' }}
                    />
                  </span>
                  <span className="step-text">{this.state.modalEligible ? "4/5" : "3/4"}</span>
                </div>
              </li>
              <li className="step topLine_t" style={{ margin: '0 0 0 3.2rem' }}>
                <div className="step-content">
                  <span className="step-title">Paiement</span>
                  <span className="step-circle">
                    <LoadingStep
                      type={this.state.type}
                      styleLoading={`loading_dataTable_step displayLoading_t ${showPaiement && !showReceveur ? "" : "hidden_t"}`}
                      height={this.state.height}
                      width={this.state.width}
                    />
                    <img
                      src={require("../../../../images/point_t.png")}
                      alt="point"
                      className={`point_t ${!showPaiement ? "display2_t" : "hidden_t"}`}
                    />
                  </span>
                  <span className="step-text">{this.state.modalEligible ? "5/5" : "4/4"}</span>
                </div>
              </li>
            </ul> */}
          </div>

          {/* {realCard ? <div className="col col-sm-5 stepDiv_t"> */}
          {(totalSum >= 23) ? <div className="col col-sm-5 stepDiv_t">
            <Alert color="success"
              style={{ maxWidth: '350px', margin: '0px 0px 0px 3.5rem' }}
            >
              <p
                style={{ fontSize: '20px' }}
              >
                🎉 Félicitations!!!🎉
              </p>
              Vous êtes eligibles
              pour le <b>paiement différé</b>.<br />
              {/* Pour continuer
              cliquez */}
              {/* <a href="javascript:;"
                onClick={this.handleHaveMembershipCard}
                style={{ color: '#FFF' }}
                className="btn membershipCardModal_t">
                Ici
              </a> */}

            </Alert>
          </div>
            :
            null}
        </div>

        {/* <HaveYouMembershipCard
          handleShow={this.handleShow}
          handleClose={this.handleClose}
          show={this.state.show}
          handleHaveMembershipCard={this.handleHaveMembershipCard}
        /> */}

        {/* <div className="col col-sm-6 stepDiv_t">
          <ul className={`steps ${showCarteadhesion || showPr || showReceveur || showPaiement ? "stepsColor_t stepsColorTrue_t" : "stepsColor_t"}`}>
            {stepsData.map((step, index) => {
              return (
                <StepsComponent
                  key_l={index + 1}
                  title={step.title}
                  showText={step.showText}
                  showContent={step.showContent}
                  type={this.state.type}
                  height={this.state.height}
                  width={this.state.width}
                  showPr={showPr}
                  showReceveur={showReceveur}
                  showPaiement={showPaiement}
                  showCart={showCart}
                />
              )
            })}
          </ul>
        </div> */}


        <div className="row" style={{ margin: '30px 0 0 0' }}>
          <div className='col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7'>
            {this.props.offline ?
              <span className="offlinePage_t">
                Verifiez votre connextion internet puis réesayez
              </span>
              : ''
            }
          </div>
          <div className='col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7'>

            {showReceveur ?
              <>
                <a
                  href="javascript:;"
                  onClick={onNotSendCart}
                  className={cartActiveBool ? "title_cart_t title_cart_active_t" : "title_cart_t"}
                  style={{ 'font-size': '15px' }}> Mon Panier</a>

                {/* <img
                  src={require("../../../../images/vertcalLine.png")}
                  alt="back"
                  className="backVLineCart_t" /> */}

                <a
                  href="javascript:;"
                  onClick={onSendCart}
                  className={cartActiveBool ? "title_cartReceveur_t" : "title_cartReceveur_t title_cart_active_t"}
                // className="title_cartReceveur_t"
                >
                  {/* Envoyer mon Panier à */}
                  J'envoie mon Panier à
                </a>
              </>
              :
              null
            }
          </div>
        </div>

        {showCart ? <span className="title_cart_t"> Mon Panier </span> : null}

        <div className={showCart ? "row display2_t panierMargin" : "row hidden_t"}>
          <Panier
            qty_t={qty_t}
            // sum_t={sum_t}
            sum_t={totalSum}
            onShowPointRelais_t={onShowPointRelais_t}
            onshowCarteadhesion_t={this.props.onshowCarteadhesion_t}
            cartValue={cartValue}
            qtyValue_t={qtyValue_t}
            qtyValueArr_t={qtyValueArr_t}
            onQty_t={onQty_t}
            coefAndPrice={coefAndPrice}
            goToParentCoefAndPrice={goToParentCoefAndPrice}
            onDeleteProduct={onDeleteProduct}
            modalEligible={this.state.modalEligible}
            showCarteadhesionGS={showCarteadhesionGS}
            onNotshowCarteadhesion_t={onNotshowCarteadhesion_t}
            onShowPayment_t={onShowPayment_t}
            clickOnCmd={clickOnCmdStore}
            montantLivraison={montantLivraison}
            isSocialAssistanceOption={isSocialAssistanceOption}

            onHandleParentStateTotal={onHandleParentStateTotal}

            totalHtArrondie={totalHtArrondie}
            totalTauxInteretArrondie={totalTauxInteretArrondie}
            totalTtcArrondie={totalTtcArrondie}

            treatment_costs={treatment_costs}
            social_assistance={social_assistance}
            delivery={delivery}
            amountS={amountS}
            fromCMD={fromCMD}
            totalHtArrondieS={totalHtArrondieS}
            interest_rate={interest_rate}
            onPayment={onPayment}
          />

        </div>

        {showCarteadhesion ? <span className="title_cart_t"> Paiement Différé </span> : null}

        {(showCarteadhesion && membershipCardClick === true && isDeferredPayment === true) ?
          <div className="col col-sm-6 stepDiv_t">
            <Alert color="danger"
              style={{ margin: '0px 0px 0px 15%' }}
            >
              <span
                style={{ fontSize: '18px', color: '#f25961', 'font-weight': '700' }}
              >
                Vous devez choisir votre mode de Paiement différé !!!
              </span>
            </Alert>
          </div>
          :
          null}

        <div className={showCarteadhesion ? "row display2_t marginRelaisP_t" : "row hidden_t"} >

          <MembershipCard
            qty_t={qty_t}
            // sum_t={sum_t}
            sum_t={totalSum}
            cartValue={cartValue}
            qtyValue_t={qtyValue_t}
            qtyValueArr_t={qtyValueArr_t}
            onQty_t={onQty_t}
            coefAndPrice={coefAndPrice}
            goToParentCoefAndPrice={goToParentCoefAndPrice}
            onShowPointRelais_t={onShowPointRelais_t}
            modalEligible={this.state.modalEligible}

            pointRelaisValue={this.props.pointRelaisValue}
            optionPointRelais_t={this.props.optionPointRelais_t}
            onChooseSocialAssistance={onChooseSocialAssistance}
            onShowPointRelaisFromPDiff_t={onShowPointRelaisFromPDiff_t}
            showCarteadhesionGS={showCarteadhesionGS}

            handleChangeSocialOption={handleChangeSocialOption}
            selectedValue={selectedValue}
            isSocialAssistanceOption={isSocialAssistanceOption}
            montantLivraison={montantLivraison}

            onHandleParentStateTotal={onHandleParentStateTotal}

            totalHtArrondie={totalHtArrondie}
            totalTauxInteretArrondie={totalTauxInteretArrondie}
            totalTtcArrondie={totalTtcArrondie}

            treatment_costs={treatment_costs}
            social_assistance={social_assistance}
            delivery={delivery}
            amountS={amountS}
            fromCMD={fromCMD}
            totalHtArrondieS={totalHtArrondieS}
            interest_rate={interest_rate}
          />

        </div>

        {showPr ? <span className="title_cartPointSection_t">
          {/* Livraison */}
          Choisissez la livraison pour votre article
        </span> : null}

        {(showPr && rpointIsNotchoosed === true) ?
          <div className="col col-sm-6 stepDiv_t">
            <Alert color="success"
              style={{ margin: '0px 0px 0px 15%' }}
            >
              <span
                style={{ fontSize: '18px', color: '#f25961', 'font-weight': '700' }}
              >
                {/* Vous devez choisir un point relais !!! */}
                Choisissez un mode de livraison pour votre article
              </span>
            </Alert>
          </div>
          :
          null}

        <div className={showPr ? "row display2_t marginRelaisP_t" : "row hidden_t"} >
          <PointRelais
            qty_t={qty_t}
            // sum_t={sum_t}
            sum_t={totalSum}
            onShowReceveur_t={onShowReceveur_t}
            pointRelaisValue={this.props.pointRelaisValue}
            optionPointRelais_t={this.props.optionPointRelais_t}
            gotToParentValue={this.props.gotToParentValue}
            bool_val={this.props.bool_val}
            cartValue={cartValue}
            qtyValue_t={qtyValue_t}
            qtyValueArr_t={qtyValueArr_t}
            onQty_t={onQty_t}
            coefAndPrice={coefAndPrice}
            goToParentCoefAndPrice={goToParentCoefAndPrice}
            relaypointIdStore={relaypointId}
            showCarteadhesionGS={showCarteadhesionGS}

            modalEligible={this.state.modalEligible}
            selectedValue={selectedValue}
            isSocialAssistanceOption={isSocialAssistanceOption}
            onHandleRelayPointId={onHandleRelayPointId}
            handleAddressBeneficiary={handleAddressBeneficiary}
            getShortContryNameOfBeneficiary={getShortContryNameOfBeneficiary}
            distanceBetweenBeneficiaryAndOurLocaltionViaGoogleMap={distanceBetweenBeneficiaryAndOurLocaltionViaGoogleMap}
            addressOfBeneficiary={addressOfBeneficiary}
            heure={heure}
            minute={minute}
            seconde={seconde}
            distanceBeneficiary={distanceBeneficiary}
            onAtHomeChoose={onAtHomeChoose}
            montantLivraison={montantLivraison}

            onHandleParentStateTotal={onHandleParentStateTotal}

            totalHtArrondie={totalHtArrondie}
            totalTauxInteretArrondie={totalTauxInteretArrondie}
            totalTtcArrondie={totalTtcArrondie}
            typeOfHLocatization={typeOfHLocatization}

            treatment_costs={treatment_costs}
            social_assistance={social_assistance}
            delivery={delivery}
            amountS={amountS}
            fromCMD={fromCMD}
            totalHtArrondieS={totalHtArrondieS}
            interest_rate={interest_rate}
          />
        </div>

        <div className={showReceveur ? "row display2_t" : "row hidden_t"} style={{ margin: '1rem 0 0 0' }}>

          <Receveur
            qty_t={qty_t}
            // sum_t={sum_t}
            sum_t={totalSum}
            pointRelaisValue={this.props.pointRelaisValue}
            optionPointRelais_t={this.props.optionPointRelais_t}
            gotToParentCatValue={this.props.gotToParentCatValue}
            bool_val={this.props.bool_val}
            cartValue={cartValue}
            qtyValue_t={qtyValue_t}
            qtyValueArr_t={qtyValueArr_t}
            onQty_t={onQty_t}
            coefAndPrice={coefAndPrice}
            goToParentCoefAndPrice={goToParentCoefAndPrice}
            Receveur={categorieLabel}
            errors={errors}
            option_sexe={option_sexe}
            option_country={option_country}
            option_city={option_city}
            eye_password_bool={eye_password_bool}
            toggle={toggle}
            isLoading={isLoading}
            isValue={isValue}
            email_exit={email_exit}
            email_not_val={email_not_val}
            postregister={postregister}
            searchR_t={searchR_t}
            gotToParentValue={gotToParentValue}
            handleChange={handleChange}
            handleChangeDistrict={handleChangeDistrict}
            seacrchList={seacrchList}
            onUserDetails={onUserDetails}
            nameChoice={nameChoice}
            firstname={firstname}
            lastname={lastname}
            country={country}
            phone={phone}
            onShowReceveurRelais_t={onShowReceveurRelais_t}
            onsubmitExitingUsers_t={onsubmitExitingUsers_t}
            onsubmitNewUser_t={onsubmitNewUser_t}
            myRef={myRef}
            showBtnTrue2={showBtnTrue2}
            showBtnTrue={showBtnTrue}
            sendCart={sendCart}
            userExistBool={userExistBool}
            userNotExistBool={userNotExistBool}
            onUserExistBool={onUserExistBool}
            onUserNotExistBool={onUserNotExistBool}
            showCarteadhesionGS={showCarteadhesionGS}

            onShowPayment_t={onShowPayment_t}
            modalEligible={this.state.modalEligible}
            selectedValue={selectedValue}
            isSocialAssistanceOption={isSocialAssistanceOption}
            montantLivraison={montantLivraison}

            onHandleParentStateTotal={onHandleParentStateTotal}

            totalHtArrondie={totalHtArrondie}
            totalTauxInteretArrondie={totalTauxInteretArrondie}
            totalTtcArrondie={totalTtcArrondie}

            treatment_costs={treatment_costs}
            social_assistance={social_assistance}
            delivery={delivery}
            amountS={amountS}
            fromCMD={fromCMD}
            totalHtArrondieS={totalHtArrondieS}
            interest_rate={interest_rate}
            creatorCreateUserProfile={creatorCreateUserProfile}
          />
        </div>

        <div className={showPaiement ? "row display2_t" : "row hidden_t"} style={{ margin: '1rem 0 0 0' }}>

          <Payment
            qty_t={qty_t}
            // sum_t={sum_t}
            sum_t={totalSum}
            pointRelaisValue={this.props.pointRelaisValue}
            optionPointRelais_t={this.props.optionPointRelais_t}
            gotToParentCatValue={this.props.gotToParentCatValue}
            bool_val={this.props.bool_val}
            cartValue={cartValue}
            cartValueForCmd={cartValueForCmd}
            qtyValue_t={qtyValue_t}
            qtyValueArr_t={qtyValueArr_t}
            onQty_t={onQty_t}
            coefAndPrice={coefAndPrice}
            goToParentCoefAndPrice={goToParentCoefAndPrice}
            Receveur={categorieLabel}
            errors={errors}
            option_sexe={option_sexe}
            option_country={option_country}
            option_city={option_city}
            eye_password_bool={eye_password_bool}
            toggle={toggle}
            isLoading={isLoading}
            isValue={isValue}
            email_exit={email_exit}
            email_not_val={email_not_val}
            postregister={postregister}
            searchR_t={searchR_t}
            gotToParentValue={gotToParentValue}
            handleChange={handleChange}
            seacrchList={seacrchList}
            onUserDetails={onUserDetails}
            nameChoice={nameChoice}
            firstname={firstname}
            lastname={lastname}
            country={country}
            phone={phone}
            onShowReceveurRelais_t={onShowReceveurRelais_t}
            onsubmitExitingUsers_t={onsubmitExitingUsers_t}
            onsubmitNewUser_t={onsubmitNewUser_t}
            myRef={myRef}
            showBtnTrue2={showBtnTrue2}
            showBtnTrue={showBtnTrue}
            sendCart={sendCart}
            userExistBool={userExistBool}
            userNotExistBool={userNotExistBool}
            onUserExistBool={onUserExistBool}
            onUserNotExistBool={onUserNotExistBool}
            onShowPayment_t={onShowPayment_t}
            onPayment={onPayment}
            creator_name={creator_name}
            showCarteadhesionGS={showCarteadhesionGS}

            modalEligible={this.state.modalEligible}
            selectedValue={selectedValue}
            isSocialAssistanceOption={isSocialAssistanceOption}

            onChooseSocialAssistance={onChooseSocialAssistance}
            postregisterStore={postregisterStore}
            handleChangeSocialOption={handleChangeSocialOption}
            nameChoiceStore={nameChoiceStore}
            totalSum={totalSum}
            montantLivraison={montantLivraison}
            onHandleParentStateTotal={onHandleParentStateTotal}

            totalHtArrondie={totalHtArrondie}
            totalTauxInteretArrondie={totalTauxInteretArrondie}
            totalTtcArrondie={totalTtcArrondie}
            paymentType={paymentType}
            isSocialPayment={isSocialPayment}

            treatment_costs={treatment_costs}
            social_assistance={social_assistance}
            delivery={delivery}
            amountS={amountS}
            fromCMD={fromCMD}
            totalHtArrondieS={totalHtArrondieS}
            interest_rate={interest_rate}
            insertInEconomicAccessibility={insertInEconomicAccessibility}
          />
        </div>

        <div className={showCart ? "row marg_null_t display2_t" : "row marg_null_t hidden_t"}>

          <Link
            onClick={(bakupVal) => this.backup("shoppingCart")}
            className="backToProduct_t">
            <img
              src={require("../../../../images/chevron_left.png")}
              alt="back"
              className="back_t" />
            continuer mes achats
          </Link>

        </div>

        {/* <div className={showCarteadhesion ? "row marg_null_t display2_t" : "row marg_null_t hidden_t"}>
          <a
            href="javascript:;"
            className="backToProduct_t"
            onClick={this.onReturnCartLocal_t}>
            <img
              src={require("../../../../images/chevron_left.png")}
              alt="back"
              className="back_t" />
            retour au panier
          </a>
        </div> */}

        <div className={showPr ? "row marg_null_t display2_t" : "row marg_null_t hidden_t"}>
          {/* {this.state.modalEligible ?
            <a
              href="javascript:;"
              className="backToProduct_t"
              onClick={onMembershipCard_t}>
              <img
                src={require("../../../../images/chevron_left.png")}
                alt="back"
                className="back_t" />
              retour au Paiement différé
            </a>
            :
            <a
              href="javascript:;"
              className="backToProduct_t"
              onClick={this.onReturnCartLocal_t}>
              <img
                src={require("../../../../images/chevron_left.png")}
                alt="back"
                className="back_t" />
              retour au panier
            </a>
          } */}

          <a
            href="javascript:;"
            className="backToProduct_t"
            onClick={onReturnCart_t}>
            <img
              src={require("../../../../images/chevron_left.png")}
              alt="back"
              className="back_t" />
            retour au panier
          </a>

        </div>

        <div className={showReceveur ? "row marg_null_t display2_t" : "row marg_null_t hidden_t"}>
          <a href="javascript:;"
            className="backToProduct_t"
            onClick={onReturnRelaisPoint_t}>
            <img
              src={require("../../../../images/chevron_left.png")}
              alt="back"
              className="back_t" />
            Retour au point relais
          </a>
        </div>

        <div className={showPaiement ? "row marg_null_t display2_t" : "row marg_null_t hidden_t"}>
          <a href="javascript:;"
            className="backToProduct_t"
            onClick={(e, relaypointIdArray) => onShowReceveur_t(e, relaypointId)}>
            <img
              src={require("../../../../images/chevron_left.png")}
              alt="back"
              className="back_t" />
            Retour sur la page Bénéficiaire
          </a>
        </div>

        {/*<div className="row" style={{margin: '1rem 0 0 5rem'}}>
        <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-6">
          <ProductList 
            cartValue={cartValue}
            qtyValue_t={qtyValue_t}
            qtyValueArr_t={qtyValueArr_t}
            onQty_t={onQty_t}
            coefAndPrice={coefAndPrice}
            goToParentCoefAndPrice={goToParentCoefAndPrice}
            />

        </div>
        <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-6 acideLeftCart_t">
          <p className="p1_t">
            <span className="al_first_span_t"> 
              { qty_t } articles
            </span>
            <span className="ar_first_span_t">
             {sum_t + ((sum_t/100)*19.28)} FCFA
            </span>
          </p>
          <p className="p2_t">
            <span className="al_first_span_t"> 
              Livraison
            </span>
            <span className="ar_first_span_t">
              gratuit
            </span>
          </p>
          <hr />
          <p className="p1_t">
            <span className="al_first_span_t"> 
              Total (HT)
            </span>
            <span className="ar_first_span_t">
             {sum_t} FCFA
            </span>
          </p>
          <p className="p2_t">
            <span className="al_first_span_t"> 
              Taxes
            </span>
            <span className="ar_first_span_t">
              { parseInt((sum_t/100)*19.28) } FCFA
            </span>
          </p>
          <hr />
           <a href="javascript:;"
             style={{color: '#FFF'}}
             className="btn next_cart_t">
               Continuer
            </a>
        </div>
       </div>*/}







        {/*<div className="row" style={{margin: '1rem 0 0 5rem'}}>
          
        </div>*/}


        {/*<img 
          src={require("../../../../images/camera.png")} 
          alt="Camera_t" 
          className="camera_t" />
        <img 
          src={require("../../../../images/jack.png")} 
          alt="Jack" 
          className="jack_t" />
        <img 
          src={require("../../../../images/casque.png")} 
          alt="Casque" 
          className="casque_t" />
        <img 
          src={require("../../../../images/casque.png")} 
          alt="Casque" 
          className="casque2_t" />
        <img 
          src={require("../../../../images/jack.png")} 
          alt="Jack" 
          className="jack2_t" />*/}



        {/* <div className="row marg_null_t justify_content_center_t acideLeftMainDiv_t">
           <div className="col col-sm-2 acideLeft_t">
           </div>
           <div className="col col-sm-10">
             <div className="row marg_null_t">
               <CatTrieComponent
                label={this.props.textTriePrix}
                prix_t={prix_t}
                devise_t={devise_t}
                parent_categorieLabel={parent_categorieLabel}
                parent_add_categorie={parent_add_categorie}
                showTrieBool_t={showTrieBool_t}
                 /> 

            </div>

            </div>
        </div> */}

        <div className="showAndHiddenRespDesk_t">
          <Footer />
        </div>

        <div className="footerMobil_t">
          &nbsp;&nbsp;
        </div>

      </div>
    );
  }
}
export default withTranslation()(PanierContent);