import Http from '../../Http'
import * as action from '../../store/actions'

export function paymentCard_t(commandId) {
    const data = {commandId: commandId}
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.post(`/create-session-stripe-account`, data)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
        })
    )
};