export const INITSTATE_T = 'INITSTATE_T';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_VERIFY = 'AUTH_VERIFY';
export const CHANGE_N_T = 'CHANGE_N_T';
export const AUTH_T = 'AUTH_T';
export const PROFILE_T = 'PROFILE_T';
export const CLASSES_T = 'CLASSES_T';
export const DATA_T = 'DATA_T';
export const LIMIT_T = 'LIMIT_T';
export const PAGE_NO_T = 'PAGE_NO_T';
export const RAMASS_TRANS = 'RAMASS_TRANS';
export const MAPSROUTES = 'MAPSROUTES';
export const ADDbUSCOLOR = 'ADDbUSCOLOR';
export const ADDMAPPOSITION = 'ADDMAPPOSITION';
export const SHOPACTION = 'SHOPACTION';
export const PRODID_T = 'PRODID_T';
export const CARTTAB_T = 'CARTTAB_T';
export const HOMECARTPRODUCTVALUE = 'HOMECARTPRODUCTVALUE';
export const CARTINITTABT = 'CARTINITTABT';
export const SHOWCARTTAB_T = 'SHOWCARTTAB_T';
export const CARUPDATETTAB_T = 'CARUPDATETTAB_T';
export const DELETECARTTAB_t = 'DELETECARTTAB_t';
export const HOMEP = 'HOMEP';
export const RELAYPOINTID = 'RELAYPOINTID';
export const SHOWALERTHOMEPRODUCT = 'SHOWALERTHOMEPRODUCT';
export const showCarteadhesion = 'showCarteadhesion';
export const TABSCATVALUE = 'TABSCATVALUE';
export const NAVCARTSTORE = 'NAVCARTSTORE';
export const EXISTANTRECEIVERUSER = 'EXISTANTRECEIVERUSER';
export const SAVEUSERINFOS = 'SAVEUSERINFOS';
export const ONCLICKONCMD = 'ONCLICKONCMD';
export const RELAYPOINTVALUE = 'RELAYPOINTVALUE';
export const BENEFICIARYLOCATION = 'BENEFICIARYLOCATION';
export const INITRELAYPOINTDATA = 'INITRELAYPOINTDATA';
export const TYPEOFHOMELOCALIZATION = 'TYPEOFHOMELOCALIZATION';
export const SOCIALASSISTANCEOBJECT = 'SOCIALASSISTANCEOBJECT';
export const SELECTEDVALUEFUNCT = 'SELECTEDVALUEFUNCT';
export const INITRELAYPOINTIDTYPE = 'INITRELAYPOINTIDTYPE';
export const ECONOMICACCESSIBILITY = 'ECONOMICACCESSIBILITY';
export const SEARCHLIST = 'SEARCHLIST';
