import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class searchUsersComponent extends Component {

  render(){
       let { t, categorieLabel } = this.props
       // let listCategorie = categorieLabel.length > 0 && categorieLabel.map((v, i)=>(
       //     <div className="cat_list_t" key={i}>
       //       <span className="sous_cat_t">
       //          {v.label} 
       //          <a 
       //            href="javascript:;" 
       //            onClick={this.delete_sous_cat.bind(this, i, v)}
       //            className="del_sous_cat_t">
       //            x
       //          </a>
       //       </span>
       //      </div>
       //   ));
          return(
                  <div className="cat_list_t">
                     <span className="sous_cat_t">
                        Ludovic Moukouri Njoh
                        {/*<a 
                          href="javascript:;" 
                          onClick={this.delete_sous_cat.bind(this, i, v)}
                          className="del_sous_cat_t">
                          x
                        </a>*/}
                     </span>
                    </div>
          );
        }
}
export default withTranslation()(searchUsersComponent);