import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

// import banner1 from '../../../images/banner-resize-2.jpeg';
// import banner2 from '../../../images/banner-resize-1.jpeg';
// import banner3 from '../../../images/banner-resize-3.jpeg';

import banner1 from '../../../images/banner_main7.jpeg';

import banner2 from '../../../images/banner_main.jpeg';
// import banner2 from '../../../images/banner-resize-1.jpeg';
import banner3 from '../../../images/banner-resize-3.jpeg';

function CarouselComponent() {
    return (
        <div className="slick-dots_t"
            style={{
                // padding: '2% 0 0 0', 
                borderRadius: '10px'
            }}>
            <Carousel data-bs-theme="dark">
                <Carousel.Item>

                    <img
                        className="bannerImg_t"
                        src={banner1}
                        alt="First banner"
                    />

                    {/* <div className="img_slider_center_t bannerImg_t">&nbsp;</div>

                    <img
                        className="img_slider_center_t bannerImg_t"
                        srcset={require('../../../images/banner-4.png')}
                     alt="imgBanner"
                    /> */}

                    {/* <div className="container-fluid">

                        <div className="row div_carousel">

                            <div classNamqe="col-12 col-sm-3">
                                <span className="text_left_slider_t">
                                    100% Epicées dans votre ville
                                </span>
                            </div>
                            <div className="col-12 col-sm-6">
                                <img
                                    className="img_slider_center_t"
                                    srcset={require('../../../images/banner-4.png')}
                                />
                            </div>
                            <div className="col-12 col-sm-3">
                                <span className="text_right_slider_t">
                                    Profitez d’un pack complet d’ingrédients
                                    <a
                                        href="javascript:;"
                                        className="btn btn_more_details"
                                    // onClick={this.moreDetails}
                                    >
                                        Voir les details
                                    </a>
                                </span>

                            </div>
                        </div>
                    </div> */}
                </Carousel.Item>

                {/* <Carousel.Item>

                    <img
                        className="bannerImg_t"
                        src={banner2}
                        alt="Second banner"
                    />


                </Carousel.Item> */}

                {/* <Carousel.Item>

                    <img
                        className="bannerImg_t"
                        src={banner3}
                        alt="Second banner"
                    />


                </Carousel.Item> */}

            </Carousel>
        </div>
    );
}

export default CarouselComponent;