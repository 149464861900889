import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import fr from 'ree-validate/dist/locale/fr';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import {Helmet} from "react-helmet";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, matchPath } from 'react-router-dom';
import {Redirect} from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import AuthService from '../../../services/auth';
import ProductService from '../../../services/product';
import UsersService from '../../../services/users';
import { lightTheme, darkTheme } from '../../../js/theme';
import { GlobalStyles } from '../../../js/global';
import { changeNavigatorTheme, auth_t, prodId_t } from '../../../store/actions';


import HeaderUsers from './../../layout/HeaderUsers';
import HeaderMenu from './../../layout/HeaderMenu';
import HeaderTop from './../../layout/HeaderTop'; 
import BandeBlanche from './../../layout/BandeBlanche';
import AcideLeftItem from './Components/AcideLeftItem';
import ReciverForms from './Components/ReciverForms';
import HeaderUsersMobile from './../../layout/HeaderUsersMobile';
import Footer from './../../layout/Footer';
import MobileCategoriesMenu from '../../layout/HeaderComponents/MobileCategoriesMenu';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NewHeader from '../../layout/NewHeader5';

const getParams = (pathname) => {
  const macthProfile = matchPath(pathname, {
    path: `/profile/:idProf`,
  });
  return (macthProfile && macthProfile.params) || {};
}
class Page extends Component { 
  constructor(props) {
        super();
        this.validator = new ReeValidate.Validator({
            lastname: 'required',
            firstname: 'required',
            email: 'required|email',
            password: 'required|min:6', 
            country: 'required',
            phone: 'required',
            sexe: 'required',
            address: 'required',
            date: 'required',
        });
        this.validator.localize('fr', fr)
        this.state = {
          postregister: {
            lastname: '',
            firstname: '',
            country_id: null,
            country: '',
            address: '',
            email: '',
            phone: '',
            password: '',
            sexe: '',
            date: new Date(),
          },
          errors: this.validator.errors,
          offline: false,
          theme_bool: false,
          langBool: true,
          def_theme: '',
          checked_switch: true,
          isLoading: false,
          options_fr: [
              {value: 'en', label: "Anglais"}
          ],
          options_eng: [
              {value: 'fr', label: "Francais"}
          ],
          options_devise: [
              {value: '€', label: "Dollar"}
          ],
          toggleBool_t: false,
          email_exit: false,
          eye_password_bool: true,
          option_country: [
              {value: 'cmr', label: "Cameroun"},
              {value: 'cg', label: "Congo k"}
          ],
          option_sexe: [
              {value: 'Masculin', label: 'Masculin'}, 
              {value: 'Feminin', label: 'Feminin'}
          ],
          email_not_val: '',
          isUpdate: false,
      allCategories: []
    };
        this.theme_botton_D = this.theme_botton_D.bind(this);
        this.theme_botton_W = this.theme_botton_W.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.checked_t = this.checked_t.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    UNSAFE_componentWillMount() {
      if(this.props.def_theme === ''){
        this.check_theme();
      }
    }
    componentDidMount() {
      this.props.dispatch(auth_t({__iA: true, __iV: true}));
          const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
        let d_theme = bytes.toString(CryptoJS.enc.Utf8);
            if(d_theme === "\"dark\"") {
        this.setState({checked_switch: true});      
      }
      if(d_theme === "\"dark\"") {
        this.setState({checked_switch: false});
      }
      if(this.props.i18n.language === 'fr-FR' || 'fr') {
        this.setState({langBool: true})
      }
      if(this.props.i18n.language === 'en') {
        this.setState({langBool: false})
      }
      const { pathname } = this.props.location;

      const currentParams = getParams(pathname);

      this.findUserById(currentParams.idProf);
      // console.log('prodId_t ', this.props.prodId_t);
    this.allCategories();
  }

  allCategories = () => {
    this.props.dispatch(ProductService.getAllCategories())
      .then((result) => {
        let data = result.data;

        const categories = this.buildCategoryHierarchy(data);

        let optionsHome = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));

        this.setState({ option_categorie: optionsHome, allCategories: categories });


        let initResp = [{ value: '', label: 'Tout' }]
        let optionsResp = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
        initResp = initResp.concat(optionsResp);
        let options = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  buildCategoryHierarchy = (data) => {
    // console.log("VBVV ", categories); return;

    const categoryMap = {};
    const hierarchy = [];

    // Map categories by slug
    data.forEach(item => {
      categoryMap[item.slug] = {
        name: item.name,
        iconUrl: item.images,
        path: item.url_slug,
        slug: item.slug,
        level: !!item.level ? parseInt(item.level) : 0,
        parent_id: item.parent_id,
        subcategories: []
      };
    });

    // Build hierarchy based on level and url_slug
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.level === 0 || category.level === '') {
        hierarchy.push(category);
      } else {
        const parentSlug = category.path.split('/')[category.level];
        if (categoryMap[parentSlug]) {
          categoryMap[parentSlug].subcategories.push(category);
        }
      }
    });

    // Update path if subcategories length is 0
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.subcategories.length > 0) {
        delete category.path;
      }
    });

    return hierarchy;
  }

    handleClick(lang) {
    const { i18n } = this.props;
      i18n.changeLanguage(lang);
      this.setState({langBool: !this.state.langBool});
   }
    checked_t() {
      this.setState({checked_switch: !this.state.checked_switch});
    }

    check_theme() {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // console.log('🎉 Dark mode is supported');
        this.setState({def_theme: 'dark', theme_bool: true, checked_switch: false})
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
        this.props.dispatch(changeNavigatorTheme(ciphertext))
      }
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
        // console.log('🎉 light mode is supported');
        this.setState({def_theme: 'light', theme_bool: true, checked_switch: true})
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
        this.props.dispatch(changeNavigatorTheme(ciphertext))
        // console.log('ciphertext ', ciphertext)
      }
    }

    // for_remove_js(e) {
    //   e.preventDefault();
    // }

    theme_botton_D(e, ip) {
      if(ip){
        e.preventDefault();
      }
      this.setState({def_theme: 'dark', theme_bool: true})
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext));
      this.setState({checked_switch: !this.state.checked_switch});
    }
    theme_botton_W(e, ip) {
      if(ip){
        e.preventDefault();
      }
      this.setState({def_theme: 'light', theme_bool: true})
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext));
      this.setState({checked_switch: !this.state.checked_switch});
    }

findUserById = (value) => {
  this.props.dispatch(UsersService.findUserById(value))
    .then((result)  => {
      const data = result.data;
          const postsProfil =  {
              lastname: data.lastname,
              firstname: data.firstname,
              country_id: data.country_id,
              country: data.country.data.name_fr,
              address: data.address,
              email: data.email,
              phone: data.phone,
              password: '',
              description: '',
              newsletter: false,
              privacy_police: null,
              sexe: data.gender,
              date: new Date(data.birth),
              cni_t: ''
            }
      this.setState({postregister: postsProfil})
      console.log('postregister ', result.data);
    })
    .catch((err) => {
        if (err.notNetwork === true) {
            this.setState({offline: true, isLoading: false})
          }else {
            this.setState({
              isLoading: false
            });
          }
    });
}

handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    const {postregister} = this.state;

    postregister[name] = value;
    if (name === 'email') {
      this.setState({email_exit: false})
    }
    this.setState({postregister})

    // this.validator.validate(name, value)
    // .then(() => {
    //     this.setState({errors, postregister})
    // });
}

handleSubmit = (e) => {
  e.preventDefault()
      const { postregister } = this.state;
      let data = {}
      this.setState({isLoading: true});
      if(postregister.password !== '') {
        data = {
          lastname: postregister.lastname,
          firstname: postregister.firstname,
          country_id: postregister.country,
          address: postregister.address, 
          // email: postregister.email,
          phone: postregister.phone,
          password: postregister.password,
          gender: postregister.sexe,
          birth: postregister.date
        } 
      } else {
        data = {
          lastname: postregister.lastname,
          firstname: postregister.firstname,
          country_id: postregister.country,
          address: postregister.address, 
          // email: postregister.email,
          phone: postregister.phone,
          gender: postregister.sexe,
          birth: postregister.date
        }
      }
       
      const { pathname } = this.props.location;

      const currentParams = getParams(pathname);

      navigator.onLine ?
        this.props.dispatch(UsersService.updateUsers(currentParams.idProf, data))
          .then((result)  => {
            this.setState({isUpdate: true})
          })
          .catch((errors) => {
            if (errors.statusCode.response !== undefined && errors.statusCode.response.data.errors.email) {
              this.setState({
                  isLoading: false,
                  email_exit: true,
                  email_not_val: errors.statusCode.response.data.errors.email[0],
              });
            } else {
               console.log('errors ', errors);
              this.setState({isLoading: false});
            }
          })
      :
        this.setState({offline: true, isLoading: false})
    }

 async validateAndSubmit(e) {
      e.preventDefault()
      alert("Submit")

      this.handleSubmit()

      // const { errors } = this.validator

      // const valid = this.validator.validateAll(postregister)
      
      // if (valid) {
      //     this.handleSubmit(postregister)
      // } else {
      //   alert("Nooo")
      //     this.setState({ errors })        
      // }    
    }

allCountries() {
  this.props.dispatch(AuthService.getAllCountries())
      .then((result)  => {

        let data = result.data;
          let options = data.map((value, index) => (

            {
              value: value.real_id, 
              label: this.state.langBool === true ? value.name_fr : value.name_en
            }
         ));
        this.setState({
          isLoading:false,
          showAlert: false, 
          alert2: null,
          option_country: options 
        });
      })
      .catch(({data}) => {
        console.log('errors ', data);
      });
}

gotToParentValue = (value, label) => {
   const { postregister } = this.state;
   if(label === "Francais" || label === "French") {
     const options_fr = [{value: 'en', label: "Anglais"}];
      const options_eng = [{value: 'fr', label: "Francais"}];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
     this.handleClick("fr");
   }
   if(label === "Anglais" || label === "English") {
     const options_fr = [{value: 'en', label: "English"}];
      const options_eng = [{value: 'fr', label: "French"}];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
     this.handleClick("en");
   }
   if (label === "Dollar") {
          this.setState({
        device_data: value
      })
   }
   if (label === "date") {
     postregister['date'] = value
          this.setState({ postregister })
   }
   if (label === "phone") {
     postregister['phone'] = value
          this.setState({ postregister })
   }
   if (label === "sexe") {
     postregister['sexe'] = value
          this.setState({ postregister })
   }
   if (label === "country") {
     postregister['country'] = value
          this.setState({ postregister })
   }
 }

 toggle = ()=> {
      this.setState({eye_password_bool: !this.state.eye_password_bool})
    }

  onToggle_t = () => {
     this.setState({toggleBool_t: !this.state.toggleBool_t});
   }
   onLogout = () => {
      // this.setState({isLoading: true});
      this.props.dispatch(AuthService.logout())
            .then((result)  => {
              this.props.history.push("/");
            })
            .catch(({data}) => {
                console.log('errors ', data);
            });
     }
     
  render(){
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
      let d_theme = bytes.toString(CryptoJS.enc.Utf8);
         
        const { t, } = this.props;
        let { errors } = this.state;
        const { pathname } = this.props.location;

      const currentParams = getParams(pathname);
    return (
      <ThemeProvider theme={d_theme === "\"dark\"" ? darkTheme : lightTheme}>
      <>
        <GlobalStyles />
        <Helmet> 
                <script src="../../../assets/js/core/popper.min.js" type="text/javascript" />
                <script src="../../../assets/js/core/bootstrap.min.js" type="text/javascript" />

                <script src="../../../assets/js/plugin/chart.js/chart.min.js" type="text/javascript" />

               <script src="../../../assets/js/plugin/datatables/datatables.min.js" type="text/javascript" />

                <script src="../../../assets/js/plugin/bootstrap-notify/bootstrap-notify.min.js" type="text/javascript" />
                <script src="../../../assets/js/plugin/sweetalert/sweetalert.min.js" type="text/javascript" />

                <script src="../../../assets/js/atlantis.min.js" type="text/javascript" />

                <script src="../../../assets/js/setting-demo.js" type="text/javascript" />
                <script src="../../../assets/js/demo.js" type="text/javascript" />
              </Helmet>
        <div className="wrapper register_t profilPage">
        <NewHeader
              allCategories={this.state.allCategories}
              fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
              cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
              idProf={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
              // gotToParentValue={this.searchCityWithCallback}
              isShowSearch_t={false}
              // searchProducts_t={this.state.searchProducts_t}
              // searchNameWithCallback={this.searchNameWithCallback}
              onLogout={this.onLogout}
              onToggle_t={this.onToggle_t}

            // propsCustom={this.props}
            // allProductsBycategories={this.allProductsBycategories}
            // includesForAllProducts_t={this.state.includesForAllProducts_t}
            />

            <div id="side-bar"
              className={this.state.toggleBool_t ? "side-bar header-two show" : "side-bar header-two"}
            >
              <button className="close-icon-menu"
                onClick={this.onToggle_t}>
                <IconButton aria-label="close">
                  <CloseIcon />
                </IconButton>
              </button>
              <MobileCategoriesMenu
                prodId_t={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
                onLogout={this.onLogout}
                allCategories={this.state.allCategories}
                isShowSearch_t={true}
                fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
              />

            </div>


        {/* <div className="showAndHiddenRespDesk_t">
        <HeaderMenu 
              langBool={this.state.langBool} 
              // for_remove_js={this.for_remove_js} 
              checked_switch={this.state.checked_switch} 
              theme_botton_D={this.theme_botton_D} 
              theme_botton_W={this.theme_botton_W} 
              checked_t={this.checked_t} 
              option_city={this.state.option_city}
              city_id={this.state.city_id}
              gotToParentValue={this.gotToParentValue}
              option_country={this.state.option_country}
              country_value={this.state.country_value}
              toggleBool_t={this.state.toggleBool_t}
              onToggle_t={this.onToggle_t}
              isAuth_t={ this.props.isAuthenticated }
              onLogout={this.onLogout}
              showCountry={false}
              idProf={this.props.prodId_t !== undefined ? this.props.prodId_t.__id : ''}
              />
        
        <BandeBlanche  />
        
        <HeaderUsers  
              langBool={this.state.langBool} 
              for_remove_js={this.for_remove_js} 
              checked_switch={this.state.checked_switch} 
              theme_botton_D={this.theme_botton_D} 
              theme_botton_W={this.theme_botton_W} 
              checked_t={this.checked_t} 
              cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
              isShowSearch_t={false}
           />
          <HeaderTop 
              langBool={this.state.langBool} 
              for_remove_js={this.for_remove_js} 
              checked_switch={this.state.checked_switch} 
              theme_botton_D={this.theme_botton_D} 
              theme_botton_W={this.theme_botton_W} 
              checked_t={this.checked_t} 
              options_fr={this.state.options_fr}
              options_eng={this.state.options_eng}
              options_devise={this.state.options_devise}
              device_data={this.state.device_data}
              gotToParentValue={this.gotToParentValue}
              logo_noir={false}
              fName={this.props.prodId_t.__pfn !== undefined ? this.props.prodId_t.__pfn : ''}
              />
          </div>
          <div className="showAndHiddenResp_t">
            
            <HeaderUsersMobile 
              cartValue={this.props.cartValue}
              isAuthResponsive={false}
              langBool={this.state.langBool} 
              for_remove_js={this.for_remove_js} 
              checked_switch={this.state.checked_switch} 
              theme_botton_D={this.theme_botton_D} 
              theme_botton_W={this.theme_botton_W} 
              checked_t={this.checked_t} 
              option_city={this.state.option_city}
              city_id={this.state.city_id}
              gotToParentValue={this.gotToParentValue}
              option_country={this.state.option_country}
              country_value={this.state.country_value}
              toggleBool_t={this.state.toggleBool_t}
              onToggle_t={this.onToggle_t}
              isAuth_t={ this.props.isAuthenticated }
              onLogout={this.onLogout}
              isLoadingHeader={this.state.isLoadingHeader}
              idProf={this.props.prodId_t !== undefined ? this.props.prodId_t.__id : ''}
              showCountry={false}
              />

          </div> */}


              <div className="container-fluid containerProfil_t">
                <div className="row">
                <div className="col col-sm-12 justifyCenter_t">
                  <img 
                    src={require("../../../images/headerProfile.png")} 
                    alt="Image prod" 
                    className="imgProfil_t" /> 

                  <div className="divProfilUser_t">
                    <img 
                      src={require("../../../images/userProfil.png")} 
                      alt="Image prod" 
                      className="imgSettingRound_t" 
                    /> 
                    <p className="profileUserProfName_t">
                      {this.state.postregister.firstname} {this.state.postregister.lastname}
                    </p>
                    <p className="nombreUserProfReceveur_t">
                      Certifié par Wemaza 
                    </p>

                  </div>

                  <div className="btnConnexionUserProf_t">
                  <Link to={`/link`} className="btnGreenUserProf_t">
                      Envoyer une demande
                    </Link>
                    <a href="javascript:;"
                       className="btnGrayUserProf_t"
                     >
                       Contacter
                    </a> 
                    <span className="twoPoint_t">
                      :
                    </span>
                  </div>

                </div>
                </div>
                {this.state.offline ? 
                          <span className="offlinePage_t"> 
                            Verifiez votre connextion internet puis réesayez
                          </span>
                        : ''
                      }
                <div className="row margUserProf_t">
                  <div className="col col-sm-4">
                    <span className="aboutUserProf_t"> A propos </span>

                    <AcideLeftItem
                      divUserImg_t={''}
                      iconList={require("../../../images/home.png")}
                      title_t={''}
                      // label_t={`Habite Akwa Nord, Douala, Cameroun`}
                      label_t={`Habite ${this.state.postregister.country}`}
                      imgUserSize_t={'imgUserProfSize_t'}
                      settingTextUserInfoAL_t={'settingTextUserProfLogOutInfoAL_t '}
                      showBtn={false}
                    />

                    <AcideLeftItem
                      divUserImg_t={''}
                      iconList={require("../../../images/mailProfil.png")}
                      title_t={''}
                      label_t={this.state.postregister.email}
                      imgUserSize_t={'imgUserProfSize_t'}
                      settingTextUserInfoAL_t={'settingTextUserProfLogOutInfoAL_t '}
                      showBtn={false}
                    />

                    <AcideLeftItem
                      divUserImg_t={''}
                      iconList={require("../../../images/callProfil.png")}
                      title_t={''}
                      label_t={`+${this.state.postregister.phone}`}
                      imgUserSize_t={'imgUserProfSize_t'}
                      settingTextUserInfoAL_t={'settingTextUserProfLogOutInfoAL_t '}
                      showBtn={false}
                    />

                    <img 
                      src={require("../../../images/bigLine.png")} 
                      alt="Image prod" 
                      className="verticalLine_t" 
                    /> 
                  </div>

                  <div className="col-12 col-sm-8">
                      <div className="col col-sm-12 titleProfilForm_t">
                        Ajouter un nouveau profil
                      </div>

                  <form id="sign_on" onSubmit={this.handleSubmit}>
                    <div className="firstDivFormProfil_t">
                          <ReciverForms
                            postdata={this.state.postregister}
                            errors={errors}
                            postregister={this.state.postregister}
                            handleChange={this.handleChange}
                            option_sexe={this.state.option_sexe}
                            gotToParentValue={this.gotToParentValue}
                            option_country={this.state.option_country}
                            // option_city={option_city}
                            eye_password_bool={this.state.eye_password_bool}
                            toggle={this.toggle}
                            isLoading={this.state.isLoading}
                            isValue={this.state.isValue}
                            offline={this.state.offline}
                            email_exit={this.state.email_exit}
                            email_not_val={this.state.email_not_val}
                            date={this.state.date}
                            prodId_t={this.props.prodId_t.__id}
                            currentprodId_t={currentParams.idProf}
                          />
                          
                        </div>
                       {this.props.prodId_t.__id !== currentParams.idProf ? 
                         '' 
                       : 
                         <div className="divSaveReceiverProfile_t">
                          <button className="btn saveReceiverProfile_t">
                            Modifier 
                          </button>

                          {this.state.isUpdate ? 
                            <div className="imgOkUpdate_t">
                              <img 
                                src={require("../../../images/checkOK.png")} 
                                alt="Check Ok"  
                              /> Terminé
                            </div> 
                          :
                            ''
                          }

                        </div>
                       }
                        
                        </form>

                  </div>
                </div>
                    
              {/* <div className="showAndHiddenRespDesk_t row">
                <Footer />
              </div> */}
              
              <div className="footerMobil_t"> 
                &nbsp;&nbsp; 
              </div>

            </div>

              

      </div>
      </>
    </ThemeProvider>
    );
  }
}
export default withTranslation()(Page);