import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

class MenuCategiries extends Component {
    constructor(props) {
        super();
    }
    render() {
        const {
            t,
            tabsCatValue,
            handleChangeTabsCatValue,
        } = this.props;


        return (
            <div className='container-fuild' style={{
                'padding-top': '12rem',
                display: 'flex',
                position: 'absolute',
                zIndex: 1
            }}>
                <Tabs
                    value={tabsCatValue}
                    onChange={handleChangeTabsCatValue}
                    aria-label="categories tabs"
                    sx={{
                        '& .MuiTab-root': {
                            padding: '12px 14px',  // Custom padding for Tab cells
                        },
                        height: '43px',
                        minHeight: '28px'
                    }}>
                    <Tab
                        sx={{
                            fontSize: '15px',
                            fontWeight: 800,
                            lineHeight: '0.5',
                            textTransform: 'capitalize',
                            fontFamily: 'Maison Neue Mono',
                            borderRight: '1px dotted #ccc',
                        }}
                        label="Accueil" />
                    <Tab
                        sx={{
                            fontSize: '15px',
                            fontWeight: 800,
                            lineHeight: '1.5',
                            textTransform: 'capitalize',
                            fontFamily: 'Maison Neue Mono',
                            borderRight: '1px dotted #ccc',
                        }}
                        label="Alimentaires" />
                    <Tab
                        sx={{
                            fontSize: '15px',
                            fontWeight: 800,
                            lineHeight: '1.5',
                            textTransform: 'capitalize',
                            fontFamily: 'Maison Neue Mono',
                            borderRight: '1px dotted #ccc',
                        }}
                        label="Epiceries et boissons" />
                    <Tab
                        sx={{
                            fontSize: '15px',
                            fontWeight: 800,
                            lineHeight: '1.5',
                            textTransform: 'capitalize',
                            fontFamily: 'Maison Neue Mono',
                            borderRight: '1px dotted #ccc',
                        }}
                        label="Beauté et bien-être" />
                    <Tab
                        sx={{
                            fontSize: '15px',
                            fontWeight: 800,
                            lineHeight: '1.5',
                            textTransform: 'capitalize',
                            fontFamily: 'Maison Neue Mono',
                            borderRight: '1px dotted #ccc',
                        }}
                        label="Jouets, enfants et bébés" />
                    <Tab
                        sx={{
                            fontSize: '15px',
                            fontWeight: 800,
                            lineHeight: '1.5',
                            textTransform: 'capitalize',
                            fontFamily: 'Maison Neue Mono',
                            borderRight: '1px dotted #ccc',
                        }}
                        label="Modes et vêtements" />
                    {/* <Tab label="Electroniques" disabled /> */}
                    <Tab
                        sx={{
                            fontSize: '15px',
                            fontWeight: 800,
                            lineHeight: '1.5',
                            textTransform: 'capitalize',
                            fontFamily: 'Maison Neue Mono',
                            borderRight: '1px dotted #ccc',
                        }}
                        label="Electroniques" />
                    {/* <Tab
                        sx={{
                            fontSize: '15px',
                            fontWeight: 800,
                            lineHeight: '1.5',
                            textTransform: 'capitalize',
                            fontFamily: 'Maison Neue Mono',
                            borderRight: '1px dotted #ccc',
                        }}
                        label="Multimedia" /> */}
                </Tabs>
            </div>
        );
    }
}
export default withTranslation()(MenuCategiries);