import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import FloatingInput from './../../../../components/FormsComponent/FloatingInput';
import FormsChoice from './../../../../components/FormsComponent/FormsChoice';
import FormsSelect from './../../../../components/FormsComponent/FormsSelect';
import LoginBtn from './../../../../components/FormsComponent/LoginBtn';

class LoginComponent extends Component {

  render() {
    let { t,
      errors,
      postlogin,
      eye_password_bool,
      toggle,
      handleChange,
      validateAndSubmit,
      offline,
      isLoading,
      lock_style,
      registerBtnCompte,
      labelRegisterBtnCompte } = this.props
    return (
      <form id={this.props.sign_in} className="user_modal_t" onSubmit={validateAndSubmit}>
        <div className="row row_t">
          <div className="col col-sm-12">
            <div className={classnames('form-line', { 'error': errors.has('email') })}>
              <FloatingInput
                className={'input_float_posit_t form-control input-solid'}
                classNamePlaceh={'label_float_posit_t placeholder'}
                style_f_floating_div={'form-group form-floating-label input_g_parent_2'}
                label_style={{ top: '.7rem' }}
                type={'text'}
                name={'email'}
                id={'email'}
                value={postlogin.email}
                placeholder={t('input_email')}
                onChange={handleChange}
                text_or_not={true}
                required={true}
                icon_user={true}
                class_for_img_icon_style={true}
                black_profile_img_t={false}
              />
            </div>
            {errors.has('email') &&
              <label id="name-error" className="error" htmlFor="email">{errors.first('email')}</label>
            }
          </div>
        </div>



        <div className="row row_t">
          <div className="col col-sm-12" style={{ margin: '3% 0 0 0' }}>
            <div className={classnames('form-line', { 'error': errors.has('password') })}>
              <FloatingInput
                className={'input_float_posit_t form-control input-solid'}
                classNamePlaceh={'label_float_posit_t placeholder'}
                style_f_floating_div={'form-group form-floating-label input_g_parent_2'}
                label_style={{
                  'z-index': '3',
                  top: '.7rem',
                  color: '#FFF!important'
                }}
                lock_style={lock_style}
                type={eye_password_bool === true ? 'password' : 'text'}
                toggle={toggle}
                eye_password_bool={eye_password_bool}
                name={'password'}
                id={'password'}
                value={postlogin.password}
                placeholder={t('input_password')}
                onChange={handleChange}
                text_or_not={false}
                required={true}
                icon_lock={true}
                class_for_img_icon_style={true}
                black_profile_img_t={false}
              />
              {/* <span className="mp_oublie_t">
                Oublié?
              </span> */}
            </div>
            {errors.has('password') &&
              <label id="name-error" className="error" htmlFor="password">{errors.first('password')}</label>
            }
          </div>
        </div>

        {/*<div className="row" style={{margin: '0 0 0 4%', 'text-align': 'left'}}>
                        <div className="col col-sm-12">
                          <FormsChoice  
                            type={"radio"}
                            write_text={t('radio_text_login')}
                            className_perso={'perso_label'}
                          />
                        </div>
                      </div>*/}

        <div className="row" style={{ position: 'relative', margin: '5.5% 0 0 0' }}>
          <div className="col col-sm-12">
            {this.props.messageBool_t ?
              this.props.messageError_t === 'The given data was invalid.' ?
                <span className="failedCredentials_t">
                  Veillez verifier vos identifiants
                </span>
                :
                <span className="failedCredentials_t">
                  Veuillez verifier vos identifiants
                </span>
              :
              ""
            }
            {offline ?
              <span className="offlineLogin_t">
                Verifiez votre connexion internet puis réesayez
              </span>
              : ''
            }
            <LoginBtn
              className={this.props.className}
              value={t('login')}
              isLoading={isLoading}
              height={'8%'}
              width={'8%'}
              btn_t={'btn_t'}
            />
            {/*<button  
                              className="btn btn_login"
                              type="submit">
                              {t('login')}
                          </button>*/}
          </div>
        </div>

        <div className="row">
          <div className="col col-sm-12 con_link">
            <span className={labelRegisterBtnCompte ? 'labelRegisterBtnCompteModal' : 'labelRegisterBtnComptePage'}>
              Nouveau sur Wemaza ?
            </span> &nbsp;
            <span className={registerBtnCompte ? 'registerBtnCompteModal' : 'registerBtnComptePage'}>
              <Link to="/register" style={{ color: '#FFF' }}>
                Créer un compte
              </Link>
            </span>
          </div>
        </div>
      </form>
    );
  }
}
export default withTranslation()(LoginComponent);