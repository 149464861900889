import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import fr from 'ree-validate/dist/locale/fr';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import AuthService from '../../../services/auth';
import ProductService from '../../../services/product';
import { lightTheme, darkTheme } from '../../../js/theme';
import { GlobalStyles } from '../../../js/global';
import { changeNavigatorTheme, auth_t, cartTab_t, tabsCatValueFunction } from '../../../store/actions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ContentComponent from './ContentComponent';
import HeaderUsersMobile from './../../layout/HeaderUsersMobile';
import MenuCategiries from '../Home/Components/MenuCategiries';
import NewHeader from '../../layout/NewHeader5';
import MobileCategoriesMenu from '../../layout/HeaderComponents/MobileCategoriesMenu';

// const Artistes = React.lazy(() => import('./Artists')); 
class Page extends Component {
  constructor(props) {
    super();
    this.validator = new ReeValidate.Validator({
      lastname: 'required',
      firstname: 'required',
      cni_t: 'required',
      address: 'required',
      email: 'required|email',
      password: 'required|min:6',
      country: 'required',
      city: 'required',
      description: 'required',
    });
    this.validator.localize('fr', fr)
    this.state = {
      postregister: {
        lastname: '',
        firstname: '',
        country: null,
        address: '',
        email: '',
        phone: '',
        password: '',
        description: '',
        newsletter: false,
        privacy_police: null,
        sexe: '',
        date: new Date(),
        cni_t: ''
      },
      errors: this.validator.errors,
      offline: false,
      theme_bool: false,
      langBool: true,
      def_theme: '',
      eye_password_bool: true,
      checked: true,
      checked_2: false,
      checked_3: false,
      checked_switch: true,
      show_t: 'hidden_t',
      text_or_not: false,
      isLoading: false,
      first_nav_btn_t: true,
      second_nav_btn_t: false,
      dir_btn_suiv_t: false,
      show_first_tab: true,
      show_second_tab: false,
      dir_btn_prec_2_t: false,
      isValue: false,
      email_exit: false,
      email_not_val: '',
      options_fr: [
        { value: 'en', label: "Anglais" }
      ],
      options_eng: [
        { value: 'fr', label: "Francais" }
      ],
      options_devise: [
        { value: '€', label: "Dollar" }
      ],
      option_country: [
        { value: 'cmr', label: "Cameroun" },
        { value: 'cg', label: "Congo k" }
      ],
      option_prix: [
        { value: '1000', label: "1000F" },
        { value: '2000', label: "2000F" }
      ],
      pays_value: '',
      country_value: '',
      prix_t: null,
      devise_t: 'FCFA',
      prix2_t: null,
      option_city: [
        { value: 'Dla', label: "Douala" },
        { value: 'Ynde', label: "Yaounde" }
      ],
      city_id: null,
      option_sexe: [
        { value: 'Masculin', label: 'Masculin' },
        { value: 'Feminin', label: 'Feminin' }
      ],
      home_t: true,
      categorie_value: '',
      option_categorie: [
        { value: ' ', label: " " }
      ],
      dropdownBtn_t: false,
      greater_than: '',
      input1_bool_t: true,
      input2_bool_t: false,
      show_val_interv: false,
      interval_error: false,
      bool_val: false,
      index_t: null,
      products: [],
      lastNewProducts: [],
      categorieLabel: [],
      textTriePrix: '',
      showTrieBool_t: false,
      total_pages: 2,
      count: 0,
      current_page: 1,
      per_page: 2,
      count: 2,
      bigImages: '',
      prodId_t: null,
      qteValue: 1,
      cartValue: props.cartValue,
      forReloadCartState: false,
      toggleBool_t: false,
      isCart_t: false,
      hash_id: '',
      searchR_t: '',
      searchProducts_t: [],
      tabsCatValue: 0,
      includesForAllProducts_t: '?include=shop.provider.user.country,annexesImages',
      includeswithSearchForAllProducts_t: '&include=shop.provider.user.country,annexesImages',
      allCategories: []
    };
    this.theme_botton_D = this.theme_botton_D.bind(this);
    this.theme_botton_W = this.theme_botton_W.bind(this);
    this.toggle = this.toggle.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.checked_t = this.checked_t.bind(this);
    this.checked_rad1_t = this.checked_rad1_t.bind(this);
    this.checked_rad2_t = this.checked_rad2_t.bind(this);
    this.checked_rad3_t = this.checked_rad3_t.bind(this);
    this.for_remove_js = this.for_remove_js.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this)
    // this.post_login_user = this.post_login_user.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.def_theme === '') {
      this.check_theme();
    }
  }
  componentDidMount() {
    this.props.dispatch(auth_t({ __iA: true, __iV: true }));
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: true });
    }
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: false });
    }
    if (this.props.i18n.language === 'fr-FR' || 'fr') {
      this.setState({ langBool: true })
    }
    if (this.props.i18n.language === 'en') {
      this.setState({ langBool: false })
    }
    const { pathname, search } = this.props.location;
    this.allCountries();
    this.allCategories();
    this.productsById();
    this.allProducts();
    this.lastNewProduct();
    this.allProductsBycategories(pathname + search);
    this.allProductsBycategories(!!search ? pathname + search + this.state.includeswithSearchForAllProducts_t : pathname + this.state.includesForAllProducts_t);
  }

  allProductsBycategories = (url) => {
    this.props.dispatch(ProductService.getAllProductsBycategories(url, 10, 1))
      .then((result) => {
        let data = result.data;

        // this.setState({prodId_t: data, bigImages: data.image})
        this.setState({ prodId_t: data, bigImages: data.annexesImages.data[0].first_annex })
        let indexCatTab = this.props.cartValue.length > 0 ? this.props.cartValue.map((v) => v.id).indexOf(data.id) : this.state.cartValue.map((v) => v.id).indexOf(data.id);

        if (indexCatTab >= 0) {
          this.setState({ isCart_t: true });
        }
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  handleChangeTabsCatValue = (event, newValue) => {
    this.props.dispatch(tabsCatValueFunction(newValue));
    this.setState({ tabsCatValue: newValue });
    if (newValue === 0) {
      this.props.history.push("/");
    }
    if (newValue === 1) {
      this.props.history.push("/products/country:33+Cameroun/categorie:8?Alimentaires");
    }
    if (newValue === 2) {
      this.props.history.push("/products/country:33+Cameroun/categorie:20?Épiceries%20et%20boisson%C2%A0");
    }
    if (newValue === 3) {
      this.props.history.push("/products/country:33+Cameroun/categorie:19?Beauté%20et%20bien-être%C2%A0");
    }
    if (newValue === 4) {
      this.props.history.push("/products/country:33+Cameroun/categorie:17?Jouets,%20enfants%20et%20bébés");
    }
    if (newValue === 5) {
      this.props.history.push("/products/country:33+Cameroun/categorie:21?modes%20&%20vêtements");
    }
    if (newValue === 6) {
      this.props.history.push("/products/country:33+Cameroun/categorie:9?Electroniques");
    }
    if (newValue === 7) {
      this.props.history.push("/products/country:33+Cameroun/categorie:13?Multimedia");
    }
  };



  // handleSubmit(e) {
  //   e.preventDefault();
  //   this.setState({email: "ludovicmoukouri@yahoo.fr"})
  // }
  allCategories = () => {
    this.props.dispatch(ProductService.getAllCategories())
      .then((result) => {
        let data = result.data;

        const categories = this.buildCategoryHierarchy(data);

        let options = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
        this.setState({ option_categorie: options, allCategories: categories });
        // this.setState({shops: data});
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  buildCategoryHierarchy = (data) => {
    // console.log("VBVV ", categories); return;

    const categoryMap = {};
    const hierarchy = [];

    // Map categories by slug
    data.forEach(item => {
      categoryMap[item.slug] = {
        name: item.name,
        iconUrl: item.images,
        path: item.url_slug,
        slug: item.slug,
        level: !!item.level ? parseInt(item.level) : 0,
        parent_id: item.parent_id,
        subcategories: []
      };
    });

    // Build hierarchy based on level and url_slug
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.level === 0 || category.level === '') {
        hierarchy.push(category);
      } else {
        const parentSlug = category.path.split('/')[category.level];
        if (categoryMap[parentSlug]) {
          categoryMap[parentSlug].subcategories.push(category);
        }
      }
    });

    // Update path if subcategories length is 0
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.subcategories.length > 0) {
        delete category.path;
      }
    });

    return hierarchy;
  }

  handleClick(lang) {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    this.setState({ langBool: !this.state.langBool });
  }
  checked_t() {
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  checked_rad1_t() {
    if (this.state.checked) {
      this.setState({ checked: true, checked_2: false, checked_3: false });
    }
    this.setState({ checked_2: false, checked_3: false });
  }
  checked_rad2_t() {
    if (this.state.checked_2) {
      this.setState({ checked: false, checked_2: true, checked_3: false });
    }
    this.setState({ checked: false, checked_3: false });
  }
  checked_rad3_t() {
    if (this.state.checked_3) {
      this.setState({ checked: false, checked_2: false, checked_3: true });
    }
    this.setState({ checked: false, checked_2: false });
  }
  toggle() {
    this.setState({ eye_password_bool: !this.state.eye_password_bool })
  }

  check_theme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // console.log('🎉 Dark mode is supported');
      this.setState({ def_theme: 'dark', theme_bool: true, checked_switch: false })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      // console.log('🎉 light mode is supported');
      this.setState({ def_theme: 'light', theme_bool: true, checked_switch: true })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
      // console.log('ciphertext ', ciphertext)
    }
  }
  for_remove_js(e) {
    e.preventDefault();
  }
  theme_botton_D(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'dark', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  theme_botton_W(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'light', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  //  post_login_user() {
  //    this.setState({isLoading: true});
  //    let credentials = {email: this.state.email, password: this.state.password}
  //    this.props.dispatch(AuthService.login(credentials))
  //      .then((result)  => {
  //        this.props.history.push("/");
  //        this.setState({isLoading: false})
  //      })
  //      .catch(({data}) => {
  //          this.setState({show_t: 'show_t'});
  //          console.log('errors ', data);
  //          this.setState({isLoading: false})
  //      });
  // }
  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    const { postregister } = this.state;

    postregister[name] = value;
    if (name === 'email') {
      this.setState({ email_exit: false })
    }
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors, postregister })
      });
    this.check_first_name(postregister)
  }
  check_first_name = (postregister) => {
    if ((postregister.lastname !== '' &&
      postregister.firstname !== '' &&
      postregister.country !== '' &&
      postregister.phone !== '' &&
      postregister.date !== '' &&
      postregister.sexe !== ''
    )) {
      this.setState({ dir_btn_suiv_t: true, isValue: true })
    }
  }
  handleChangeCheck = (e, label) => {
    const value = e.target.value;
    const { postregister } = this.state;
    if (label === 'newsletter') {
      postregister[label] = value;
      this.setState({ postregister })
    }
  }

  allCountries() {
    this.props.dispatch(AuthService.getAllCountries())
      .then((result) => {

        let data = result.data;
        let options = data.map((value, index) => (

          {
            value: value.real_id,
            label: this.state.langBool === true ? value.name_fr : value.name_en
          }
        ));
        this.setState({
          isLoading: false,
          showAlert: false,
          alert2: null,
          option_country: options
        });
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  handleSubmit(postregister) {
    this.setState({ isLoading: true });
    let data = {
      lastname: postregister.lastname,
      firstname: postregister.firstname,
      country_id: postregister.country,
      address: postregister.address,
      email: postregister.email,
      phone: postregister.phone,
      password: postregister.password,
      gender: postregister.sexe,
      birth: postregister.date
    }
    navigator.onLine ?
      this.props.dispatch(AuthService.register(data))
        .then((result) => {
          this.props.history.push("/login");
        })
        .catch((errors) => {
          if (errors.statusCode.response.data.errors.email) {
            // console.log('errors ', errors.statusCode.response.data.errors.email[0]);
            this.setState({
              isLoading: false,
              email_exit: true,
              email_not_val: errors.statusCode.response.data.errors.email[0],
            });
          } else {
            console.log('errors ', errors);
            this.setState({ isLoading: false });
          }
        })
      :
      this.setState({ offline: true, isLoading: false })
  }

  async validateAndSubmit(e) {
    e.preventDefault()

    const { postregister } = this.state
    const { errors } = this.validator

    const valid = this.validator.validateAll(postregister)

    if (valid) {
      this.handleSubmit(postregister)
    } else {
      this.setState({ errors })
    }
  }

  precedent_t = (e, isValue, label) => {
    if (label === 'premier' && isValue === true) {
      this.setState({
        show_perso_sa: false,
        show_loca_sa: false,
        show_params_sa: false,
      })
    }
    if (label === 'second' && isValue === true) {
      this.setState({
        show_second_tab: false,
        show_first_tab: true,
        dir_btn_suiv_t: true,
        first_nav_btn_t: true,
        second_nav_btn_t: false,
        dir_btn_prec_2_t: false,
        save_t: false
      })
    }
    e.preventDefault();
  }


  Suivant_t = (e, isValue, label) => {
    const { postregister } = this.state;
    if (label === 'premier' && isValue === true) {
      this.setState({
        show_second_tab: true,
        show_first_tab: false,
        first_nav_btn_t: false,
        second_nav_btn_t: true,
        dir_btn_suiv_2_t: false,
        dir_btn_prec_2_t: true,
      })
    }
    if (label === 'second' && isValue === true) {
      this.setState({
        show_perso_sa: false,
        show_loca_sa: false,
        show_params_sa: true,
        first_nav_btn_t: false,
        second_nav_btn_t: false,
        isValue_suiv_sec: true,
        error_bool: false,
      })
    }
    e.preventDefault();
  }

  gotToParentValue = (value, label) => {
    const { postregister } = this.state;
    if (label === "Francais" || label === "French") {
      const options_fr = [{ value: 'en', label: "Anglais" }];
      const options_eng = [{ value: 'fr', label: "Francais" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("fr");
    }
    if (label === "Anglais" || label === "English") {
      const options_fr = [{ value: 'en', label: "English" }];
      const options_eng = [{ value: 'fr', label: "French" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("en");
    }
    if (label === "Dollar") {
      this.setState({
        device_data: value
      })
    }
    if (label === "date") {
      postregister['date'] = value
      this.setState({ postregister }, () => this.check_first_name(postregister))
    }
    if (label === "phone") {
      postregister['phone'] = value
      this.setState({ postregister }, () => this.check_first_name(postregister))
    }
    if (label === "sexe") {
      postregister['sexe'] = value
      this.setState({ postregister }, () => this.check_first_name(postregister))
    }
    if (label === "country") {
      postregister['country'] = value
      this.setState({ postregister }, () => this.check_first_name(postregister))
    }
    if (label === "cat_prod") {
      const { categorieLabel, option_categorie } = this.state;
      categorieLabel.push(value);
      this.setState({ categorieLabel: categorieLabel, home_t: false });
      let indexCatTab = option_categorie.map((v) => v.label).indexOf(label);
      option_categorie.splice(indexCatTab, 1);
    }
  }
  gotToParentCatValue = (value, label, name) => {
    if (name === "cat_prod") {
      const { categorieLabel, option_categorie } = this.state;
      let indexCatTab = option_categorie.map((v) => v.label).indexOf(label);
      categorieLabel.push({ value: value, label: label });
      option_categorie.splice(indexCatTab, 1);
      this.setState({ bool_val: false, categorieLabel: categorieLabel, option_categorie: option_categorie, home_t: false });
    }
  }
  saveCount = (e) => {
    e.preventDefault();
  }
  onDropdownBtn_t = () => {
    this.setState({ dropdownBtn_t: !this.state.dropdownBtn_t, interval_error: false })
  }
  gotToParentShowInput = (interOrNot, value) => {
    if (interOrNot === 'intervalle') {
      this.setState({
        input2_bool_t: true,
        input1_bool_t: true,
        show_val_interv: true,
        interval_error: false,
        prix2_t: 0
      })
    } else {
      this.setState({
        input2_bool_t: false,
        input1_bool_t: true,
        show_val_interv: false,
        interval_error: false
      })
      if (parseInt(value) === 1) {
        this.setState({ textTriePrix: 'Moins de ' })
      }
      if (parseInt(value) === 2) {
        this.setState({ textTriePrix: 'Plus de ' })
      }
      if (parseInt(value) === 3) {
        this.setState({ textTriePrix: 'Egale à ' })
      }
    }
  }
  onChange_t = (e) => {
    if (e.target.name === 'prix_t') {
      this.setState({ prix_t: e.target.value })
    }
    if (e.target.name === 'prix2_t') {
      this.setState({ prix2_t: e.target.value }, () => this.onPrice_t(e))
    }
  }
  onPrice_t = (e) => {
    if (parseInt(this.state.prix_t) < parseInt(this.state.prix2_t)) {
      // 'Intervalle is Ok'
      this.setState({
        interval_error: false,
        showTrieBool_t: true,
        // dropdownBtn_t: !this.state.dropdownBtn_t
      })
    } else {
      this.setState({
        interval_error: true,
        showTrieBool_t: true,
        dropdownBtn_t: !this.state.dropdownBtn_t
      })
    }
    e.preventDefault();
  }
  allProducts = () => {
    this.props.dispatch(ProductService.getAllProducts())
      .then((result) => {
        let data = result.data;
        this.setState({ products: data });
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  productsById = () => {
    this.props.dispatch(ProductService.getProductsById(this.props.match.params.id))
      .then((result) => {
        let data = result.data;
        // this.setState({prodId_t: data, bigImages: data.image})
        // this.setState({ prodId_t: data, bigImages: data.annexesImages.data[0].first_annex })
        // let indexCatTab = this.props.cartValue.length > 0 ? this.props.cartValue.map((v) => v.id).indexOf(data.id) : this.state.cartValue.map((v) => v.id).indexOf(data.id);

        // if (indexCatTab >= 0) {
        //   this.setState({ isCart_t: true });
        // }
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  lastNewProductId = (hashId) => {
    this.props.dispatch(ProductService.getProductsById(hashId))
      .then((result) => {
        let data = result.data;
        // this.setState({prodId_t: data, bigImages: data.image})
        this.setState({ prodId_t: data, bigImages: data.annexesImages.data[0].first_annex })
        let indexCatTab = this.props.cartValue.length > 0 ?
          this.props.cartValue.map((v) => v.id).indexOf(data.id)
          :
          this.state.cartValue.map((v) => v.id).indexOf(data.id);

        if (indexCatTab >= 0) {
          this.setState({ isCart_t: true });
        }
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  onChangeValue = (e) => {
    if (e.target.value >= 1 && e.target.value <= this.state.prodId_t.quantity) {
      this.setState({ qteValue: e.target.value })
    } else {
      if (e.target.value < 1) {
        this.setState({ qteValue: 1 })
      }
      if (e.target.value > this.state.prodId_t.quantity) {
        this.setState({ qteValue: this.state.prodId_t.quantity })
      }
    }
  }
  onAddCart_t = (id, real_id, shopName, productName, price, reduction, image, totalQty, country_real_id) => {
    // Nom de la boutique, nom du produit, le prix reel et le prix promotionnel
    const { cartValue } = this.state;
    let productObject = {
      shopName,
      productName,
      // price: parseFloat((price / 650).toFixed(2)),
      // usedPrice: reduction === 0 ? parseFloat((price / 650).toFixed(2)) : parseFloat((reduction / 650).toFixed(2)),
      // reduction: parseFloat((reduction / 650).toFixed(2)), 
      price,
      usedPrice: reduction === 0 ? price : reduction,
      reduction,
      id,
      image,
      // totalQty: qty,
      totalQty,
      //  qty: 1,
      qty: this.state.qteValue,
      real_id,
      country_real_id
    }


    let indexCatTab = this.props.cartValue.length > 0 ? this.props.cartValue.map((v) => v.id).indexOf(id) : cartValue.map((v) => v.id).indexOf(id);
    let countryRealId = this.props.cartValue.length > 0 ? this.props.cartValue[0].country_real_id : "";
    if (indexCatTab < 0) {
      if (parseInt(country_real_id) === parseInt(countryRealId)) {
        this.setState({ forReloadCartState: !this.state.forReloadCartState, isCart_t: true });
        this.props.dispatch(cartTab_t(productObject));
      } else {
        if (this.props.cartValue.length === 0) {
          this.setState({ forReloadCartState: !this.state.forReloadCartState, isCart_t: true });
          this.props.dispatch(cartTab_t(productObject));
        } else {
          alert("Les produits divent etres dans un meme pays")
        }
      }
    } else {
      if (cartValue.length === 0) {
        // alert("2")
        // this.setState({forReloadCartState: !this.state.forReloadCartState, isCart_t: true});
        // this.props.dispatch(cartTab_t(productObject));

        this.setState({ isCart_t: true });
        return;
      }
      else {
        this.setState({ isCart_t: true });
        return;
      }
    }
  }
  // onAddProduct = (e) => {
  //    // Here we use redux for increments and decrements  number in panier icon
  //    e.preventDefault();
  //  }

  onAddProduct = (e, hash_id, real_id, nameProd, price, reduction, quantity, image, shopName, country, city, country_real_id) => {
    this.setState({
      showAlert: true,
      hash_id: hash_id,
      nameProd: nameProd,
      imageUrl: image,
      shopName: shopName,
      country: country,
      cityProd: city,
      real_idProd: real_id,
      priceProd: price,
      reductionProd: reduction,
      quantityProd: quantity,
      isCart_t: false,
      country_real_id: country_real_id
    });
    this.lastNewProductId(hash_id);
    e.preventDefault();
  }

  goToParentBigImg = (value) => {
    this.setState({ bigImages: value })
  }
  parent_categorieLabel = (categorieLabel) => {
    this.setState({ categorieLabel: categorieLabel });
  }
  parent_add_categorie = (value) => {
    const { option_categorie } = this.state;
    option_categorie.push(value);

    this.setState({ bool_val: true, option_categorie: option_categorie, categorie_value: value });
  }
  onToggle_t = () => {
    this.setState({ toggleBool_t: !this.state.toggleBool_t });
  }

  lastNewProduct = () => {
    this.props.dispatch(ProductService.lastNewProduct())
      .then((result) => {
        let data = result.data;

        this.setState({ lastNewProducts: data });
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  handleChangeProductAndReloadPage = (id) => {
    // this.productsById();
    this.props.history.push(`/product/${id}`);
    window.location.reload();
  }

  searchNameWithCallback = (value) => {
    // console.log("GGG ", value); return;
    const { searchTimeout } = this.state;
    this.setState({ searchR_t: value })

    // Annulez le délai existant si l'utilisateur continue de taper
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    // Configurez un nouveau délai pour déclencher la recherche après 2 secondes
    const timeoutId = setTimeout(() => {
      this.filterByName(value);
    }, 500);
    this.setState({ searchTimeout: timeoutId });
  }

  filterByName = (value) => {
    this.props.dispatch(ProductService.filterByName(value))
      .then((result) => {
        let data = result.data;


        let optionsVal = data.map((value, index) => (
          {
            name: value.name,
            value: value.id,
            shopName: value.shop.data.name,
            image: value.annexesImages.data[0].first_annex,
            url_slug: value.category.data.url_slug
          }
        ));

        this.setState({ searchProducts_t: optionsVal });
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            // show_t: 'show_t', 
            isLoading: false
          });
        }
      })
  }

  onLogout = () => {
    // this.setState({isLoading: true});
    this.props.dispatch(AuthService.logout())
      .then((result) => {
        this.props.history.push("/");
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }
  render() {
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);

    const { t } = this.props;
    let { errors } = this.state;

    return (
      <ThemeProvider theme={d_theme === "\"dark\"" ? darkTheme : lightTheme}>
        <>
          <GlobalStyles />
          <Helmet>
            <meta charSet="UTF-8" />
            <meta
              name="description"
              content="Ekomart-Grocery-Store(e-Commerce) HTML Template: A sleek, responsive, and user-friendly HTML template designed for online grocery stores."
            />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="keywords" content="Grocery, Store, stores" />
            <title>Wemaza</title>
            {/* <link rel="shortcut icon" type="image/x-icon" href="assets/images/fav.png" /> */}
            <link rel="shortcut icon" type="image/x-icon" href="assets/favicon.ico" />
            {/* plugins css */}
            <link rel="stylesheet preload" href="assets/css/plugins.css" as="style" />
            {/* <link rel="stylesheet preload" href="assets/css/style.css" as="style" /> */}
            {/* rts header area start */}
            <script defer src="assets/js/plugins.js"></script>
            <script defer src="assets/js/main.js"></script>
          </Helmet>

          <NewHeader
            option_categorie={this.state.option_categorie}
            allCategories={this.state.allCategories}
            fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
            cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
            idProf={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
            gotToParentValue={this.searchCityWithCallback}
            isShowSearch_t={true}
            searchProducts_t={this.state.searchProducts_t}
            searchNameWithCallback={this.searchNameWithCallback}
            onLogout={this.onLogout}
            onToggle_t={this.onToggle_t}

            propsCustom={this.props}
            allProductsBycategories={this.allProductsBycategories}
            includesForAllProducts_t={this.state.includesForAllProducts_t}
          />

          <div id="side-bar"
            className={this.state.toggleBool_t ? "side-bar header-two show" : "side-bar header-two"}
          >
            <button className="close-icon-menu"
              onClick={this.onToggle_t}>
              <IconButton aria-label="close">
                <CloseIcon />
              </IconButton>
            </button>
            <MobileCategoriesMenu
              prodId_t={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
              onLogout={this.onLogout}
              allCategories={this.state.allCategories}
              isShowSearch_t={true}
              fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
            />

          </div>

          <div className="register_t">

            {/* <div className="showAndHiddenRespDesk_t">
              <HeaderMenu
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                option_city={this.state.option_city}
                city_id={this.state.city_id}
                gotToParentValue={this.gotToParentValue}
                option_country={this.state.option_country}
                country_value={this.state.country_value}
                toggleBool_t={this.state.toggleBool_t}
                onToggle_t={this.onToggle_t}
                isAuth_t={this.props.isAuthenticated}
                onLogout={this.onLogout}
                showCountry={false}
                idProf={this.props.prodId_t !== undefined ? this.props.prodId_t.__id : ''}
              />



              <BandeBlanche />

              <HeaderUsers
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
                isShowSearch_t={true}
                searchR_t={this.state.searchR_t}
                searchProducts_t={this.state.searchProducts_t}
                searchNameWithCallback={this.searchNameWithCallback}
              />
              <HeaderTop
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                options_fr={this.state.options_fr}
                options_eng={this.state.options_eng}
                options_devise={this.state.options_devise}
                device_data={this.state.device_data}
                gotToParentValue={this.gotToParentValue}
                logo_noir={false}
                fName={this.props.prodId_t.__pfn !== undefined ? this.props.prodId_t.__pfn : ''}
              />
              <MenuCategiries
                tabsCatValue={this.state.tabsCatValue}
                // tabsCatValue={this.props.tabsCatValueStore}
                handleChangeTabsCatValue={this.handleChangeTabsCatValue}
              />
            </div>
            <div className="showAndHiddenResp_t">

              <HeaderUsersMobile
                cartValue={this.props.cartValue}
                isAuthResponsive={false}
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                option_city={this.state.option_city}
                city_id={this.state.city_id}
                gotToParentValue={this.gotToParentValue}
                option_country={this.state.option_country}
                country_value={this.state.country_value}
                toggleBool_t={this.state.toggleBool_t}
                onToggle_t={this.onToggle_t}
                isAuth_t={this.props.isAuthenticated}
                onLogout={this.onLogout}
                isLoadingHeader={this.state.isLoadingHeader}
                idProf={this.props.prodId_t !== undefined ? this.props.prodId_t.__id : ''}
                showCountry={false}
              />

            </div> */}


            <ContentComponent
              categorie_value={this.state.categorie_value}
              option_categorie={this.state.option_categorie}
              gotToParentCatValue={this.gotToParentCatValue}
              gotToParentValue={this.gotToParentValue}
              categorieLabel={this.state.categorieLabel}
              parent_categorieLabel={this.parent_categorieLabel}
              parent_add_categorie={this.parent_add_categorie}
              onPrice_t={this.onPrice_t}
              onDropdownBtn_t={this.onDropdownBtn_t}
              dropdownBtn_t={this.state.dropdownBtn_t}
              onChange_t={(e) => this.onChange_t(e)}
              required={this.props.required}
              greater_than={this.state.greater_than}
              less_than={this.state.less_than}
              equal_t={this.state.equal_t}
              interval_t={this.state.interval_t}
              input2_bool_t={this.state.input2_bool_t}
              input1_bool_t={this.state.input1_bool_t}
              show_val_interv={this.state.show_val_interv}
              prix_t={this.state.prix_t}
              devise_t={this.state.devise_t}
              prix2_t={this.state.prix2_t}
              interval_error={this.state.interval_error}
              pagination={{
                enabled: true,
                type: "short" // long, short
              }}
              // pageLength={this.state.count}
              pageLength={this.state.count}
              total_pages={this.state.total_pages}
              current_page={this.state.current_page}
              data={this.state.products}
              gotToParentShowInput={this.gotToParentShowInput}
              onAddProduct={this.onAddProduct}
              index_t={this.state.index_t}
              // products={this.state.products}
              products={this.state.lastNewProducts}
              bool_val={this.state.bool_val}
              textTriePrix={this.state.textTriePrix}
              showTrieBool_t={this.state.showTrieBool_t}
              goToParentBigImg={this.goToParentBigImg}
              bigImages={this.state.bigImages}
              prodId_t={this.state.prodId_t}
              qteValue={this.state.qteValue}
              onChangeValue={this.onChangeValue}
              onAddCart_t={this.onAddCart_t}
              isCart_t={this.state.isCart_t}
              offline={this.state.offline}
              hash_id={this.state.hash_id}
              handleChangeProductAndReloadPage={this.handleChangeProductAndReloadPage}
            />

          </div>
        </>
      </ThemeProvider>
    );
  }
}
export default withTranslation()(Page);