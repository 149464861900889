import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from "react-select";
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class CatSelectComponent extends Component {

	handleChange = (newValue: any, actionMeta: any) => {
		if (this.props.name === "country") {
			this.props.gotToParentValue(newValue.value, 'country');
		}
		if (this.props.name === "ville") {
			this.props.gotToParentValue(newValue.value, 'city_id');
		}
		if (this.props.name === "categories") {
			this.props.gotToParentValue(newValue.value, 'categories');
		}
		if (this.props.name === "sexe") {
			this.props.gotToParentValue(newValue.value, 'sexe');
		}
		if (this.props.name === "sexe_usr") {
			this.props.gotToParentValue(newValue.value, 'sexe_usr');
		}
		if (this.props.name === "categorie_prod") {
			this.props.gotToParentValue(newValue.value, 'categorie_prod');
		}
		if (this.props.name === "point_relais") {
			this.props.gotToParentValue(newValue.value, 'point_relais');
		}
		if (this.props.name === "cat_prod") {
			this.props.gotToParentCatValue(newValue.value, newValue.label, 'cat_prod');
		}
		if (this.props.name === "cat_prod_mobil") {
			this.props.gotToParentCatValue(newValue.value, newValue.label, 'cat_prod_mobil');
		}
	};
	render() {
		let { t, options, placeholder, select_value } = this.props
		return (
			<div className="form-group select_t" style={this.props.style_f_Select_div}>

				<span style={{ color: '#000' }}>
					{this.props.bool_val ?
						<CreatableSelect
							onChange={this.handleChange}
							onInputChange={this.handleInputChange}
							components={animatedComponents}
							options={options}
							value={select_value}
							placeholder={placeholder}
						/>
						:
						<CreatableSelect
							onChange={this.handleChange}
							onInputChange={this.handleInputChange}
							components={animatedComponents}
							options={options}
							placeholder={placeholder}
						/>
					}
				</span>

			</div>
		);
	}
}
export default withTranslation()(CatSelectComponent);