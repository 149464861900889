import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import LoadingDifferent from '../components/LoadingDifferent';

class SeConnecterBtn extends Component {
	constructor(props) {
		super(props);
    this.state = {
      styleLoading: 'loading_dataTable2',
      type: 'spin',
      height: props.height,
      width: props.width
    }
	}
	render(){
        	const { t } = this.props;
        	return(
                	<>
                          <a 
                          href={this.props.href}
                          className={this.props.className}
                          onClick={this.props.onClick} 
                          >
                            {this.props.isLoading ? 
                              <>
                              <div className="bnt_and_load_t">
                              {t(this.props.value)} 
                              </div>
                              <div>
                              <LoadingDifferent 
                                type={this.state.type} 
                                styleLoading={this.state.styleLoading} 
                                height={this.state.height} 
                                width={this.state.width}
                                /> 
                              </div>
                              </>
                              :
                              <span style={{'font-size': '17px'}}>
                                {t(this.props.value)} 
                              </span>
                          } 
                          </a>
                    </>
        	);
        }
}
export default withTranslation()(SeConnecterBtn);