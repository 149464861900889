import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class AcideLeft extends Component {
	constructor(props) {
		super();
	}
	render(){
		const { t } = this.props;
		return(
			<div className="col-6 body_l_t">
                                <div className="row">
                                        <img alt="" className="acide_left_image_position" src={require("../../images/acide_left_logo.png")} /> 
                                        {/*<img alt="" className="img_background1" src={require("../../images/jack.png")} /> */}
                                        {/*<img alt="" className="img_background" src={require("../../images/camera.png")} /> */}
                                        <span className="text_a_l_i">Wemaza<span style={{color: 'blue'}}>.</span></span>
                                        <p className="text_a_l_under_i">{t('welcome')}!</p>
                                        <p className="text_a_l_under_i_2">{t('welcome_to_see_you')}!</p>
                                        <img alt="" className="rejoignez_nous" src={require("../../images/rejoignez_nous.png")} /> 
                               			<span className="text_rejoignez_n">{t('joint_us')}</span>
                                </div>
                        </div>
		);
	}
}
export default withTranslation()(AcideLeft);