import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, Route, Switch } from "react-router-dom";
import SeConnecterBtn from './../components/SeConnecterBtn'
import SearchInput from './../components/FormsComponent/SearchInput'
import FormsSelect from './../components/FormsComponent/FormsSelect';
import LoadingDifferent from './../components/LoadingDifferent';

class HeaderUsersMobile extends Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
    }
  }
  onShowT = () => {
    this.setState({ show: !this.state.show })
  }
  render() {
    const {
      t,
      cartValue,
      onToggle_t,
      toggleBool_t,
      isAuth_t,
      idProf,
      isAuthResponsive,
      showCountry, } = this.props;
    return (
      <>
        <div className="main-header main-header-color mrg2r_t headerUsersMobile_t">
          {/* Logo Header */}
          <div className="logo-header logo-header-width">
            <Link to="/panier" className="cartMobilMargin_t logo" style={{ right: '4%' }}>
              <span style={{ color: '#FFF', 'font-weight': '700', 'font-size': '22px' }}>
                {cartValue.length}
              </span>
              <i class="fas fa-cart"></i>
              <img
                src={require("../../images/cart.png")}
                alt="Search"
                className="cart_t"
                style={{ width: '60%' }}
              />

            </Link>

            <Link to="/" className="logo" style={{ top: '1.5vh', right: '23%' }}>
              <img
                className="navbar-brand logo_header_size"
                src={require("../../images/logo_header.png")}
                alt="navbar brand"
              />
              <span className="text_header_size"
                style={{ fontFamily: 'Maison Neue Bold' }}>
                Wemaza
                <span style={{ color: 'blue' }}>
                  .
                </span>
              </span>
            </Link>
            <button
              className="btn btn-toggle toggle-sidebar btn-toggle-h-menu_t"
              onClick={onToggle_t}>
              <img
                src={require("../../images/menu_line_white_1.png")}
                alt="Search"
                className="search_toggle_t"
                style={{ position: 'absolute', width: '15px', margin: '5px' }}
              />
              <img
                src={require("../../images/menu_line_white_2.png")}
                alt="Search"
                className="search_toggle_t"
                style={{ margin: '6px 0 0 6px', width: '10px' }}
              />
              <span className="menu_tog_t">
                Menu
              </span>
            </button>
            <div className={toggleBool_t ? "toggleDiv_t display_t" : "toggleDiv_t hidden_t"}>
              {isAuth_t ?
                <>
                  <Link to="/orders" className="togglePage_t">
                    Mes commandes en cours
                  </Link>  
                  <a href="javascript:;" className="togglePage_t" onClick={this.onShowT}>
                    Liaisons {this.state.show ?
                      <img src={require("../../images/caret-down.png")} className="caretUp_Size" alt="caret Up" />
                      :
                      <img src={require("../../images/caret-up.png")} className="caretUp_Size" alt="caret Up" />
                    }
                  </a>
                  <div className={this.state.show ? "transfDropDownT" : "hidden_t"}>
                    <ul>
                      <ol>
                        <Link to="/request">
                          Mes demandes
                        </Link>
                      </ol>
                      <ol>
                        <Link to="/link">
                          Mes liaisons
                        </Link>
                      </ol>
                    </ul>
                  </div>
                  {/*<Link to="/request" className="togglePage_t">
                      Mes demandes de liason
                    </Link>
                    <Link to="/link" className="togglePage_t">
                      Mes liaisons
                    </Link>*/}
                  <Link to="/setting" className="togglePage_t">
                    Réglages et confidentialité
                  </Link>
                  <a href="javascript:;" className="togglePageText_t">
                    Historique de paiements
                  </a>
                  <Link to={`/profile/${idProf}`} className="togglePageText_t">
                    Mon profil
                  </Link>
                  <a href="javascript:;" className="togglePageText_t">
                    Aide et assistance
                  </a>

                  {this.props.isAuth_t ?
                    <div class="nav-item nav_item_width togglePageText_t">

                      <a href="javascript:;" onClick={this.props.onLogout}>
                        {this.props.isLoadingHeader ?
                          <>
                            <span style={{ 'font-size': '15px', margin: '0 0 0 10px' }}>
                              Se deconnecter
                            </span>
                            <div>
                              <LoadingDifferent
                                type={'spin'}
                                styleLoading={'loadingLogin'}
                              />
                            </div>
                          </>
                          :
                          <p style={{ 'font-size': '15px' }}>
                            Se deconnecter
                          </p>
                        }

                      </a>
                    </div>
                    :
                    <>
                      <div className="nav-item nav_item_width togglePageText_t">
                        <Link to="/register" style={{ color: '#FFF' }}>
                          S'enregitrer
                        </Link>
                      </div>
                      <div class="nav-item nav_item_width loginBtn_t">
                        <Link to="/login" style={{ color: '#FFF' }}>
                          Se connecter
                        </Link>
                      </div>
                    </>
                  }

                </>
                :
                <>
                  <a href="javascript:;" className="togglePageText_t">
                    Aide et assistance
                  </a>

                  {this.props.isAuth_t ?
                    <a href="javascript:;" className="togglePageText_t" onClick={this.props.onLogout}>
                      {this.props.isLoadingHeader ?
                        <>
                          <span style={{ 'font-size': '15px', margin: '0 0 0 10px' }}>
                            Se deconnecter
                          </span>
                          <div>
                            <LoadingDifferent
                              type={'spin'}
                              styleLoading={'loadingLogin'}
                            />
                          </div>
                        </>
                        :
                        <span style={{ 'font-size': '15px' }}>
                          Se deconnecter
                        </span>
                      }


                    </a>
                    :
                    <>
                      <Link to="/register" className="togglePageText_t">
                        S'enregitrer
                      </Link>
                      <Link to="/login" className="togglePageText_t">
                        Se connecter
                      </Link>
                    </>
                  }

                </>
              }

            </div>
          </div>

        </div>
        {isAuthResponsive ?
          ""
          :
          <div className="container-fluid" style={{ color: '#FFF', padding: '60px 0px 0px 0px', background: '#1e2431' }}>
            <div className={this.props.menuBottom ? "row marginRResponse_t marginTopAndDownMenuDown" : "row marginRResponse_t"}>
              {this.props.isShowSearch_t ?
                <>
                  <div className="col-1"></div>
                  <div className="col-10">
                    <SearchInput
                      className_div_2={'form-group with-button search-input is-empty'}
                      input_className={'form-control'}
                      input_id={'headerSearch'}
                      input_placeholder={'Vous recherchez quelque chose en particulier ?'}
                      input_type={'search'}
                      name={'searchR_t'}
                      images_className={'search_t'}
                      gotToParentValue={this.props.gotToParentValue} />
                  </div>
                  <div className="col-1"></div>
                </>
                :
                ""
              }
            </div>

            <div className={this.props.menuBottom ? "row marginRResponse_t marginTopMenuBottom pdgSBot" : "row marginRResponse_t"}>

              <div className={showCountry ? "col-5 nav-item" : "nav-item hidden_t"} style={{ margin: '0px 0 0 0' }}>
                <FormsSelect
                  style_f_Select_div={'form-group select_t styleFSelectHeader_div'}
                  classNameLabel={"showAndHiddenRespDesk_t"}
                  label_t={''}
                  name={'country'}
                  options={this.props.option_country}
                  placeholder={this.props.country_value ? this.props.country_value : 'Pays'}
                  gotToParentValue={this.props.gotToParentValue}
                />
              </div>
              <div className={showCountry ? "col-5 nav-item" : "nav-item hidden_t"} style={{ margin: '0px 0 0 0' }}>
                <FormsSelect
                  style_f_Select_div={'form-group select_t styleFSelectHeader_div'}
                  classNameLabel={"showAndHiddenRespDesk_t"}
                  label_t={''}
                  name={'city'}
                  options={this.props.option_city}
                  placeholder={this.props.city_id ? this.props.city_id : 'Ville'}
                  gotToParentValue={this.props.gotToParentValue}
                />
              </div>
            </div>
          </div>
        }

      </>
    );
  }
}
export default withTranslation()(HeaderUsersMobile);