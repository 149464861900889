import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

class AcideRightItem extends Component {

	render(){
        	const { 
            t, 
            iconList,
            settingDivHeader,
            label_t,
            settingDiv,
            br_t,
            imgBool,
            flaticon,
            linkTo
          } = this.props;
        	return(
                  <Link to={linkTo} className={settingDivHeader}>
                   { imgBool ? <img 
                      src={iconList} 
                      alt="Image prod" 
                      className="imgSize_t" 
                    /> 
                    :
                    <i className={flaticon}></i>
                  }
                    <span className={settingDiv}>
                      {br_t ? 
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp; <br />
                        </>
                      : 
                        ''
                      }

                      {label_t}
                    </span>
                  </Link>
        	);
        }
}
export default withTranslation()(AcideRightItem);