import * as ActionTypes from '../action-types'
import Http from '../../Http'

const initialState = {
    isAuthenticated: false,
    isVerify: true,
    ln: "",
    role: "",
    email: "",
    _ipvkd: "",
    ipvkds: "",
    __dt: "",
    __pid: "",
    __cv: [],
    __showC: [],
    shid: "",
    shrid: "",
    sh_n: "",
    __hp: true,
    __sahp: false,
    tabsCatValue: 0,
    montantLivraison: 0,
    selectedValueStore: 0,
    __tcosts: 0,
    // __sassist: 3,
    __sassist: 0,
    __delivery: 0,
    __irate: 0,
    __amountCMD: 0,
    __fromCMD: false,
    __tHtAr: 0,
    paymentType: 'direct',
    isSocialPayment: false,
    isSocialAssistanceOption: false,
    seacrchListStore: []
    // __sPr: false,
    // __sCart: true,
    // __sCarteA: false,
    // __sReceveur: false,
    // __sPaiement: false,
    // __realCard: false,
    // __cartBrowse: ' '
};

const Auth = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ActionTypes.INITSTATE_T:
            return initialStatManual(state, payload);
        case ActionTypes.AUTH_LOGIN:
            return authLogin(state, payload);
        case ActionTypes.AUTH_CHECK:
            return checkAuth(state);
        case ActionTypes.AUTH_LOGOUT:
            return logout(state);
        case ActionTypes.AUTH_VERIFY:
            return authVerify(state);
        case ActionTypes.PROFILE_T:
            return profile(state, payload);
        case ActionTypes.SHOPACTION:
            return shopAction(state, payload);
        case ActionTypes.PRODID_T:
            return prodId_t(state, payload);
        case ActionTypes.CARTTAB_T:
            return cartTab_t(state, payload);
        case ActionTypes.HOMECARTPRODUCTVALUE:
            return homeCartvalue(state, payload);
        case ActionTypes.SHOWALERTHOMEPRODUCT:
            return showAlertHomeProduct(state, payload);
        case ActionTypes.SHOWCARTTAB_T:
            return showCartTab_t(state, payload);
        case ActionTypes.CARTINITTABT:
            return cartInitTab_t(state, payload);
        case ActionTypes.CARUPDATETTAB_T:
            return cartUpdateTab_t(state, payload);
        case ActionTypes.DELETECARTTAB_t:
            return deleteCartTab_t(state, payload);
        case ActionTypes.HOMEP:
            return home_p(state, payload);
        case ActionTypes.RELAYPOINTID:
            return relaypointId_t(state, payload);
        case ActionTypes.showCarteadhesion:
            return showCarteadhesionStore(state, payload);
        case ActionTypes.TABSCATVALUE:
            return tabsCatValueFunction(state, payload);
        case ActionTypes.NAVCARTSTORE:
            return navCartStore(state, payload);
        case ActionTypes.EXISTANTRECEIVERUSER:
            return existantReceiverUser(state, payload);
        case ActionTypes.SAVEUSERINFOS:
            return saveUserInfos(state, payload);
        case ActionTypes.ONCLICKONCMD:
            return onClickOnCmd(state, payload);
        case ActionTypes.RELAYPOINTVALUE:
            return relayPointValue(state, payload);
        case ActionTypes.BENEFICIARYLOCATION:
            return beneficiaryLocation(state, payload);
        case ActionTypes.INITRELAYPOINTDATA:
            return initRelayPointData(state);
        case ActionTypes.TYPEOFHOMELOCALIZATION:
            return typeOfHomeLocalization(state, payload);
        case ActionTypes.SOCIALASSISTANCEOBJECT:
            return socialAssistanceObject(state, payload);
        case ActionTypes.SELECTEDVALUEFUNCT:
            return selectedValueFunct(state, payload);
        case ActionTypes.INITRELAYPOINTIDTYPE:
            return initRelaypointId(state);
        case ActionTypes.ECONOMICACCESSIBILITY:
            return economicAccessibility(state, payload);
        case ActionTypes.SEARCHLIST:
            return seacrchListAction(state, payload);
        case "CHANGE_N_T":
            return changeFunction(state, payload);
        default:
            return state;
    }
};

const initialStatManual = (state, payload) => {
    state = Object.assign({}, state, {
        isAuthenticated: false,
        isVerify: true,
        ln: "",
        role: "",
        email: "",
        _ipvkd: "",
        ipvkds: "",
        __dt: "",
        __pid: "",
        __cv: [],
        __chv: "",
        __showC: [],
        shid: "",
        shrid: "",
        sh_n: "",
        __hp: true
    });
    return state;
};

const shopAction = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;
};

const authLogin = (state, payload) => {
    const jwtToken = payload.access_token;
    const expiresIn = payload.expires_in;
    const refreshToken = payload.refresh_token;
    // const ia_t = JSON.stringify({isAuthenticated: true, isVerify: true});
    localStorage.setItem('jwt_token_user', jwtToken);
    localStorage.setItem('refresh_token', refreshToken);
    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('isVerify', true);
    Http.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
    state = Object.assign({}, state, {
        isAuthenticated: true,
        isVerify: true,
    });
    return state;

};

const checkAuth = (state) => {
    state = Object.assign({}, state, {
        isAuthenticated: !!localStorage.getItem('jwt_token_user'),
    });
    if (state.isAuthenticated) {
        Http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt_token_user')}`;
    }
    return state;
};

const logout = (state) => {
    localStorage.removeItem('jwt_token_user');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('isVerify');

    if (!initialState.distanceBeneficiary) {
        delete state.distanceBeneficiary;
    }

    if (!initialState.typeOfHLocatization) {
        delete state.typeOfHLocatization;
    }

    if (!initialState.isSocialAssistanceOption) {
        delete state.isSocialAssistanceOption;
    }

    state = Object.assign({}, state, {
        isAuthenticated: false,
    });
    return state;
};

const authVerify = (state, payload) => {
    state = Object.assign({}, state, {
        isVerify: true,
    });
    return state;
};
const profile = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;

};
const prodId_t = (state, payload) => {
    state = Object.assign({}, state, { __pid: payload });
    return state;
};
const cartInitTab_t = (state, payload) => {
    state = Object.assign({}, state, { __showC: [], __cv: [] });
    return state;
};
const cartTab_t = (state, payload) => {
    state = Object.assign({}, state, { __showC: [] });
    state.__cv.push(payload);
    state = Object.assign({}, state, { __cv: state.__cv });
    return state;
};
const homeCartvalue = (state, payload) => {
    state = Object.assign({}, state, { __chv: payload });
    return state;
};
const showAlertHomeProduct = (state, payload) => {
    state = Object.assign({}, state, { __sahp: payload });
    return state;
};
const showCartTab_t = (state, payload) => {
    state.__showC.push(payload);
    state = Object.assign({}, state, { __showC: state.__showC });
    return state;
};
const cartUpdateTab_t = (state, payload) => {
    if (payload.cart === "cartValue") {
        let indexOfCartValue = state.__cv.map((v) => v.id).indexOf(payload.id);
        state.__cv.splice(parseInt(indexOfCartValue), 1, payload.productObject);
        state = Object.assign({}, state, { __cv: state.__cv });
    }
    if (payload.cart === "showCartValue") {

        let updatedShowC = state.__showC.map(item =>
            item.id === payload.id ? payload.productObject : item
        );

        state = Object.assign({}, state, { __showC: updatedShowC });



        // let indexOfShowCartValue = state.__showC.map((v) => v.id).indexOf(payload.id);
        // state.__showC.splice(parseInt(indexOfShowCartValue), 1, payload.productObject);
        // state = Object.assign({}, state, { __showC: state.__showC });
    }

    return state;
};
const deleteCartTab_t = (state, payload) => {
    if (payload.cart === "cartValue") {
        let indexOfCartValue = state.__cv.map((v) => v.real_id).indexOf(payload.real_id);
        state.__cv.splice(parseInt(indexOfCartValue), 1);
        state = Object.assign({}, state, { __cv: state.__cv });
    }
    if (payload.cart === "showCartValue") {
        let indexOfShowCartValue = state.__showC.map((v) => v.real_id).indexOf(payload.real_id);
        state.__showC.splice(parseInt(indexOfShowCartValue), 1);
        state = Object.assign({}, state, { __showC: state.__showC });
    }
    return state;
};
const home_p = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;
};
const relaypointId_t = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;

};
const changeFunction = (state, payload) => {
    state = Object.assign({}, state, { __dt: payload });
    return state;
};

const showCarteadhesionStore = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;
};

const tabsCatValueFunction = (state, payload) => {
    state = Object.assign({}, state, { tabsCatValue: payload });
    return state;
};

const navCartStore = (state, payload) => {
    state = Object.assign({}, state, {
        __sPr: payload.showPr,
        __sCart: payload.showCart,
        __sCarteA: payload.showCarteadhesion,
        __sReceveur: payload.showReceveur,
        __sPaiement: payload.showPaiement,
        __realCard: payload.realCard,
        __cartBrowse: 'Yes'
    });
    return state;
};

const existantReceiverUser = (state, payload) => {
    state = Object.assign({}, state, {
        __amtusrL: payload.amountUserLink !== null ? payload.amountUserLink : state.__amtusrL,
        __recusrRId: payload.receiverUserReal_id !== null ? payload.receiverUserReal_id : state.__recusrRId,
        __isusrPrf: payload.isUserProf !== null ? payload.isUserProf : state.__isusrPrf,
        __nmeChoice: payload.nameChoice !== null ? payload.nameChoice : state.__nmeChoice,
       
        // __amtusrL: payload.amountUserLink,
        // __recusrRId: payload.receiverUserReal_id,
        // __isusrPrf: payload.isUserProf,
        // __nmeChoice: payload.nameChoice
    });
    return state;
};

const saveUserInfos = (state, payload) => {
    state = Object.assign({}, state, {
        __postRter: payload.postregister
    });
    return state;
};

const onClickOnCmd = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;
};

const relayPointValue = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;
};

const beneficiaryLocation = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;
};

const initRelayPointData = (state) => {
    if (!initialState.distanceBeneficiary) {
        delete state.distanceBeneficiary;
    }
    state = Object.assign({}, state, {
    });
    return state;
};

const typeOfHomeLocalization = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;
};

const socialAssistanceObject = (state, payload) => {
    // state = Object.assign({}, state, payload);
    state = Object.assign({}, state, {
        paymentType: payload.paymentType,
        isSocialPayment: payload.isSocialPayment,
        isSocialAssistanceOption: payload.isSocialAssistanceOption
    });

    return state;
};

const selectedValueFunct = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;
};

const economicAccessibility = (state, payload) => {
    state = Object.assign({}, state, {
        __tcosts: payload.treatment_costs !== null ? payload.treatment_costs : state.__tcosts,
        __sassist: payload.social_assistance !== null ? payload.social_assistance : state.__sassist,
        __delivery: payload.delivery !== null ? payload.delivery : state.__delivery,
        __irate: payload.interest_rate !== null ? payload.interest_rate : state.__irate,
        __amountCMD: payload.amountCMD !== null ? payload.amountCMD : state.__amountCMD,
        __fromCMD: payload.fromCMD !== null ? payload.fromCMD : state.__fromCMD,
        __tHtAr: payload.fromCMD !== null ? payload.totalHtArrondie : state.__tHtAr,

    });

    return state;
};

const seacrchListAction = (state, payload) => {
    state = Object.assign({}, state, payload);
    return state;
};


const initRelaypointId = (state) => {
    delete state.relaypointId;
    state = Object.assign({}, state, {
    });
    return state;
};

export default Auth;
