import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from "react-select";
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class FormsSelect extends Component {

	handleChange = (newValue: any, actionMeta: any) => {
		if (this.props.name === "country") {
			this.props.gotToParentValue(newValue, 'country');
		}
		if (this.props.name === "city") {
			this.props.gotToParentValue(newValue, 'city_id');
		}
		if (this.props.name === "categories") {
			this.props.gotToParentValue(newValue.value, 'categories');
		}
		if (this.props.name === "sexe") {
			this.props.gotToParentValue(newValue.value, 'sexe');
		}
		if (this.props.name === "sexe_usr") {
			this.props.gotToParentValue(newValue.value, 'sexe_usr');
		}
		if (this.props.name === "categorie_prod") {
			this.props.gotToParentValue(newValue.value, 'categorie_prod');
		}
	};
	render() {
		let { t } = this.props
		return (
			<div className={this.props.style_f_Select_div}>

				<label
					for={this.props.id}
					className={this.props.classNameLabel}>
					{t(this.props.label_t)}
				</label>
				<span style={{ color: '#000' }}>
					<CreatableSelect
						onChange={this.handleChange}
						onInputChange={this.handleInputChange}
						components={animatedComponents}
						options={this.props.options}
						placeholder={this.props.placeholder}
					/>
				</span>

			</div>
		);
	}
}
export default withTranslation()(FormsSelect);