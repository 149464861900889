import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
// import AuthService from '../../../services/auth';

const PopularProduct = (props) => {
  const history = useHistory();
  let dispatch = useDispatch();
  const [langBool, setLangBool] = useState(true);

//   let cartValue = useSelector((state) => state.__iA ? state.__iA.__cv : []);

  const { t, i18n } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {

    if (i18n.language === 'fr-FR' || 'fr') {
      setLangBool(true);
    }

    if (i18n.language === 'en') {
      setLangBool(false);
    }

  }, []);

//   const allCitiesCountry = (value) => {
//     dispatch(AuthService.getCitiesCountry(value))
//       .then((result) => {

//         let data = result;
//         let options = data.map((value, index) => (
//           {
//             value: value.id,
//             label: value.name
//           }
//         ));

//         setIsLoading(false);
//         setShowAlert(false);
//         setOptionCity(options);

//       })
//       .catch(({ data }) => {
//         console.log('errors ', data);
//       });
//   }

  return (
    // {/* rts feature product 2 area end */}
        // {/* popular -product wrapper 7 */}
        <div className="popular-product-col-7-area rts-section-gapBottom">
          <div className="container cover-card-main-over-white mt--60 ">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-area-between mb--15">
                  <h2 className="title-left">
                    Popular Products
                  </h2>
                  <ul className="nav nav-tabs best-selling-grocery" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Frozen Foods</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Diet Foods</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Healthy Foods</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="vitamine-tab" data-bs-toggle="tab" data-bs-target="#vitamine" type="button" role="tab" aria-controls="vitamine" aria-selected="false">Vitamin Items</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row plr--30 plr_sm--5">
              <div className="col-lg-12">
                <div className="tab-content" id="myTabContent">
                  {/* first tabs area start*/}
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="row g-4 mt--0">
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/17.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Rame lattafa, parfum</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">13.08€</span>
                              <div className="previous">13.08€</div>
                            </div>
                            <div className="cart-counter-action">
                              <a
                                href="shop-grid-top-filter.html"
                                className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/18.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Tech Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/19.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Best Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/20.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Fresh Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/21.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Common Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/22.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">lazy Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/23.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/24.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Full Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/17.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Juice Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/18.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/19.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Modern Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/20.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* first tabs area start*/}
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="row g-4 mt--0">
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/15.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/16.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/17.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/18.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/19.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/20.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/21.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/22.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/23.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="shop-grid-top-filter.html" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/24.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/17.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/18.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/19.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/20.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <div className="row g-4 mt--0">
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/15.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/16.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/17.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/18.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/19.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/20.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/21.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/22.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/23.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/24.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/17.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/18.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/19.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/20.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="vitamine" role="tabpanel" aria-labelledby="vitamine-tab">
                    <div className="row g-4 mt--0">
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/15.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/16.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/17.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/18.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/19.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/20.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/21.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/22.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/23.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/24.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/17.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/18.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary disable-select">
                                <div className="btn-text">
                                  Select Option
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/19.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="single-shopping-card-one deals-of-day">
                          <div className="image-and-action-area-wrapper">
                            <a href="shop-details.html" className="thumbnail-preview">
                              <img src="assets/images/grocery/20.jpg" alt="grocery" />
                            </a>
                            <div className="action-share-option">
                              <div className="single-action openuptip message-show-action" data-flow="up" title="Add To Wishlist">
                                <i className="fa-light fa-heart" />
                              </div>
                              <div className="single-action openuptip" data-flow="up" title="Compare" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fa-solid fa-arrows-retweet" />
                              </div>
                              <div className="single-action openuptip cta-quickview product-details-popup-btn" data-flow="up" title="Quick View">
                                <i className="fa-regular fa-eye" />
                              </div>
                            </div>
                          </div>
                          <div className="body-content">
                            <div className="start-area-rating">
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                              <i className="fa-solid fa-star" />
                            </div>
                            <a href="shop-details.html">
                              <h4 className="title">Mixed Fruits &amp;
                                Wheat with Milk</h4>
                            </a>
                            <span className="availability">500g Pack</span>
                            <div className="price-area">
                              <span className="current">$36.00</span>
                              <div className="previous">$36.00</div>
                            </div>
                            <div className="cart-counter-action">
                              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                                <div className="btn-text">
                                  Add To Cart
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                                <div className="arrow-icon">
                                  <i className="fa-regular fa-cart-shopping" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
}

export default PopularProduct