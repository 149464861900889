import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import fr from 'ree-validate/dist/locale/fr';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Redirect } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import AuthService from '../../../services/auth';
import UsersService from '../../../services/users';
import { lightTheme, darkTheme } from '../../../js/theme';
import { GlobalStyles } from '../../../js/global';
import { changeNavigatorTheme, auth_t, prodId_t } from '../../../store/actions';


import HeaderUsers from './../../layout/HeaderUsers';
import HeaderMenu from './../../layout/HeaderMenu';
import HeaderTop from './../../layout/HeaderTop';
import BandeBlanche from './../../layout/BandeBlanche';
import DemandesComponent from './Components/DemandesComponent';
import HeaderUsersMobile from './../../layout/HeaderUsersMobile';
import Footer from './../../layout/Footer';
import MenuCategiries from '../Home/Components/MenuCategiries';

class Page extends Component {
  constructor(props) {
    super();
    this.state = {
      theme_bool: false,
      langBool: true,
      def_theme: '',
      checked_switch: true,
      isLoading: false,
      options_fr: [
        { value: 'en', label: "Anglais" }
      ],
      options_eng: [
        { value: 'fr', label: "Francais" }
      ],
      options_devise: [
        { value: '€', label: "Dollar" }
      ],
      toggleBool_t: false,
      userLinks: [],
      user_id: null,
      offline: false,
      tabsCatValue: 0
    };
    this.theme_botton_D = this.theme_botton_D.bind(this);
    this.theme_botton_W = this.theme_botton_W.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.checked_t = this.checked_t.bind(this);
    // this.for_remove_js = this.for_remove_js.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.def_theme === '') {
      this.check_theme();
    }
  }
  componentDidMount() {
    this.props.dispatch(auth_t({ __iA: true, __iV: true }));
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: true });
    }
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: false });
    }
    if (this.props.i18n.language === 'fr-FR' || 'fr') {
      this.setState({ langBool: true })
    }
    if (this.props.i18n.language === 'en') {
      this.setState({ langBool: false })
    }
    this.profile();
  }

  handleClick(lang) {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    this.setState({ langBool: !this.state.langBool });
  }
  checked_t() {
    this.setState({ checked_switch: !this.state.checked_switch });
  }

  check_theme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // console.log('🎉 Dark mode is supported');
      this.setState({ def_theme: 'dark', theme_bool: true, checked_switch: false })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      // console.log('🎉 light mode is supported');
      this.setState({ def_theme: 'light', theme_bool: true, checked_switch: true })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
      // console.log('ciphertext ', ciphertext)
    }
  }

  // for_remove_js(e) {
  //   e.preventDefault();
  // }

  theme_botton_D(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'dark', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  theme_botton_W(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'light', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }

  onToggle_t = () => {
    this.setState({ toggleBool_t: !this.state.toggleBool_t });
  }
  onLogout = () => {
    // this.setState({isLoading: true});
    this.props.dispatch(AuthService.logout())
      .then((result) => {
        this.props.history.push("/");
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  profile = (value) => {
    this.props.dispatch(UsersService.profile())
      .then((result) => {
        let data = result;
        this.setState({ user_id: data.id });
        this.getUserLink(data.real_id)
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  getUserLink = (real_id) => {
    this.props.dispatch(UsersService.getReceiverLink(real_id, 0))
      .then((result) => {
        let data = result;
        this.setState({ userLinks: data });
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }
  onAccept_t = (hash_id) => {
    this.props.dispatch(UsersService.respondToUserLinkDemand(hash_id, 1))
      .then((result) => {
        let data = result;
        this.getUserLink(this.state.user_id);
        // this.setState({userLinks: data});
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  handleChangeTabsCatValue = (event, newValue) => {
    this.setState({ tabsCatValue: newValue });
    if (newValue === 0) {
      this.props.history.push("/");
    }
    if (newValue === 1) {
      this.props.history.push("/products/country:33+Cameroun/categorie:8?Alimentaires");
    }
    if (newValue === 2) {
      this.props.history.push("/products/country:33+Cameroun/categorie:20?Épiceries%20et%20boisson%C2%A0");
    }
    if (newValue === 3) {
      this.props.history.push("/products/country:33+Cameroun/categorie:19?Beauté%20et%20bien-être%C2%A0");
    }
    if (newValue === 4) {
      this.props.history.push("/products/country:33+Cameroun/categorie:17?Jouets,%20enfants%20et%20bébés");
    }
    if (newValue === 5) {
      this.props.history.push("/products/country:33+Cameroun/categorie:21?modes%20&%20vêtements");
    }
    if (newValue === 6) {
      this.props.history.push("/products/country:33+Cameroun/categorie:9?Electroniques");
    }
    if (newValue === 7) {
      this.props.history.push("/products/country:33+Cameroun/categorie:13?Multimedia");
    }
  };


  render() {
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);

    const { t } = this.props;
    let { errors } = this.state;

    return (
      <ThemeProvider theme={d_theme === "\"dark\"" ? darkTheme : lightTheme}>
        <>
          <GlobalStyles />
          <Helmet>
            <script src="../../../assets/js/core/popper.min.js" type="text/javascript" />
            <script src="../../../assets/js/core/bootstrap.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/chart.js/chart.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/datatables/datatables.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/bootstrap-notify/bootstrap-notify.min.js" type="text/javascript" />
            <script src="../../../assets/js/plugin/sweetalert/sweetalert.min.js" type="text/javascript" />

            <script src="../../../assets/js/atlantis.min.js" type="text/javascript" />

            <script src="../../../assets/js/setting-demo.js" type="text/javascript" />
            <script src="../../../assets/js/demo.js" type="text/javascript" />
          </Helmet>
          <div className="wrapper register_t">

            <div className="showAndHiddenRespDesk_t">
              <HeaderMenu
                langBool={this.state.langBool}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                option_city={this.state.option_city}
                city_id={this.state.city_id}
                gotToParentValue={this.gotToParentValue}
                option_country={this.state.option_country}
                country_value={this.state.country_value}
                toggleBool_t={this.state.toggleBool_t}
                onToggle_t={this.onToggle_t}
                isAuth_t={this.props.isAuthenticated}
                onLogout={this.onLogout}
                showCountry={false}
                idProf={this.props.prodId_t !== undefined ? this.props.prodId_t.__id : ''}
              />



              <BandeBlanche />

              <HeaderUsers
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
                isShowSearch_t={false}
              />
              <HeaderTop
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                options_fr={this.state.options_fr}
                options_eng={this.state.options_eng}
                options_devise={this.state.options_devise}
                device_data={this.state.device_data}
                gotToParentValue={this.gotToParentValue}
                logo_noir={false}
                fName={this.props.prodId_t.__pfn !== undefined ? this.props.prodId_t.__pfn : ''}
              />

              {/* <MenuCategiries
                tabsCatValue={this.state.tabsCatValue}
                handleChangeTabsCatValue={this.handleChangeTabsCatValue}
              /> */}

            </div>
            <div className="showAndHiddenResp_t">

              <HeaderUsersMobile
                cartValue={this.props.cartValue}
                isAuthResponsive={false}
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                option_city={this.state.option_city}
                city_id={this.state.city_id}
                gotToParentValue={this.gotToParentValue}
                option_country={this.state.option_country}
                country_value={this.state.country_value}
                toggleBool_t={this.state.toggleBool_t}
                onToggle_t={this.onToggle_t}
                isAuth_t={this.props.isAuthenticated}
                onLogout={this.onLogout}
                isLoadingHeader={this.state.isLoadingHeader}
                idProf={this.props.prodId_t !== undefined ? this.props.prodId_t.__id : ''}
                showCountry={false}
              />

            </div>

            <div className="container-fluid container_demande_t marginCmdAndDmd_t">
              <div className="row marg_null_t demandeRowTitle_t">
                <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-9">
                  <span className="demandeTitle_t">
                    Mes demandes de liason ({this.state.userLinks.length})
                  </span>
                </div>
                <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-3 showAndHiddenRespDesk_t">
                  <span className="trierTitleDemande_t">Trier par :</span>
                  Récent
                </div>
              </div>
              {this.state.offline ?
                <span className="offlinePage_t">
                  Verifiez votre connextion internet puis réesayez
                </span>
                : ''
              }
              <div className="row marg_null_t demandeMargin_t">
                <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-12">

                  <DemandesComponent
                    userLinks={this.state.userLinks}
                    image={require("../../../images/userProfil.png")}
                    imageVerticale={require("../../../images/vertcalLine.png")}
                    onAccept_t={this.onAccept_t}
                  />

                </div>
              </div>

              <div className="showAndHiddenRespDesk_t">
                <Footer />
              </div>
              <div className="footerMobil_t">
                &nbsp;&nbsp;
              </div>

            </div>

          </div>
        </>
      </ThemeProvider>
    );
  }
}
export default withTranslation()(Page);