import * as React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import HistoryIcon from '@mui/icons-material/History';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/system';

const StyledMenuItem = styled(MenuItem)({
    color: '#2C3C28',
    fontWeight: 500,
    fontSize: '18px !important',
    fontFamily: '"Barlow", sans-serif !important',
});

const StyledButton = styled(Button)({
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    color: '#2C3C28',
    fontWeight: 500,
    fontSize: 18,
    fontFamily: '"Barlow", sans-serif',
    '& .dotsMyCount': {
        marginLeft: 8,
    },
});

export default function AccountMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const open = Boolean(anchorEl);

    const onShowT = () => {
        this.setState({ show: !this.state.show })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <StyledButton
                    className="btn-border-only account"
                    sx={{ backgroundColor: '#FFFFFF' }}
                    onClick={handleClick}
                >
                    <PersonIcon sx={{ color: '#2C3C28', fontSize: 20 }} />
                    <span className="dotsMyCount">
                        {props.fName ? `${props.fName}` : "Mon Compte"}
                    </span>
                </StyledButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <StyledMenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <ShoppingBasketIcon sx={{ color: '#2C3C28', fontSize: '22px' }} />
                    </ListItemIcon>
                    <Link to="/orders" className="togglePage_t">
                        Mes commandes en cours
                    </Link>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings sx={{ color: '#2C3C28', fontSize: '22px' }} />
                    </ListItemIcon>
                    <Link to="/setting" className="togglePage_t">
                        Réglages et confidentialité
                    </Link>
                </StyledMenuItem>
                {/* <StyledMenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <HistoryIcon sx={{ color: '#2C3C28', fontSize: '22px' }} />
                    </ListItemIcon>
                    <Link to="/history" className="togglePageText_t">
                        Historique de Commandes
                    </Link>
                </StyledMenuItem> */}
                <StyledMenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <PersonIcon sx={{ color: '#2C3C28', fontSize: '22px' }} />
                    </ListItemIcon>
                    <Link to={`/profile/${props.idProf}`} className="togglePageText_t">
                        Mon profil
                    </Link>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <HelpOutlineIcon sx={{ color: '#2C3C28', fontSize: '22px' }} />
                    </ListItemIcon>
                    <a href="javascript:;" className="togglePageText_t">
                        Aide et assistance
                    </a>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Logout sx={{ color: '#2C3C28', fontSize: '22px' }} />
                    </ListItemIcon>
                    <a href="javascript:;" onClick={props.onLogout} className="togglePageText_t">
                        Déconnexion
                    </a>
                </StyledMenuItem>
            </Menu>
        </React.Fragment>
    );
}
