import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, Route, Switch } from "react-router-dom";
import SeConnecterBtn from './../components/SeConnecterBtn'
import SearchInput from './../components/FormsComponent/SearchInput'
import SearchWithAutoComplete from '../components/FormsComponent/SearchWithAutoComplete';

class HeaderUsers extends Component {

  render() {
    const { t, cartValue, searchR_t, gotToParentValue, propsCustom, 
      allProductsBycategories, includesForAllProducts_t } = this.props;
    return (
      <div className="main-header main-header-color">
        {/* Logo Header */}
        <div className="logo-header logo-header-width">
          <Link to="/" className="logo" style={{ top: '1.5vh' }}>
            <img
              className="navbar-brand logo_header_size logoIsResponsive_t"
              src={require("../../images/logo_header.png")}
              alt="navbar brand"
            />
            <span className="text_header_size"
              style={{ fontFamily: 'Maison Neue Bold' }}>
              Wemaza
              <span style={{ color: 'blue' }}>
                .
              </span>
            </span>
          </Link>
          {/*<button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
                <i className="icon-menu" />
              </span>
            </button>
            <button className="topbar-toggler more">
              <i className="icon-options-vertical" />
            </button>*/}
          {/*<div className="nav-toggle">
              <button className="btn btn-toggle toggle-sidebar">
                <i className="icon-menu" />
              </button>
            </div>*/}
        </div>
        {/* End Logo Header */}
        {/* Navbar Header */}
        <nav className="navbar navbar-header navbar-expand-lg navbar_header_t">
          <div className="container-fluid">

            <ul className="row navbar-nav topbar-nav">
              <li class="nav-item" style={{ margin: '10px 0 0 0' }}>

                {this.props.isShowSearch_t ?
                  <SearchWithAutoComplete
                    options={this.props.searchProducts_t}
                    searchNameWithCallback={this.props.searchNameWithCallback}


                    className_div_2={'form-group with-button search-input is-empty'}
                    input_className={'form-control searchFontFamily_t'}
                    input_id={'headerSearch'}
                    input_placeholder={'Recherche sur wemaza'}
                    input_type={'search'}
                    name={'searchR_t'}
                    images_className={'search_t'}
                    gotToParentValue={gotToParentValue}
                    searchR_t={searchR_t}
                    propsCustom={propsCustom}
                    allProductsBycategories={allProductsBycategories}
                    includesForAllProducts_t={includesForAllProducts_t}

                  />
                  :
                  ""
                }



              </li>
            </ul>
            <ul className="row navbar-nav topbar-nav ml-md-auto align-items-center" style={{ margin: '0 1% 0 0' }}>
              {/*<div className="col col-md-6 col-8 theme_t">
                     <a href="#" className="dark_img_t" onClick={(e)=>this.props.theme_botton_D(e, true)}>🌜</a> &nbsp;
                      <label className="switch">
                        <input type="checkbox" onClick={this.props.checked_switch ? this.props.theme_botton_D : this.props.theme_botton_W} checked={this.props.checked_switch ? "checked" : ""} onChange={this.props.checked_t}/>
                        <span className="slider round"></span>
                      </label>
                       &nbsp;<a href="#" className="white_img_t" onClick={(e)=>this.props.theme_botton_W(e, true)}>🌞</a>
                      </div>*/}

              {/* <li class="nav-item nav_item_width">
                <br />
                <img
                  src={require("../../images/location.png")}
                  alt="Search"
                  className="search_t"
                />
                <br /><br /><br />
                <span style={{ color: '#42a350' }}>
                  Vous etes ici <br /><br /><br />
                  France, Paris
                </span>
              </li> */}
              <li class="nav-item nav_item_width">
                <a href="javascript:;">
                  <br />
                  <img
                    src={require("../../images/comment.png")}
                    alt="Search"
                    className="li_img_t"
                  />
                  <br /><br /><br /><br />
                  <span style={{ color: '#FFF' }}>
                    Un soucis ? <br /><br /><br />
                    Contactez-nous
                  </span>
                </a>
              </li>
              {/*<li class="nav-item nav_item_width">
                  <a href="#">
                  <br />
                    <img 
                      src={require("../../images/profile.png")} 
                      alt="Search" 
                      className="li_img_t" 
                    />
                        <br /><br /><br /><br />
                       <span style={{color: '#FFF'}}>
                           C'est le moment <br /><br /><br />
                           de vous connecter
                        </span>
                  </a>
                </li>*/}
              <li class="nav-item nav_item_width">
                <Link to="/panier">
                  <br /><br />
                  <br /><br />
                  <br /><br />
                  <span style={{ color: '#FFF', 'font-weight': '700', 'font-size': '23px' }}>
                    {cartValue.length}
                  </span>
                  <i class="fas fa-cart"></i>
                  <img
                    src={require("../../images/cart.png")}
                    alt="Search"
                    className="cart_t"
                  />

                </Link>
              </li>
            </ul>
          </div>
        </nav>
        {/* End Navbar */}
      </div>
    );
  }
}
export default withTranslation()(HeaderUsers);