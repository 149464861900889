import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from "react-select";
import FloatingInput from './../../../components/FormsComponent/FloatingInput';
import FormsSelect from './../../../components/FormsComponent/FormsSelect';
import FloatingTextArea from './../../../components/FormsComponent/FloatingTextArea';
import LoginBtn from './../../../components/FormsComponent/LoginBtn';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class ReciverForms extends Component {
  setStartDate = (date) => {
    this.props.gotToParentValue(date, 'date');
  }
  handleOnChange = (value) => {
    this.props.gotToParentValue(value, 'phone');
  };
  render() {
    let { t,
      postdata,
      errors,
      postregister,
      handleChange,
      gotToParentValue,
      offline } = this.props
    return (
      <>
        <div className="form-group user_modal_t profilForm" style={{ textAlign: 'left' }}>

          <div className="form-row row_t">

            <div className="col-12 col-sm-6">
              <div className={classnames('form-line', { 'error': errors.has('lastname') })}>
                <FloatingInput
                  className={'input_float_posit_t form-control input-solid'}
                  classNamePlaceh={'label_float_posit_t placeholder'}
                  style_f_floating_div={'form-group form-floating-label input_g_parent'}
                  type={'text'}
                  name={'lastname'}
                  id={'lastname'}
                  value={postregister.lastname}
                  placeholder={t('Nom')}
                  onChange={handleChange}
                  text_or_not={true}
                  required={true}
                  icon_user={true}
                  class_for_img_icon_style={true}
                  input_left_t={false}
                  black_profile_img_t={true}
                  disabled={this.props.prodId_t !== this.props.currentprodId_t ? true : false}
                />
              </div>
              {errors.has('lastname') &&
                <p
                  className="error_perso"
                  id="name-error"
                  htmlFor="name">
                  {errors.first('lastname')}
                </p>
              }
            </div>

            <div className="col-12 col-sm-6">
              <div className={classnames('form-line', { 'error': errors.has('firstname') })}>
                <FloatingInput
                  className={'input_float_posit_t form-control input-solid'}
                  classNamePlaceh={'label_float_posit_t placeholder'}
                  style_f_floating_div={'form-group form-floating-label input_g_parent_2'}
                  type={'text'}
                  name={'firstname'}
                  id={'firstname'}
                  value={postregister.firstname}
                  placeholder={t('input_firstname')}
                  onChange={handleChange}
                  text_or_not={true}
                  required={true}
                  input_left_t={false}
                  black_profile_img_t={true}
                  icon_user={true}
                  class_for_img_icon_style={true}
                  disabled={this.props.prodId_t !== this.props.currentprodId_t ? true : false}
                />
              </div>
              {errors.has('firstname') &&
                <p
                  className="error_perso"
                  id="name-error"
                  htmlFor="firstname">
                  {errors.first('firstname')}
                </p>
              }
            </div>

          </div>

          <div className="form-row form-row_2_t">

            <div className="col-12 col-sm-6">
              <div className={classnames('form-line', { 'error': errors.has('email') })}>
                <FloatingInput
                  className={'input_float_posit_t form-control input-solid'}
                  classNamePlaceh={'label_float_posit_t placeholder'}
                  style_f_floating_div={'form-group form-floating-label input_g_parent_2'}
                  type={'email'}
                  name={'email'}
                  id={'email'}
                  value={postregister.email}
                  placeholder={t('Email')}
                  onChange={handleChange}
                  text_or_not={true}
                  required={true}
                  // disabled={this.props.UpdateUser_bool ? true : false}
                  icon_email={true}
                  class_for_img_icon_style={true} //for style of email image icon
                  disabled={this.props.prodId_t !== this.props.currentprodId_t ? true : false}
                />
              </div>
              {errors.has('email') &&
                <p
                  className="error_perso"
                  id="name-error"
                  htmlFor="email">
                  {errors.first('email')}
                </p>
              }
              {this.props.email_exit ?
                <p
                  className="error_perso"
                  id="name-error"
                  htmlFor="email_exit">
                  {this.props.email_not_val}
                </p>
                :
                ''
              }
            </div>
            <div className="col-12 col-sm-6 password_register_t">

              <div
                className={classnames('form-line', { 'error': errors.has('password') })}
              >
                <FloatingInput
                  className={'input_float_posit_t form-control input-solid'}
                  classNamePlaceh={'label_float_posit_t placeholder'}
                  style_f_floating_div={'form-group form-floating-label input_g_parent_2'}
                  label_style={{ 'z-index': '3', top: '4px', 'word-spacing': '-3px', margin: '0 0 0 1rem' }}
                  type={this.props.eye_password_bool === true ? 'password' : 'text'}
                  toggle={this.props.toggle}
                  eye_password_bool={this.props.eye_password_bool}
                  name={'password'}
                  id={'password'}
                  value={postregister.password}
                  placeholder={t('Nouveau Mot de passe')}
                  onChange={handleChange}
                  text_or_not={false}
                  required={false}
                  icon_lock={true}
                  class_for_img_icon_style={true}
                  black_profile_img_t={true}
                  disabled={this.props.prodId_t !== this.props.currentprodId_t ? true : false}
                />
              </div>
              {errors.has('password') &&
                <p
                  className="error_perso"
                  id="name-error"
                  htmlFor="password">
                  {errors.first('password')}
                </p>
              }

            </div>

          </div>


          <div className="form-row form-row_2_t">
            {/* <div className="col-12 col-sm-6 phonMargResp_t"> */}
            <div className="col-12 col-sm-6">
              <div>
                <FormsSelect
                  select_value={postregister.country_id}
                  style_f_Select_div={'form-group select_t styleFSelectRigth_div'}
                  classNameLabel={"label_select_t"}
                  name={'country'}
                  options={this.props.option_country}
                  placeholder={postregister.country !== '' ? postregister.country : 'Select...'}
                  label_t={'Pays'}
                  gotToParentValue={gotToParentValue}
                />
              </div>
            </div>

            {/* <div className="col-12 col-sm-6 input_phone_regist_t phonMargResp_t"> */}
            <div className="col-12 col-sm-6 input_phone_regist_t">
              <div>
                <label
                  for="birth"
                  className="label_select_t"
                  style={{ 'margin-top': '8px', 'margin-left': '10%' }}>Phone

                </label>
                <PhoneInput
                  className="form-control"
                  country={'cm'}
                  Defaultvalue={postregister.phone}
                  value={postregister.phone}
                  placeholder={postregister.phone !== '' ? postregister.phone : "— Téléphone"}
                  onChange={this.handleOnChange}
                />
              </div>

            </div>
          </div>

          <div className="form-row form-row_2_t">

            <div className="col-12">
              <label
                for="birth"
                className="label_select_t"
                style={{ 'margin-top': '8px', 'margin-left': '3%' }}>D. Naissance

              </label>
              <DatePicker
                className="input_t input_t_DatePicker"
                selected={postdata.date}
                value={postdata.date}
                onChange={(date) => this.setStartDate(date)} />
              <i
                className="fa fa-calendar-alt"
                style={{
                  position: 'absolute',
                  margin: '.7rem 0px 0px -2.5rem',
                  color: '#FFF'
                }}></i>
            </div>
            <div className="col-12 col-sm-6">

              <FormsSelect
                select_value={postdata.sexe}
                style_f_Select_div={'form-group select_t styleFSelectRigth_div phonMargResp_t'}
                classNameLabel={"label_select_t"}
                name={'sexe'}
                options={this.props.option_sexe}
                placeholder={postdata.sexe !== '' ? postdata.sexe : 'Select...'}
                label_t={'Sexe'}
                gotToParentValue={gotToParentValue}
              />

            </div>
          </div>

          <div className="form-row form-row_2_t">

            <div className="col-12 col-sm-6">
              <div>
                <FloatingInput
                  className={'input_float_posit_t form-control input-solid'}
                  classNamePlaceh={'label_float_posit_t placeholder addressMargResp_t'}
                  style_f_floating_div={'form-group form-floating-label input_g_parent_2'}
                  type={'text'}
                  name={'address'}
                  id={'address'}
                  value={postregister.address}
                  placeholder={t('Addresse')}
                  onChange={handleChange}
                  text_or_not={true}
                  required={true}

                  // icon_user={true}
                  // class_for_img_icon_style={true}
                  // input_left_t={false}
                  // black_profile_img_t={true}
                />
              </div>

            </div>


            {/* <div className="col-12 col-sm-6">
              <div
                className={classnames('form-line', { 'error': errors.has('address') })}
              >
                <FloatingTextArea
                  className={'input_float_posit_t form-control input-solid'}
                  classNamePlaceh={'label_float_posit_t placeholder'}
                  style_f_floating_div={{ margin: '0 0 0 8%' }}
                  name={'address'}
                  id={'address'}
                  value={postregister.address}
                  placeholder={t('Addresse')}
                  onChange={handleChange}
                  text_or_not={true}
                  required={true}
                />
              </div>
              {errors.has('address') &&
                <p
                  className="error_perso"
                  id="name-error"
                  htmlFor="address">
                  {errors.first('address')}
                </p>
              }
            </div> */}


            <div className="col-12 col-sm-6 input_phone_regist_t" style={{ display: 'flex', 'justify-content': 'center' }} >
              <div className="divDowload_t">
                <img
                  src={require("../../../../images/plus.png")}
                  alt="Image prod"
                  className="imgSettingPlusRound_t"
                />
                <img
                  src={require("../../../../images/downloadIcon.png")}
                  alt="Image prod"
                  className="imgSettingDownloadIconRound_t"
                />
              </div>
              <p className="downloadTitle_t">
                Télécharger une image
              </p>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-12 col-sm-4"></div>

          <div className="col col-sm-8" style={{ position: 'absolute', right: '-2.5%' }}>

            {offline ?
              <span className="offline_t">
                Verifiez votre connextion internet puis réesayez
              </span>
              : ''
            }
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(ReciverForms);