import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CatSelectComponent from './../../../components/CatSelectComponent';
import ProductList from './ProductList';
import InvoiceDetails from './InvoiceDetails';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import BuyingHome from '../../../../assets/img/BuyingHome.png';
import SearchBar from '../../Auth/Register/Components/SearchBar';


class PointRelais extends Component {
  render() {
    const { t,
      optionPointRelais_t,
      onShowPointRelais_t,
      pointRelaisValue,
      cartValue,
      sum_t,
      onShowReceveur_t,
      relaypointIdStore,
      showCarteadhesionGS,
      isSocialAssistanceOption,
      selectedValue,
      onHandleRelayPointId,
      getShortContryNameOfBeneficiary,
      distanceBetweenBeneficiaryAndOurLocaltionViaGoogleMap,
      handleAddressBeneficiary,
      addressOfBeneficiary,
      heure, minute, seconde,
      distanceBeneficiary,
      onAtHomeChoose,
      montantLivraison,
      onHandleParentStateTotal,
      totalHtArrondie,
      totalTauxInteretArrondie,
      totalTtcArrondie,
      typeOfHLocatization,
      treatment_costs,
      social_assistance,
      delivery,
      amountS,
      fromCMD,
      totalHtArrondieS,
      interest_rate
    } = this.props;
    let relaypointArray = []
    let prelaiyId = []

    let relaypointId = cartValue.length > 0 && cartValue.map((v) => v.relaypointId);
    prelaiyId = relaypointId.length > 0 && relaypointId.filter((val, i) => val !== undefined);

    let relaypoint = cartValue.length > 0 && cartValue.map((v) => v.relaypoint);
    relaypointArray = relaypoint.length > 0 && relaypoint.filter((v, i) => v !== undefined);

    let arrayQty = cartValue.length > 0 && cartValue.map((v, i) => v.qty);
    let sumQtys = arrayQty.length > 0 && arrayQty.reduce(function (a, b) {
      return parseInt(a) + parseInt(b);
    }, 0);

    let dateDuJour = new Date();

    let dateDemain = new Date(dateDuJour);

    dateDemain.setDate(dateDuJour.getDate() + 1);

    let deuxjourApres = new Date(dateDuJour);

    let sixjourApres = new Date(dateDuJour);

    deuxjourApres.setDate(dateDuJour.getDate() + 2);
    sixjourApres.setDate(dateDuJour.getDate() + 6);

    // Extraction de la date du jour
    let jour = dateDuJour.getDate();
    let mois = dateDuJour.getMonth() + 1; // Les mois commencent à partir de 0, donc on ajoute 1
    let annee = dateDuJour.getFullYear();

    let heureActuelle = dateDuJour.getHours();
    let minuteActuelle = dateDuJour.getMinutes();
    let secondeActuelle = dateDuJour.getSeconds();



    // Extraction de la date du jour
    let lendemain = dateDemain.getDate();
    let deuxjourApresExtract = deuxjourApres.getDate();
    let sixjourApresExtract = sixjourApres.getDate();

    var nomCourtJour = dateDuJour.toLocaleString('fr-FR', { weekday: 'short' });
    var nomCourtDemain = dateDemain.toLocaleString('fr-FR', { weekday: 'short' });
    var nomCourtdejourApres = deuxjourApres.toLocaleString('fr-FR', { weekday: 'short' });
    var nomCourtsixjourApres = sixjourApres.toLocaleString('fr-FR', { weekday: 'short' });

    let optionPoint_t = optionPointRelais_t.map((val, id) => (
      <div className='col-md-12 rPointDiv'>
        <span className='rPointDivParent'>
          <img src={BuyingHome}
            style={{
              width: '80px',
              height: '100px',
              display: 'inline-flex',
              position: 'absolute',
              // left: '25px'
            }} alt='' />
          <span className='rPointDivLocation'>
            <span style={{ color: '#e23a05' }}>En express</span> à {val.label}</span>
          <span className='rPointDivOpeningHours'>
            Livré entre le {nomCourtDemain} <b>{lendemain}/{mois}</b> et le {nomCourtdejourApres} <b>{deuxjourApresExtract}/{mois}</b>
            {/* mer. 15/05 et le jeu. 16/05 */}
          </span>
          <span className='rPointDivDescription'>Lieu: {val.description}</span>
        </span>

        <span className='rPointDivOpeningHours'>
          ouvert de {val.open_hour} h à {val.close_hour} h
        </span>
        <span className='rPointDivPrice'> Gratuit
        </span>
        <a href="javascript:;"
          onClick={(value) => onHandleRelayPointId(val)}
          style={
            !!typeOfHLocatization && typeOfHLocatization === "relayPoint" ?
              {
                backgroundColor: '#54a649',
                borderRadius: '10px',
                color: '#FFFFFF',
                padding: '10px',
                margin: '-75px 0px 0px 520px',
                position: 'absolute'
              }
              :
              {
                border: '2px solid #54a649',
                borderRadius: '10px',
                color: '#575962',
                padding: '10px',
                margin: '-75px 0px 0px 520px',
                position: 'absolute'
              }
          }>
          Choisir
        </a>
      </div>));

    return (
      <>
        <div className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-7 relayPointSection">
          {/* <p style={{ background: '#f9f9f9' }}> */}
          <p style={{ background: '#E8E8E8' }}>
            {/* <p className="chooseRelayPoint_t">
              Choisissez la livraison pour votre article expédié par Wemaza
            </p> */}



            <div className="row" style={{ marginBottom: '60px', padding: '20px 0 0 0' }}>
              <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-12">
                <span className='homeDelivery_t'>Livraison à domicile</span>
              </div>

              <div className="col-12 col-sm-8" style={{ marginLeft: '10px' }}>
                <label for="inputEmail4">
                  {/* Adresse du bénéficiaire */}
                </label>

                <SearchBar
                  name="address"
                  address={addressOfBeneficiary}
                  handleAddressGeocode={handleAddressBeneficiary}
                  getProfileschedulesWithMyLocation={getShortContryNameOfBeneficiary}
                  distanceBetweenBeneficiaryAndOurLocaltionViaGoogleMap={distanceBetweenBeneficiaryAndOurLocaltionViaGoogleMap}
                  placeholderText={"Entrer votre adresse de livraison"}
                />
              </div>
              {(!!distanceBeneficiary && distanceBeneficiary.isAtHome === "Yes") ?
                <div className="row" style={{ marginLeft: '0px' }}>
                  <div className='col-md-12 rPointDivParentPriceStadart'>
                    <span className='rPointDivParent'>
                      <img src={BuyingHome}
                        style={{
                          width: '80px',
                          height: '100px',
                          display: 'inline-flex',
                          position: 'absolute',
                          left: '0px'
                        }} alt='' />
                      {/* <span className='rPointDivFirstDiv'> */}
                      <span className='rPointDivLocation'>
                        <span>En standard</span>
                      </span>
                      <span className='rPointDivOpeningHours'>
                        Vous serez livré &nbsp;
                        {nomCourtDemain} <b>{lendemain}/{mois}</b>

                        {/* Livré entre le 
                        {nomCourtdejourApres} <b>{deuxjourApresExtract}/{mois}</b> 
                        et le 
                        {nomCourtsixjourApres} <b>{sixjourApresExtract}/{mois}</b> */}

                      </span>
                      <span className='rPointDivDescription'>{distanceBeneficiary.libelle}</span>
                      {/* </span> */}

                    </span>
                    <span className='rPointDivPriceStadart'> {distanceBeneficiary.montant}€
                    </span>
                    <a href='javascript:void();'
                      onClick={(montant, distanceBeneficiaryVal, typeOfLocatization) => onAtHomeChoose(distanceBeneficiary.montant, distanceBeneficiary, "standard")}
                      style={!!typeOfHLocatization && typeOfHLocatization === "standard" ?
                        {
                          backgroundColor: '#54a649',
                          borderRadius: '10px',
                          color: '#FFFFFF',
                          padding: '10px',
                          // margin: '-45px 0px 0px 125px',
                          margin: '-45px 0px 0px 90px',
                          position: 'absolute'
                        }
                        :
                        {
                          border: '2px solid #54a649',
                          borderRadius: '10px',
                          color: '#575962',
                          padding: '10px',
                          margin: '-45px 0px 0px 90px',
                          position: 'absolute'
                        }}>
                      Choisir
                    </a>
                  </div>


                  <div className='col-md-12 rPointDivParentPriceStadart'>
                    <span className='rPointDivParent'>
                      <img src={BuyingHome}
                        style={{
                          width: '80px',
                          height: '100px',
                          display: 'inline-flex',
                          position: 'absolute',
                          // left: '25px'
                        }} alt='' />
                      <span className='rPointDivLocation'>
                        <span style={{ color: '#e23a05' }}>En express</span>
                      </span>
                      <span className='rPointDivOpeningHours'>
                        {/* Livré dans &nbsp;
                        <b>les 4h</b>&nbsp; pendant nos heures d'ouverture(7h30-17h30)&nbsp;
                        à compter de <br />
                        <b>{heure}h {minute}min {seconde}sec</b> */}

                        Livré dans &nbsp;
                        <b>les 4h</b>&nbsp;
                        à compter de <br />
                        <b>{heure}h {minute}min {seconde}sec</b>
                      </span>
                      <span className='rPointDivDescription'>{distanceBeneficiary.libelle}</span>
                    </span>
                    <span className='rPointDivPriceStadart2'> {2 * distanceBeneficiary.montant}€
                    </span>
                    <a href='javascript:void();'
                      onClick={(montant, distanceBeneficiaryVal, typeOfLocatization) => onAtHomeChoose(2 * distanceBeneficiary.montant, distanceBeneficiary, "express")}
                      style={
                        !!typeOfHLocatization && typeOfHLocatization === "express" ?
                          {
                            backgroundColor: '#54a649',
                            borderRadius: '10px',
                            color: '#FFFFFF',
                            padding: '10px',
                            margin: '-55px 0px 0px 90px',
                            position: 'absolute'
                          }
                          :
                          {
                            border: '2px solid #54a649',
                            borderRadius: '10px',
                            color: '#575962',
                            padding: '10px',
                            margin: '-55px 0px 0px 90px',
                            position: 'absolute'
                          }}>
                      Choisir
                    </a>
                  </div>
                </div>
                :
                ""
              }


              {/* <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-12">

                <Box
                  sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    padding: '0 0 0 10px',
                    '& .MuiTextField-root': { width: '55ch' },
                  }}
                >

                  <TextField label={'Adresse du bénéficiaire'} id="margin-normal" margin="normal" />

                </Box>

                <a href='javascript:void();'
                  style={{
                    border: '2px solid #54a649',
                    borderRadius: '10px',
                    color: '#575962',
                    padding: '10px',
                    margin: '0 0 0 15px',
                    top: '20px'
                  }}>
                  Rechercher
                </a>

              </div> */}
            </div>

            <hr style={{ 'border-top': '5px solid #fff' }} />
            <div className="row">
              <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-12">
                <span className='homeDelivery_t'>Retrait au point relais</span>
              </div>
              {/* <div className='rPointDiv'>
                <span className='rPointDivParent'>
                  <img src={BuyingHome}
                    style={{
                      width: '80px',
                      height: '100px',
                      display: 'inline-flex',
                      position: 'absolute',
                      left: '25px'
                    }} alt='' />
                  <span className='rPointDivLocation'>
                    <span style={{ color: '#e23a05' }}>En express</span> Wemaza Relais point</span>
                  <span className='rPointDivOpeningHours'>
                    Livré entre le {nomCourtDemain} <b>{lendemain}/{mois}</b> et le {nomCourtdejourApres} <b>{deuxjourApresExtract}/{mois}</b>
                  </span>
                  <span className='rPointDivDescription'>Lieu: Ndogsimbi, carrefour matango</span>
                </span>
                <span className='rPointDivOpeningHours'>
                  ouvert de 8h à 17h
                </span>
                <span className='rPointDivPrice'> Gratuit
                </span>
                <a href='javascript:void();'
                  style={{
                    border: '2px solid #54a649',
                    borderRadius: '10px',
                    color: '#575962',
                    padding: '10px',
                    margin: '-75px 0px 0px 425px',
                    position: 'absolute'
                  }}>
                  Choisir
                </a>
              </div> */}

              {optionPoint_t}


              {/* <div className="col-3 col-xs-12 col-sm-6 col-md-4 col-lg-2" style={{ padding: '25px' }}>
                <span>Lieu</span>
              </div>
              <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-10">
                <CatSelectComponent
                  select_value={pointRelaisValue}
                  style_f_Select_div={{ margin: '5px 0 0 -5px', color: '#000', maxWidth: '450px' }}
                  name={'point_relais'}
                  options={optionPointRelais_t}
                  placeholder={relaypointArray.length > 0 ? relaypointArray[0] : 'Point Relais'}
                  gotToParentValue={gotToParentValue}
                  bool_val={bool_val}
                />
              </div> */}

            </div>

          </p>
        </div>

        <InvoiceDetails
          sumQtys={sumQtys}
          sum_t={sum_t}
          modalEligible={this.props.modalEligible}
          showCarteadhesionGS={showCarteadhesionGS}
          onBtnClick={(e, relaypointIdArray) => onShowReceveur_t(e, relaypointIdStore)}
          classNameFirstDiv={'col-12 col-xs-12 col-sm-6 col-md-4 col-lg-5 acideLeftCart_t'}
          selectedValue={selectedValue}
          isSocialAssistanceOption={isSocialAssistanceOption}
          // deliveryPrice={!!distanceBeneficiary && distanceBeneficiary.isAtHome === "Yes" ? distanceBeneficiary.montant : "gratuit"}
          deliveryPrice={montantLivraison}
          onHandleParentStateTotal={onHandleParentStateTotal}
          totalHtArrondie={totalHtArrondie}
          totalTauxInteretArrondie={totalTauxInteretArrondie}
          totalTtcArrondie={totalTtcArrondie}

          treatment_costs={treatment_costs}
          social_assistance={social_assistance}
          delivery={delivery}
          amountS={amountS}
          fromCMD={fromCMD}
          totalHtArrondieS={totalHtArrondieS}
          interest_rate={interest_rate}
          isRelayPoint={true}
        />


      </>
    );
  }
}
export default withTranslation()(PointRelais);