import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withTranslation } from 'react-i18next';

class Carousel extends Component {
  render() {

    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className="slick-next"
          style={{ ...style, display: "block" }}
          onClick={onClick}
        />
      );
    }

    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, display: "block" }}
          onClick={onClick}
        />
      );
    }

    var settings = {
      dots: false,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const { t } = this.props;
    return (
      <div className="slick-dots_t" style={{ padding: '2% 0 0 0' }}>
        {/*<div className="container-fluid slick-dots_t" style={{padding: '4% 0 0 0'}}>*/}
        <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <style>{cssstyle}</style>
        <Slider {...settings}>
          <div className="container-fluid">
            <div className="row div_carousel">

              <div className="col-12 col-sm-4">
                <span className="text_left_slider_t">
                  100% Epicées dans votre ville
                </span>
              </div>
              <div className="col-12 col-sm-4">
                <img className="img_slider_center_t" srcset={require('../../../images/webp/sliderImage.webp')} />
              </div>
              <div className="col-12 col-sm-4">
                <span className="text_right_slider_t">
                  Profitez d’un pack complet d’ingrédients
                  <a
                    href="javascript:;"
                    className="btn btn_more_details"
                    onClick={this.moreDetails}>
                    Voir les details
                  </a>
                </span>

              </div>
            </div>
          </div>
          <div className="row div_carousel">
            {/*Carousel 2*/}
          </div>
          <div className="row div_carousel">
            <div>
              {/*Carousel 3*/}
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default withTranslation()(Carousel)

const cssstyle = `
.h3 {
    background: red;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
`