import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Stats extends Component {
	render(){
        	const { t } = this.props;
        	return(
                	<div className="container marg_placeh_t">
                        <div className="row">
                            {/*<div className="col-4 stat_round"></div>*/}
                            <div className="col">
                                <div className="stat_lign1"></div>
                                <div className="stat_lign2"></div>
                                <div className="stat_lign1bis1"></div>
                                <div className="stat_lign1bis2"></div>
                                <div className="stat_lign3"></div>
                           <span style={{
                               margin: '-23% 0 0 2rem', 
                               position: 'absolute',
                               color: '#FFF'
                           }}> ====== Chargement ... veillez patienter ====== </span>
                            </div>

                        </div>
                  </div>
        	);
        }
}
export default withTranslation()(Stats);