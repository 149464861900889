import React from 'react';
import ReactDOM from 'react-dom';
// import './styleSheets/index.css';
import App from './App';
import { I18nextProvider } from "react-i18next";
import i18n from './i18n';

import store from './store';
import { Provider } from 'react-redux';

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<Provider store={store}>
			<App />
		</Provider>
	</I18nextProvider>,
	document.getElementById('root')
);