import React, { useState, useEffect } from "react";
import { Alert, Row, Col, Progress } from "reactstrap";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import DateRange from '@mui/icons-material/CalendarMonth';

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom"

import Swal from 'sweetalert2';
// import 'react-phone-input-2/lib/style.css'; 
import Spinner from 'react-bootstrap/Spinner';

//MUI autocomplete
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// router
import { Link, Redirect } from "react-router-dom";

//Availity forme
import { AvForm, AvField } from "availity-reactstrap-validation";

//Phone Input
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

// import CountryService from 'services/country';
// import CityService from 'services/city';
import AuthService from '../../../../services/auth';

// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";

// hooks
// import { useProfile } from "../../hooks";

//actions
// import { registerUser } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "./Components/NonAutnLayoutWrapper";
import AuthHeader from "./Components/AuthHeader";
import SearchBar from "./Components/SearchBar";
import { BiHome, BiCalendarEvent } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import DataModal from './Components/DataModal';
import { useTranslation } from 'react-i18next';

const RegisterW = props => {
  let dispatch = useDispatch();
  const history = useHistory()
  const [error, setError] = useState(null)

  let pageActiv = useSelector((state) => state.settings ? state.settings.__pt : false);

  const [phone, setPhone] = useState('')
  const [options_countries, setOptionsCountries] = useState([{ value: null, label: null, hash_id: null }])
  const [countryVal, setCountryVal] = useState()
  const [cityVal, setCityVal] = useState()

  const [options_cities, setOptionsCities] = useState([{ value: '', label: "" }])

  const [phone_input, setPhone_input] = useState();
  const [next_step, setNext_step] = useState(0);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [city_real_id, setCity_real_id] = useState();
  const [countryId, setCountryId] = useState();
  const [hCId, setHCId] = useState("");
  const [cityId, setCityId] = useState();
  const [cityName, setCityName] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [spinnerBool, setSpinnerBool] = useState(false)
  const [alpha2, setAlpha2] = useState("fr")
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [address, setAddress] = useState("");
  const [shortCountryName, setShortCountryName] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [selectedDateIsVerified, setSelectedDateIsVerified] = useState(false);


  const [formValues, setFormValues] = useState({
    lastname: "",
    firstname: "",
    birth: "",
    gender: "",
    country: null,
    city: null,
    phone: "",
    address: "",
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    lastname: false,
    firstname: false,
    birth: false,
    gender: false,
    country: false,
    city: false,
    phone: false,
    address: false,
    email: false,
    password: false,
  });

  const { t, i18n } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const onTakeRDV = () => {
    setShowAlert(true)
  }

  const onCancel = () => {
    setShowAlert(false);
  }

  const oncompaniesOrPers = (id) => {
    dispatch({ type: "COM_OR_PAR", payload: { id } });
    history.push('/appointment')
  }

  const onConfirm = () => {
    history.push('/appointment')
  }

  useEffect(() => {
    // allCountries();
  }, [next_step]);

  // const allCountries = () => {
  //   dispatch(CountryService.getAllCountries())
  //     .then((result) => {
  //       let data = result.data;
  //       let options = data.map((value, index) => (
  //         {
  //           value: value.real_id,
  //           label: value.name_fr,
  //           hash_id: value.id,
  //           alpha2: value.alpha2
  //         }
  //       ));
  //       setOptionsCountries(options);
  //     })
  //     .catch(({ data }) => {
  //       console.log('errors ', data);
  //     });
  // }

  // const allCitiesByCountryId = (id) => {
  //   dispatch(CityService.findCitiesByCountryId(id))
  //     .then((result) => {
  //       let dataT = result.data.data;
  //       let optionsVal = dataT.map((value, index) => (
  //         {
  //           value: value.real_id,
  //           label: value.name,
  //           hash_id: value.id
  //         }
  //       ));

  //       setOptionsCities(optionsVal);

  //     })
  //     .catch((err) => {
  //       console.log('errors ', err);
  //     });
  // }

  // const allCities = (hash_id) => {
  //   dispatch(CityService.getCitiesCountry(hash_id))
  //     .then((result) => {
  //       let data = result.data.cities.data;
  //       let options = data.map((value, index) => (
  //         {
  //           value: value.real_id,
  //           label: value.name,
  //           hash_id: value.id
  //         }
  //       ));
  //       setOptionsCities(options);
  //     })
  //     .catch(({ data }) => {
  //       console.log('errors ', data);
  //     });
  // }


  // const searchCity = (name) => {
  //   dispatch(CityService.searchCity(hCId, name))
  //     .then((result) => {
  //       let dataT = result.data.data;
  //       let optionsVal = dataT.map((value, index) => (
  //         {
  //           value: value.id,
  //           label: value.name
  //         }
  //       ));

  //       setOptionsCities(optionsVal);

  //     })
  //     .catch((err) => {
  //       console.log('errors ', err);
  //     });
  // }

  const registerUser = () => {
    setSpinnerBool(true);
    const postregister = {
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      email: formValues.email,
      password: formValues.password,
      gender: formValues.gender,
      birth: formValues.birth,
      phone: formValues.phone,
      // adresse: alpha2 === 'cm' ? formValues.address : ".",
      address: address,
      // country_id: countryId,
      country_id: 69,
      isAdmin: "0",
      // city_id: cityId
      city_id: 61325
    }

    pageActiv === true ?
      dispatch(AuthService.registerSpecialist(postregister))
        .then((result) => {
          setSpinnerBool(false)
          Swal.fire({
            icon: 'success',
            title: 'Registration Sucessfull',
            text: 'Welcome Mr.' + formValues.firstname + ' ' + formValues.lastname
          })
          history.push("/login");
        })
        .catch((err) => {
          if (err.statusCode.response.data.errors.email[0] === "The email has already been taken.") {
            // alert("le mail " + formValues.email + " existe déjà")
            setError(0)
            setSpinnerBool(false);
          }
        })

      :

      dispatch(AuthService.register(postregister))
        .then((result) => {
          setSpinnerBool(false);
          Swal.fire({
            icon: 'success',
            title: 'Registration Sucessfull',
            text: 'Welcome Mr.' + formValues.firstname + ' ' + formValues.lastname
          })
          history.push("/login");
        })
        .catch((err) => {

          if (err.notNetwork === true) {
            setError(2)
            setSpinnerBool(false);
          } else {
            if (err.statusCode.response.data.errors.email[0] === "The email has already been taken.") {
              // alert("le mail " + formValues.email + " existe déjà")
              setError(0)
              setSpinnerBool(false);
            }
          }
        })
  }

  //   const { user, registrationError, regLoading } = useAppSelector(state => ({
  //     user: state.Register.user,
  //     registrationError: state.Register.registrationError,
  //     regLoading: state.Register.loading,
  //     isUserRegistered: state.Register.isUserRegistered,
  //   }));

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    // const formData = {
    //   ...formValues,
    //   ...values,
    //   phone: phone_input,
    //   cityId: city_real_id,
    // };
    // dispatch(registerUser(formData));
  };

  //   const { userProfile, loading } = useProfile();

  //   if (userProfile && !loading) {
  //     return <Redirect to={{ pathname: "/dashboard" }} />;
  //   }

  // const handleCountryChange = (event, newValue) => {
  //   if (newValue) {
  //     setFormValues({ ...formValues, country: newValue.label });
  //     setCountryId(newValue.value);
  //     // allCities(newValue.hash_id);
  //     allCitiesByCountryId(newValue.hash_id)
  //     setAlpha2(newValue.alpha2.toLowerCase())
  //     setHCId(newValue.hash_id);
  //     setCountryVal(newValue)
  //   } else {
  //     setFormValues({ ...formValues, country: null });
  //     setCities([]);
  //   }
  // };

  // const handleCityChange = (event, newValue) => {

  //   if (typeof newValue === 'string' || newValue instanceof String) {
  //     searchCityWithCallback(newValue)
  //   } else {
  //     setFormValues({ ...formValues, city: newValue.label })
  //     setCityId(newValue.value);
  //     // console.log("Lud ", newValue) 
  //   }

  // };

  // const searchCityWithCallback = useCallback(
  //   (name) => {
  //     // Annulez le délai existant si l'utilisateur continue de taper
  //     if (searchTimeout) {
  //       clearTimeout(searchTimeout);
  //     }
  //     // Configurez un nouveau délai pour déclencher la recherche après 500 ms
  //     const timeoutId = setTimeout(() => {
  //       if (name.length > 0) {
  //         searchCity(name);
  //       } else {
  //         searchCity("");
  //       }
  //     }, 500);
  //     setSearchTimeout(timeoutId);
  //   },
  //   [dispatch, searchTimeout]
  // );

  const handlePhoneChange = value => {
    if (value !== '') {
      setFormValues({ ...formValues, phone: value });
    } else {
      setFormValues({ ...formValues, phone: "" });
    }
  };

  const handleInputChange = event => {
    const { name, value } = event.target || {};

    if (name === 'birth') {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      // Vérification si la date est supérieure à la date actuelle
      if (selectedDate > currentDate) {
        setSelectedDateIsVerified(true);
      } else {
        setSelectedDateIsVerified(false);
        setFormValues({
          ...formValues,
          [name]: value,
        });
      }
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
  }

  };

  const go_next_step = () => {
    const newFormErrors = { ...formErrors };
    let hasErrors = false;
    if (next_step === 0) {
      if (!formValues.lastname) {
        newFormErrors.lastname = true;
        hasErrors = true;
      } else {
        newFormErrors.lastname = false;
      }

      if (!formValues.firstname) {
        newFormErrors.firstname = true;
        hasErrors = true;
      } else {
        newFormErrors.firstname = false;
      }

      if (!formValues.birth) {
        newFormErrors.birth = true;
        hasErrors = true;
      } else {
        newFormErrors.birth = false;
      }

      if (!formValues.gender) {
        newFormErrors.gender = true;
        hasErrors = true;
      } else {
        newFormErrors.gender = false;
      }
      setFormErrors(newFormErrors);
      if (!hasErrors) {
        setNext_step(cur => cur + 1);
      }
    } else if (next_step === 1) {

      if (!formValues.phone) {
        newFormErrors.phone = true;
        hasErrors = true;
      } else {
        newFormErrors.phone = false;
      }

      if (!formValues.address) {
        newFormErrors.address = true;
        hasErrors = true;
      } else {
        newFormErrors.address = false;
      }
      setFormErrors(newFormErrors);
      if (!hasErrors) {
        setNext_step(cur => cur + 1);
      }
    } else if (next_step === 2) {
      if (!formValues.email) {
        newFormErrors.email = true;
        hasErrors = true;
      } else {
        newFormErrors.email = false;
      }

      if (!formValues.password) {
        newFormErrors.password = true;
        hasErrors = true;
      } else {
        newFormErrors.password = false;
      }
      setFormErrors(newFormErrors);

      if (!hasErrors) {
        setNext_step(cur => cur + 1);
      }
    }
  };

  const go_preview_step = () => {
    setNext_step(cur => cur - 1);
  };

  const handleAddressGeocode = (addr) => {
    setAddress(addr);
    formValues.address = addr;
    setFormValues({
      ...formValues
    });
  }

  const getProfileschedulesWithMyLocation = (coord) => {
    setShortCountryName(coord.short_countryName);
  }

  return (
    <>
      {/* <NonAuthLayoutWrapper
        pageActiv={pageActiv}> */}
      <Row className=" justify-content-center mb-3">
        <Col sm={12} lg={11} xl={11} className="col-xxl-11 register_page">
          {/* <div className="py-md-4 py-4"> */}
          <div>
            {/* <AuthHeader title={pageActiv ? "Veuillez créer votre compte." : "Cher parent veuillez créer votre compte."} /> */}

            {error === 0 ? <Alert color="danger">le mail {formValues.email} existe déjà</Alert> : null}
            {error === 2 ? <Alert color="danger">Veuillez verifier votre connexion internet</Alert> : null}

            <AvForm
              className="form-horizontal"
              id="register-form"
              onValidSubmit={(e, values) => {
                handleValidSubmit(e, values);
              }}
            >
              {props.user && props.user ? (
                <Alert color="success">Register User Successfully</Alert>
              ) : null}
              {/* {regLoading && <Loader />} */}

              {next_step === 0 && (
                <section>
                  <div className="col-12" style={{ margin: '0 0 15px 0' }}>
                    <Progress value="40">1/3</Progress>
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-sm-12 col-lg-12 col-xl-6 mb-5">
                      <AvField
                        name="firstname"
                        label="Prenom"
                        type="text"
                        value={props.postregister.firstname}
                        required
                        placeholder="Entrez votre prénom"
                        className="field-class"
                        onChange={handleInputChange}
                        style={{
                          borderColor: formErrors.firstname ? "red" : null,
                        }}
                      />
                      {formErrors.firstname && (
                        <div style={{ color: "red" }}>
                          Veuillez entrer votre nom
                        </div>
                      )}
                    </div>
                    <div className="col-xxl-6 col-sm-12 col-lg-12 col-xl-6 mb-5">
                      <AvField
                        name="lastname"
                        label="Nom"
                        type="text"
                        required
                        value={formValues.lastname}
                        placeholder="Entrez votre nom"
                        className="field-class"
                        onChange={handleInputChange}
                        style={{
                          borderColor: formErrors.lastname ? "red" : null,
                        }}
                      />
                      {formErrors.lastname && (
                        <div style={{ color: "red" }}>
                          Veuillez entrer votre prénom
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xxl-6 col-sm-12 col-lg-12 col-xl-6 mb-5">
                      <AvForm>
                        <AvField
                          name="birth"
                          label="Date de naissance"
                          type="date"
                          className="hidden-date-input"
                          value={formValues.birth}
                          required
                          onChange={handleInputChange}
                          style={{ borderColor: formErrors.birth ? "red" : null }}
                        />
                        {/* <DateRange className="date-icon" /> */}
                      </AvForm>
                      {selectedDateIsVerified === true
                        ?
                        <div style={{ color: "red" }}>
                          La date de naissance ne peut pas être supérieure à la date actuelle
                        </div>
                        :
                        formErrors.birth && (
                          <div style={{ color: "red" }}>
                            Veuillez entrer votre date de naissance
                          </div>
                        )}
                    </div>

                    <div className="col-xxl-6 col-sm-12 col-lg-12 col-xl-6 mb-5">
                      <label htmlFor="gender_unit">Sexe<span className="text-danger">*</span></label>
                      <select
                        value={formValues.gender}
                        onChange={handleInputChange}
                        style={{ width: '100%' }}
                        name="gender"
                        id="gender_unit"
                        required>
                        <option value="">Sexe</option>
                        <option value="M">Masculin</option>
                        <option value="F">Feminin</option>
                      </select>

                      {formErrors.gender && (
                        <div style={{ color: "red" }}>
                          Veuillez choisir le sexe
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-green-t waves-effect waves-light"
                      type="button"
                      style={{ width: '150px', float: 'right', 'border-radius': '10px' }}
                      onClick={go_next_step}
                    >
                      Suivant
                    </button>
                  </div>
                </section>
              )}

              {next_step === 1 && (
                <section>
                  <div className="col-12" style={{ margin: '0 0 15px 0' }}>
                    <Progress value="60">2/3</Progress>
                  </div>
                  <div className="row justify-content-center my-auto">
                    <div className="col-xxl-9 col-sm-12 col-lg-12 col-xl-9 mb-3">
                      <label for="inputEmail4">Adresse</label>

                      <SearchBar
                        name="address"
                        address={address}
                        handleAddressGeocode={handleAddressGeocode}
                        getProfileschedulesWithMyLocation={getProfileschedulesWithMyLocation}
                      />

                      {formErrors.address && (
                        <div style={{ color: "red" }}>
                          Veuillez remplir l'adresse
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row justify-content-center my-auto">
                    <div className="col-xxl-9 col-sm-12 col-lg-9 col-xl-9 mb-3">
                      <label for="inputEmail4">Phone</label>
                      <PhoneInput
                        country={!!shortCountryName ? shortCountryName : alpha2}
                        Defaultvalue={formValues.phone}
                        value={formValues.phone}
                        onChange={(e) => handlePhoneChange(e)}
                        placeholder={formValues.phone !== '' ? formValues.phone : "— Téléphone"}
                        className="field-class"
                        style={{
                          borderColor: formErrors.phone ? "red" : null,
                        }}
                        required
                      />
                      {formErrors.phone && (
                        <div style={{ color: "red", margin: '20px 0 0 0' }}>
                          Veuillez entrer votre numéro de téléphone
                        </div>
                      )}
                    </div>

                  </div>


                  <div className="col-12 d-flex flex-row mt-5  justify-content-center">
                    <div className="p-2">
                      <button
                        className="btn btn-green-t waves-effect waves-light"
                        type="button"
                        style={{ width: '150px', float: 'left', 'border-radius': '10px' }}
                        onClick={go_preview_step}
                      >
                        Précedent
                      </button>
                    </div>
                    <div className="p-2 ">
                      <button
                        className="btn btn-green-t waves-effect waves-light"
                        type="button"
                        style={{ width: '150px', float: 'right', 'border-radius': '10px' }}
                        onClick={go_next_step}
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                </section>
              )}

              {next_step === 2 && (
                <section>
                  <div className="col-12" style={{ margin: '0 0 15px 0' }}>
                    <Progress value="100">3/3</Progress>
                  </div>

                  <div className="row justify-content-center my-auto">
                    <div className="col-xxl-9 col-sm-12 col-lg-9 col-xl-9 mb-3">
                      <AvField
                        id="email"
                        name="email"
                        label="Email"
                        className="form-control field-class"
                        placeholder="Entrez votre email"
                        type="email"
                        // required
                        onChange={handleInputChange}
                        style={{
                          borderColor: formErrors.email ? "red" : null,
                        }}
                        validate={{
                          required: { value: true, errorMessage: "Veuillez entrer votre e-mail." },
                          email: { value: true, errorMessage: "Adresse email invalide." }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center my-auto">
                    <div className="col-xxl-9 col-sm-12 col-lg-9 col-xl-9">
                      <AvField
                        name="password"
                        label="Mot de passe"
                        type={showPassword ? "text" : "password"}
                        // required
                        placeholder="Entrez votre mot de passe"
                        className="field-class"
                        onChange={handleInputChange}
                        style={{
                          borderColor: formErrors.password ? "red" : null,
                        }}

                        validate={{
                          required: { value: true, errorMessage: "Veuillez entrer votre mot de passe" },
                          minLength: { value: 6, errorMessage: "Le mot de passe doit comporter au moins 6 caractères" }
                        }}

                      />

                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        style={{ right: '10px', display: 'flex', top: '25px' }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        data-password={showPassword ? "true" : "false"}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </button>

                    </div>
                  </div>

                  <div className="col-12 d-flex flex-row  justify-content-center">
                    <div className="p-2">
                      <button
                        className="btn btn-green-t w-100 waves-effect waves-light"
                        type="button"
                        style={{ width: '150px', float: 'left', 'border-radius': '10px' }}
                        onClick={go_preview_step}
                      >
                        Précedent
                      </button>
                    </div>
                    <div className="p-2">
                      <button
                        className="btn btn-green-t w-100 waves-effect waves-light"
                        type="submit"
                        style={{ width: '150px', float: 'right', 'border-radius': '10px' }}
                        onClick={registerUser}
                      >
                        {spinnerBool ?
                          <>
                            <Spinner animation="grow" variant="light" size="sm" />
                            <span> &nbsp;&nbsp;Loading...</span>
                          </>
                          :
                          <span>Enregistrer</span>
                        }

                      </button>
                    </div>
                  </div>

                  <div className="mt-1 text-center">
                    <p className="mb-0">
                      En vous inscrivant, vous acceptez les {" "}
                      <Link to="#" className="text-primary">
                        Conditions d'utilisation
                      </Link> &nbsp;
                      de Wemaza
                    </p>
                  </div>
                </section>
              )}
            </AvForm>

            {/* <div className="mt-3 text-center text-muted">
              <p>
                Vous avez déjà un compte ?{" "}
                <Link
                  to="/login"
                  className="fw-medium text-decoration-underline bleuCustomer"
                >
                  Se connecter
                </Link>
              </p>
            </div> */}
          </div>
        </Col>

      </Row>
      {/* </NonAuthLayoutWrapper > */}
    </>
  );
};

export default RegisterW;
