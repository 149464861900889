import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import fr from 'ree-validate/dist/locale/fr';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import ProductService from '../../../../services/product';
import AuthService from '../../../../services/auth';
import { lightTheme, darkTheme } from '../../../../js/theme';
import { GlobalStyles } from '../../../../js/global';
import { changeNavigatorTheme, auth_t } from '../../../../store/actions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RegisterW from './RegisterW.js';
import NewHeader from '../../../layout/NewHeader5.jsx';
import MobileCategoriesMenu from '../../../layout/HeaderComponents/MobileCategoriesMenu.jsx';

class Page extends Component {
  constructor(props) {
    super();
    this.validator = new ReeValidate.Validator({
      lastname: 'required',
      firstname: 'required',
      cni_t: 'required',
      address: 'required',
      email: 'required|email',
      password: 'required|min:6',
      country: 'required',
      city: 'required',
      description: 'required',
    });
    this.validator.localize('fr', fr)
    this.state = {
      postregister: {
        lastname: '',
        firstname: '',
        country: null,
        address: '',
        email: '',
        phone: '',
        password: '',
        description: '',
        newsletter: false,
        privacy_police: null,
        sexe: '',
        date: new Date(),
        cni_t: ''
      },
      errors: this.validator.errors,
      offline: false,
      theme_bool: false,
      langBool: true,
      def_theme: '',
      eye_password_bool: true,
      checked: true,
      checked_2: false,
      checked_3: false,
      checked_switch: true,
      show_t: 'hidden_t',
      text_or_not: false,
      isLoading: false,
      first_nav_btn_t: true,
      second_nav_btn_t: false,
      dir_btn_suiv_t: false,
      show_first_tab: true,
      show_second_tab: false,
      dir_btn_prec_2_t: false,
      isValue: false,
      email_exit: false,
      email_not_val: '',
      options_fr: [
        { value: 'en', label: "Anglais" }
      ],
      options_eng: [
        { value: 'fr', label: "Francais" }
      ],
      options_devise: [
        { value: '€', label: "Dollar" }
      ],
      option_country: [
        { value: 'cmr', label: "Cameroun" },
        { value: 'cg', label: "Congo k" }
      ],
      pays_value: '',
      country_value: '',
      option_city: [
        { value: 'Dla', label: "Douala" },
        { value: 'Ynde', label: "Yaounde" }
      ],
      city_id: null,
      option_sexe: [
        { value: 'Masculin', label: 'Masculin' },
        { value: 'Feminin', label: 'Feminin' }
      ],
      toggleBool_t: false,
      allCategories: []
    };
    this.theme_botton_D = this.theme_botton_D.bind(this);
    this.theme_botton_W = this.theme_botton_W.bind(this);
    this.toggle = this.toggle.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.checked_t = this.checked_t.bind(this);
    this.checked_rad1_t = this.checked_rad1_t.bind(this);
    this.checked_rad2_t = this.checked_rad2_t.bind(this);
    this.checked_rad3_t = this.checked_rad3_t.bind(this);
    this.for_remove_js = this.for_remove_js.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this)
    // this.post_login_user = this.post_login_user.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.def_theme === '') {
      // this.check_theme();
    }
  }
  componentDidMount() {
    this.props.dispatch(auth_t({ __iA: true, __iV: true }));
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: true });
    }
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: false });
    }
    if (this.props.i18n.language === 'fr-FR' || 'fr') {
      this.setState({ langBool: true })
    }
    if (this.props.i18n.language === 'en') {
      this.setState({ langBool: false })
    }
    this.allCountries()
    this.allCategories();
  }


  allCategories = () => {
    this.props.dispatch(ProductService.getAllCategories())
      .then((result) => {
        let data = result.data;

        const categories = this.buildCategoryHierarchy(data);

        let optionsHome = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));

        this.setState({ option_categorie: optionsHome, allCategories: categories });


        let initResp = [{ value: '', label: 'Tout' }]
        let optionsResp = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
        initResp = initResp.concat(optionsResp);
        let options = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  // handleSubmit(e) {
  //   e.preventDefault();
  //   this.setState({email: "ludovicmoukouri@yahoo.fr"})
  // }
  handleClick(lang) {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    this.setState({ langBool: !this.state.langBool });
  }
  checked_t() {
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  checked_rad1_t() {
    if (this.state.checked) {
      this.setState({ checked: true, checked_2: false, checked_3: false });
    }
    this.setState({ checked_2: false, checked_3: false });
  }
  checked_rad2_t() {
    if (this.state.checked_2) {
      this.setState({ checked: false, checked_2: true, checked_3: false });
    }
    this.setState({ checked: false, checked_3: false });
  }
  checked_rad3_t() {
    if (this.state.checked_3) {
      this.setState({ checked: false, checked_2: false, checked_3: true });
    }
    this.setState({ checked: false, checked_2: false });
  }
  toggle() {
    this.setState({ eye_password_bool: !this.state.eye_password_bool })
  }

  check_theme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // console.log('🎉 Dark mode is supported');
      this.setState({ def_theme: 'dark', theme_bool: true, checked_switch: false })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      // console.log('🎉 light mode is supported');
      this.setState({ def_theme: 'light', theme_bool: true, checked_switch: true })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
      // console.log('ciphertext ', ciphertext)
    }
  }
  for_remove_js(e) {
    e.preventDefault();
  }
  theme_botton_D(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'dark', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  theme_botton_W(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'light', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  //  post_login_user() {
  //    this.setState({isLoading: true});
  //    let credentials = {email: this.state.email, password: this.state.password}
  //    this.props.dispatch(AuthService.login(credentials))
  //      .then((result)  => {
  //        this.props.history.push("/");
  //        this.setState({isLoading: false})
  //      })
  //      .catch(({data}) => {
  //          this.setState({show_t: 'show_t'});
  //          console.log('errors ', data);
  //          this.setState({isLoading: false})
  //      });
  // }
  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    const { postregister } = this.state;

    postregister[name] = value;
    if (name === 'email') {
      this.setState({ email_exit: false })
    }
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors, postregister })
      });
    this.check_first_name(postregister)
  }
  check_first_name = (postregister) => {
    if ((postregister.lastname !== '' &&
      postregister.firstname !== '' &&
      postregister.country !== '' &&
      postregister.phone !== '' &&
      postregister.date !== '' &&
      postregister.sexe !== ''
    )) {
      this.setState({ dir_btn_suiv_t: true, isValue: true })
    }
  }
  handleChangeCheck = (e, label) => {
    const value = e.target.value;
    const { postregister } = this.state;
    if (label === 'newsletter') {
      postregister[label] = value;
      this.setState({ postregister })
    }
  }

  allCountries() {
    this.props.dispatch(AuthService.getAllCountries())
      .then((result) => {

        let data = result.data;
        let options = data.map((value, index) => (

          {
            value: value.real_id,
            label: this.state.langBool === true ? value.name_fr : value.name_en
          }
        ));
        this.setState({
          isLoading: false,
          showAlert: false,
          alert2: null,
          option_country: options
        });
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  handleSubmit(postregister) {
    this.setState({ isLoading: true });
    let data = {
      lastname: postregister.lastname,
      firstname: postregister.firstname,
      country_id: postregister.country,
      address: postregister.address,
      email: postregister.email,
      phone: postregister.phone,
      password: postregister.password,
      gender: postregister.sexe,
      birth: postregister.date
    }

    this.props.dispatch(AuthService.register(data))
      .then((result) => {
        this.props.history.push("/login");
      })
      .catch((err) => {

        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            show_t: 'show_t',
            isLoading: false
          });
        }

      })
  }

  async validateAndSubmit(e) {
    e.preventDefault()

    const { postregister } = this.state
    const { errors } = this.validator

    const valid = this.validator.validateAll(postregister)

    if (valid) {
      this.handleSubmit(postregister)
    } else {
      this.setState({ errors })
    }
  }

  precedent_t = (e, isValue, label) => {
    if (label === 'premier' && isValue === true) {
      this.setState({
        show_perso_sa: false,
        show_loca_sa: false,
        show_params_sa: false,
      })
    }
    if (label === 'second' && isValue === true) {
      this.setState({
        show_second_tab: false,
        show_first_tab: true,
        dir_btn_suiv_t: true,
        first_nav_btn_t: true,
        second_nav_btn_t: false,
        dir_btn_prec_2_t: false,
        save_t: false
      })
    }
    e.preventDefault();
  }


  Suivant_t = (e, isValue, label) => {
    const { postregister } = this.state;
    if (label === 'premier' && isValue === true) {
      this.setState({
        show_second_tab: true,
        show_first_tab: false,
        first_nav_btn_t: false,
        second_nav_btn_t: true,
        dir_btn_suiv_2_t: false,
        dir_btn_prec_2_t: true,
      })
    }
    if (label === 'second' && isValue === true) {
      this.setState({
        show_perso_sa: false,
        show_loca_sa: false,
        show_params_sa: true,
        first_nav_btn_t: false,
        second_nav_btn_t: false,
        isValue_suiv_sec: true,
        error_bool: false,
      })
    }
    e.preventDefault();
  }

  gotToParentValue = (value, label) => {
    const { postregister } = this.state;
    if (label === "Francais" || label === "French") {
      const options_fr = [{ value: 'en', label: "Anglais" }];
      const options_eng = [{ value: 'fr', label: "Francais" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("fr");
    }
    if (label === "Anglais" || label === "English") {
      const options_fr = [{ value: 'en', label: "English" }];
      const options_eng = [{ value: 'fr', label: "French" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("en");
    }
    if (label === "Dollar") {
      this.setState({
        device_data: value
      })
    }
    if (label === "date") {
      postregister['date'] = value
      this.setState({ postregister }, () => this.check_first_name(postregister))
    }
    if (label === "phone") {
      postregister['phone'] = value
      this.setState({ postregister }, () => this.check_first_name(postregister))
    }
    if (label === "sexe") {
      postregister['sexe'] = value
      this.setState({ postregister }, () => this.check_first_name(postregister))
    }
    if (label === "country") {
      postregister['country'] = value
      this.setState({ postregister }, () => this.check_first_name(postregister))
    }
  }
  saveCount = (e) => {
    e.preventDefault();
  }

  onToggle_t = () => {
    this.setState({ toggleBool_t: !this.state.toggleBool_t });
  }

  onLogout = () => {
    this.props.dispatch(AuthService.logout())
      .then((result) => {
        this.props.history.push("/");
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  render() {
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);

    const { t } = this.props;
    let { errors } = this.state;

    return (
      <>
        {/* <GlobalStyles /> */}
        {/* <Helmet>
            <script src="../../../assets/js/core/popper.min.js" type="text/javascript" />
            <script src="../../../assets/js/core/bootstrap.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/chart.js/chart.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/datatables/datatables.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/bootstrap-notify/bootstrap-notify.min.js" type="text/javascript" />
            <script src="../../../assets/js/plugin/sweetalert/sweetalert.min.js" type="text/javascript" />

            <script src="../../../assets/js/atlantis.min.js" type="text/javascript" />

            <script src="../../../assets/js/setting-demo.js" type="text/javascript" />
            <script src="../../../assets/js/demo.js" type="text/javascript" />
          </Helmet> */}
        <div className="wrapper register_t">

        <NewHeader
            allCategories={this.state.allCategories}
            fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
            cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
            idProf={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
            // gotToParentValue={this.searchCityWithCallback}
            isShowSearch_t={false}
            // searchProducts_t={this.state.searchProducts_t}
            // searchNameWithCallback={this.searchNameWithCallback}
            onLogout={this.onLogout}
            onToggle_t={this.onToggle_t}

            // propsCustom={this.props}
            // allProductsBycategories={this.allProductsBycategories}
            // includesForAllProducts_t={this.state.includesForAllProducts_t}
          />

          <div id="side-bar"
            className={this.state.toggleBool_t ? "side-bar header-two show" : "side-bar header-two"}
          >
            <button className="close-icon-menu"
              onClick={this.onToggle_t}>
              <IconButton aria-label="close">
                <CloseIcon />
              </IconButton>
            </button>
            <MobileCategoriesMenu
              prodId_t={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
              onLogout={this.onLogout}
              allCategories={this.state.allCategories}
              isShowSearch_t={true}
              fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
            />

          </div>

          {/* <div className="showAndHiddenRespDesk_t">
            <BandeBlanche />

            <HeaderUsers
              langBool={this.state.langBool}
              for_remove_js={this.for_remove_js}
              checked_switch={this.state.checked_switch}
              theme_botton_D={this.theme_botton_D}
              theme_botton_W={this.theme_botton_W}
              checked_t={this.checked_t}
              cartValue={this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
            />
            <HeaderTop
              langBool={this.state.langBool}
              for_remove_js={this.for_remove_js}
              checked_switch={this.state.checked_switch}
              theme_botton_D={this.theme_botton_D}
              theme_botton_W={this.theme_botton_W}
              checked_t={this.checked_t}
              options_fr={this.state.options_fr}
              options_eng={this.state.options_eng}
              options_devise={this.state.options_devise}
              device_data={this.state.device_data}
              gotToParentValue={this.gotToParentValue}
              logo_noir={false}
              fName={this.props.prodId_t.__pfn !== undefined ? this.props.prodId_t.__pfn : ''}
            />

          </div>

          <div className="showAndHiddenResp_t">
            <HeaderUsersMobile
              langBool={this.state.langBool}
              for_remove_js={this.for_remove_js}
              checked_switch={this.state.checked_switch}
              theme_botton_D={this.theme_botton_D}
              theme_botton_W={this.theme_botton_W}
              checked_t={this.checked_t}
              cartValue={this.props.cartValue}
            />
          </div> */}



          <div className="container-fluid h-100 img_right_t">
            <div className="row med_resp_register_t">
              <div className="col col-sm-3 showAndHiddenRespDesk_t"></div>
              <div className={this.state.show_second_tab ? "col div_register_t div_register_t2" : "col div_register_t div_register_t1"}>

                <p className="header_f_reg_t">
                  Créer mon compte
                </p>
                <form id="sign_on" onSubmit={this.validateAndSubmit}>
                  <RegisterW
                    postdata={this.state.postregister}
                    show_first_tab={this.state.show_first_tab}
                    errors={errors}
                    postregister={this.state.postregister}
                    handleChange={this.handleChange}
                    option_sexe={this.state.option_sexe}
                    gotToParentValue={this.gotToParentValue}
                    first_nav_btn_t={this.state.first_nav_btn_t}
                    second_nav_btn_t={this.state.second_nav_btn_t}
                    dir_btn_prec_2_t={this.state.dir_btn_prec_2_t}
                    precedent_t={this.precedent_t}
                    Suivant_t={this.Suivant_t}
                    dir_btn_suiv_t={this.state.dir_btn_suiv_t}
                    show_second_tab={this.state.show_second_tab}
                    option_country={this.state.option_country}
                    option_city={this.state.option_city}
                    eye_password_bool={this.state.eye_password_bool}
                    toggle={this.toggle}
                    isLoading={this.state.isLoading}
                    isValue={this.state.isValue}
                    offline={this.state.offline}
                    email_exit={this.state.email_exit}
                    email_not_val={this.state.email_not_val}
                  />

                  <div className="row">
                    <div className="col-12 col-lg-3"></div>
                    <div className="col-12 col-lg-12 belogin_w text-center">
                      <span className="mt-3 text-center" style={{ 'font-size': '15px' }}>
                        Vous avez déjà un compte ?{" "}
                      </span>
                      <span style={{ 'font-weight': '700', 'font-size': '16px', color: "#1e2431" }}>
                        <Link
                          to="/login"
                          className="fw-medium text-decoration-underline bleuCustomer"
                        >
                          Se connecter
                        </Link>
                      </span>
                    </div>
                    <div className="col-12 col-lg-3"></div>
                  </div>

                </form>
              </div>
              <div className="col col-sm-3 showAndHiddenRespDesk_t"></div>

            </div>
          </div >
        </div >
      </>
    );
  }
}
export default withTranslation()(Page);