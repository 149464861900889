import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from "react-router-dom";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Settings from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import Logout from '@mui/icons-material/Logout';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

const MobileCategoriesMenu = (props) => {
    const [openCategories, setOpenCategories] = useState({});
    const history = useHistory();

    const handleToggleCategory = (index) => {
        setOpenCategories((prevOpenCategories) => ({
            ...prevOpenCategories,
            [index]: !prevOpenCategories[index],
        }));
    };

    const handleNavigation = (path) => {
        if (path) {
            history.push(path);
            props.isShowSearch_t
                ? props.allProductsBycategories(`${path}?include=shop.provider.user.country,annexesImages`)
                : console.log('');
        }
    };

    const renderSubcategories = (subcategories, categoryIndex, level = 1) => {
        return (
            <ul className={`submenu mm-collapse ${openCategories[categoryIndex] ? 'mm-show' : ''}`}>
                {subcategories.map((sub, index) => {
                    const currentIndex = `${categoryIndex}-${index}`;
                    const hasSubcategories = sub.subcategories.length > 0;
                    return (
                        <li key={currentIndex} style={{ marginLeft: level * 10 }}>
                            <a
                                href="javascript:void();"
                                className="mobile-menu-link"
                                onClick={() =>
                                    hasSubcategories
                                        ? handleToggleCategory(currentIndex)
                                        : handleNavigation("/categories" + sub.path)
                                }
                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} // Ajout du style ici
                            >
                                <span>{sub.name}</span>
                                {hasSubcategories && (
                                    openCategories[currentIndex] ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                )}
                            </a>
                            {hasSubcategories && openCategories[currentIndex] && (
                                renderSubcategories(sub.subcategories, currentIndex, level + 1)
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <div className="mobile-menu-nav-area tab-nav-btn mt--60">
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                        className="nav-link active"
                        id="nav-home-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                    >
                        Menu
                    </button>
                    <button
                        className="nav-link"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                    >
                        Category
                    </button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex={0}>
                    {/* mobile menu area start */}
                    {
                        !props.fName
                            ?
                            <p
                                style={{
                                    'font-size': '16px',
                                    'line-height': '1.3',
                                    fontWeight: '700',
                                    'margin-top': '30px'
                                }}
                            >
                                Veuillez vous connecter
                            </p>
                            :

                            <div className="mobile-menu-main">
                                <nav className="nav-main mainmenu-nav mt--30">
                                    <ul className="mainmenu metismenu" id="mobile-menu-active">
                                        <li>
                                            <ListItem
                                                component="div"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '12px', // Adjust the space between the icon and the text
                                                    padding: '0', // Add padding for better spacing within each item
                                                    marginBottom: '2px', // Adjust the space between the menu items
                                                }}
                                            >
                                                <ListItemIcon sx={{ minWidth: 'unset' }}>
                                                    <ShoppingBasketIcon sx={{ color: '#2C3C28', fontSize: '18px' }} />
                                                </ListItemIcon>
                                                <Link to="/orders" className="main"
                                                    style={{ textDecoration: 'none', color: '#6e777d', fontSize: '16px', 'line-height': '1.3' }}>
                                                    Mes commandes en cours
                                                </Link>
                                            </ListItem>
                                        </li>
                                        <li>
                                            <ListItem
                                                component="div"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '12px',
                                                    padding: '0',
                                                    marginBottom: '2px', // Adjust the space between the menu items
                                                }}
                                            >
                                                <ListItemIcon sx={{ minWidth: 'unset' }}>
                                                    <Settings sx={{ color: '#2C3C28', fontSize: '22px' }} />
                                                </ListItemIcon>
                                                <Link to="/setting" className="main"
                                                    style={{ textDecoration: 'none', color: '#6e777d', fontSize: '16px', 'line-height': '1.3' }}>
                                                    Réglages et confidentialité
                                                </Link>
                                            </ListItem>
                                        </li>
                                        <li>
                                            <ListItem
                                                component="div"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '12px',
                                                    padding: '0',
                                                    marginBottom: '2px', // Adjust the space between the menu items
                                                }}
                                            >
                                                <ListItemIcon sx={{ minWidth: 'unset' }}>
                                                    <HistoryIcon sx={{ color: '#2C3C28', fontSize: '22px' }} />
                                                </ListItemIcon>
                                                <Link to="/history" className="main"
                                                    style={{ textDecoration: 'none', color: '#6e777d', fontSize: '16px', 'line-height': '1.3' }}>
                                                    Historique de Commandes
                                                </Link>
                                            </ListItem>
                                        </li>
                                        <li>
                                            <ListItem
                                                component="div"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '12px',
                                                    padding: '0',
                                                    marginBottom: '2px', // Adjust the space between the menu items
                                                }}
                                            >
                                                <ListItemIcon sx={{ minWidth: 'unset' }}>
                                                    <PersonIcon sx={{ color: '#2C3C28', fontSize: '22px' }} />
                                                </ListItemIcon>
                                                <Link to={`/profile/${props.prodId_t}`} className="main"
                                                    style={{ textDecoration: 'none', color: '#6e777d', fontSize: '16px', 'line-height': '1.3' }}>
                                                    Mon profil
                                                </Link>
                                            </ListItem>
                                        </li>
                                        <li>
                                            <ListItem
                                                component="div"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '12px',
                                                    padding: '0',
                                                    marginBottom: '2px', // Adjust the space between the menu items
                                                }}
                                            >
                                                <ListItemIcon sx={{ minWidth: 'unset' }}>
                                                    <Logout sx={{ color: '#2C3C28', fontSize: '22px' }} />
                                                </ListItemIcon>
                                                <a href="javascript:;"
                                                    onClick={props.onLogout}
                                                    className="main"
                                                    style={{ textDecoration: 'none', color: '#6e777d', fontSize: '16px', 'line-height': '1.3' }}>
                                                    Déconnexion
                                                </a>
                                            </ListItem>
                                        </li>
                                        {/* <li>
                        <a href="contact.html" className="main">Contact Us</a>
                      </li> */}
                                    </ul>
                                </nav>
                            </div>
                    }
                    {/* mobile menu area end */}
                </div>

                <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                    tabIndex={0}
                >
                    <div className="category-btn category-hover-header menu-category">
                        <ul className="category-sub-menu" id="category-active-menu">
                            {props.allCategories.map((category, index) => {
                                const hasSubcategories = category.subcategories.length > 0;
                                return (
                                    <li key={index}>
                                        <a
                                            href="javascript:void();"
                                            className="menu-item"
                                            onClick={() =>
                                                hasSubcategories
                                                    ? handleToggleCategory(index)
                                                    : handleNavigation("/categories" + category.path)
                                            }
                                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} // Ajout du style ici
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    style={{
                                                        height: '40px',
                                                        'max-width': '30px',
                                                        'margin-right': '10px'
                                                    }}
                                                    src={category.iconUrl} alt={category.name} />
                                                <span>{category.name}</span>
                                            </div>
                                            {hasSubcategories && (
                                                openCategories[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                            )}
                                        </a>
                                        {hasSubcategories && openCategories[index] && (
                                            renderSubcategories(category.subcategories, index)
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            {/* button area wrapper start */}
            <div className="button-area-main-wrapper-menuy-sidebar mt--50">
                <div className="contact-area">
                    <div className="phone">
                        {/* <i className="fa-light fa-headset" /> */}
                        <HeadsetMicIcon
                        sx={{ color: '#2C3C28', fontSize: '18px' }} />
                        <a href="javascript:void();"> Tel:  1</a>
                    </div>
                    <div className="phone">
                        {/* <i className="fa-light fa-envelope" /> */}
                        <HeadsetMicIcon
                        sx={{ color: '#2C3C28', fontSize: '18px' }} />
                        <a href="javascript:void();">Tel:  2</a>
                    </div>
                </div>

                {
                    !!props.fName
                        ?
                        ''
                        :
                        <div className="buton-area-bottom">
                            <Link to="/login" className="rts-btn btn-primary"
                                style={{
                                    fontSize: '14px',
                                    'line-height': '1.3',
                                    padding: '14px 14px'
                                }}>
                                Se connecter
                            </Link>
                            <Link to="/register" className="rts-btn btn-primary"
                                style={{
                                    fontSize: '14px',
                                    'line-height': '1.3',
                                    padding: '14px 14px'
                                }}>
                                Creer son compte
                            </Link>
                        </div>
                }
            </div>
            {/* button area wrapper end */}

        </div>
    );
};

export default MobileCategoriesMenu;
