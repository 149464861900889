import Http from '../../Http'
import * as action from '../../store/actions'
const config = {
   headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  data: null,
};

export function findUserById(hashId) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => { 
            // Http.get(`/users/${hashId}?include=country`)
            Http.get(`/users/${hashId}`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                if (!err.response) {
                    // network error (server is down or no internet)
                    const data = {
                        notNetwork: true
                    };
                    return reject(data)
                } else {
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                        notNetwork: false
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                return reject(data);
                }
              })
                
        })
    )
};

export function updateUsers(hashId, data) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.put(`/users/${hashId}`, data)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function getPointRelaisById(id) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/relaypoints/${id}?include=city`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function getSearchOfClients(donator_id, name) {
    let formdata = new FormData();
    
    formdata.append('name', name);
    const data = {name: name}
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.post(`/search-clients/${donator_id}?include=receivers`, formdata)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
        })
    )
};

export function search(ville_name) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/relaypoints?search=name:${ville_name}`)
            .then(function (res) {  
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};


export function searchTargetUser(userConnectId) {
    const data = {created_by: userConnectId}
    let formdata = new FormData();
    
    formdata.append('created_by', userConnectId);
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.post(`/get-targetuser-by-userid`, data)
            .then(function (res) {  
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function profile() {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/user/profile`)
            .then(function (res) {   
                const data = res.data.data
                return resolve(data);
              })
              .catch(function (err) {

                if (!err.response) {
                    // network error (server is down or no internet)
                    const data = {
                        notNetwork: true
                    };
                    return reject(data)
                } else {
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                        notNetwork: false
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                return reject(data);
                }
              })
                
        })
    )
};

export function searchClientLink(donator_id, name) {
    const limit = 250
    const data = {name: name}
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.post(`/search-clients-link/${donator_id}`, data)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
        })
    )
};

export function createLinkDemand(id, receiver_id, amount) {
    const limit = 250
    const data = {
            receiver_id: receiver_id,
            amount: amount !== null ? amount : null}
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.post(`/users/${id}/create-link-demand`, data)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
        })
    )
};

export function getUserLink(id, status) {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/user-link?include=receivers,donators&search=donator_id:${id};status:${status}&searchJoin=and`)
            .then(function (res) {   
                const data = res.data.data
                return resolve(data);
              })
              .catch(function (err) {

                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function getReceiverLink(id, status) {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/user-link?include=receivers,donators&search=receiver_id:${id};status:${status}&searchJoin=and`)
            .then(function (res) {   
                const data = res.data.data
                return resolve(data);
              })
              .catch(function (err) {

                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function getUserReceiverLink(id, status) {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/user-link?include=donators&search=receiver_id:${id};status:${status}&searchJoin=and`)
            .then(function (res) {   
                const data = res.data.data
                return resolve(data);
              })
              .catch(function (err) {

                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function getUserDonatorsLink(id, status) {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/user-link?include=receivers&search=donator_id:${id};status:${status}&searchJoin=and`)
            .then(function (res) {   
                const data = res.data.data
                return resolve(data);
              })
              .catch(function (err) {

                if (!err.response) {
                    // network error (server is down or no internet)
                    const data = {
                        notNetwork: true
                    };
                    return reject(data)
                } else {
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                        notNetwork: false
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                return reject(data);
                }
              })
                
        })
    )
};

export function respondToUserLinkDemand(id, validateBoolean) {
    const data = { validate: validateBoolean }
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.post(`/user-link/${id}/validate_or_not`, data)
            .then(function (res) {   
                const data = res.data.data
                return resolve(data);
              })
              .catch(function (err) {

                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function createReceiverProfile(data) {
    let formdata = new FormData();
    
    formdata.append('name', data.name);
    formdata.append('email', data.email);
    formdata.append('phone', data.phone);
    formdata.append('district', data.district);
    formdata.append('city_id', data.city_id);

    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.post(`/receiverprofiles`, data)
            .then(function (res) {         
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode: err,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function creatorCreateUserProfile(data) {
    let formdata = new FormData();
    
    formdata.append('firstname', data.firstname);
    formdata.append('lastname', data.lastname);
    formdata.append('email', data.email);
    formdata.append('phone', data.phone);
    formdata.append('address', data.address);
    formdata.append('created_by', data.created_by);
    formdata.append('full_name', data.firstname+' '+data.lastname);

    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.post(`/creator-create-user-profile`, data)
            .then(function (res) {         
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode: err,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function getAllReceiverProfiles(real_id) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/receiverprofiles?search=user_id:${real_id}`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function getAllReceiverProfilesByid(hashUserProfil_id) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/receiverprofiles/${hashUserProfil_id}?include=user`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function deleteReceiverprofiles(hashUserProfil_id, data) {
    let formdata = new FormData();
    formdata.append('user_id', data.user_id);

    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.post(`/receiverprofiles/${hashUserProfil_id}`, data)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};