import Http from '../../Http'
import * as action from '../../store/actions'

export function commands(data) {
    // let formdata = new FormData();

    // formdata.append('amount', data.amount);
    // formdata.append('quantity', data.quantity);
    // formdata.append('relaypoint_id', data.relaypoint_id);
    // formdata.append('receiverprofile_id', data.receiverprofile_id);
    // formdata.append('donator_id', data.donator_id);
    // formdata.append('commandLines', data.commandLines);
    // formdata.append('name', data.name);
    // formdata.append('phone', data.phone);
    // formdata.append('is_pay_by_creator', data.is_pay_by_creator);

    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`/commands`, data)
                .then(function (res) {
                    // handle success           
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // network error (server is down or no internet)
                        const data = {
                            notNetwork: true
                        };
                        return reject(data)
                    } else {
                        const statusCode = err.response;
                        const data = {
                            error: null,
                            statusCode: err,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // status 401 means unauthorized
                            // status 422 means unprocessable entity
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                })

        })
    )
};

export function userLink_t() {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/user-link`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })
        })
    )
};