import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import FormControl from '@mui/material/FormControl';

import { Alert } from "reactstrap";

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
};

export default function PaymentRadioTypeComponent(props) {

    const [paymentType, setPaymentType] = useState('');
    const [isSocialPayment, setIsSocialPayment] = useState(false);

    // const [selectedValue, setSelectedValue] = useState('');

    // const handleChange = (event) => {
    //     setSelectedValue(event.target.value);
    // };

    // console.log("Selected value:", selectedValue);

    const [selectedValue, setSelectedValue] = useState('3%');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };


    // React.useEffect(() => {
    //     const handleRadioChange = (event) => {
    //         alert("Ters")
    //         const selectedValue = event.target.value;
    //         setPaymentType(selectedValue);
    //         if (selectedValue === "aide social") {
    //             setIsSocialPayment(true);
    //             props.onChooseSocialAssistance(true, 2, true, false);
    //         }
    //         if (selectedValue === "sans aide social") {
    //             setIsSocialPayment(false);
    //             props.onChooseSocialAssistance(true, 3, false, false);
    //         }
    //         if (selectedValue === "direct") {
    //             setIsSocialPayment(false);
    //             props.onChooseSocialAssistance(false, 4, false, false);
    //         }
    //     };

    //     const radios = document.querySelectorAll('.cardDefferedPayment input[type="radio"]');
    //     radios.forEach((radio) => {
    //         radio.addEventListener('change', handleRadioChange);
    //     });

    //     return () => {
    //         radios.forEach((radio) => {
    //             radio.removeEventListener('change', handleRadioChange);
    //         });
    //     };
    // }, []);

    const handleRadioChange = (event) => {
        const selectedValue = event.target.value;
        setPaymentType(selectedValue);
        if (selectedValue === "aide social") {
            setIsSocialPayment(true);
            props.onChooseSocialAssistance(true, 2, true, false);
        }
        if (selectedValue === "sans aide social") {
            setIsSocialPayment(false);
            props.onChooseSocialAssistance(true, 3, false, false);
        }
        if (selectedValue === "direct") {
            setIsSocialPayment(false);
            props.onChooseSocialAssistance(false, 4, false, false);
        }
    };


    return (
        <div className="cardDefferedPayment">
            <RadioGroup
                name="use-radio-group"
                // defaultValue="first"
                value={props.paymentType}
                onChange={handleRadioChange}
            >

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-3 col-xs-12 col-sm-6 col-md-12 col-lg-12"
                            style={{
                                margin: 'auto',
                                fontFamily: "Maison Neue Bold",
                                fontSize: '14px',
                                color: '#1e2431',
                                marginBottom: '10px'
                            }}>
                            <span className='paymentTypeLabel'>Paiement direct (Par carte bancaire) : </span>
                        </div>
                        <div className={props.paymentType
                            ?
                            "col col-xs-12 col-sm-6 col-md-10 col-lg-10 paymentRadioTypeLabelActive"
                            :
                            "col col-xs-12 col-sm-6 col-md-10 col-lg-10 paymentRadioTypeLabel"
                        }>
                            <MyFormControlLabel
                                value="direct"
                                control={<Radio style={{ color: props.paymentType === 'direct' ? '#54a649' : '', margin: '0px' }} />}
                                label={<span style={{ color: props.paymentType === 'direct' ? '#54a649' : '' }}>
                                    <img className='imgPaymentType' style={{ width: '120px' }} src={require("../../../../../images/rectanglePayment.png")} />
                                </span>}
                            />
                        </div>
                        {(props.totalSum >= 23) ?
                            <>
                                <div className="col-3 col-xs-12 col-sm-6 col-md-12 col-lg-12"
                                    style={{
                                        margin: 'auto',
                                        fontFamily: "Maison Neue Bold",
                                        fontSize: '14px',
                                        color: '#1e2431',
                                        margin: '30px 0 10px 0'
                                    }}>
                                    <span className='paymentTypeLabel'>Paiement différé (Par carte bancaire) à rembourser un mois après: </span>
                                </div>
                                {/* <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-8 paymentRadioTypeLabel">
                                </div> */}



                                <div className="col-3 col-xs-12 col-sm-6 col-md-12 col-lg-12"
                                    style={{
                                        margin: 'auto',
                                        fontFamily: "Maison Neue Bold",
                                        fontSize: '14px',
                                        color: '#1e2431'
                                    }}>
                                    {/* <span className='paymentTypeLabel'>Paiement différé : </span> */}
                                </div>
                                <div className=
                                    {props.paymentType
                                        ?
                                        "col col-xs-12 col-sm-6 col-md-10 col-lg-10 paymentRadioTypeLabelActive"
                                        :
                                        "col col-xs-12 col-sm-6 col-md-10 col-lg-10 paymentRadioTypeLabel"
                                    }>
                                    <MyFormControlLabel
                                        value="sans aide social"
                                        control={<Radio style={{ color: props.paymentType === 'sans aide social' ? '#54a649' : '' }} />}
                                        label={<span style={{ color: props.paymentType === 'sans aide social' ? '#54a649' : '' }}>
                                            sans aide social
                                            <img className='imgPaymentType' style={{ width: '120px' }} src={require("../../../../../images/rectanglePayment.png")} />
                                        </span>}

                                    />
                                </div>


                                {(props.isSocialPayment === false && props.paymentType === 'sans aide social') ?
                                    <div className="row">
                                        {/* <div className="col-3 col-xs-12 col-sm-6 col-md-1 col-lg-1">
                            </div> */}
                                        <div className="col col-xs-12 col-sm-6 col-md-11 col-lg-11">

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: '10px 20px 0 50px',
                                                    fontSize: '17px'
                                                }}>
                                                <Alert color="success">
                                                    <p className='cgvPaymentAlert'>
                                                        <b>NB:</b>
                                                        En validant cette option aujourd'hui, vous vous engagez à payer
                                                        votre facture dans 30 jours, comprenant le prix total hors taxes (HT)
                                                        de votre panier, majoré d'un taux d'intérêt de 10%.
                                                    </p>
                                                </Alert>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }


                                <div className="col-12 col-xs-12 col-sm-6 col-md-2 col-lg-2"
                                    style={{
                                        margin: 'auto',
                                        fontFamily: "Maison Neue Bold",
                                        fontSize: '14px',
                                        color: '#1e2431'
                                    }}>
                                    {/* <span className='paymentTypeLabel'>Paiement différé : </span> */}
                                </div>
                                <div className=
                                    {props.paymentType
                                        ?
                                        "col col-xs-12 col-sm-6 col-md-10 col-lg-10 paymentRadioTypeLabelActive"
                                        :
                                        "col col-xs-12 col-sm-6 col-md-10 col-lg-10 paymentRadioTypeLabel"
                                    }>
                                    <MyFormControlLabel
                                        value="aide social"
                                        control={<Radio style={{ color: props.paymentType === 'aide social' ? '#54a649' : '' }} />}
                                        label={<span style={{ color: props.paymentType === 'aide social' ? '#54a649' : '' }}>
                                            avec aide sociale
                                            <img className='imgPaymentType' style={{ width: '120px' }} src={require("../../../../../images/rectanglePayment.png")} />
                                        </span>}
                                    />
                                </div>
                            </>
                            :
                            ''}

                    </div>

                    {props.isSocialPayment ?
                        <div className="row optionWelfare">
                            <div className="col-3 col-xs-12 col-sm-6 col-md-3 col-lg-3"
                                style={{ fontFamily: "Maison Neue bold" }}>
                                Choisir votre option sociale
                            </div>
                            <div className="col col-xs-12 col-sm-6 col-md-9 col-lg-9 paymentRadioTypeLabelOption">
                                <FormControl>
                                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Choisissez votre option sociale</FormLabel> */}

                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={props.selectedValue}
                                        onChange={props.handleChangeSocialOption}
                                    >
                                        <FormControlLabel
                                            value="3%"
                                            control={<Radio style={{ color: props.selectedValue === '3%' ? '#54a649' : '' }} className="custom-label" />}
                                            label={<span style={{ color: props.selectedValue === '3%' ? '#54a649' : '' }} className="custom-label">3%</span>}
                                        />
                                        <FormControlLabel
                                            value="5%"
                                            control={<Radio style={{ color: props.selectedValue === '5%' ? '#54a649' : '' }} className="custom-label" />}
                                            label={<span style={{ color: props.selectedValue === '5%' ? '#54a649' : '' }} className="custom-label">5%</span>}
                                        />
                                        <FormControlLabel
                                            value="10%"
                                            control={<Radio style={{ color: props.selectedValue === '10%' ? '#54a649' : '' }} className="custom-label" />}
                                            label={<span style={{ color: props.selectedValue === '10%' ? '#54a649' : '' }} className="custom-label">10%</span>}
                                        />
                                        <FormControlLabel
                                            value="15%"
                                            control={<Radio style={{ color: props.selectedValue === '15%' ? '#54a649' : '' }} className="custom-label" />}
                                            label={<span style={{ color: props.selectedValue === '15%' ? '#54a649' : '' }} className="custom-label">15%</span>}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        :
                        null
                    }

                    {(props.isSocialPayment && props.selectedValue === '3%') ?
                        <div className="row">
                            {/* <div className="col-3 col-xs-12 col-sm-6 col-md-1 col-lg-1">
                            </div> */}
                            <div className="col col-xs-12 col-sm-6 col-md-11 col-lg-11">

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        margin: '10px 20px 0 50px',
                                        fontSize: '17px'
                                    }}>
                                    <Alert color="success">
                                        Le bénéficiaire recevra en main propre <b>3%</b> de <b>{props.totalHtArrondie} €</b> qui indique le montant
                                        qui sera remis en main propre à votre bénéficiaire lorsqu'il entrera en possession de ses produits
                                        c'est à dire : <b> {parseFloat(((props.sum_t * 3) / 100).toFixed(2))} € </b>
                                        soit <b> {parseFloat(((props.sum_t * 3) / 100) * 650).toFixed(0)} XFA</b>
                                        <p className='cgvPaymentAlert'>
                                            <b>NB:</b>
                                            En validant cette option aujourd'hui, vous vous engagez à payer
                                            votre facture dans 30 jours, comprenant le prix total hors taxes (HT)
                                            de votre panier, majoré d'un taux d'intérêt de 10%. Les options (3%, 5%, 10%, 15%) indiquent les pourcentages
                                            du prix total (HT) qui seront remis en main propre à votre bénéficiaire
                                            lorsqu'il recevra ses produits.
                                        </p>
                                    </Alert>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    {(props.isSocialPayment && props.selectedValue === '5%') ?
                        <div className="row">
                            {/* <div className="col-3 col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            </div> */}
                            <div className="col col-xs-12 col-sm-6 col-md-11 col-lg-11">

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '10px 20px 0 50px',
                                    fontSize: '17px'
                                }}>
                                    <Alert color="success">
                                        Le bénéficiaire recevra en main propre <b>5%</b> de <b>{props.totalHtArrondie} €</b> qui indique le montant
                                        qui sera remis en main propre à votre bénéficiaire lorsqu'il entrera en possession de ses produits
                                        c'est à dire  : <b> {parseFloat(((props.sum_t * 5) / 100).toFixed(2))} € </b>
                                        soit <b> {parseFloat(((props.sum_t * 5) / 100) * 650).toFixed(0)} XFA</b>
                                        <p className='cgvPaymentAlert'>
                                            <b>NB:</b>
                                            En validant cette option aujourd'hui, vous vous engagez à payer
                                            votre facture dans 30 jours, comprenant le prix total hors taxes (HT)
                                            de votre panier, majoré d'un taux d'intérêt de 10%. Les options (3%, 5%, 10%, 15%) indiquent les pourcentages
                                            du prix total (HT) qui seront remis en main propre à votre bénéficiaire
                                            lorsqu'il recevra ses produits.
                                        </p>
                                    </Alert>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    {(props.isSocialPayment && props.selectedValue === '10%') ?
                        <div className="row">
                            {/* <div className="col-3 col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            </div> */}
                            <div className="col col-xs-12 col-sm-6 col-md-11 col-lg-11">

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '10px 20px 0 50px',
                                    fontSize: '17px'
                                }}>
                                    <Alert color="success">

                                        Le bénéficiaire recevra en main propre <b>10%</b> de <b>{props.totalHtArrondie} €</b> qui indique le montant
                                        qui sera remis en main propre à votre bénéficiaire lorsqu'il entrera en possession de ses produits
                                        c'est à dire : <b> {parseFloat(((props.sum_t * 10) / 100).toFixed(2))} € </b>
                                        soit <b> {parseFloat(((props.sum_t * 10) / 100) * 650).toFixed(0)} XFA</b>
                                        <p className='cgvPaymentAlert'>
                                            <b>NB:</b>
                                            En validant cette option aujourd'hui, vous vous engagez à payer
                                            votre facture dans 30 jours, comprenant le prix total hors taxes (HT)
                                            de votre panier, majoré d'un taux d'intérêt de 10%. Les options (3%, 5%, 10%, 15%) indiquent les pourcentages
                                            du prix total (HT) qui seront remis en main propre à votre bénéficiaire
                                            lorsqu'il recevra ses produits.
                                        </p>
                                    </Alert>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    {(props.isSocialPayment && props.selectedValue === '15%') ?
                        <div className="row">
                            {/* <div className="col-3 col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            </div> */}
                            <div className="col col-xs-12 col-sm-6 col-md-11 col-lg-11">

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '10px 20px 0 50px',
                                    fontSize: '17px'
                                }}>
                                    <Alert color="success">
                                        {/* <img
                                            src={require("../../../../../images/checkOK.png")}
                                            alt="Check Ok"
                                        /> */}
                                        Le bénéficiaire recevra en main propre <b>15%</b> de <b>{props.totalHtArrondie} €</b> qui indique le montant
                                        qui sera remis en main propre à votre bénéficiaire lorsqu'il entrera en possession de ses produits
                                        c'est à dire : <b> {parseFloat(((props.sum_t * 15) / 100).toFixed(2))} € </b>
                                        soit <b> {parseFloat(((props.sum_t * 15) / 100) * 650).toFixed(0)} XFA</b>
                                        <p className='cgvPaymentAlert'>
                                            <b>NB:</b>
                                            En validant cette option aujourd'hui, vous vous engagez à payer
                                            votre facture dans 30 jours, comprenant le prix total hors taxes (HT)
                                            de votre panier, majoré d'un taux d'intérêt de 10%. Les options (3%, 5%, 10%, 15%) indiquent les pourcentages
                                            du prix total (HT) qui seront remis en main propre à votre bénéficiaire
                                            lorsqu'il recevra ses produits.
                                        </p>
                                    </Alert>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                </div>

            </RadioGroup>
        </div>
    );
}
