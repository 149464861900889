import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NavDropdown from 'react-bootstrap/NavDropdown';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from "react-select";

import SeConnecterBtn from './../components/SeConnecterBtn'

import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class BandeBlanche extends Component {
  
  render(){
    const { t } = this.props;
    return(
      <div className="main-header bande_blanche_t isResponsive_t">
      </div>
      );
  }
}
export default withTranslation()(BandeBlanche);