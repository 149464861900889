import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NavDropdown from 'react-bootstrap/NavDropdown';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from "react-select";
import CryptoJS from 'crypto-js';
import { changeNavigatorTheme, auth_t } from './../../store/actions';
import SeConnecterBtn from './../components/SeConnecterBtn';

import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class HeaderTop extends Component {

  render() {
    const { t } = this.props;
    return (
      <div className="main-header main-header-top-color">
        {/* Logo Header */}
        <div className="logo-header logo-header-top-width">
          <a href="#" className="logo isResponsive_t">
            <img
              src={require("../../images/WhatsApp Inc. Icon.png")}
              alt="WhatsApp"
              className="navbar-brand icon_header_size" />
          </a>
          <a href="#" className="logo isResponsive_t">
            <img
              src={require("../../images/Facebook Icon.png")}
              alt="Facebook"
              className="navbar-brand icon_header_size" />
          </a>
          <a href="#" className="logo isResponsive_t">
            <img
              src={require("../../images/instagram_icon.png")}
              alt="navbar brand"
              className="navbar-brand icon_header_size" />
          </a>

          <button className="navbar-toggler ml-auto isBtnNavBarToggler_t" type="button" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
              <i className="icon-menu" />
            </span>
          </button>
          {/* <button className="topbar-toggler more"><i className="icon-options-vertical" /></button> */}
          {/*<div className="nav-toggle">
              <button className="btn btn-toggle toggle-sidebar">
                <i className="icon-menu" />
              </button>
            </div>*/}
        </div>
        {/* End Logo Header */}
        {/* Navbar Header */}
        <nav className="navbar navbar-header navbar-expand-lg navbar_header_t">
          <div className="container-fluid">

            {this.props.logo_noir ?
              <a href="index.html" className="logo_noir_t">
                <img
                  src={require("../../images/logo_noir.png")}
                  alt="navbar brand"
                  className="logo_header_size" />
                <span className="text_header_size"> Wemaza<span style={{ color: 'blue' }}>.</span></span>
              </a>
              :
              ''
            }

            <ul className="navbar-nav topbar-nav ml-md-auto"
            // style={{ margin: 'auto' }}
            >
              <div className="welcomeStyle_t">
                {this.props.fName ? `Bienvenue ${this.props.fName} !` : ""}
              </div>
            </ul>
            <ul className="navbar-nav topbar-nav ml-md-auto align-items-center" style={{ margin: '0 1% 0 0' }}>

              <div>
                {this.props.logo_noir ?

                  <div className="img_header_t"></div>

                  :
                  ''
                }

              </div>
              <div className="padding_ul_t">
                <span style={{ color: '#000' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
              <div>
                <span style={{ color: '#000' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
              {/* <div>
                   <span style={{color: '#000'}}>
                     <CreatableSelect
                        onChange={this.handleChange}
                        onInputChange={this.handleInputChange}
                        components={animatedComponents}
                        options={this.props.options_devise}  
                        placeholder={"Euro € "}
                      />
                   </span>
                </div> */}

              <div>
                <span style={{ color: '#000' }}>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                </span>
              </div>

              <div>
                <span style={{ color: '#000' }}>
                  <CreatableSelect
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    components={animatedComponents}
                    value={this.props.langBool ? "Francais" : "English"}
                    options={this.props.langBool ? this.props.options_fr : this.props.options_eng}
                    placeholder={this.props.langBool ? "Francais" : "English"}
                  />
                </span>
              </div>
            </ul>
          </div>
        </nav>
        {/* End Navbar */}
      </div>
    );
  }
}
export default withTranslation()(HeaderTop);