import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import fr from 'ree-validate/dist/locale/fr';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, matchPath } from 'react-router-dom';
import { Redirect } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import AuthService from '../../../services/auth';
import ProductService from '../../../services/product';
import { lightTheme, darkTheme } from '../../../js/theme';
import { GlobalStyles } from '../../../js/global';
import { changeNavigatorTheme, auth_t, prodId_t, home_p } from '../../../store/actions';
import BasicInput from './../../components/BasicInput';
import AcideLeft from '../../layout/AcideLeftLogin';
import ReactPlaceholder from './../../components/ReactPlaceholder';
import Carousel from './../../components/Carousel/Carousel';
import CarouselTemoignage from './../../components/Carousel/CarouselTemoignage';
import CatSelectComponent from './../../components/CatSelectComponent';
import CatListComponent from './../../components/CatListComponent';
import FloatingInput from './../../components/FormsComponent/FloatingInput';
import FormsChoice from './../../components/FormsComponent/FormsChoice';
import FormsSelect from './../../components/FormsComponent/FormsSelect';
import Trier from './../../components/FormsComponent/Trier';
import LoginBtn from './../../components/FormsComponent/LoginBtn';
import PhoneNumber from './../../components/FormsComponent/PhoneNumber';
import SeConnecterBtn from './../../components/SeConnecterBtn';
import ProduitComponent from './ProduitComponent';
import GoodDealProdComponent from './../../components/GoodDealProdComponent';
import TemoignageComponent from './../../components/TemoignageComponent';
import FooterTop from './../../components/FooterTop';
import Footer from './../../layout/Footer';

class ContentComponent extends Component {

  render() {
    const {
      t,
      onAddProduct,
      index_t,
      products,
      goToParentBigImg,
      bigImages,
      prodId_t,
      qteValue,
      onChangeValue,
      onAddCart_t,
      isCart_t,
      hash_id,
      handleChangeProductAndReloadPage
    } = this.props;

    return (
      <div className="container-fluid container_product_t">

        {/* <div className="row marg_null_t">q
         <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-2 color_cat_t">
          <CatSelectComponent  
              select_value={categorie_value}
              style_f_Select_div={{margin: '5px 0 0 -5px',color: '#000'}}
              name={'cat_prod'}
              options={option_categorie}
              placeholder={'Catégorie'}
              gotToParentCatValue={gotToParentCatValue}
            />
          </div>
          <CatListComponent
            categorieLabel={categorieLabel}
            parent_categorieLabel={parent_categorieLabel} />  
        </div> */}

        <div className="row marg_null_t">
          <ProduitComponent
            onUpdateProductModal={onAddProduct}
            index_t={index_t}
            products={products}
            goToParentBigImg={goToParentBigImg}
            bigImages={bigImages}
            prodId_t={prodId_t}
            qteValue={qteValue}
            onChangeValue={onChangeValue}
            onAddCart_t={onAddCart_t}
            isCart_t={isCart_t}
            offline={this.props.offline}
          />
        </div>
        {/* <div className="row marg_null_t">
          <Link to="/products/country:33+Cameroun" className="backToProduct_t">
            <img
              src={require("../../../images/chevron_left.png")}
              alt="back"
              className="back_t" />
            produits
          </Link>
        </div> */}

        <p className="paragr_m_deal_t">
          Les clients Wemaza ont également apprécié
        </p>

        <div className="row marg_null_gd_t">
          <GoodDealProdComponent
            onAddProduct={onAddProduct}
            index_t={index_t}
            products={products}
            handleChangeProductAndReloadPage={handleChangeProductAndReloadPage}
            // isCart_t={isCart_t}
            hash_id={hash_id}
          />
        </div>
        {/* <span className="paragr_t">
          Témoignage client
        </span>

        <CarouselTemoignage /> */}

        <div style={{ margin: '55px 0 0 0' }}>
          <div className="row marg_footerTop_gd_t" >
            <FooterTop
              require={require("../../../images/smile.png")}
              first_title={'Satisfait ou remboursé'}
              second_title={'Remise pour les membre'}
            />
            <FooterTop
              require={require("../../../images/thumbs-up.png")}
              first_title={'Retours sans frais'}
              second_title={'Service Après Vente agrée'}
            />
            <FooterTop
              require={require("../../../images/truck.png")}
              first_title={'Paiement 100% sécurisé'}
              second_title={'Nous sécurison vos paiements'}
            />
            <FooterTop
              require={require("../../../images/truck.png")}
              first_title={'Assistance 24/7'}
              second_title={'Contactez-nous 24/24'}
            />
          </div>
        </div>

        <div className="showAndHiddenRespDesk_t">
          <Footer />
        </div>
        <div className="footerMobil_t">
          &nbsp;&nbsp;
        </div>

      </div>
    );
  }
}
export default withTranslation()(ContentComponent);