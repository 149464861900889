import React, { useState } from 'react';
import HeaderTop from './HeaderTop';
import Header from './Header';
import HeaderMenu from './HeaderMenu';
import HeaderUsersMobile from './HeaderUsersMobile'

const HeaderOne = (props) => {

    return (
        <>
            <div className="showAndHiddenRespDesk_t">
                <HeaderTop
                    t={props.t}
                    changeLanguage={props.changeLanguage}
                    langBool={props.langBool}
                    fName={props.fName}
                />
                <Header
                    t={props.t}
                    changeLanguage={props.changeLanguage}
                />
                <HeaderMenu
                    t={props.t}
                    changeLanguage={props.changeLanguage}
                    isAuth_t={props.isAuth_t}
                    toggleBool_t={props.toggleBool_t}
                    onToggle_t={props.onToggle_t}
                    idProf={props.idProf}
                />
            </div>

            <div className="showAndHiddenResp_t">
                <HeaderUsersMobile
                    cartValue={props.cartValue}
                    isAuthResponsive={false}
                    langBool={props.langBool}
                    option_city={props.option_city}
                    city_id={props.city_id}
                    gotToParentValue={props.gotToParentValue}
                    option_country={props.option_country}
                    country_value={props.country_value}
                    toggleBool_t={props.toggleBool_t}
                    onToggle_t={props.onToggle_t}
                    isAuth_t={props.isAuth_t}
                    onLogout={props.onLogout}
                    isLoadingHeader={props.isLoadingHeader}
                    idProf={props.idProf}
                    showCountry={true}
                    menuBottom={true}
                />
            </div>
        </>
    )
}

export default HeaderOne