import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class CatListComponent extends Component {
  delete_sous_cat(i, v) {
    const { categorieLabel } = this.props;
    categorieLabel.splice(i, 1);
    this.props.parent_categorieLabel(categorieLabel);
    this.props.parent_add_categorie(v);
  }

  render() {
    let { t, categorieLabel, showResponsiveClass } = this.props
    let listCategorie = categorieLabel.length > 0 && categorieLabel.map((v, i) => {
      const decodedText = decodeURIComponent(v.label);
      return (
        <div
          className={showResponsiveClass}
          key={i}
          style={{
            margin: '20px 0px 0px 0px',
          }}>
          <span className="sous_cat_produc_t">

            {decodedText}
            <a
              href="javascript:;"
              onClick={this.delete_sous_cat.bind(this, i, v)}
              className="del_sous_cat_t">
              x
            </a>
          </span>
        </div>
      )
    });
    return (
      <>
        {listCategorie}
      </>
    );
  }
}
export default withTranslation()(CatListComponent);