import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import PlaceIcon from '@mui/icons-material/Place';

const SearchBar = (props) => {
  const [address, setAddress] = useState('');
  const [placeholderState, setPlaceholderState] = useState(props.placeholderText ? props.placeholderText : 'Recherchez votre adresse...');
  // const handleAddressGeocode = (addr) => {
  //   setAddress(addr)
  // }

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const { lat, lng } = await getLatLng(results[0]);
      // console.log('Selected Address: ', selectedAddress, lat, lng);
      // setAddress(selectedAddress);
      props.handleAddressGeocode(selectedAddress);

      const data = results[0].address_components;

      const filterAndReturn = (data, targetType) => {
        const filteredData = data.filter(item => item.types.includes(targetType));

        if (filteredData.length > 0) {
          return filteredData[0].short_name; // Vous pouvez choisir la propriété que vous souhaitez retourner ici
        } else {
          return null;
        }
      };

      const countryValue = filterAndReturn(data, "country");

      const coord = {
        userLat: lat,
        userLng: lng,
        adress: selectedAddress,
        name: props.name,
        short_countryName: countryValue.toLowerCase()
      }

      props.getProfileschedulesWithMyLocation(coord)




      // coordonnées de de Ndogsimbi
      const referenceCoord = { lat: 4.0408857, lng: 9.7334968 }; // ndogsimbi, Douala

      // Calcul de la distance en utilisant DistanceMatrixService
      const service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [{ lat, lng }],
          destinations: [referenceCoord],
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            const distance = response.rows[0].elements[0].distance.text;
            // console.log(`Distance to reference point lud1: ${distance}`);
            props.distanceBetweenBeneficiaryAndOurLocaltionViaGoogleMap(distance, selectedAddress)
          } else {
            console.error('DistanceMatrixService failed due to: ' + status);
          }
        }
      );

    } catch (error) {
      console.error('Error:', error);
    }
  };

  // const handleFormSubmit = (e) => {
  //   e.preventDefault();
  //   if (!props.address) {
  //     alert('Veuillez remplir l\'adresse');
  //     return;
  //   }
  // };

  return (
    // <form onSubmit={handleFormSubmit}>
      <div>
        <PlacesAutocomplete
          value={props.address}
          onChange={props.handleAddressGeocode}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                style={{ padding: '23px' }}
                {...getInputProps({
                  placeholder: placeholderState,
                  className: 'location-search-input',
                  name: props.name,
                  onFocus: () => setPlaceholderState(''),
                  onBlur: () => setPlaceholderState(props.placeholderText ? props.placeholderText : 'Recherchez votre adresse...'),
                })}
                required
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.placeId}
                    style={{ padding: '5px', fontFamily: "Maison Neue Bold" }}
                  >
                    <PlaceIcon sx={{ fontSize: 20, color: '#28a745', margin: '0 3px' }} />

                    {suggestion.description}

                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    // </form>
  );
};

export default SearchBar;
