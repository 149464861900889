import React, { useState } from 'react';

const Historique = () => {
    // const [showAlert, setShowAlert] = useState("Tootree");

    return (
        <div className='pdg220_t'>
            <div className="container-fluid">
                <div className="row">
                    <div className='col-12 col-md-8'>
                        <div className="histoAchat_t">
                            Historique des achats
                        </div>
                    </div>
                    <div className='col-12 col-md-4'>
                        <div className="posAbsolute_t">
                            <span className='lite_t'>Trier par : </span>
                            <span className='midleBold_t'>Recent</span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className='col-12 col-md-3'>
                        <div className="historyBlock_t">
                            <div className='blockNameAvatar_t'>
                                <span className='textAvatarBlock_t'>
                                    {/* <div className='imgAvatarHisto_t'></div> */}
                                    <img
                                        src={require("../../../../images/HistoAvatar1.png")}
                                        alt="Camera_t"
                                        className="imgAvatarHisto_t" />

                                    Bessie Cooper
                                </span>
                            </div>
                            <p className='textCenter_t'>
                                Code : 0023856
                            </p>
                            <p className='textCenter_t'>
                                Montant : 2000 xaf
                            </p>
                            <p className='textCenter_t'>
                                Nombre d’articles : 23
                            </p>
                            <span className='textCenterGreen_t'>
                                Recu
                            </span>
                            <a href='javascript:;' className='btnShowDetailsHisto_t'>
                                Voir les details
                            </a>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                    <div className="historyBlock_t">
                            <div className='blockNameAvatar_t'>
                                <span className='textAvatarBlock_t'>
                                    {/* <div className='imgAvatarHisto_t'></div> */}
                                    <img
                                        src={require("../../../../images/HistoAvatar2.png")}
                                        alt="Camera_t"
                                        className="imgAvatarHisto_t" />

                                    Dell Computer
                                </span>
                            </div>
                            <p className='textCenter_t'>
                                Code : 0023856
                            </p>
                            <p className='textCenter_t'>
                                Montant : 2000 xaf
                            </p>
                            <p className='textCenter_t'>
                                Nombre d’articles : 23
                            </p>
                            <span className='textCenterGreen_t'>
                                Recu
                            </span>
                            <a href='javascript:;' className='btnShowDetailsHisto_t'>
                                Voir les details
                            </a>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                    <div className="historyBlock_t">
                            <div className='blockNameAvatar_t'>
                                <span className='textAvatarBlock_t'>
                                    {/* <div className='imgAvatarHisto_t'></div> */}
                                    <img
                                        src={require("../../../../images/HistoAvatar3.png")}
                                        alt="Camera_t"
                                        className="imgAvatarHisto_t" />

                                    Imawori Fassi
                                </span>
                            </div>
                            <p className='textCenter_t'>
                                Code : 0023856
                            </p>
                            <p className='textCenter_t'>
                                Montant : 12.500 xaf
                            </p>
                            <p className='textCenter_t'>
                                Nombre d’articles : 6
                            </p>
                            <span className='textCenterRed_t'>
                                En attente
                            </span>
                            <a href='javascript:;' className='btnShowDetailsHisto_t'>
                                Voir les details
                            </a>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                    <div className="historyBlock_t">
                            <div className='blockNameAvatar_t'>
                                <span className='textAvatarBlock_t'>
                                    {/* <div className='imgAvatarHisto_t'></div> */}
                                    <img
                                        src={require("../../../../images/HistoAvatar4.png")}
                                        alt="Camera_t"
                                        className="imgAvatarHisto_t" />

                                    Esther Howard
                                </span>
                            </div>
                            <p className='textCenter_t'>
                                Code : 0023856
                            </p>
                            <p className='textCenter_t'>
                                Montant : 2000 xaf
                            </p>
                            <p className='textCenter_t'>
                                Nombre d’articles : 23
                            </p>
                            <span className='textCenterGreen_t'>
                                Recu
                            </span>
                            <a href='javascript:;' className='btnShowDetailsHisto_t'>
                                Voir les details
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Historique