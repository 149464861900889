import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import fr from 'ree-validate/dist/locale/fr';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, matchPath, withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import AuthService from '../../../services/auth';
import ProductService from '../../../services/product';
import { lightTheme, darkTheme } from '../../../js/theme';
import { GlobalStyles } from '../../../js/global';
import { changeNavigatorTheme, auth_t, prodId_t, showAlertHomeProduct, home_p, tabsCatValueFunction } from '../../../store/actions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ProductListPageComponent from './Components/ProductListPageComponent';
import HeaderUsersMobile from './../../layout/HeaderUsersMobile';
import MenuCategiries from '../Home/Components/MenuCategiries';
import NewHeader from '../../layout/NewHeader5';
import MobileCategoriesMenu from '../../layout/HeaderComponents/MobileCategoriesMenu';

// Fonction pour chiffrer une valeur avec une clé
const encryptValue = (value, key) => {
  return CryptoJS.AES.encrypt(value, key).toString();
};

const getParams = (pathname) => {
  const macthProfile = matchPath(pathname, {
    path: `/products/:id1?/:id2?/:id3?/:id4?/:id5?/:id6?`,
  });
  return (macthProfile && macthProfile.params) || {};
}

class Page extends Component {
  constructor(props) {
    super();
    this.validator = new ReeValidate.Validator({
      lastname: 'required',
      firstname: 'required',
      cni_t: 'required',
      address: 'required',
      email: 'required|email',
      password: 'required|min:6',
      country: 'required',
      city: 'required',
      description: 'required',
    });
    this.validator.localize('fr', fr)
    this.state = {
      postregister: {
        lastname: '',
        firstname: '',
        country: null,
        address: '',
        email: '',
        phone: '',
        password: '',
        description: '',
        newsletter: false,
        privacy_police: null,
        sexe: '',
        date: new Date(),
        cni_t: ''
      },
      errors: this.validator.errors,
      offline: false,
      theme_bool: false,
      langBool: true,
      def_theme: '',
      eye_password_bool: true,
      checked: true,
      checked_2: false,
      checked_3: false,
      checked_switch: true,
      show_t: 'hidden_t',
      text_or_not: false,
      isLoading: false,
      first_nav_btn_t: true,
      second_nav_btn_t: false,
      dir_btn_suiv_t: false,
      show_first_tab: true,
      show_second_tab: false,
      dir_btn_prec_2_t: false,
      isValue: false,
      email_exit: false,
      email_not_val: '',
      searchR_t: '',
      options_fr: [
        { value: 'en', label: "Anglais" }
      ],
      options_eng: [
        { value: 'fr', label: "Francais" }
      ],
      options_devise: [
        { value: '€', label: "Dollar" }
      ],
      option_country: [
        { value: 'cmr', label: "Cameroun" },
        { value: 'cg', label: "Congo k" }
      ],
      option_prix: [
        { value: '1000', label: "1000F" },
        { value: '2000', label: "2000F" }
      ],
      pays_value: '',
      country_value: '',
      prix_t: null,
      devise_t: 'FCFA',
      prix2_t: null,
      searchName: '',
      option_city: [
        { value: 'Dla', label: "Douala" },
        { value: 'Ynde', label: "Yaounde" }
      ],
      city_id: null,
      option_sexe: [
        { value: 'Masculin', label: 'Masculin' },
        { value: 'Feminin', label: 'Feminin' }
      ],
      categorie_value: '',
      categorieDefault_value: '',
      option_categorie: [
        { value: ' ', label: " " }
      ],
      optionsResp: [
        { value: ' ', label: " " }
      ],
      dropdownBtn_t: false,
      greater_than: '',
      input1_bool_t: true,
      input2_bool_t: false,
      show_val_interv: false,
      interval_error: false,
      bool_val: false,
      index_t: null,
      products: [],
      lastNewProducts: [],
      categorieLabel: [],
      filterAllProdCat: [],
      textTriePrix: '',
      showTrieBool_t: false,
      total: 8,
      pageNo: 1,
      limit: 16,
      total_pages: 0,
      current_page: 0,
      per_page: 2,
      count: 8,
      country_t: null,
      countryHash_t: '',
      cartValue: [],
      maxValue: null,
      minValue: null,
      toggleBool_t: false,
      allIdsLabel_t: [],
      allIdsValue_t: [],
      paginationProductValue: 'product',
      categoriesFucnt: [],
      country_idFucnt: null,
      city_idFucnt: null,
      price_minFucnt: null,
      price_maxFucnt: null,
      nameFucnt: '',
      hasMore: true,
      searchTimeout: null,
      open: false,
      minFieldSetInputValue: '',
      maxFieldSetInputValue: '',
      searchProducts_t: [],
      tabsCatValue: 0,
      urlPath: null,
      includesForAllProducts_t: '?include=shop.provider.user.country,annexesImages',
      includeswithSearchForAllProducts_t: '&include=shop.provider.user.country,annexesImages',
      allCategories: []
    };
    this.theme_botton_D = this.theme_botton_D.bind(this);
    this.theme_botton_W = this.theme_botton_W.bind(this);
    this.toggle = this.toggle.bind(this);
    this.checked_t = this.checked_t.bind(this);
    this.checked_rad1_t = this.checked_rad1_t.bind(this);
    this.checked_rad2_t = this.checked_rad2_t.bind(this);
    this.checked_rad3_t = this.checked_rad3_t.bind(this);
    this.for_remove_js = this.for_remove_js.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this)
  }
  // componentWillUpdate(prevProps, prevState) {

  // }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.match !== prevState.match) {
      let tabId1 = nextProps.match.params.id1 !== undefined ? nextProps.match.params.id1.split(':') : []
      let tabId2 = nextProps.match.params.id2 !== undefined ? nextProps.match.params.id2.split(':') : []
      let tabId3 = nextProps.match.params.id3 !== undefined ? nextProps.match.params.id3.split(':') : []
      let tabId4 = nextProps.match.params.id4 !== undefined ? nextProps.match.params.id4.split(':') : []
      let tabId5 = nextProps.match.params.id5 !== undefined ? nextProps.match.params.id5.split(':') : []
      let tabId6 = nextProps.match.params.id6 !== undefined ? nextProps.match.params.id6.split(':') : []

      let tab = [];
      let tab1 = [];
      if (tabId1.length > 0) {
        tab.push(tabId1[0]);
        tab1.push(tabId1[1]);
      }
      if (tabId2.length > 0) {
        tab.push(tabId2[0]);
        tab1.push(tabId2[1]);
      }
      if (tabId3.length > 0) {
        tab.push(tabId3[0]);
        tab1.push(tabId3[1]);
      }
      if (tabId4.length > 0) {
        tab.push(tabId4[0]);
        tab1.push(tabId4[1]);
      }
      if (tabId5.length > 0) {
        tab.push(tabId5[0]);
        tab1.push(tabId5[1]);
      }
      if (tabId6.length > 0) {
        tab.push(tabId6[0]);
        tab1.push(tabId6[1]);
      }

      return {
        allIdsLabel_t: tab,
        allIdsValue_t: tab1,
      }
    }
  }
  UNSAFE_componentWillMount() {
    if (this.props.def_theme === '') {
      this.check_theme();
    }
  }

  componentWillUnmount() {
    const { searchTimeout } = this.state;
    // Nettoyez le timeout lors du démontage du composant pour éviter les fuites de mémoire
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
  }

  componentDidMount() {

    this.handleShowAlert();

    this.props.dispatch(auth_t({ __iA: true, __iV: true }));
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: true });
    }
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: false });
    }
    if (this.props.i18n.language === 'fr-FR' || 'fr') {
      this.setState({ langBool: true })
    }
    if (this.props.i18n.language === 'en') {
      this.setState({ langBool: false })
    }
    this.allCountries();

    const { pathname, search } = this.props.location;

    // this.setState({ urlPath: url})

    // const currentUrl = window.location.href;


    // const currentUrlCheck = currentUrl.includes("?")


    // const parts = currentUrlCheck ? currentUrl.split("?") : [currentUrl];
    // let matches = parts[0].match(/categorie:([\d,]+)/);

    // if (parts.length > 1) {

    //   const { categorieLabel } = this.state;

    //   let elements = parts[1].split("+"); // Diviser la chaîne en un tableau ["Electroniques", "Alimentaires"]
    //   let valeurs = matches[1].split(",");

    //   elements.forEach((element, index) => {
    //     categorieLabel.push({ value: valeurs[index - 1], label: element.replace(/%C3%A9/g, "é").replace(/%20/g, " ") });
    //   });

    //   this.setState({ categorieLabel })


    // } else {
    //   this.setState({ categorieLabel: [] })
    // }

    // const currentParams = getParams(pathname);

    // let tabId1 = currentParams?.id1 ? currentParams.id1.split(':') : []
    // let tabId2 = currentParams?.id2 ? currentParams.id2.split(':') : []
    // let tabId3 = currentParams?.id3 ? currentParams.id3.split(':') : []
    // let tabId4 = currentParams?.id4 ? currentParams.id4.split(':') : []
    // let tabId5 = currentParams?.id5 ? currentParams.id5.split(':') : []
    // let tabId6 = currentParams?.id6 ? currentParams.id6.split(':') : []

    // let tab = [];
    // let tab1 = [];
    // if (tabId1.length > 0) {
    //   tab.push(tabId1[0]);
    //   tab1.push(tabId1[1]);
    // }

    // if (tabId2.length > 0) {
    //   tab.push(tabId2[0]);
    //   tab1.push(tabId2[1]);
    // }

    // if (tabId3.length > 0) {
    //   tab.push(tabId3[0]);
    //   tab1.push(tabId3[1]);
    // }

    // if (tabId4.length > 0) {
    //   tab.push(tabId4[0]);
    //   tab1.push(tabId4[1]);
    // }

    // if (tabId5.length > 0) {
    //   tab.push(tabId5[0]);
    //   tab1.push(tabId5[1]);
    // }

    // if (tabId6.length > 0) {
    //   tab.push(tabId6[0]);
    //   tab1.push(tabId6[1]);
    // }

    // this.setState({
    //   allIdsLabel_t: tab,
    //   allIdsValue_t: tab1
    // });


    // this.filterAllProducts(
    //   ((tabId1.length > 0 || tabId2.length > 0 || tabId3.length > 0 || tabId4.length > 0 || tabId5.length > 0 || tabId6.length > 0)
    //     && tab.includes("categorie") ? tab1[tab.indexOf('categorie')]
    //     : []),
    //   ((tabId1.length > 0 || tabId2.length > 0 || tabId3.length > 0 || tabId4.length > 0 || tabId5.length > 0 || tabId6.length > 0)
    //     && tab.includes("country") ? tab1[tab.indexOf('country')]
    //     : null),
    //   ((tabId1.length > 0 || tabId2.length > 0 || tabId3.length > 0 || tabId4.length > 0 || tabId5.length > 0 || tabId6.length > 0)
    //     && tab.includes("city") ? tab1[tab.indexOf('city')]
    //     : null),
    //   ((tabId1.length > 0 || tabId2.length > 0 || tabId3.length > 0 || tabId4.length > 0 || tabId5.length > 0 || tabId6.length > 0)
    //     && tab.includes("mval") ? tab1[tab.indexOf('mval')]
    //     : null),
    //   ((tabId1.length > 0 || tabId2.length > 0 || tabId3.length > 0 || tabId4.length > 0 || tabId5.length > 0 || tabId6.length > 0)
    //     && tab.includes("mxval") ? tab1[tab.indexOf('mxval')]
    //     : null),
    //   ((tabId1.length > 0 || tabId2.length > 0 || tabId3.length > 0 || tabId4.length > 0 || tabId5.length > 0 || tabId6.length > 0)
    //     && tab.includes("name") ? tab1[tab.indexOf('name')]
    //     : ''),
    //   this.state.pageNo,
    //   this.state.limit
    // );

    this.allCategories();
    this.allCitiesCountry();
    this.allProductsBycategories(!!search ? pathname + search + this.state.includeswithSearchForAllProducts_t : pathname + this.state.includesForAllProducts_t);

  }

  allProductsBycategories = (url) => {
    this.props.dispatch(ProductService.getAllProductsBycategories(url, this.state.limit, this.state.pageNo))
      .then((result) => {
        let data = result;

        this.setState({
          current_page: result.meta.pagination.current_page,
          total_pages: result.meta.pagination.total_pages,
          per_page: result.meta.pagination.per_page,
          count: result.meta.pagination.count,
          total: result.meta.pagination.total,
          products: data.data

        });

        this.setState({
          hasMore: result.meta.pagination.current_page < result.meta.pagination.total_pages ?
            true
            :
            false
        })

        // if (
        //   categories.length === 0 &&
        //   !!country_id &&
        //   city_value_id === null &&
        //   price_min === null &&
        //   price_max === null &&
        //   name === ''
        // ) {
        //   this.allCitiesCountry(country_value_id);
        //   this.searchCountry(country_value_id);
        // }

      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  allCategories = () => {
    this.props.dispatch(ProductService.getAllCategories())
      .then((result) => {
        let data = result.data;

        const categories = this.buildCategoryHierarchy(data);

        let optionsHome = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));

        this.setState({ option_categorie: optionsHome, allCategories: categories });


        let initResp = [{ value: '', label: 'Tout' }]
        let optionsResp = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
        initResp = initResp.concat(optionsResp);
        let options = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));

        // if (this.state.categoriesFucnt.length > 0) {
        //   let elements = this.state.categoriesFucnt?.split(","); // Divise la chaîne de caractères en fonction de la virgule

        //   let removeDataSelectedFromOptions = options.filter(item => !elements.some(element => element === String(item.value)));

        //   this.setState({
        //     option_categorie: removeDataSelectedFromOptions,
        //     optionsResp: initResp
        //   });

        // } else {
        //   this.setState({ option_categorie: options, optionsResp: initResp });
        // }



      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  buildCategoryHierarchy = (data) => {
    // console.log("VBVV ", categories); return;

    const categoryMap = {};
    const hierarchy = [];

    // Map categories by slug
    data.forEach(item => {
      categoryMap[item.slug] = {
        name: item.name,
        iconUrl: item.images,
        path: item.url_slug,
        slug: item.slug,
        level: !!item.level ? parseInt(item.level) : 0,
        parent_id: item.parent_id,
        subcategories: []
      };
    });

    // Build hierarchy based on level and url_slug
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.level === 0 || category.level === '') {
        hierarchy.push(category);
      } else {
        const parentSlug = category.path.split('/')[category.level];
        if (categoryMap[parentSlug]) {
          categoryMap[parentSlug].subcategories.push(category);
        }
      }
    });

    // Update path if subcategories length is 0
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.subcategories.length > 0) {
        delete category.path;
      }
    });

    return hierarchy;
  }

  handleClick(lang) {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    this.setState({ langBool: !this.state.langBool });
  }
  checked_t() {
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  checked_rad1_t() {
    if (this.state.checked) {
      this.setState({ checked: true, checked_2: false, checked_3: false });
    }
    this.setState({ checked_2: false, checked_3: false });
  }
  checked_rad2_t() {
    if (this.state.checked_2) {
      this.setState({ checked: false, checked_2: true, checked_3: false });
    }
    this.setState({ checked: false, checked_3: false });
  }
  checked_rad3_t() {
    if (this.state.checked_3) {
      this.setState({ checked: false, checked_2: false, checked_3: true });
    }
    this.setState({ checked: false, checked_2: false });
  }
  toggle() {
    this.setState({ eye_password_bool: !this.state.eye_password_bool })
  }

  check_theme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // console.log('🎉 Dark mode is supported');
      this.setState({ def_theme: 'dark', theme_bool: true, checked_switch: false })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      // console.log('🎉 light mode is supported');
      this.setState({ def_theme: 'light', theme_bool: true, checked_switch: true })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
    }
  }
  for_remove_js(e) {
    e.preventDefault();
  }
  theme_botton_D(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'dark', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }

  theme_botton_W(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'light', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    const { postregister } = this.state;

    postregister[name] = value;
    if (name === 'email') {
      this.setState({ email_exit: false })
    }
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors, postregister })
      });
    this.check_first_name(postregister)
  }
  check_first_name = (postregister) => {
    if ((postregister.lastname !== '' &&
      postregister.firstname !== '' &&
      postregister.country !== '' &&
      postregister.phone !== '' &&
      postregister.date !== '' &&
      postregister.sexe !== ''
    )) {
      this.setState({ dir_btn_suiv_t: true, isValue: true })
    }
  }
  handleChangeCheck = (e, label) => {
    const value = e.target.value;
    const { postregister } = this.state;
    if (label === 'newsletter') {
      postregister[label] = value;
      this.setState({ postregister })
    }
  }

  allCountries() {
    this.props.dispatch(AuthService.getAllCountries())
      .then((result) => {

        let data = result.data;
        let options = data.map((value, index) => (
          {
            value: { id: value.id, real_id: value.real_id },
            label: this.state.langBool === true ? value.name_fr : value.name_en
          }
        ));
        this.setState({
          isLoading: false,
          showAlert: false,
          alert2: null,
          option_country: options
        });
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  allCitiesCountry(value) {
    const countryRId = 33

    this.props.dispatch(AuthService.getCitiesCountry(countryRId))
      .then((result) => {

        let data = result;
        let options = data.map((value, index) => (

          {
            value: value.id,
            label: value.name
          }
        ));
        this.setState({
          isLoading: false,
          showAlert: false,
          alert2: null,
          option_city: options
        });
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  handleSubmit(postregister) {
    this.setState({ isLoading: true });
    let data = {
      lastname: postregister.lastname,
      firstname: postregister.firstname,
      country_id: postregister.country,
      address: postregister.address,
      email: postregister.email,
      phone: postregister.phone,
      password: postregister.password,
      gender: postregister.sexe,
      birth: postregister.date
    }
    navigator.onLine ?
      this.props.dispatch(AuthService.register(data))
        .then((result) => {
          this.props.history.push("/login");
        })
        .catch((errors) => {
          if (errors.statusCode.response.data.errors.email) {

            this.setState({
              isLoading: false,
              email_exit: true,
              email_not_val: errors.statusCode.response.data.errors.email[0],
            });
          } else {
            console.log('errors ', errors);
            this.setState({ isLoading: false });
          }
        })
      :
      this.setState({ offline: true, isLoading: false })
  }

  async validateAndSubmit(e) {
    e.preventDefault()

    const { postregister } = this.state
    const { errors } = this.validator

    const valid = this.validator.validateAll(postregister)

    if (valid) {
      this.handleSubmit(postregister)
    } else {
      this.setState({ errors })
    }
  }

  precedent_t = (e, isValue, label) => {
    if (label === 'premier' && isValue === true) {
      this.setState({
        show_perso_sa: false,
        show_loca_sa: false,
        show_params_sa: false,
      })
    }
    if (label === 'second' && isValue === true) {
      this.setState({
        show_second_tab: false,
        show_first_tab: true,
        dir_btn_suiv_t: true,
        first_nav_btn_t: true,
        second_nav_btn_t: false,
        dir_btn_prec_2_t: false,
        save_t: false
      })
    }
    e.preventDefault();
  }


  Suivant_t = (e, isValue, label) => {
    const { postregister } = this.state;
    if (label === 'premier' && isValue === true) {
      this.setState({
        show_second_tab: true,
        show_first_tab: false,
        first_nav_btn_t: false,
        second_nav_btn_t: true,
        dir_btn_suiv_2_t: false,
        dir_btn_prec_2_t: true,
      })
    }
    if (label === 'second' && isValue === true) {
      this.setState({
        show_perso_sa: false,
        show_loca_sa: false,
        show_params_sa: true,
        first_nav_btn_t: false,
        second_nav_btn_t: false,
        isValue_suiv_sec: true,
        error_bool: false,
      })
    }
    e.preventDefault();
  }

  gotToParentValue = (value, label) => {
    const { categorieLabel, option_categorie, filterAllProdCat } = this.state;
    const { postregister } = this.state;
    if (label === "Francais" || label === "French") {
      const options_fr = [{ value: 'en', label: "Anglais" }];
      const options_eng = [{ value: 'fr', label: "Francais" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("fr");
    }
    if (label === "Anglais" || label === "English") {
      const options_fr = [{ value: 'en', label: "English" }];
      const options_eng = [{ value: 'fr', label: "French" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("en");
    }
    if (label === "Dollar") {
      this.setState({
        device_data: value
      })
    }
    if (label === "searchR_t") {
      alert("Search");
      // let tab = this.state.allIdsLabel_t;
      // let tab1 = this.state.allIdsValue_t;
      // let text = [];
      // this.setState({ searchR_t: value })

      // if (tab.includes("name")) {
      //   let index = tab.indexOf('name');
      //   tab.splice(index, 1);
      //   tab1.splice(index, 1);

      //   for (var i = 0; i < tab.length; i++) {
      //     text += tab[i] + ':' + tab1[i] + '/'
      //   }
      // } else {

      //   for (var i = 0; i < tab.length; i++) {
      //     text += tab[i] + ':' + tab1[i] + '/'
      //   }
      // }

      // this.filterAllProducts(
      //   tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : [],
      //   tab.includes("country") ? tab1[tab.indexOf('country')] : null,
      //   tab.includes("city") ? tab1[tab.indexOf('city')] : null,
      //   tab.includes("mval") ? tab1[tab.indexOf('mval')] : null,
      //   tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : null,
      //   tab.includes("name") ? tab1[tab.indexOf('name')] : value,
      //   this.state.pageNo,
      //   this.state.limit
      // )

      // let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
      // let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;

      // this.props.history.push(`/products/${text}name:${value}?${!!nouvelleChaine ? nouvelleChaine : ''}`);

      const urlPath = this.state.urlPath.includes("?") ? this.state.urlPath + "&name=" + value : this.state.urlPath + "?name=" + value
      this.allProductsBycategories(urlPath, this.state.limit, this.state.pageNo)

      this.props.history.push(urlPath);
    }
    if (label === "country") {

      // this.setState({
      //   country_t: value.value.real_id,
      //   countryHash_t: value.value.id,
      //   city_id: null
      // })

      // this.allCitiesCountry(value.value.real_id);

      // let tab = this.state.allIdsLabel_t;
      // let tab1 = this.state.allIdsValue_t;
      // let text = [];


      // if (tab.includes("country")) {
      //   let index = tab.indexOf('country');
      //   tab.splice(index, 1);
      //   tab1.splice(index, 1);

      //   for (var i = 0; i < tab.length; i++) {
      //     text += tab[i] + ':' + tab1[i] + '/'
      //   }
      // } else {

      //   for (var i = 0; i < tab.length; i++) {
      //     text += tab[i] + ':' + tab1[i] + '/'
      //   }
      // }

      // this.filterAllProducts(
      //   tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : [],
      //   tab.includes("country") ? tab1[tab.indexOf('country')] : `${value.value.real_id}+${value.label}`,
      //   tab.includes("city") ? tab1[tab.indexOf('city')] : null,
      //   tab.includes("mval") ? tab1[tab.indexOf('mval')] : null,
      //   tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : null,
      //   tab.includes("name") ? tab1[tab.indexOf('name')] : '',
      //   this.state.pageNo,
      //   this.state.limit
      // )
      // const { categorieLabel } = this.state;

      // let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
      // let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;
      // this.props.history.push(`/products/${text}country:${value.value.real_id}+${value.label}${!!nouvelleChaine ? '?' + nouvelleChaine : ''}`);

      const urlPath = this.state.urlPath.includes("?") ? this.state.urlPath + "&country=" + value.value.real_id : this.state.urlPath + "?country=" + value.value.real_id
      this.allProductsBycategories(urlPath, this.state.limit, this.state.pageNo)

      this.props.history.push(urlPath);

    }
    if (label === "city_id") {
      // let tab = this.state.allIdsLabel_t;
      // let tab1 = this.state.allIdsValue_t;
      // let text = [];

      // if (tab.includes("city")) {
      //   let index = tab.indexOf('city');
      //   tab.splice(index, 1);
      //   tab1.splice(index, 1);

      //   for (var i = 0; i < tab.length; i++) {
      //     text += tab[i] + ':' + tab1[i] + '/'
      //   }
      // } else {

      //   for (var i = 0; i < tab.length; i++) {
      //     text += tab[i] + ':' + tab1[i] + '/'
      //   }
      // }

      // this.setState({ city_id: value.value }, () => this.filterAllProducts(
      //   tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : [],
      //   tab.includes("country") ? tab1[tab.indexOf('country')] : null,
      //   tab.includes("city") ? tab1[tab.indexOf('city')] : `${value.value}+${value.label}`,
      //   tab.includes("mval") ? tab1[tab.indexOf('mval')] : null,
      //   tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : null,
      //   tab.includes("name") ? tab1[tab.indexOf('name')] : '',
      //   this.state.pageNo,
      //   this.state.limit
      // ));

      // const { categorieLabel } = this.state;

      // let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
      // let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;

      // this.props.history.push(`/products/${text}city:${value.value}+${value.label}${!!nouvelleChaine ? '?' + nouvelleChaine : ''}`);


      const urlPath = this.state.urlPath.includes("?") ? this.state.urlPath + "&city=" + value.value : this.state.urlPath + "?city=" + value.value
      this.allProductsBycategories(urlPath, this.state.limit, this.state.pageNo)

      this.props.history.push(urlPath);
    }
  }

  searchNameWithCallback = (value) => {
    // console.log("GGG ", value); return;
    const { searchTimeout } = this.state;
    this.setState({ searchR_t: value })

    // Annulez le délai existant si l'utilisateur continue de taper
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    // Configurez un nouveau délai pour déclencher la recherche après 2 secondes
    const timeoutId = setTimeout(() => {
      this.filterByName(value);
    }, 500);
    this.setState({ searchTimeout: timeoutId });
  }

  filterByName = (value) => {
    this.props.dispatch(ProductService.filterByName(value))
      .then((result) => {
        let data = result.data;

        let optionsVal = data.map((value, index) => (
          {
            name: value.name,
            value: value.id,
            slug: value.slug,
            shopName: value.shop.data.name,
            image: value.annexesImages.data[0].first_annex,
            url_slug: value.category.data.url_slug
            // urlPath: this.state.urlPath,
          }
        ));

        this.setState({ searchProducts_t: optionsVal });

      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            // show_t: 'show_t', 
            isLoading: false
          });
        }
      })
  }

  searchCityWithCallback = (value, label) => {
    const { searchTimeout } = this.state;
    this.setState({ searchR_t: value })

    // Annulez le délai existant si l'utilisateur continue de taper
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    // Configurez un nouveau délai pour déclencher la recherche après 2 secondes
    const timeoutId = setTimeout(() => {
      this.gotToParentValue(value, label);
    }, 500);
    this.setState({ searchTimeout: timeoutId });
  }

  gotToParentCatValue = (value, label, name) => {

    if (name === "cat_prod") {
      const { categorieLabel, option_categorie, filterAllProdCat } = this.state;
      if (categorieLabel.length < 4) {
        let indexCatTab = option_categorie.map((v) => v.label).indexOf(label);
        categorieLabel.push({ value: value, label: label });
        filterAllProdCat.push(value);
        option_categorie.splice(indexCatTab, 1);

        // this.props.dispatch(home_p({__hp: false}));

        this.setState({
          bool_val: false,
          categorieLabel: categorieLabel,
          option_categorie: option_categorie,
          filterAllProdCat: filterAllProdCat
        });

        let tab = this.state.allIdsLabel_t;
        let tab1 = this.state.allIdsValue_t;
        let text = [];

        if (tab.includes("categorie")) {
          let index = tab.indexOf('categorie');
          tab.splice(index, 1);
          tab1.splice(index, 1);

          for (var i = 0; i < tab.length; i++) {
            text += tab[i] + ':' + tab1[i] + '/'
          }
        } else {

          for (var i = 0; i < tab.length; i++) {
            text += tab[i] + ':' + tab1[i] + '/'
          }
        }

        this.filterAllProducts(
          tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : filterAllProdCat,
          tab.includes("country") ? tab1[tab.indexOf('country')] : null,
          tab.includes("city") ? tab1[tab.indexOf('city')] : null,
          tab.includes("mval") ? tab1[tab.indexOf('mval')] : null,
          tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : null,
          tab.includes("name") ? tab1[tab.indexOf('name')] : '',
          this.state.pageNo,
          this.state.limit
        );
        let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
        let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;
        this.props.history.push(`/products/${text}categorie:${filterAllProdCat.toString()}?${nouvelleChaine}`);
      } else {
        alert("Le maximum de catégories atteint");
      }

    }

    if (name === "cat_prod_mobil") {
      this.setState({ categorieLabel: [], filterAllProdCat: [] })
      const { categorieLabel, option_categorie, filterAllProdCat } = this.state;
      let indexCatTab = option_categorie.map((v) => v.label).indexOf(label);
      // categorieLabel.push({ value: value, label: label });
      // filterAllProdCat.push(value);
      // option_categorie.splice(indexCatTab, 1);

      // this.props.dispatch(home_p({__hp: false}));

      this.setState({
        bool_val: false,
        categorieLabel: [{ value: value, label: label }],
        filterAllProdCat: [value]
      });

      let tab = this.state.allIdsLabel_t;
      let tab1 = this.state.allIdsValue_t;
      let text = [];

      if (tab.includes("categorie")) {
        let index = tab.indexOf('categorie');
        tab.splice(index, 1);
        tab1.splice(index, 1);

        for (var i = 0; i < tab.length; i++) {
          text += tab[i] + ':' + tab1[i] + '/'
        }
      } else {

        for (var i = 0; i < tab.length; i++) {
          text += tab[i] + ':' + tab1[i] + '/'
        }
      }

      this.filterAllProducts(
        tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : filterAllProdCat,
        tab.includes("country") ? tab1[tab.indexOf('country')] : null,
        tab.includes("city") ? tab1[tab.indexOf('city')] : null,
        tab.includes("mval") ? tab1[tab.indexOf('mval')] : null,
        tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : null,
        tab.includes("name") ? tab1[tab.indexOf('name')] : '',
        this.state.pageNo,
        this.state.limit
      )
      let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
      let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;

      this.props.history.push(`/products/${text}categorie:${[value].toString()}?${nouvelleChaine}`);
    }

  }
  saveCount = (e) => {
    e.preventDefault();
  }
  onDropdownBtn_t = () => {
    this.setState({ dropdownBtn_t: !this.state.dropdownBtn_t, interval_error: false })
  }
  gotToParentShowInput = (interOrNot, value) => {
    if (interOrNot === 'intervalle') {
      this.setState({
        input2_bool_t: true,
        input1_bool_t: true,
        show_val_interv: true,
        // interval_error:false,
        maxValue: this.state.prix_t,
        minValue: this.state.prix2_t,
        textTriePrix: 'intervalle '
      })
    } else {
      this.setState({
        input2_bool_t: false,
        input1_bool_t: true,
        show_val_interv: false,
        interval_error: false
      })
      if (parseInt(value) === 1 && this.state.prix_t !== null) {
        this.setState({ textTriePrix: 'Moins de ', maxValue: this.state.prix_t });
      }
      if (parseInt(value) === 2 && this.state.prix_t !== null) {
        this.setState({ textTriePrix: 'Plus de ', minValue: this.state.prix_t });
      }
    }
  }
  onChange_t = (e) => {
    if (e.target.name === 'prix_t') {
      if (this.state.textTriePrix === 'Moins de ') {
        this.setState({
          prix_t: e.target.value,
          minValue: e.target.value,
          maxValue: null
        })
      }
      if (this.state.textTriePrix === 'Plus de ') {
        this.setState({
          prix_t: e.target.value,
          minValue: null,
          maxValue: e.target.value
        })
      } else {
        this.setState({ prix_t: e.target.value, interval_error: false })
      }
    }

    if (e.target.name === 'prix2_t') {
      this.setState({
        prix2_t: e.target.value,
        minValue: this.state.prix_t,
        maxValue: e.target.value
      })
    }
  }

  handleMinChange = (event) => {
    this.setState({ minFieldSetInputValue: event.target.value });
  };

  handleMaxChange = (event) => {
    this.setState({ maxFieldSetInputValue: event.target.value });
  };

  // handleGoClick = () => {
  //   console.log("Valeur Min :", this.state.minFieldSetInputValue);
  //   console.log("Valeur Max :", this.state.maxFieldSetInputValue);
  //   // Vous pouvez faire d'autres opérations avec les valeurs ici
  // };

  handleGoClick = (e) => {
    if (!!this.state.minFieldSetInputValue && !!this.state.maxFieldSetInputValue) {
      const { categorieLabel, option_categorie, filterAllProdCat } = this.state;

      let tab = this.state.allIdsLabel_t;
      let tab1 = this.state.allIdsValue_t;
      let text = [];

      if (tab.includes("mval")) {
        let index = tab.indexOf('mval');
        tab.splice(index, 1);
        tab1.splice(index, 1);
      }

      if (tab.includes("mxval")) {
        let index = tab.indexOf('mxval');
        tab.splice(index, 1);
        tab1.splice(index, 1);

        for (var i = 0; i < tab.length; i++) {
          text += tab[i] + ':' + tab1[i] + '/'
        }
      } else {

        for (var i = 0; i < tab.length; i++) {
          text += tab[i] + ':' + tab1[i] + '/'
        }
      }

      this.filterAllProducts(
        tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : [],
        tab.includes("country") ? tab1[tab.indexOf('country')] : null,
        tab.includes("city") ? tab1[tab.indexOf('city')] : null,
        tab.includes("mval") ? tab1[tab.indexOf('mval')] : this.state.minFieldSetInputValue,
        tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : this.state.maxFieldSetInputValue,
        tab.includes("name") ? tab1[tab.indexOf('name')] : '',
        this.state.pageNo,
        this.state.limit
      )

      let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
      let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;

      this.props.history.push(`/products/${text}mxval:${this.state.maxFieldSetInputValue}/mval:${this.state.minFieldSetInputValue}${!!nouvelleChaine ? '?' + nouvelleChaine : ''}`);

    }
    else {
      // this.setState({
      //   interval_error: true,
      //   showTrieBool_t: true,
      //   dropdownBtn_t: !this.state.dropdownBtn_t
      // })

      if (!!this.state.minFieldSetInputValue === true && !!this.state.maxFieldSetInputValue === false) {

        // if (this.state.textTriePrix === 'Moins de ' && this.state.prix_t !== null) {
        const { categorieLabel, option_categorie, filterAllProdCat } = this.state;
        // this.setState({ minValue: null });

        let tab = this.state.allIdsLabel_t;
        let tab1 = this.state.allIdsValue_t;
        let text = [];

        if (tab.includes("mval")) {
          let index = tab.indexOf('mval');
          tab.splice(index, 1);
          tab1.splice(index, 1);
        }

        if (tab.includes("mxval")) {
          let index = tab.indexOf('mxval');
          tab.splice(index, 1);
          tab1.splice(index, 1);

          for (var i = 0; i < tab.length; i++) {
            text += tab[i] + ':' + tab1[i] + '/'
          }
        } else {

          for (var i = 0; i < tab.length; i++) {
            text += tab[i] + ':' + tab1[i] + '/'
          }
        }

        this.filterAllProducts(
          tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : [],
          tab.includes("country") ? tab1[tab.indexOf('country')] : null,
          tab.includes("city") ? tab1[tab.indexOf('city')] : null,
          tab.includes("mval") ? tab1[tab.indexOf('mval')] : this.state.minFieldSetInputValue,
          tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : null,
          tab.includes("name") ? tab1[tab.indexOf('name')] : '',
          this.state.pageNo,
          this.state.limit
        )
        let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
        let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;

        this.props.history.push(`/products/${text}mval:${this.state.minFieldSetInputValue}${!!nouvelleChaine ? '?' + nouvelleChaine : ''}`);

      }
      if (!!this.state.minFieldSetInputValue === false && !!this.state.maxFieldSetInputValue === true) {
        // if (this.state.textTriePrix === 'Plus de ' && this.state.prix_t !== null) {
        const { categorieLabel, option_categorie, filterAllProdCat } = this.state;
        // this.setState({ maxValue: null });

        let tab = this.state.allIdsLabel_t;
        let tab1 = this.state.allIdsValue_t;
        let text = [];

        if (tab.includes("mxval")) {
          let index2 = tab.indexOf('mxval');
          tab.splice(index2, 1);
          tab1.splice(index2, 1);
        }

        if (tab.includes("mval")) {
          let index = tab.indexOf('mval');
          tab.splice(index, 1);
          tab1.splice(index, 1);

          for (var i = 0; i < tab.length; i++) {
            text += tab[i] + ':' + tab1[i] + '/'
          }
        } else {

          for (var i = 0; i < tab.length; i++) {
            text += tab[i] + ':' + tab1[i] + '/'
          }
        }

        this.filterAllProducts(
          tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : [],
          tab.includes("country") ? tab1[tab.indexOf('country')] : null,
          tab.includes("city") ? tab1[tab.indexOf('city')] : null,
          tab.includes("mval") ? tab1[tab.indexOf('mval')] : null,
          tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : this.state.maxFieldSetInputValue,
          tab.includes("name") ? tab1[tab.indexOf('name')] : '',
          this.state.pageNo,
          this.state.limit
        )

        let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
        let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;

        this.props.history.push(`/products/${text}mval:${this.state.maxFieldSetInputValue}${!!nouvelleChaine ? '?' + nouvelleChaine : ''}`);
      }
    }
    e.preventDefault();
  }


  onPrice_t = (e) => {
    if (this.state.textTriePrix === 'intervalle ') {
      if (parseInt(this.state.prix_t) < parseInt(this.state.prix2_t)) {
        // 'Intervalle is Ok'
        this.setState({
          interval_error: false,
          showTrieBool_t: true,
          dropdownBtn_t: !this.state.dropdownBtn_t,
          maxValue: this.state.prix2_t,
          minValue: this.state.prix_t
        }, () => this.filterAllProducts());
      } else {
        this.setState({
          interval_error: true,
          showTrieBool_t: true,
        })
      }
    }
    else {
      this.setState({
        interval_error: true,
        showTrieBool_t: true,
        dropdownBtn_t: !this.state.dropdownBtn_t
      })
      // this.filterAllProducts(this.state.prix_t);

      if (this.state.textTriePrix === 'Moins de ' && this.state.prix_t !== null) {
        const { categorieLabel, option_categorie, filterAllProdCat } = this.state;
        this.setState({ minValue: null });

        let tab = this.state.allIdsLabel_t;
        let tab1 = this.state.allIdsValue_t;
        let text = [];

        if (tab.includes("mval")) {
          let index = tab.indexOf('mval');
          tab.splice(index, 1);
          tab1.splice(index, 1);
        }

        if (tab.includes("mxval")) {
          let index = tab.indexOf('mxval');
          tab.splice(index, 1);
          tab1.splice(index, 1);

          for (var i = 0; i < tab.length; i++) {
            text += tab[i] + ':' + tab1[i] + '/'
          }
        } else {

          for (var i = 0; i < tab.length; i++) {
            text += tab[i] + ':' + tab1[i] + '/'
          }
        }

        this.filterAllProducts(
          tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : [],
          tab.includes("country") ? tab1[tab.indexOf('country')] : null,
          tab.includes("city") ? tab1[tab.indexOf('city')] : null,
          tab.includes("mval") ? tab1[tab.indexOf('mval')] : null,
          tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : this.state.prix_t,
          tab.includes("name") ? tab1[tab.indexOf('name')] : '',
          this.state.pageNo,
          this.state.limit
        )
        let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
        let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;

        this.props.history.push(`/products/${text}mxval:${this.state.prix_t}${!!nouvelleChaine ? '?' + nouvelleChaine : ''}`);

      }
      if (this.state.textTriePrix === 'Plus de ' && this.state.prix_t !== null) {
        const { categorieLabel, option_categorie, filterAllProdCat } = this.state;
        this.setState({ maxValue: null });

        let tab = this.state.allIdsLabel_t;
        let tab1 = this.state.allIdsValue_t;
        let text = [];

        if (tab.includes("mxval")) {
          let index2 = tab.indexOf('mxval');
          tab.splice(index2, 1);
          tab1.splice(index2, 1);
        }

        if (tab.includes("mval")) {
          let index = tab.indexOf('mval');
          tab.splice(index, 1);
          tab1.splice(index, 1);

          for (var i = 0; i < tab.length; i++) {
            text += tab[i] + ':' + tab1[i] + '/'
          }
        } else {

          for (var i = 0; i < tab.length; i++) {
            text += tab[i] + ':' + tab1[i] + '/'
          }
        }

        this.filterAllProducts(
          tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : [],
          tab.includes("country") ? tab1[tab.indexOf('country')] : null,
          tab.includes("city") ? tab1[tab.indexOf('city')] : null,
          tab.includes("mval") ? tab1[tab.indexOf('mval')] : this.state.prix_t,
          tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : null,
          tab.includes("name") ? tab1[tab.indexOf('name')] : '',
          this.state.pageNo,
          this.state.limit
        )

        let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
        let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;

        this.props.history.push(`/products/${text}mval:${this.state.prix_t}${!!nouvelleChaine ? '?' + nouvelleChaine : ''}`);
      }
    }
    e.preventDefault();
  }

  allProducts = () => {
    this.props.dispatch(ProductService.getAllProducts())
      .then((result) => {
        let data = result.data;
        this.setState({ products: data });
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  price_minFilterAllProducts = (value) => {

    this.props.dispatch(ProductService.price_minFilterAllProducts(value))
      .then((result) => {
        let data = result.data;
        this.setState({ products: data });
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  filterAllProducts = (categories, country_id, city_id, price_min, price_max, name, pageNo, limit) => {
    let country_value_id = country_id;

    let categoriesTab = (categories && categories.length === 0 || categories === '') ?
      categories
      :
      typeof categories === 'string' ?
        categories.split(",")
        :
        categories

    let city_value_id = city_id;
    if (!!country_id) {
      let elements = country_id.split("+");

      country_value_id = elements[0]; // "33"
      let country_name = elements[1]; // "Cameroun"
      this.setState({ country_value: country_name });
    }

    if (!!city_id) {
      let elements = city_id.split("+");

      city_value_id = elements[0]; // "33"
      let city_name = elements[1]; // "Cameroun"
      this.setState({ city_id: city_name });
    }


    this.setState({ searchR_t: name });
    if (limit !== undefined) {
      this.setState({ limit: limit, pageNo: pageNo });
      this.setState({ isLoading: true });
    }
    this.setState({
      categoriesFucnt: categories,
      filterAllProdCat: categoriesTab,
      country_idFucnt: country_value_id,
      // country_idFucnt: country_id,
      // city_idFucnt: city_id,
      city_idFucnt: city_value_id,
      price_minFucnt: price_min,
      price_maxFucnt: price_max,
      nameFucnt: name
    })
    this.props.dispatch(ProductService.filterAllProducts(
      categories,
      // country_id,
      country_value_id,
      // city_id,
      city_value_id,
      price_min,
      price_max,
      name,
      limit,
      // pageNo
      null
    ))
      .then((result) => {
        let data = result.data;
        this.setState({
          current_page: result.meta.pagination.current_page,
          total_pages: result.meta.pagination.total_pages,
          per_page: result.meta.pagination.per_page,
          count: result.meta.pagination.count,
          total: result.meta.pagination.total,
          // products: data
        });


        // if (data.length === 0) {
        this.setState({
          hasMore: result.meta.pagination.current_page < result.meta.pagination.total_pages ?
            true
            :
            false
        })
        // }
        // result.meta.pagination.current_page - 1 < result.meta.pagination.total_pages ?
        //   this.setState({ products: [...this.state.products, ...data] })
        //   :
        //   console.log(null)


        // this.setState({
        //   hasMore: result.meta.pagination.current_page < result.meta.pagination.total_pages ?
        //     true
        //     :
        //     false
        // })
        // this.setState({
        //   pageNo: result.meta.pagination.current_page < result.meta.pagination.total_pages ?
        //     pageNo + 1
        //     :
        //     result.meta.pagination.total_pages
        // })


        if (
          categories.length === 0 &&
          !!country_id &&
          city_value_id === null &&
          price_min === null &&
          price_max === null &&
          name === ''
        ) {
          this.allCitiesCountry(country_value_id);
          this.searchCountry(country_value_id);
        }

      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }


  filterAllProductsInfiniscroll = (categories, country_id, city_id, price_min, price_max, name, pageNo, limit) => {
    let country_value_id = country_id;

    let categoriesTab = (categories && categories.length === 0 || categories === '') ?
      categories
      :
      typeof categories === 'string' ?
        categories.split(",")
        :
        categories

    let city_value_id = city_id;
    if (!!country_id) {
      let elements = country_id.split("+");

      country_value_id = elements[0]; // "33"
      let country_name = elements[1]; // "Cameroun"
      this.setState({ country_value: country_name });
    }

    if (!!city_id) {
      let elements = city_id.split("+");

      city_value_id = elements[0]; // "33"
      let city_name = elements[1]; // "Cameroun"
      this.setState({ city_id: city_name });
    }


    this.setState({ searchR_t: name });
    if (limit !== undefined) {
      this.setState({ limit: limit, pageNo: pageNo });
      this.setState({ isLoading: true });
    }
    this.setState({
      categoriesFucnt: categories,
      filterAllProdCat: categoriesTab,
      country_idFucnt: country_value_id,
      // country_idFucnt: country_id,
      // city_idFucnt: city_id,
      city_idFucnt: city_value_id,
      price_minFucnt: price_min,
      price_maxFucnt: price_max,
      nameFucnt: name
    })
    this.props.dispatch(ProductService.filterAllProducts(
      categories,
      // country_id,
      country_value_id,
      // city_id,
      city_value_id,
      price_min,
      price_max,
      name,
      limit,
      pageNo
    ))
      .then((result) => {
        let data = result.data;
        this.setState({
          current_page: result.meta.pagination.current_page,
          total_pages: result.meta.pagination.total_pages,
          per_page: result.meta.pagination.per_page,
          count: result.meta.pagination.count,
          total: result.meta.pagination.total
          // products: data
        });

        result.meta.pagination.current_page - 1 < result.meta.pagination.total_pages ?
          this.setState({ products: [...this.state.products, ...data] })
          :
          console.log(null)


        this.setState({
          hasMore: result.meta.pagination.current_page < result.meta.pagination.total_pages ?
            true
            :
            false
        })
        this.setState({
          pageNo: result.meta.pagination.current_page < result.meta.pagination.total_pages ?
            pageNo + 1
            :
            result.meta.pagination.total_pages
        })


        if (
          categories.length === 0 &&
          !!country_id &&
          city_value_id === null &&
          price_min === null &&
          price_max === null &&
          name === ''
        ) {
          this.allCitiesCountry(country_value_id);
          this.searchCountry(country_value_id);
        }
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  searchCountry = (country_id) => {

    this.props.dispatch(AuthService.searchCountry(country_id))
      .then((result) => {
        let data = result.data;
        this.setState({ country_value: result.data[0].name_fr });
      })
      .catch((e) => {
        console.log('errors ', e);
      });

  }

  lastNewProduct = () => {
    this.props.dispatch(ProductService.lastNewProduct())
      .then((result) => {
        let data = result.data;
        this.setState({ lastNewProducts: data });
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  onAddProduct = (e) => {
    // Here we use redux for increments and decrements  number in panier icon
    e.preventDefault();
  }
  onProductId = (value) => {
    this.props.dispatch(prodId_t(value));
  }
  parent_categorieLabel = (categorieLabel) => {
    console.log("CategorieLabelTab ", categorieLabel);
    this.setState({ categorieLabel: categorieLabel });
  }
  parent_add_categorie = (labelValue) => {
    const { categorieLabel, option_categorie, filterAllProdCat } = this.state;
    option_categorie.push(labelValue);
    let indexRemoveCat_t = filterAllProdCat.indexOf(parseInt(labelValue.value));
    filterAllProdCat.splice(indexRemoveCat_t, 1);
    // this.setState({
    //   bool_val: true,
    //   option_categorie: option_categorie,
    //   categorie_value: labelValue,
    //   filterAllProdCat
    // });
    // }, () => this.filterAllProducts());

    let tab = this.state.allIdsLabel_t;
    let tab1 = this.state.allIdsValue_t;
    let text = [];

    if (tab.includes("categorie")) {
      let index = tab.indexOf('categorie');
      tab.splice(index, 1);
      tab1.splice(index, 1);

      for (var i = 0; i < tab.length; i++) {
        text += tab[i] + ':' + tab1[i] + '/'
      }
    } else {

      for (var i = 0; i < tab.length; i++) {
        text += tab[i] + ':' + tab1[i] + '/'
      }
    }

    let catNameList = categorieLabel.length > 0 && categorieLabel.map((v, i) => v.label);
    let nouvelleChaine = catNameList.length > 1 ? catNameList.join("+") : catNameList;

    let charUrl = `/products/${text}`;
    let newUrl = charUrl.replace(/\/categorie:\d+(?:,\d+)*\??/, "");

    if (filterAllProdCat.length > 0) {
      // alert(newUrl)
      // this.props.history.push(`/products/${text}${value !== '' ? "categorie:" + filterAllProdCat.toString : ''}${!!nouvelleChaine ? '?' + nouvelleChaine : ''} `);
      this.props.history.push(`${newUrl}categorie:${filterAllProdCat.toString()}?${nouvelleChaine}`);
    } else {
      // alert(newUrl)
      this.props.history.push(`${newUrl}`);
      // this.props.history.push(`/products/${text} `);
    }

    this.filterAllProducts(
      tab.includes("categorie") ? tab1[tab.indexOf('categorie')] : filterAllProdCat,
      tab.includes("country") ? tab1[tab.indexOf('country')] : null,
      tab.includes("city") ? tab1[tab.indexOf('city')] : null,
      tab.includes("mval") ? tab1[tab.indexOf('mval')] : null,
      tab.includes("mxval") ? tab1[tab.indexOf('mxval')] : null,
      tab.includes("name") ? tab1[tab.indexOf('name')] : '',
      this.state.pageNo,
      this.state.limit
    )
  }

  onToggle_t = () => {
    this.setState({ toggleBool_t: !this.state.toggleBool_t });
  }

  handleClose = () => {
    // this.setState({ open: false });
    this.props.dispatch(showAlertHomeProduct(false));
  };

  handleShowAlert = () => {
    // this.setState({ open: true });
    // this.props.dispatch(showAlertHomeProduct(true));
    setTimeout(() => {
      // this.setState({ open: false });
      this.props.dispatch(showAlertHomeProduct(false));
    }, 2500); // Disparition après 1 seconde (2000 millisecondes)
  };


  handleChangeTabsCatValue = (event, newValue) => {
    this.props.dispatch(tabsCatValueFunction(newValue));
    this.setState({ tabsCatValue: newValue });
    if (newValue === 0) {
      this.props.history.push("/");
    }
    if (newValue === 1) {
      this.props.history.push("/products/country:33+Cameroun/categorie:8?Alimentaires");
      window.location.reload();
      // window.location.href = `/login`;
    }
    if (newValue === 2) {
      this.props.history.push("/products/country:33+Cameroun/categorie:20?Épiceries%20et%20boisson%C2%A0");
      window.location.reload();
    }
    if (newValue === 3) {
      this.props.history.push("/products/country:33+Cameroun/categorie:19?Beauté%20et%20bien-être%C2%A0");
      window.location.reload();
    }
    if (newValue === 4) {
      this.props.history.push("/products/country:33+Cameroun/categorie:17?Jouets,%20enfants%20et%20bébés");
      window.location.reload();
    }
    if (newValue === 5) {
      this.props.history.push("/products/country:33+Cameroun/categorie:21?modes%20&%20vêtements");
      window.location.reload();
    }
    if (newValue === 6) {
      this.props.history.push("/products/country:33+Cameroun/categorie:9?Electroniques");
      window.location.reload();
    }
    if (newValue === 7) {
      this.props.history.push("/products/country:33+Cameroun/categorie:13?Multimedia");
      window.location.reload();
    }
  };

  onLogout = () => {
    this.props.dispatch(AuthService.logout())
      .then((result) => {
        this.props.history.push("/");
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  render() {
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);

    const { t } = this.props;
    let { errors } = this.state;

    return (
      <ThemeProvider theme={d_theme === "\"dark\"" ? darkTheme : lightTheme}>
        <>
          <GlobalStyles />
          <Helmet>
            <meta charSet="UTF-8" />
            <meta
              name="description"
              content="Ekomart-Grocery-Store(e-Commerce) HTML Template: A sleek, responsive, and user-friendly HTML template designed for online grocery stores."
            />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="keywords" content="Grocery, Store, stores" />
            <title>Wemaza</title>
            {/* <link rel="shortcut icon" type="image/x-icon" href="assets/images/fav.png" /> */}
            <link rel="shortcut icon" type="image/x-icon" href="assets/favicon.ico" />
            {/* plugins css */}
            {/* <link rel="stylesheet preload" href="assets/css/plugins.css" as="style" /> */}
            {/* <link rel="stylesheet preload" href="assets/css/style.css" as="style" /> */}
            {/* rts header area start */}
            <script defer src="assets/js/plugins.js"></script>
            <script defer src="assets/js/main.js"></script>
          </Helmet>

          <NewHeader
            allCategories={this.state.allCategories}
            fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
            cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
            idProf={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
            gotToParentValue={this.searchCityWithCallback}
            isShowSearch_t={true}
            searchProducts_t={this.state.searchProducts_t}
            searchNameWithCallback={this.searchNameWithCallback}
            onLogout={this.onLogout}
            onToggle_t={this.onToggle_t}

            propsCustom={this.props}
            allProductsBycategories={this.allProductsBycategories}
            includesForAllProducts_t={this.state.includesForAllProducts_t}
          />

          <div id="side-bar"
            className={this.state.toggleBool_t ? "side-bar header-two show" : "side-bar header-two"}
          >
            <button className="close-icon-menu"
              onClick={this.onToggle_t}>
              <IconButton aria-label="close">
                <CloseIcon />
              </IconButton>
            </button>
            <MobileCategoriesMenu
              prodId_t={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
              onLogout={this.onLogout}
              allCategories={this.state.allCategories}
              isShowSearch_t={true}
              fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
            />

          </div>

          <div className=" register_t productParentPage" style={{position: 'relative'}}>

            {/* <div className="showAndHiddenRespDesk_t">
              <HeaderMenu
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                option_city={this.state.option_city}
                city_id={this.state.city_id}
                // gotToParentValue={this.gotToParentValue}
                gotToParentValue={this.searchCityWithCallback}
                option_country={this.state.option_country}
                country_value={this.state.country_value}
                toggleBool_t={this.state.toggleBool_t}
                onToggle_t={this.onToggle_t}
                isAuth_t={this.props.isAuthenticated}
                onLogout={this.onLogout}
                showCountry={true}
                idProf={this.props.prodId_t !== undefined ? this.props.prodId_t.__id : ''}

                categorie_value={this.state.categorie_value}
                option_categorie={this.state.option_categorie}
                categorieDefault_value={this.state.categorieDefault_value}
                gotToParentCatValue={this.gotToParentCatValue}
                bool_val={this.state.bool_val}
              />



              <BandeBlanche />

              <HeaderUsers
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
                // gotToParentValue={this.gotToParentValue}
                gotToParentValue={this.searchCityWithCallback}
                isShowSearch_t={true}
                searchR_t={this.state.searchR_t}
                searchProducts_t={this.state.searchProducts_t}
                searchNameWithCallback={this.searchNameWithCallback}
                propsCustom={this.props}
                allProductsBycategories={this.allProductsBycategories}
                includesForAllProducts_t={this.state.includesForAllProducts_t}
              />
              <HeaderTop
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                options_fr={this.state.options_fr}
                options_eng={this.state.options_eng}
                options_devise={this.state.options_devise}
                device_data={this.state.device_data}
                // gotToParentValue={this.gotToParentValue}
                gotToParentValue={this.searchCityWithCallback}
                logo_noir={false}
                fName={this.props.prodId_t.__pfn !== undefined ? this.props.prodId_t.__pfn : ''}
              />

              <MenuCategiries
                tabsCatValue={this.props.tabsCatValueStore}
                handleChangeTabsCatValue={this.handleChangeTabsCatValue}
              />
            </div>
            <div className="showAndHiddenResp_t">

              <HeaderUsersMobile
                cartValue={this.props.cartValue}
                isAuthResponsive={false}
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                option_city={this.state.option_city}
                city_id={this.state.city_id}
                // gotToParentValue={this.gotToParentValue}
                gotToParentValue={this.searchCityWithCallback}
                option_country={this.state.option_country}
                country_value={this.state.country_value}
                toggleBool_t={this.state.toggleBool_t}
                onToggle_t={this.onToggle_t}
                isAuth_t={this.props.isAuthenticated}
                onLogout={this.onLogout}
                isLoadingHeader={this.state.isLoadingHeader}
                idProf={this.props.prodId_t !== undefined ? this.props.prodId_t.__id : ''}
                showCountry={true}
                isShowSearch_t={true}
                menuBottom={true}
              />

            </div> */}

            <ProductListPageComponent
              categorie_value={this.state.categorie_value}
              categorieDefault_value={this.state.categorieDefault_value}
              option_categorie={this.state.option_categorie}
              optionsResp={this.state.optionsResp}
              gotToParentCatValue={this.gotToParentCatValue}
              // gotToParentValue={this.gotToParentValue}
              gotToParentValue={this.searchCityWithCallback}
              categorieLabel={this.state.categorieLabel}
              parent_categorieLabel={this.parent_categorieLabel}
              parent_add_categorie={this.parent_add_categorie}
              onPrice_t={this.onPrice_t}
              onDropdownBtn_t={this.onDropdownBtn_t}
              dropdownBtn_t={this.state.dropdownBtn_t}
              onChange_t={(e) => this.onChange_t(e)}
              required={this.props.required}
              greater_than={this.state.greater_than}
              less_than={this.state.less_than}
              equal_t={this.state.equal_t}
              interval_t={this.state.interval_t}
              input2_bool_t={this.state.input2_bool_t}
              input1_bool_t={this.state.input1_bool_t}
              show_val_interv={this.state.show_val_interv}
              prix_t={this.state.prix_t}
              devise_t={this.state.devise_t}
              prix2_t={this.state.prix2_t}
              interval_error={this.state.interval_error}
              pagination={{
                enabled: true,
                type: "short" // long, short
              }}
              pageLength={this.state.count}
              total_pages={this.state.total_pages}
              current_page={this.state.current_page}
              data={this.state.products}
              gotToParentShowInput={this.gotToParentShowInput}
              onAddProduct={this.onAddProduct}
              index_t={this.state.index_t}
              products={this.state.products}
              bool_val={this.state.bool_val}
              textTriePrix={this.state.textTriePrix}
              showTrieBool_t={this.state.showTrieBool_t}
              onProductId={this.onProductId}
              paginationProductValue={this.state.paginationProductValue}
              filterAllProducts={this.filterAllProducts}
              filterAllProductsInfiniscroll={this.filterAllProductsInfiniscroll}
              limit={this.state.limit}
              offline={this.state.offline}
              pageNo={this.state.pageNo}
              categoriesFucnt={this.state.categoriesFucnt}
              city_idFucnt={this.state.city_idFucnt}
              country_idFucnt={this.state.country_idFucnt}
              price_minFucnt={this.state.price_minFucnt}
              price_maxFucnt={this.state.price_maxFucnt}
              nameFucnt={this.state.nameFucnt}
              hasMore={this.state.hasMore}
              handleShowAlert={this.handleShowAlert}
              handleClose={this.handleClose}
              open={this.state.open}
              homeCartvalue_t={this.props.homeCartvalue_t}
              handleMinChange={this.handleMinChange}
              handleMaxChange={this.handleMaxChange}
              maxFieldSetInputValue={this.state.maxFieldSetInputValue}
              minFieldSetInputValue={this.state.minFieldSetInputValue}
              handleGoClick={this.handleGoClick}
              showAlertHProd={this.props.showAlertHProd}
            />

          </div>
        </>
      </ThemeProvider>
    );
  }
}
export default withTranslation()(Page);