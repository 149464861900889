import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './pagination.css';

const PaginationClass = (props) => {

    const renderPaginationItems = () => {
        const items = [];
        const visiblePages = 4; // Nombre de pages visibles entre les 3 points

        // Afficher le bouton "<<" pour revenir à la première page
        items.push(
            <PaginationItem key="<<">
                <PaginationLink onClick={() => props.handlePageChange(1)}>&laquo;</PaginationLink>
            </PaginationItem>
        );

        // Afficher le bouton "<" pour aller à la page précédente
        items.push(
            <PaginationItem key="<">
                <PaginationLink onClick={() => props.handlePageChange(props.currentPage - 1)}>
                    &lt;
                    précédent
                </PaginationLink>
            </PaginationItem>
        );

        // Afficher le premier nombre de page
        items.push(
            props.totalPages === 0 ?
                null
                :
                <PaginationItem key={1} active={props.currentPage === 1}>
                    <PaginationLink onClick={() => props.handlePageChange(1)}>1</PaginationLink>
                </PaginationItem>
        );

        // Afficher les trois points (...) si nécessaire
        if (props.currentPage > visiblePages) {
            items.push(
                <PaginationItem key="ellipsis-start" disabled>
                    <PaginationLink>...</PaginationLink>
                </PaginationItem>
            );
        }

        // Afficher les nombres de page entre les trois points
        const start = Math.max(2, props.currentPage - Math.floor(visiblePages / 2));
        const end = Math.min(props.totalPages - 1, start + visiblePages - 1);
        for (let i = start; i <= end; i++) {
            items.push(
                <PaginationItem key={i} active={props.currentPage === i}>
                    <PaginationLink onClick={() => props.handlePageChange(i)}>{i}</PaginationLink>
                </PaginationItem>
            );
        }

        // Afficher les trois points (...) à la fin si nécessaire
        if (props.currentPage < props.totalPages - visiblePages + 1) {
            items.push(
                <PaginationItem key="ellipsis-end" disabled>
                    <PaginationLink>...</PaginationLink>
                </PaginationItem>
            );
        }

        // Afficher le dernier nombre de page

        items.push(
            props.totalPages === 1 ?
                null
                :
                <PaginationItem key={props.totalPages} active={props.currentPage === props.totalPages}>
                    <PaginationLink onClick={() => props.handlePageChange(props.totalPages)}>{props.totalPages}</PaginationLink>
                </PaginationItem>
        )

        // Afficher le bouton ">" pour aller à la page suivante
        items.push(
            props.totalPages === 1 ?
                <PaginationItem key=">">
                    <PaginationLink onClick={() => console.log(null)}>
                        suivant
                        &gt;
                    </PaginationLink>
                </PaginationItem>
                :
                <PaginationItem key=">">
                    <PaginationLink onClick={() => props.handlePageChange(props.currentPage + 1)} >
                        suivant
                        &gt;
                    </PaginationLink>
                </PaginationItem>
        );

        // Afficher le bouton ">>" pour aller à la dernière page
        items.push(
            <PaginationItem key=">>">
                <PaginationLink onClick={() => props.handlePageChange(props.totalPages)}>&raquo;</PaginationLink>
            </PaginationItem>
        );

        return items;
    };


    return (

        <Pagination className="justify-content-center">
            {renderPaginationItems()}
        </Pagination>
    );
};

export default PaginationClass;
