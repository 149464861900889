import React from 'react';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Button from '@mui/material/Button';

function CartButton() {
  return (
    <Button
      variant="text"
      startIcon={
        <Badge badgeContent={3} color="success">
          <ShoppingCartIcon />
        </Badge>
      }
      style={{ textTransform: 'none', color: '#4E4E4E' }}
    >
      Mon Panier
    </Button>
  );
}

export default CartButton;
