import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import LoadingDifferent from '../../components/LoadingDifferent';

class LoginBtn extends Component {
	constructor(props) {
		super(props);
    this.state = {
      styleLoading: 'loading_dataTable2',
      type: 'spin',
      height: props.height,
      width: props.width
    }
	}
	render(){
        	const { t } = this.props;
        	return(
                  <button  
                      className={this.props.className}
                      type="submit">
                      {this.props.isLoading ? 
                              <>
                              {/*<div className="bnt_and_load_t">
                              {t(this.props.value)} 
                              </div>*/}
                              <span className="bnt_and_load_t">
                              {t(this.props.value)} 
                              </span>
                              <div>
                              <LoadingDifferent  
                                type={this.state.type} 
                                styleLoading={this.state.styleLoading} 
                                height={this.state.height} 
                                width={this.state.width}
                              /> 
                              </div>
                              </>
                              :
                              <span className={this.props.btn_t}>
                                {t(this.props.value)} 
                              </span>
                          }
                    </button>
        	);
        }
}
export default withTranslation()(LoginBtn);