import Http from '../../Http'
import * as action from '../../store/actions'

export function login(credentials) {
    let formdata = new FormData();

    formdata.append('email', credentials.email);
    formdata.append('password', credentials.password);

    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`/clients/web/admin/login`, formdata)
                .then(function (res) {
                    // handle success           
                    dispatch(action.authLogin(res.data));
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // network error (server is down or no internet)
                        const data = {
                            notNetwork: true
                        };
                        return reject(data)
                    } else {
                        const statusCode = err.response;
                        const data = {
                            error: null,
                            statusCode: err,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // status 401 means unauthorized
                            // status 422 means unprocessable entity
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }

                })

        })
    )
};

export function socialLogin(credentials) {
    let formdata = new FormData();

    formdata.append('email', credentials.email);
    formdata.append('social_provider', credentials.social_provider);
    formdata.append('social_id', credentials.social_id);
    formdata.append('social_nickname', credentials.social_nickname);
    formdata.append('social_token', credentials.social_token);
    formdata.append('social_refresh_token', credentials.social_refresh_token);

    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`/login-using-social-account`, formdata)
                .then(function (res) {
                    // handle success           
                    dispatch(action.authLogin(res.data));
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};

export function register(data) {
    let formdata = new FormData();

    formdata.append('lastname', data.lastname);
    formdata.append('firstname', data.firstname);
    formdata.append('country_id', data.country_id);
    formdata.append('address', data.address);
    formdata.append('email', data.email);
    formdata.append('phone', data.phone);
    formdata.append('password', data.password);
    formdata.append('gender', data.gender);
    formdata.append('birth', data.birth);

    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`/register`, formdata)
                .then(function (res) {
                    dispatch(action.authLogin(res));
                    return resolve(res);
                })
                .catch(function (err) {
                    if (!err.response) {
                        // network error (server is down or no internet)
                        const data = {
                            notNetwork: true
                        };
                        return reject(data)
                    } else {
                        const statusCode = err.response;
                        const data = {
                            error: null,
                            statusCode: err,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // status 401 means unauthorized
                            // status 422 means unprocessable entity
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                })

        })
    )
};

export function profile() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/user/profile?include=provider`)
                .then(function (res) {
                    // handle success     
                    const data = res.data.data
                    const profileD = {
                        ln: data.lastname,
                        fn: data.firstname,
                        email: data.email,
                        _ipvkd: data.provider.data.real_id,
                        ipvkds: data.country_id,
                        __ridu: data.real_id
                    }
                    dispatch(action.profile(profileD));
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};

export function logout() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.delete(`/logout`)
                .then(function (res) {
                    // handle success             
                    dispatch(action.authLogout());
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error=
                    return reject(err);
                })

        })
    )
};

export function getAllCountries() {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/countries`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // network error (server is down or no internet)
                        const data = {
                            notNetwork: true
                        };
                        return reject(data)
                    } else {
                        const statusCode = err.response;
                        const data = {
                            error: null,
                            statusCode: err,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // status 401 means unauthorized
                            // status 422 means unprocessable entity
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                })

        })
    )
};

export function searchCountry(real_id) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/countries?search=id:${real_id}`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};

export function getCitiesCountry(hash_id) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/get-all-cities/${hash_id}`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};