import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class ProductList extends Component {
  constructor(props) {
    super();
  }
  render() {
    const { t,
      cartValue,
      onQty_t,
      goToParentCoefAndPrice,
      onDeleteProduct,
      sum_t
    } = this.props;
    let percent_t
    let priceArroundit
    let diffBetweenPriceAndReduction
    let reductionArroundit
    let formuleDeReductionEnPourcentage

    let productsList = cartValue.length > 0 && cartValue.map((v, i) => {
      priceArroundit = parseFloat((parseInt(v.price) / 650).toFixed(2))
      reductionArroundit = parseFloat((parseInt(v.reduction) / 650).toFixed(2))
      diffBetweenPriceAndReduction = parseFloat(((parseInt(v.price) - parseInt(v.reduction)) / 650).toFixed(2))


      percent_t = parseFloat((((v.price - v.reduction) / v.price) * 100).toFixed(2))
      formuleDeReductionEnPourcentage = parseFloat((((parseInt(v.price) - parseInt(v.reduction)) / parseInt(v.price)) * 100).toFixed(2))
      goToParentCoefAndPrice({
        id: v.id,
        price: v.price,
        reduction: v.reduction,
        qtyValue_t: 1,
        // qtyValue_t: v.qty,
        productsPrice: parseInt(v.qty) * (parseInt(v.price) - parseInt(v.reduction))
      })
      return (
        <div key={i} className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-6 mr-3 cart_border_t cart_list_t">
          <div className="row" style={{ border: '1px solid #E5E5E5', 'border-radius': '15px' }}>
            <div className="col-12 col-md-3 col-lg-2">
              <img
                src={v.image}
                alt="Image prod"
                className="img_cart_t" />
            </div>
            <div className="col col-md-7 col-lg-8 cart_marg_img_text">
              <span className="isShow_t">{v.shopName.toUpperCase()}

              </span>
              <div className="cart_inc_right_t">
                <span>
                  <input
                    key={i}
                    type="number"
                    value={v.qty}
                    onChange={(e, real_id, price, id, reduction, totalQty, qty, image, productName, shopName, country_real_id, index) => onQty_t(e, v.real_id, v.price, v.id, v.reduction, v.totalQty, v.qty, v.image, v.productName, v.shopName, v.country_real_id, i)}
                    className="numCart_t" />
                </span>
                <span className="cart_green_price_left_t">
                  {/* {
                    parseInt(v.reduction) === 0 ?
                      parseInt(v.qty) * (priceArroundit)
                      :
                      parseInt(v.qty) * (reductionArroundit)
                  } € */}
                  {parseFloat(v.qty * v.usedPrice).toFixed(2)} €
                  {/* {sum_t} */}
                </span>
                <a
                  href="javascript:;"
                  onClick={(e, real_id) => onDeleteProduct(e, v.real_id)}
                >
                  <img
                    src={require("../../../../images/delete.png")}
                    alt="delete"
                    className="delete_t" />
                </a>
              </div>

              <span className="cart_desc">
                {v.productName}
              </span>



              <span className="product_gd_ran">
                <span className="cart_left_t">
                  {parseFloat((v.usedPrice).toFixed(2))} €
                </span>
                <span className="grp2_t">
                  <span className={parseInt(v.reduction) === 0 ? "cart_right_t" : "cartUnderLineright_t"}>
                    {parseFloat(parseInt(v.reduction).toFixed(0)) === 0 ?
                      ''
                      :
                      `${parseFloat((v.price).toFixed(2))} €`

                    }
                  </span>
                  {/*<span className="percent_t">{parseInt(v.reduction) === 0 ? "0%" : `-${Math.round(parseInt(100) - percent_t)}%`}</span>*/}
                  <span className="percent_t">{
                    parseFloat(parseInt(v.reduction).toFixed(0)) === 0 ? "0%" : `- ${Math.round(percent_t)}%`}</span>
                </span>
              </span>


            </div>
          </div>
        </div>
      )
    }
    );
    return (
      <>
        {productsList}
      </>
    );
  }
}
export default withTranslation()(ProductList);